import { MenuEventArgs } from "@syncfusion/ej2-navigations";
import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import { DropDownButtonComponent, ItemModel } from "@syncfusion/ej2-react-splitbuttons";
import moment from "moment";
import { msalInstance } from "../../..";
import { GridColumnTemplates, GridColumnWidth } from "../../../RAFComponents/Grid/RAFSFGridUtils";
import RAFDetailFieldCustom from "../../../RAFComponents/Navigation/RAFDetailFieldCustom";
import RAFDetailsValueWithSeparator from "../../../RAFComponents/Navigation/RAFDetailsValueWithSeparator/RAFDetailsValueWithSeparator";
import RAFRecordInfo from "../../../RAFComponents/Navigation/RAFRecordInfo";
import { isCurrentTenantCareESIOPartner } from "../../../RAFComponents/helpers/AppHelper";
import { IsNotNullOrWhiteSpace, getFileExtension, isNotEmptyArray, isNotNullAndUndefined, isNullOrUndefined } from "../../../RAFComponents/helpers/utils";
import { QueryAttributeJM } from "../../../RAFComponents/models/Common/QueryAttributeJM";
import { RAFDataType } from "../../../RAFComponents/models/Common/RAFDataType";
import { RAFButtonConstant, RAFDocumentType, RAFInputType, RAFTenantName, getDocumentTypeDisplayName } from "../../../constants/Common/Constants";
import { RAFEntityName } from "../../../constants/Common/EntityConstants";
import { ContentLibraryCurrentStatus, getFileSize } from "./Library/DocumentHelper";

export const DocumentGridColumnTemplates: GridColumnTemplates[] = [
    { key: "DisplayName", value: "documentTableDisplayNameTemplate" },
    { key: "ActionButton", value: "documentActionButtonTemplate" },
    { key: "DueDate", value: "dueDateTemplate" },
    { key: "ModifiedDate", value: "dateTimeTemplate" },
    { key: "CreatedDate", value: "dateTimeTemplate" },
    { key: "TagsListJson", value: "tagsTemplate" },
    { key: "Category", value: "dropdownTemplate" },
    //{ key: "RecordCategory", value: "defaultDropdownTemplate" },
    { key: "RecordType", value: "dropdownTemplateAsDisc" },
    { key: "Entity", value: "dropdownTemplate" },
    { key: "FileSize", value: "documentFileSizeTemplate" },
];

export const DocumentCategoryColumnTemplates: GridColumnTemplates[] = [
    { key: "Title", value: "DocumentCategoryTemplate" },
];

export const DocumentCategoryTableColumnTemplates: GridColumnTemplates[] = [
    { key: "Title", value: "DocumentTableCategoryTemplate" },
];

export const DocumentListGridColumnTemplates: GridColumnTemplates[] = [
    { key: "DisplayName", value: "documentRowDisplayNameTemplate" },
    // { key: "DisplayName", value: "documentDisplayNameTemplate" },
];
export const relatedDocumentListGridColumnTemplates: GridColumnTemplates[] = [
    //{ key: "DisplayName", value: "relatedDocumentRowDisplayNameTemplate" },
    { key: "DisplayName", value: "documentRowDisplayNameTemplate" },
];
export const DocumentListGridColumnTemplates1: GridColumnTemplates[] = [
    { key: "DisplayName", value: "documentDisplayNameListTemplate" },
];

export const ContentLibraryColumnWidths: GridColumnWidth[] = [
    { field: "DisplayName", width: "400px" },
    { field: "FileName", width: "400px" },
];


export const documentDisplayNameListTemplate = (
    moduleName: string,
    allowEditing: boolean,
    showEdit: boolean,
    item: QueryAttributeJM,
    isDynamic: boolean,
    data,
    navigate: any,
    onActionMenuClicked: (data: any, selectedMenu: MenuEventArgs) => void
) => {
    const fieldName = data.column.field;
    const fieldValue = data[fieldName];
    const createdBy = data["CreatedBy"];
    const description = data["Notes"];
    const createdDate = data["CreatedDate"];
    const modifiedDate = data["ModifiedDate"];
    const modifiedBy = data["ModifiedBy"];
    const lastActivityDate = data["LastActivityDate"];
    const documentType: string = data["DocumentType"];
    const category = data["RecordCategory"];
    const isSystem: boolean = data["IsSystem"];
    const tags = data["TagsListJson"];
    let relatedToUID = data["RelatedToUID"];
    let versionNumber = data["Revision"];
    let dueDate = data["DueDate"];
    //const fileSize = getFileSize(getFileSize)
    let fileSize = getFileSize(data["FileSize"]);
    let fileClassname;
    let showMoreButton = true;

    const fileNameExtension = data["FileName"];
    const fileExtension = getFileExtension(fileNameExtension);
    if (isNotNullAndUndefined(fileExtension)) {
        fileClassname = "fiv-viv fiv-icon-blank fiv-icon-" + fileExtension;
    }

    if (isNotNullAndUndefined(fieldValue)) {
        let menuItems = [];

        return (
            <div className="bg-white p-3 py-2 border-bottom">
                <div className="gridTempleteTextOuter my-1">
                    <span className="header-text-sm">{fieldValue}</span>
                </div>
            </div>
        );
    }
    return;
};


export const documentFileSizeTemplate = (
    moduleName: string,
    allowEditing: boolean,
    showEdit: boolean,
    item: QueryAttributeJM,
    isDynamic: boolean,
    data,
    navigate: any,
    onActionMenuClicked: (data: any, selectedMenu: MenuEventArgs) => void
) => {
    let fieldValue = data["FileSize"];
    if (isNotNullAndUndefined(fieldValue)) {
        let fileSize = getFileSize(fieldValue);

        return (
            <div className="gridDefault d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center">
                    <span className="">{fileSize}</span>
                </div>
                {showEdit === true && allowEditing === true && (
                    <div className="gridEditBtn">
                        <ButtonComponent
                            type="button"
                            className="e-flat btndefault-icon e-editbutton"
                            iconCss="fal fa-pencil"
                        />
                    </div>
                )}
            </div>
        );
    }
    return;
};

export const documentDisplayNameTemplate = (
    moduleName: string,
    allowEditing: boolean,
    showEdit: boolean,
    item: QueryAttributeJM,
    isDynamic: boolean,
    data,
    navigate: any,
    onActionMenuClicked: (data: any, selectedMenu: MenuEventArgs) => void
) => {
    const fieldName = data.column.field;
    const fieldValue = data[fieldName];
    const createdBy = data["CreatedBy"];
    const createdDate = data["CreatedDate"];
    const modifiedDate = data["ModifiedDate"];
    const modifiedBy = data["ModifiedBy"];
    const lastActivityDate = data["LastActivityDate"];
    const documentType: string = data["DocumentType"];
    const recordType: string = data["RecordType"];
    const isSystem: boolean = data["IsSystem"];
    const tags = data["TagsListJson"];
    let versionNumber = data["Revision"];
    let dueDate = data["DueDate"];
    //const fileSize = getFileSize(getFileSize)
    let fileSize = getFileSize(data["FileSize"]);
    let fileClassname;

    const fileNameExtension = data["FileName"];
    const fileExtension = getFileExtension(fileNameExtension);

    if (isNotNullAndUndefined(fieldValue)) {
        if (documentType === RAFDocumentType.Folder) {
            fileClassname = "fiv-viv-lg fiv-viv fiv-icon-folder";
        } else if (documentType === RAFDocumentType.File) {
            if (isNotNullAndUndefined(fileExtension)) {
                fileClassname =
                    "fiv-viv-lg fiv-viv fiv-icon-blank fiv-icon-" + fileExtension;
            }
        } else if (documentType === RAFDocumentType.Page) {
            fileClassname = "fiv-viv-lg fiv-viv fiv-icon-blank fiv-icon-wiki";
        }
        return (
            <div className="listGridItemOutter">
                <div className="listGridItemContent">
                    <div className="listGridItem">
                        <div className={""}>
                            <div className="" id={`care_Recipient_${data["UID"]}`}>
                                <div className="row gx-3">
                                    <div className="col-auto d-flex align-items-center">
                                        <div className="fiv-viv-outer">
                                            <span className={fileClassname}></span>
                                        </div>
                                    </div>
                                    <div className="col ps-1">
                                        <div className="subtitle_1">
                                            <span className="subtitle_1 ecllipseFirstLine text-wrap word-break-all semi_bold">
                                                {fieldValue}
                                            </span>
                                        </div>
                                        <RAFDetailsValueWithSeparator rowClassName="flex-nowrap gx-3 gy-2">
                                            {isNotNullAndUndefined(fileSize) && (
                                                <div className="body_2_light">{fileSize}</div>
                                            )}
                                            {false && (
                                                <div className="body_2_light">
                                                    <RAFDetailFieldCustom
                                                        field="RecordCategory"
                                                        title="Category"
                                                        value={data["RecordCategory"]}
                                                        fieldFormat={{
                                                            type: RAFDataType.Dropdown,
                                                            format: RAFInputType.LookupDropdownList,
                                                        }}
                                                        rowClassName="gy-1"
                                                        showLabel={false}
                                                        valueClassName="body_2_light ecllipseFirstLine"
                                                    ></RAFDetailFieldCustom>
                                                </div>
                                            )}
                                        </RAFDetailsValueWithSeparator>
                                        {/* <div className="d-flex">
                            {isNotNullAndUndefined(recordType) && (
                              <div className="body_2_dark">
                                <RAFDetailFieldCustom
                                  value={recordType}
                                  displayValue={recordType}
                                  title="RecordType"
                                  moduleName={RAFEntityName.ContentLibrary}
                                  isColorOption
                                  mode="discView"
                                  field="RecordType"
                                  showLabel={false}
                                />
                              </div>
                            )}
                            {isNotNullAndUndefined(recordType) &&
                              isNotNullAndUndefined(fileSize) && (
                                <div className="mx-2">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="3"
                                    height="4"
                                    viewBox="0 0 3 4"
                                    fill="none"
                                  >
                                    <circle
                                      cx="1.5"
                                      cy="2"
                                      r="1.5"
                                      fill="#A3A3A3"
                                    />
                                  </svg>
                                </div>
                              )}
                            {isNotNullAndUndefined(fileSize) && (
                              <div className="body_2_dark">{fileSize}</div>
                            )}
                            {isNotNullAndUndefined(fileSize) &&
                              isNotNullAndUndefined(versionNumber) && (
                                <div className="mx-2">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="3"
                                    height="4"
                                    viewBox="0 0 3 4"
                                    fill="none"
                                  >
                                    <circle
                                      cx="1.5"
                                      cy="2"
                                      r="1.5"
                                      fill="#A3A3A3"
                                    />
                                  </svg>
                                </div>
                              )}
                            {isNotNullAndUndefined(versionNumber) &&
                              documentType !== RAFDocumentType.Folder && (
                                <div className="body_2_dark">
                                  <span>{`v${versionNumber}.0`}</span>
                                </div>
                              )}
                          </div> */}
                                    </div>
                                    <div className="col-auto noRowclick">
                                        <DropDownButtonComponent
                                            items={[
                                                { id: "Edit", text: "Edit" },
                                                { id: "Delete", text: "Delete" },
                                            ]}
                                            cssClass="bg-transparent border-0 e-caret-hide custom-button-sm"
                                            select={(selectedMenu) => {
                                                if (onActionMenuClicked) {
                                                    onActionMenuClicked(data, selectedMenu);
                                                }
                                            }}
                                            iconCss={RAFButtonConstant.More.IconCss}
                                        ></DropDownButtonComponent>
                                    </div>
                                </div>
                            </div>
                            <div className="mt-3 custom__card__separator custom__card__separator__light"></div>
                            <div className="pt-2">
                                <div className="body_3_light medium pb-0-5">Tags:</div>
                                {isNotEmptyArray(tags) ? (
                                    <RAFDetailFieldCustom
                                        field="TagsListJson"
                                        title="Tags"
                                        value={data["TagsListJson"]}
                                        enableChipArray={{ visible: true, uiType: "Badge" }}
                                        rowClassName="g-1"
                                        showLabel={false}
                                    ></RAFDetailFieldCustom>
                                ) : (
                                    <span className="body_2_dark">Not set</span>
                                )}
                            </div>
                        </div>
                        {false && (
                            <div className="row gx-3 flex-nowrap">
                                <div className="col-auto document-item-icon">
                                    <span className={fileClassname}></span>
                                </div>
                                <div className="col overflow-auto">
                                    <div className="row g-0 gy-2">
                                        <div className="col-12">
                                            <div className="gridTempleteTextOuter">
                                                <span className="header-text-sm">{fieldValue}</span>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="detailsLeftCard-second-title h-auto row-d-inline-block">
                                                <div className="row gx-2 flex-nowrap align-items-center">
                                                    {isNotNullAndUndefined(recordType) ? (
                                                        <div className="col-auto detailsLeftCard-second-title-col">
                                                            <div className="detailsLeftCard-second-title-item">
                                                                <RAFDetailFieldCustom
                                                                    value={recordType}
                                                                    displayValue={recordType}
                                                                    title="RecordType"
                                                                    moduleName={RAFEntityName.ContentLibrary}
                                                                    isColorOption
                                                                    mode="discView"
                                                                    field="RecordType"
                                                                    showLabel={false}
                                                                />
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        ""
                                                    )}
                                                    {false && isNotNullAndUndefined(documentType) ? (
                                                        <div className="col-auto detailsLeftCard-second-title-col">
                                                            <div className="detailsLeftCard-second-title-item">
                                                                <RAFDetailFieldCustom
                                                                    value={
                                                                        isNotNullAndUndefined(documentType)
                                                                            ? documentType
                                                                            : "Not set"
                                                                    }
                                                                    displayValue={getDocumentTypeDisplayName(
                                                                        documentType
                                                                    )}
                                                                    title="DocumentType"
                                                                    moduleName={RAFEntityName.ContentLibrary}
                                                                    isColorOption
                                                                    field="DocumentType"
                                                                    showLabel={false}
                                                                    // valueDivClassName="custom_key_value_sm"
                                                                    mode="outlineView"
                                                                />
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        ""
                                                    )}
                                                    {documentType === RAFDocumentType.File &&
                                                        isNotNullAndUndefined(fileSize) && (
                                                            <div className="col-auto detailsLeftCard-second-title-col ecllipseFirstLine">
                                                                <div className="detailsLeftCard-second-title-item">
                                                                    <div className="ecllipseFirstLine">
                                                                        <span>{fileSize}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}
                                                    {/* {isNotNullAndUndefined(category) ? (
                            <div className="col-auto detailsLeftCard-second-title-col">
                              <div className="detailsLeftCard-second-title-item">
                                <RAFDetailFieldCustom
                                  value={
                                    isNotNullAndUndefined(category) ? category : "Not set"
                                  }
                                  title="category"
                                  moduleName={RAFEntityName.ContentLibrary}
                                  field="category"
                                  showLabel={false}
                                  enableChipArray={{ visible: true, uiType: 'showFirstItem' }}
                                />
                              </div>
                            </div>
                          ) : (
                            ""
                          )} */}
                                                    {isNotNullAndUndefined(versionNumber) &&
                                                        documentType !== RAFDocumentType.Folder && (
                                                            <div className="col-auto detailsLeftCard-second-title-col ecllipseFirstLine">
                                                                <div className="detailsLeftCard-second-title-item">
                                                                    <div className="ecllipseFirstLine">
                                                                        <span>{`v${versionNumber}.0`}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}
                                                    {isNotNullAndUndefined(dueDate) && (
                                                        <div className="col-auto detailsLeftCard-second-title-col ecllipseFirstLine">
                                                            <div className="detailsLeftCard-second-title-item">
                                                                <div className="ecllipseFirstLine">
                                                                    <span>
                                                                        {"Due: " +
                                                                            moment(dueDate).format("DD/MM/YYYY")}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                    <div className="col-auto detailsLeftCard-second-title-col ecllipseFirstLine">
                                                        <div className="detailsLeftCard-second-title-item">
                                                            <div className="ecllipseFirstLine noRowclick">
                                                                <RAFRecordInfo
                                                                    createdBy={createdBy}
                                                                    createdDate={createdDate}
                                                                    modifiedDate={modifiedDate}
                                                                    modifiedBy={modifiedBy}
                                                                    lastActivityDate={lastActivityDate}
                                                                    dropdownBtnClassName="unset-custom-button-md"
                                                                    hideClockIcon
                                                                ></RAFRecordInfo>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* {documentType === RAFDocumentType.Page && (
                  <div className="col-auto d-flex align-items-center">
                    <RAFButtonComponent
                      action={RAFButtonConstant.Edit}
                      btnContent="Designer"
                      className="link-button e-custom-actionbutton"
                    />
                  </div>
                )}
                {documentType === RAFDocumentType.File && (
                  <div className="col-auto d-flex align-items-center">
                    <RAFButtonComponent
                      action={RAFButtonConstant.Download}
                      className="link-button e-custombutton"
                    />
                  </div>
                )} */}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    }
    return;
};

export const documentRowDisplayNameTemplate = (
    moduleName: string,
    allowEditing: boolean,
    showEdit: boolean,
    item: QueryAttributeJM,
    isDynamic: boolean,
    data,
    navigate: any,
    onActionMenuClicked: (data: any, selectedMenu: MenuEventArgs) => void
) => {
    const fieldValue = data["DisplayName"];
    const documentType: string = data["DocumentType"];
    const currentStatus = data["CurrentStatus"];
    //const fileSize = getFileSize(getFileSize)
    let fileSize = getFileSize(data["FileSize"]);
    let fileClassname;
    let moreMenuItem;
    let outerClassname = "fiv-viv-outer";

    let versionNumber = data["Revision"];

    const createdBy = data["CreatedBy"];
    const createdDate = data["CreatedDate"];
    const modifiedDate = data["ModifiedDate"];
    const modifiedBy = data["ModifiedBy"];
    const lastActivityDate = data["LastActivityDate"];

    const fileNameExtension = data["FileName"];
    const fileExtension = getFileExtension(fileNameExtension);
    if (isNotNullAndUndefined(fileExtension)) {
        fileClassname =
            "fiv-viv-lg fiv-viv fiv-icon-blank fiv-icon-" + fileExtension;

    }

    const isCareESIOTenant = (msalInstance.currentTenantName === RAFTenantName.CareESIO || isCurrentTenantCareESIOPartner() === true) ? true : false;

    if (isNotNullAndUndefined(fieldValue)) {
        if (documentType === RAFDocumentType.Folder) {
            fileClassname = "fas fa-folder-open content_state_warning_base fa-md-noraml";
            outerClassname = `${outerClassname} surface_state_warning_light`;
            moreMenuItem = [
                { id: "FileProperties", text: "Details", iconCss: "far fa-file" },
            ];
        }
        else if (documentType === RAFDocumentType.Page) {
            fileClassname = "far fa-file-invoice content_brand_tertiary_base fa-md-noraml";
            outerClassname = `${outerClassname} surface_brand_tertiary_light`;
            if (isNullOrUndefined(currentStatus) || currentStatus === ContentLibraryCurrentStatus.Published) {
                moreMenuItem = [
                    //{ id: "View", text: "View", iconCss: "fal fa-eye" },
                    { id: "Detail", text: "Details", iconCss: "far fa-file" },
                ];
                if (isCareESIOTenant) {
                    moreMenuItem.push(
                        { id: "RequestAcknowledgement", text: "Request Acknowledgement", iconCss: "fal fa-user" },
                    );
                }
            } else {
                moreMenuItem = [
                    //{ id: "View", text: "View", iconCss: "fal fa-eye" },
                    { id: "Detail", text: "Details", iconCss: "far fa-file" },
                ];
            }

        }
        else if (documentType === RAFDocumentType.Collection) {
            fileClassname = "fas fa-books content_brand_primary_base fa-md-noraml";
            outerClassname = `${outerClassname} surface_brand_primary_light`;
            moreMenuItem = [
                { id: "FileProperties", text: "Details", iconCss: "far fa-file" },
            ];
        }
        else if (documentType === RAFDocumentType.File) {
            if (isNullOrUndefined(currentStatus) || currentStatus === ContentLibraryCurrentStatus.Published) {
                moreMenuItem = [
                    { id: "Detail", text: "Details", iconCss: "far fa-file" },
                ];
                if (isCareESIOTenant) {
                    moreMenuItem.push(
                        { id: "RequestAcknowledgement", text: "Request Acknowledgement", iconCss: "fal fa-user" },
                    );
                }
            } else {
                moreMenuItem = [
                    { id: "FileProperties", text: "Details", iconCss: "far fa-file" },
                ];
            }
        }

        return (
            <div className="listGridItemOutter">
                <div className="listGridItemContent border_base"
                >
                    <div className="listGridItem">
                        <div className="" id={`care_Recipient_${data["UID"]}`}>
                            <div className="row gx-3 align-items-center">
                                <div className="col-auto d-flex align-items-center">
                                    <div className={outerClassname}>
                                        <span className={fileClassname}></span>
                                    </div>
                                </div>
                                <div className="col ps-1">
                                    <div className="d-flex align-items-center flex-nowrap">
                                        <div className="subtitle_2 mb-1">
                                            <span className="subtitle_2 ecllipseFirstLine text-wrap word-break-all semi_bold">
                                                {fieldValue}
                                            </span>
                                        </div>
                                        {isNotNullAndUndefined(versionNumber) && (
                                            <div className="ms-1">
                                                <span className="raf_badge raf_badge_neutral">{`${versionNumber}.0.0`}</span>
                                            </div>
                                        )}
                                    </div>
                                    <RAFDetailsValueWithSeparator
                                        rowClassName="flex-nowrap gx-2 gy-2"
                                        outerClassName="h-auto"
                                    >
                                        <div className="body_3_light">{documentType}</div>
                                        {IsNotNullOrWhiteSpace(data["FileSize"]) && (
                                            <div className="body_3_light">{fileSize}</div>
                                        )}
                                        <RAFRecordInfo
                                            createdBy={createdBy}
                                            createdDate={createdDate}
                                            modifiedDate={modifiedDate}
                                            modifiedBy={modifiedBy}
                                            lastActivityDate={lastActivityDate}
                                        ></RAFRecordInfo>
                                    </RAFDetailsValueWithSeparator>
                                </div>
                                <div className="col-auto">
                                    <div className="row gx-2">
                                        <div className="col-auto noRowclick"
                                            onClick={(e) => e.stopPropagation()}
                                        >
                                            <DropDownButtonComponent
                                                items={moreMenuItem}
                                                cssClass="new_style raf_sm e-caret-hide btn_neutral outline"
                                                select={(selectedMenu) => {
                                                    onActionMenuClicked(data, selectedMenu);
                                                }}
                                                iconCss={`${RAFButtonConstant.More.IconCss} fa- mt-0`}
                                            ></DropDownButtonComponent>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="listGridItemFooter">
                        <div className="row gx-2 align-items-center">
                            <div className="col-auto">
                                <div className="body_3_light">Tags:</div>
                            </div>

                            <div className="col">
                                <RAFDetailFieldCustom
                                    field="TagsListJson"
                                    title="Tags"
                                    value={data["TagsListJson"]}
                                    enableChipArray={{ visible: true, uiType: "Badge" }}
                                    rowClassName="g-1"
                                    valueClassName={"body_3_light"}
                                    showLabel={false}
                                ></RAFDetailFieldCustom>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    return;
};

export const relatedDocumentRowDisplayNameTemplate = (
    moduleName: string,
    allowEditing: boolean,
    showEdit: boolean,
    item: QueryAttributeJM,
    isDynamic: boolean,
    data,
    navigate: any,
    onActionMenuClicked: (data: any, selectedMenu: MenuEventArgs) => void
) => {
    const fieldName = data.column.field;
    const fieldValue = data[fieldName];
    const createdBy = data["CreatedBy"];
    const createdDate = data["CreatedDate"];
    const modifiedDate = data["ModifiedDate"];
    const modifiedBy = data["ModifiedBy"];
    const lastActivityDate = data["LastActivityDate"];
    const documentType: string = data["DocumentType"];
    let versionNumber = data["Revision"];
    //const fileSize = getFileSize(getFileSize)
    const currentStatus = data["CurrentStatus"];
    let fileSize = getFileSize(data["FileSize"]);
    let fileClassname;
    let moreMenuItem;
    let outerClassname = "fiv-viv-outer";

    const fileNameExtension = data["FileName"];
    const fileExtension = getFileExtension(fileNameExtension);
    if (isNotNullAndUndefined(fileExtension)) {
        fileClassname =
            "fiv-viv-lg fiv-viv fiv-icon-blank fiv-icon-" + fileExtension;

    }
    if (isNotNullAndUndefined(fieldValue)) {
        if (documentType === RAFDocumentType.Folder) {
            fileClassname = "fas fa-folder-open content_state_warning_base fa-md-noraml";
            outerClassname = `${outerClassname} surface_state_warning_light`;
            moreMenuItem = [
                { id: "FileProperties", text: "Details", iconCss: "far fa-file" },
            ];
        }
        else if (documentType === RAFDocumentType.Page) {
            fileClassname = "far fa-file-invoice content_brand_tertiary_base fa-md-noraml";
            outerClassname = `${outerClassname} surface_brand_tertiary_light`;
            if (isNullOrUndefined(currentStatus) || currentStatus === ContentLibraryCurrentStatus.Published) {
                moreMenuItem = [
                    //{ id: "View", text: "View", iconCss: "fal fa-eye" },
                    { id: "Detail", text: "Details", iconCss: "far fa-file" },
                    { id: RAFButtonConstant.Delete.Id, text: RAFButtonConstant.Delete.DisplayName, iconCss: RAFButtonConstant.Delete.IconCss },
                ];
            } else {
                moreMenuItem = [
                    //{ id: "View", text: "View", iconCss: "fal fa-eye" },
                    { id: "Detail", text: "Details", iconCss: "far fa-file" },
                    { id: RAFButtonConstant.Delete.Id, text: RAFButtonConstant.Delete.DisplayName, iconCss: RAFButtonConstant.Delete.IconCss },
                ];
            }

        }
        else if (documentType === RAFDocumentType.Collection) {
            fileClassname = "fas fa-books content_brand_primary_base fa-md-noraml";
            outerClassname = `${outerClassname} surface_brand_primary_light`;
            moreMenuItem = [
                { id: "FileProperties", text: "Details", iconCss: "far fa-file" },
            ];
        }
        else if (documentType === RAFDocumentType.File) {
            if (isNullOrUndefined(currentStatus) || currentStatus === ContentLibraryCurrentStatus.Published) {
                moreMenuItem = [
                    { id: "Detail", text: "Details", iconCss: "far fa-file" },
                    { id: RAFButtonConstant.Edit.Id, text: RAFButtonConstant.Edit.DisplayName, iconCss: RAFButtonConstant.Edit.IconCss },
                    { id: RAFButtonConstant.Delete.Id, text: RAFButtonConstant.Delete.DisplayName, iconCss: RAFButtonConstant.Delete.IconCss },
                ];
            } else {
                moreMenuItem = [
                    { id: "Detail", text: "Details", iconCss: "far fa-file" },
                    { id: RAFButtonConstant.Edit.Id, text: RAFButtonConstant.Edit.DisplayName, iconCss: RAFButtonConstant.Edit.IconCss },
                    { id: RAFButtonConstant.Delete.Id, text: RAFButtonConstant.Delete.DisplayName, iconCss: RAFButtonConstant.Delete.IconCss },
                ];
            }
        }
        return (
            <div className="listGridItemOutter">
                <div className="listGridItemContent border_base"
                >
                    <div className="listGridItem">
                        <div className="" id={`care_Recipient_${data["UID"]}`}>
                            <div className="row gx-3 align-items-center">
                                <div className="col-auto d-flex align-items-center">
                                    <div className={outerClassname}>
                                        <span className={fileClassname}></span>
                                    </div>
                                </div>
                                <div className="col ps-1">
                                    <div className="d-flex align-items-center flex-nowrap mb-1">
                                        <div className="subtitle_2">
                                            <span className="subtitle_2 ecllipseFirstLine text-wrap word-break-all semi_bold">
                                                {fieldValue}
                                            </span>
                                        </div>
                                        {isNotNullAndUndefined(versionNumber) && (
                                            <div className="ms-1">
                                                <span className="raf_badge raf_badge_neutral">{`${versionNumber}.0.0`}</span>
                                            </div>
                                        )}
                                    </div>
                                    <RAFDetailsValueWithSeparator
                                        rowClassName="flex-nowrap gx-2 gy-2"
                                        outerClassName="h-auto"
                                    >
                                        <div className="body_3_light">{documentType}</div>
                                        {IsNotNullOrWhiteSpace(data["FileSize"]) && (
                                            <div className="body_3_light">{fileSize}</div>
                                        )}
                                        <RAFRecordInfo
                                            createdBy={createdBy}
                                            createdDate={createdDate}
                                            modifiedDate={modifiedDate}
                                            modifiedBy={modifiedBy}
                                            lastActivityDate={lastActivityDate}
                                        ></RAFRecordInfo>
                                    </RAFDetailsValueWithSeparator>
                                </div>
                                <div className="col-auto">
                                    <div className="row gx-2">
                                        <div className="col-auto noRowclick"
                                            onClick={(e) => e.stopPropagation()}
                                        >
                                            <DropDownButtonComponent
                                                items={moreMenuItem}
                                                cssClass="new_style raf_sm e-caret-hide btn_neutral outline"
                                                select={(selectedMenu) => {
                                                    onActionMenuClicked(data, selectedMenu);
                                                }}
                                                iconCss={`${RAFButtonConstant.More.IconCss} fa- mt-0`}
                                            ></DropDownButtonComponent>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="listGridItemFooter">
                        <div className="row gx-2 align-items-center">
                            <div className="col-auto">
                                <div className="body_3_light">Tags:</div>
                            </div>

                            <div className="col">
                                <RAFDetailFieldCustom
                                    field="TagsListJson"
                                    title="Tags"
                                    value={data["TagsListJson"]}
                                    enableChipArray={{ visible: true, uiType: "Badge" }}
                                    rowClassName="g-1"
                                    valueClassName={"body_3_light"}
                                    showLabel={false}
                                ></RAFDetailFieldCustom>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    return;
};

export const documentTableDisplayNameTemplate = (
    moduleName: string,
    allowEditing: boolean,
    showEdit: boolean,
    item: QueryAttributeJM,
    isDynamic: boolean,
    data,
    navigate: any,
    onActionMenuClicked: (data: any, selectedMenu: MenuEventArgs) => void
) => {
    const fieldName = data.column.field;
    const fieldValue = data[fieldName];
    const documentType: string = data["DocumentType"];
    const currentStatus: string = data["CurrentStatus"];


    const fileNameExtension = data["FileName"];
    const fileExtension = getFileExtension(fileNameExtension);
    const isCareESIOTenant = (msalInstance.currentTenantName === RAFTenantName.CareESIO || isCurrentTenantCareESIOPartner() === true) ? true : false;

    if (isNotNullAndUndefined(fieldValue)) {
        let fileClassname;
        if (documentType === RAFDocumentType.Folder) {
            fileClassname = "fiv-viv-lg fiv-viv fiv-icon-folder";
        } else if (documentType === RAFDocumentType.File) {
            if (isNotNullAndUndefined(fileExtension)) {
                fileClassname =
                    "fiv-viv-lg fiv-viv fiv-icon-blank fiv-icon-" + fileExtension;
            }
        } else if (documentType === RAFDocumentType.Page) {
            fileClassname = "fiv-viv-lg fiv-viv fiv-icon-blank fiv-icon-wiki";
        }

        const moreMenuItems: ItemModel[] = [];
        if (documentType === RAFDocumentType.Page) {
            moreMenuItems.push(
                { id: "View", text: "View", iconCss: "fal fa-eye" },
                { id: "Detail", text: "Detail", iconCss: "fal fa-paperclip" },
                { id: "Edit", text: "Edit", iconCss: "fal fa-pencil" },
                { id: "Delete", text: "Delete", iconCss: "fas fa-trash" },
            );
            if (isCareESIOTenant && currentStatus === ContentLibraryCurrentStatus.Published) {
                moreMenuItems.push(
                    { id: "RequestAcknowledgement", text: "Request Acknowledgement", iconCss: "fal fa-user" },
                );
            }
        } else if (documentType === RAFDocumentType.File) {
            moreMenuItems.push(
                { id: "Edit", text: "Edit", iconCss: "fal fa-pencil" },
                { id: "Delete", text: "Delete", iconCss: "fas fa-trash" },
            );

            if (isCareESIOTenant && currentStatus === ContentLibraryCurrentStatus.Published) {
                moreMenuItems.push(
                    { id: "RequestAcknowledgement", text: "Request Acknowledgement", iconCss: "fal fa-user" },
                );
            }
        } else {
            moreMenuItems.push(
                { id: "Edit", text: "Edit", iconCss: "fal fa-pencil" },
                { id: "Delete", text: "Delete", iconCss: "fas fa-trash" },
            );
        }

        return (
            <div className="" id={`care_Recipient_${data["UID"]}`}>
                <div className="row gx-3 align-items-center">
                    <div className="col-auto d-flex align-items-center">
                        <div className="fiv-viv-outer">
                            <span className={fileClassname}></span>
                        </div>
                    </div>
                    <div className="col ps-1 overflow-auto">
                        <div className="body_1_dark">
                            <span className="body_1_dark ecllipseFirstLine text-wrap word-break-all">
                                {fieldValue}
                            </span>
                        </div>
                    </div>
                    <div className="col-auto">
                        <DropDownButtonComponent
                            items={moreMenuItems}
                            cssClass="bg-transparent border-0 e-caret-hide noRowclick"
                            select={(selectedMenu) => {
                                if (onActionMenuClicked) {
                                    onActionMenuClicked(data, selectedMenu);
                                }
                            }}
                            iconCss={RAFButtonConstant.More.IconCss}
                        ></DropDownButtonComponent>
                    </div>
                </div>
            </div>
        );
    }
    return;
};

export const DocumentCategoryTemplate = (
    moduleName: string,
    allowEditing: boolean,
    showEdit: boolean,
    item: QueryAttributeJM,
    isDynamic: boolean,
    data,
    navigate: any,
    onActionMenuClicked: (data: any, selectedMenu: MenuEventArgs) => void
) => {
    const fieldName = data.column.field;
    const fieldValue = data[fieldName];
    const fileNameExtension = data["FileName"];

    if (isNotNullAndUndefined(fieldValue)) {
        return (
            <div className="listGridItemOutter">
                <div className="listGridItemContent">
                    <div className="listGridItem">
                        <div className={""}>
                            <div className="" id={`care_Recipient_${data["UID"]}`}>
                                <div className="row gx-3 align-items-center">
                                    <div className="col-auto d-flex align-items-center">
                                        <div className={`fiv-viv-outer brand_badge`}>
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="24"
                                                height="24"
                                                viewBox="0 0 24 24"
                                                fill="none"
                                            >
                                                <path
                                                    d="M19.4346 4.03448C19.5669 4.2424 19.3403 4.48461 19.1 4.43C18.63 4.29 18.11 4.22 17.58 4.22H14.2797C14.1225 4.22 13.9744 4.14605 13.88 4.02037L12.73 2.49C12.5891 2.29044 12.7223 2 12.9665 2H15.72C17.281 2 18.6561 2.81073 19.4346 4.03448Z"
                                                    fill="inherit"
                                                />
                                                <path
                                                    d="M20.14 6.54C19.71 6.23 19.22 6 18.69 5.87C18.33 5.77 17.96 5.72 17.58 5.72H13.86C13.28 5.72 13.24 5.67 12.93 5.26L11.53 3.4C10.88 2.53 10.37 2 8.74 2H6.42C3.98 2 2 3.98 2 6.42V17.58C2 20.02 3.98 22 6.42 22H17.58C20.02 22 22 20.02 22 17.58V10.14C22 8.65 21.27 7.34 20.14 6.54ZM14.39 16.34H9.6C9.21 16.34 8.91 16.03 8.91 15.64C8.91 15.26 9.21 14.94 9.6 14.94H14.39C14.78 14.94 15.09 15.26 15.09 15.64C15.09 16.03 14.78 16.34 14.39 16.34Z"
                                                    fill="inherit"
                                                />
                                            </svg>
                                        </div>
                                    </div>
                                    <div className="col ps-1 overflow-auto">
                                        <div className="subtitle_1">
                                            <span className="subtitle_1 ecllipseFirstLine text-wrap word-break-all semi_bold">
                                                {fieldValue}
                                            </span>
                                        </div>
                                        {/* <div className="d-flex">
                            <div className="body_2_dark">0 file</div>
                            <div className="mx-2">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="3"
                                height="4"
                                viewBox="0 0 3 4"
                                fill="none"
                              >
                                <circle cx="1.5" cy="2" r="1.5" fill="#A3A3A3" />
                              </svg>
                            </div>
                            <div className="body_2_dark">{"0GB"}</div>
                          </div> */}
                                    </div>
                                    <div className="col-auto noRowclick">
                                        <DropDownButtonComponent
                                            items={[
                                                { id: "Edit", text: "Edit" },
                                                { id: "Delete", text: "Delete" },
                                            ]}
                                            cssClass="bg-transparent border-0 e-caret-hide custom-button-sm"
                                            select={(selectedMenu) => {
                                                if (onActionMenuClicked) {
                                                    onActionMenuClicked(data, selectedMenu);
                                                }
                                            }}
                                            iconCss={RAFButtonConstant.More.IconCss}
                                        ></DropDownButtonComponent>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    return;
};

export const DocumentTableCategoryTemplate = (
    moduleName: string,
    allowEditing: boolean,
    showEdit: boolean,
    item: QueryAttributeJM,
    isDynamic: boolean,
    data,
    navigate: any,
    onActionMenuClicked: (data: any, selectedMenu: MenuEventArgs) => void
) => {
    const fieldName = data.column.field;
    const fieldValue = data[fieldName];
    const fileNameExtension = data["FileName"];

    if (isNotNullAndUndefined(fieldValue)) {
        return (
            <div className="" id={`care_Recipient_${data["UID"]}`}>
                <div className="row gx-3 align-items-center">
                    <div className="col-auto d-flex align-items-center">
                        <div className={`fiv-viv-outer brand_badge`}>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                            >
                                <path
                                    d="M19.4346 4.03448C19.5669 4.2424 19.3403 4.48461 19.1 4.43C18.63 4.29 18.11 4.22 17.58 4.22H14.2797C14.1225 4.22 13.9744 4.14605 13.88 4.02037L12.73 2.49C12.5891 2.29044 12.7223 2 12.9665 2H15.72C17.281 2 18.6561 2.81073 19.4346 4.03448Z"
                                    fill="inherit"
                                />
                                <path
                                    d="M20.14 6.54C19.71 6.23 19.22 6 18.69 5.87C18.33 5.77 17.96 5.72 17.58 5.72H13.86C13.28 5.72 13.24 5.67 12.93 5.26L11.53 3.4C10.88 2.53 10.37 2 8.74 2H6.42C3.98 2 2 3.98 2 6.42V17.58C2 20.02 3.98 22 6.42 22H17.58C20.02 22 22 20.02 22 17.58V10.14C22 8.65 21.27 7.34 20.14 6.54ZM14.39 16.34H9.6C9.21 16.34 8.91 16.03 8.91 15.64C8.91 15.26 9.21 14.94 9.6 14.94H14.39C14.78 14.94 15.09 15.26 15.09 15.64C15.09 16.03 14.78 16.34 14.39 16.34Z"
                                    fill="inherit"
                                />
                            </svg>
                        </div>
                    </div>
                    <div className="col ps-1 overflow-auto">
                        <div className="body_1_dark">
                            <span className="body_1_dark ecllipseFirstLine text-wrap word-break-all">
                                {fieldValue}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    return;
};

export const documentActionButtonTemplate = (
    moduleName: string,
    allowEditing: boolean,
    showEdit: boolean,
    item: QueryAttributeJM,
    isDynamic: boolean,
    data,
    navigate: any,
    onActionMenuClicked: (data: any, selectedMenu: MenuEventArgs) => void
) => {
    return (
        <DropDownButtonComponent
            items={[
                { id: "Edit", text: "Edit" },
                { id: "Delete", text: "Delete" },
            ]}
            cssClass="bg-transparent border-0 e-caret-hide"
            select={(selectedMenu) => {
                if (onActionMenuClicked) {
                    onActionMenuClicked(data, selectedMenu);
                }
            }}
            iconCss={RAFButtonConstant.More.IconCss}
        ></DropDownButtonComponent>
    );
};
