import * as R from "ramda";
import { msalInstance } from "../../../..";
import { RetrieveRecord } from "../../../../RAFComponents/helpers/AppHelper";
import {
  IsNotNullOrWhiteSpace,
  getSaveRequest,
  isNotNullAndUndefined,
} from "../../../../RAFComponents/helpers/utils";
import * as repositoryActions from "../../../../RAFComponents/store/actions/repositoryActions";
import { EntityRow } from "../../../../RAFMaster/RMModules/Entity/EntityRow";
import { CareEsioEntity } from "../../../../constants/CareESIO/CareEsioConstants";
import { ContentType } from "../../../../constants/Common/Constants";
import { RAFEntityName } from "../../../../constants/Common/EntityConstants";
import {
  RAFChecklistItemTransStatus,
  saveChecklistItemTransAPI,
} from "../../../CareESIO/ChecklistItemTrans/ChecklistItemTransHelper";
import { ChecklistItemTransRow } from "../../../CareESIO/ChecklistItemTrans/ChecklistItemTransRow";
import { createUpdateActivityRow } from "../../Activity/ActivityHelper";
import {
  ActivityRow,
  RAFActivityCommentType,
} from "../../Activity/ActivityRow";
import { ContentLibraryRow } from "../Library/ContentLibraryRow";

export const onSubmitDocumentSubForm = (
  contentLibrary: ContentLibraryRow,
  selectedRelatedForm: EntityRow,
  rafRelatedDocumentFormValue: any
) => {
  let values = R.clone(rafRelatedDocumentFormValue);
  values.UID = contentLibrary.UID;
  let saveRequestData = getSaveRequest(values, contentLibrary.UID);
  saveRequestData.EntityName = selectedRelatedForm.EntityName;
  return new Promise<string>((resolve) => {
    repositoryActions
      .postDataAndGetResponse(
        "datalist/save",
        saveRequestData,
        null,
        ContentType.applicationJson
      )
      .then((response) => {
        if (
          isNotNullAndUndefined(response) &&
          isNotNullAndUndefined(response.data) &&
          isNotNullAndUndefined(response.data.EntityId)
        ) {
          resolve(response.data.EntityId);
        } else {
          resolve(null);
        }
      })
      .catch((error) => error);
  });
};

export const updateChecklistItemTransAndCreateActivityObjectData = (
  documentFormValue: any,
  docUID: string,
  relatedToUID: string
) => {
  return new Promise<boolean>(async (resolve) => {
    if (isNotNullAndUndefined(documentFormValue)) {
      const moduleName = CareEsioEntity.ChecklistItemTrans.EntityName;
      const expiryDate = documentFormValue.ExpiryDate;
      const checklistItemTransRowUID = isNotNullAndUndefined(
        documentFormValue.RelatedToUID
      )
        ? documentFormValue.RelatedToUID
        : relatedToUID;
      const [uploadedDoc, checklistItemTrans] = await Promise.all([
        RetrieveRecord(
          docUID,
          RAFEntityName.ContentLibrary
        ) as ContentLibraryRow,
        RetrieveRecord(checklistItemTransRowUID, moduleName),
      ]);

      if (
        isNotNullAndUndefined(uploadedDoc) &&
        isNotNullAndUndefined(checklistItemTrans)
      ) {
        const newChecklistItemTransRow = new ChecklistItemTransRow();
        newChecklistItemTransRow.UID = checklistItemTransRowUID;
        newChecklistItemTransRow.DocumentUID = docUID;
        newChecklistItemTransRow.Document = uploadedDoc.DisplayName;
        newChecklistItemTransRow.ExpiryDate = expiryDate;
        newChecklistItemTransRow.Status = RAFChecklistItemTransStatus.Uploaded;

        const isRevision = uploadedDoc.Revision > 1 ? true : false;

        const [checklistItemTransResponse, activityResponse] =
          await Promise.all([
            saveChecklistItemTransAPI(newChecklistItemTransRow, isRevision),
            submitActivityItemFormDocumentForm(documentFormValue),
          ]);
        resolve(true);
      } else {
        resolve(null);
      }
    } else {
      resolve(null);
    }
  });
};

const submitActivityItemFormDocumentForm = (documentFormValue: any) => {
  return new Promise<{
    entityId: string;
    objectName: string;
  }>(async (resolve) => {
    if (isNotNullAndUndefined(documentFormValue)) {
      let activityRow: ActivityRow = new ActivityRow();
      activityRow.UserUID = msalInstance.currentUserId;
      activityRow.UserName = msalInstance.currentUserName;

      activityRow.IsSystem = false;

      activityRow.RelatedToType = documentFormValue.RelatedToType;
      activityRow.RelatedToUID = documentFormValue.RelatedToUID;
      activityRow.RelatedTo = documentFormValue.RelatedTo;

      const mentions = documentFormValue["Mentions"];
      const message = documentFormValue["Message"];

      activityRow.Mentions = mentions;
      activityRow.Message = message;

      activityRow.CommentType = RAFActivityCommentType.Reason;

      if (IsNotNullOrWhiteSpace(message)) {
        const activityResponse = await createUpdateActivityRow(activityRow);
        resolve(activityResponse);
      } else {
        resolve(null);
      }
    }
  });
};
