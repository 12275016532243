import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import {
  AnimationSettingsModel,
  DialogComponent,
  DialogUtility,
} from "@syncfusion/ej2-react-popups";
import React, { Component, Suspense } from "react";
import RAFGrid4 from "../../../../RAFComponents/Grid/RAFGrid4";
import RAFGridColumn from "../../../../RAFComponents/Grid/RAFGridColumn";
import CustomCardWidget from "../../../../RAFComponents/Navigation/CustomCardWidget";
import {
  showSuccessToast,
  showWarningToast,
} from "../../../../RAFComponents/Utility/RAFToastComponent";
import {
  hideProgress,
  showProgress,
} from "../../../../RAFComponents/helpers/AppHelper";
import RAFPermissionRender from "../../../../RAFComponents/helpers/PermissionHelper";
import {
  IDialogProps,
  deepEqual,
  getFileExtension,
  isNotNullAndUndefined,
  lazyRetry,
} from "../../../../RAFComponents/helpers/utils";
import { RetrieveRequest } from "../../../../RAFComponents/models/Common/ListRequest";
import * as repositoryActions from "../../../../RAFComponents/store/actions/repositoryActions";
import ACLoadingPanel from "../../../../components/shared/ACLoadingPanel";
import {
  Constants,
  ContentType,
  RAFDocumentType,
  RAFLayout,
  SFColumnType,
} from "../../../../constants/Common/Constants";
import { RAFEntityName } from "../../../../constants/Common/EntityConstants";
import { PermissionConstants } from "../../../../constants/Common/PermissionConstants";
import { NavigateParamsProps } from "../../../../router";
import { ContentLibraryRow } from "../Library/ContentLibraryRow";
import {
  ContentLibraryCurrentStatus,
  downloadDocumentFile,
  publishDocumentByID
} from "../Library/DocumentHelper";
import { VersionHistoryColumnListTemplates, VersionHistoryColumnTemplates } from "./DocumentVersionHistoryTemplate";
import { VersionHistoryRow } from "./VersionHistoryRow";

const RAFGridTemplates = React.lazy(() =>
  lazyRetry(
    () => import("../../../../helpers/RAFGridTemplates"),
    "RAFGridTemplates"
  )
);
const RAFFieldStateProvider = React.lazy(() =>
  lazyRetry(
    () => import("../../../../RAFComponents/Grid/RAFFieldStateProvider"),
    "RAFFieldStateProvider"
  )
);
//const RAFGrid4 = React.lazy(() => lazyRetry(() => import("../../../../RAFComponents/Grid/RAFGrid4"), "RAFGrid4"));
const RAFCollapse = React.lazy(() =>
  lazyRetry(
    () => import("../../../../RAFComponents/Navigation/RAFCollapse"),
    "RAFCollapse"
  )
);
const RAFManageDocumentPage = React.lazy(() =>
  lazyRetry(
    () => import("../ManageDocument/RAFManageDocumentPage"),
    "RAFManageDocumentPage"
  )
);
const RAFManageUploadDocumentFile = React.lazy(() =>
  lazyRetry(
    () => import("../ManageDocument/RAFManageUploadDocumentFile"),
    "RAFManageUploadDocumentFile"
  )
);

interface IProps {
  isActive: boolean;
  documentUID: string;
  documentRow: ContentLibraryRow;
  module: string;
  onUpdate: (id) => void;
  cardClassName?: string;
  showIconButton?: boolean;
  uiMode?: "list" | "Grid";
  showInCard?: boolean;
  displayStyle?: 'Dialog' | 'default';
  hasPermissionToCreateOrEdit?: boolean;

  updatePermissionName?: string;
}

interface IState {
  isActive: boolean;
  showUploadDialog: boolean;
  showUploadContent: boolean;
  documentVersionItem: VersionHistoryRow;
  documentVersions: VersionHistoryRow[];
  showManagePageDocContent: boolean;
  isShowPreview: boolean;
}

class RAFContentLibraryVersionHistory extends Component<
  IDialogProps & NavigateParamsProps & IProps,
  IState
> {
  setActiveDialog: any;
  _isMounted = false;
  animationSettings: AnimationSettingsModel;
  fileExtensions: string;

  constructor(props) {
    super(props);
    this.state = {
      isActive: false,
      documentVersionItem: null,
      showUploadDialog: false,
      showUploadContent: false,
      documentVersions: [],
      showManagePageDocContent: false,
      isShowPreview: false,
    };
  }

  componentDidMount = () => {
    this._isMounted = true;
    this.refresh();
  };

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(prevProps /*, prevState*/) {
    if (
      !deepEqual(prevProps.isActive, this.props.isActive) ||
      !deepEqual(prevProps.documentUID, this.props.documentUID)
    ) {
      if (this._isMounted) {
        //this.getBusinessRuleActions();
        this.setState(
          {
            isActive: false,
          },
          () => {
            this.refresh();
          }
        );
      }
    }
  }

  refresh = () => {
    let progressDiv = showProgress("#documentDetailsDialog");
    let { documentRow, isActive } = this.props;
    /*if (isActive === true && isNotNullAndUndefined(documentRow) && isNotNullAndUndefined(documentRow.UID)) {
            let documentRetrieveRequest: DocumentRetrieveRequest = new DocumentRetrieveRequest();
            let url = documentRow.DocumentType === RAFDocumentType.Page ? 'ContentLibrary/PageVersions' : 'ContentLibrary/Versions';
            //documentRetrieveRequest.Module = this.state.module;
            documentRetrieveRequest.EntityId = this.props.documentUID as any;
            repositoryActions.postDataAndGetResponse(url, documentRetrieveRequest, { ...this.props }, ContentType.applicationJson)
                .then((response) => {
                    hideProgress(progressDiv);
                    if (isNotNullAndUndefined(response) && isNotNullAndUndefined(response.data)) {
                        if (this._isMounted) {
                            this.setState({ documentVersions: response.data.Entities, isActive });
                        }
                    } else {
                        if (this._isMounted) {
                            this.setState({ isActive: false });
                        }
                    }
                })
                .catch(error => error);
        } else {
            if (this._isMounted) {
                this.setState({ isActive: false });
            }
        }*/
    if (
      isActive === true &&
      isNotNullAndUndefined(documentRow) &&
      isNotNullAndUndefined(documentRow.UID)
    ) {
      if (this._isMounted) {
        this.setState({ isActive });
      }
    }
  };

  customActionClick = (item) => {
    let { documentRow } = this.props;

    if (documentRow.DocumentType === RAFDocumentType.File) {
      downloadDocumentFile(item, true);
    } else {
      this.previewPageDocument(item);
    }
  };

  onSetActiveClick = (item) => {
    if (this.props.hasPermissionToCreateOrEdit === false) {
      showWarningToast("You do not have permission to update document");
      return;
    }
    this.setActiveDialog = DialogUtility.confirm({
      animationSettings: { effect: "Fade" },
      cancelButton: { text: "No" },
      closeOnEscape: false,
      content: "Are you sure want to set this document as active?",
      okButton: {
        text: "Yes",
        click: this.setActiveDocument.bind(this, item.UID),
      },
      title: "Document",
      position: { X: "center", Y: "center" },
      cssClass: "alert-dialog",
    });
  };

  setActiveDocument = (entityId: object) => {
    let progressDiv = showProgress("body", true);
    let { documentRow } = this.props;
    let url =
      documentRow.DocumentType === RAFDocumentType.Page
        ? "ContentLibrary/RevertPageVersion"
        : "ContentLibrary/RevertVersion";
    let retrieveRequest: RetrieveRequest = new RetrieveRequest();
    retrieveRequest.EntityId = entityId;
    repositoryActions
      .postDataAndGetResponse(
        url,
        retrieveRequest,
        { ...this.props },
        ContentType.applicationJson
      )
      .then(async (response) => {
        if (
          documentRow.DocumentType === RAFDocumentType.Page &&
          documentRow.CurrentStatus === ContentLibraryCurrentStatus.Published
        ) {
          await publishDocumentByID(documentRow.UID);
        }
        hideProgress(progressDiv);
        this.setActiveDialog.hide();
        if (
          isNotNullAndUndefined(response) &&
          isNotNullAndUndefined(response.data) &&
          isNotNullAndUndefined(response.data.EntityId)
        ) {
          showSuccessToast("File set as active");
          if (this._isMounted) {
            this.setState({ showUploadDialog: false }, () => {
              this.refreshOnVersionUpdate(response.data.EntityId);
            });
          }
        } else {
          showWarningToast("Sorry something went wrong !");
        }
      })
      .catch((error) => error);
  };

  onClickuploadAndLinkDocumentContent = () => {
    if (this.props.hasPermissionToCreateOrEdit === false) {
      showWarningToast("You do not have permission to update document");
      return;
    }
    if (this._isMounted) {
      this.setState({ showUploadContent: true, showUploadDialog: true });
    }
  };

  uploadAndLinkDocumentContent() {
    if (this.state.showUploadContent === true) {
      return (
        <Suspense fallback={<ACLoadingPanel loadingText="Loading..." />}>
          <RAFManageUploadDocumentFile
            onSave={(UID) => this.onUploadDocumentFile(UID)}
            onClose={() => this.uploadDialogClose()}
            selectedFolder={null}
            selectedDocumentRow={this.props.documentRow}
            mode={"updateVersion"}
            isActive
          />
        </Suspense>
      );
    } else {
      return <div></div>;
    }
  }

  onUploadDocumentFile = (UID) => {
    if (this._isMounted) {
      this.setState({ showUploadDialog: false }, () => {
        this.refreshOnVersionUpdate(UID);
      });
    }
  };

  private uploadDialogClose(): void {
    if (this._isMounted) {
      this.setState({ showUploadContent: false, showUploadDialog: false });
    }
  }

  refreshOnVersionUpdate(UID: string) {
    if (this.props.onUpdate) {
      this.props.onUpdate(UID);
    }
  }

  //document_Page start
  showManagePageDocDialogContent = () => {
    if (this._isMounted) {
      this.setState(
        { showManagePageDocContent: true, isShowPreview: false },
        () => {
          document.body.classList.add("overflow-hidden");
        }
      );
    }
  };

  previewPageDocument = (documentVersionItem) => {
    if (this._isMounted) {
      this.setState(
        {
          showManagePageDocContent: true,
          documentVersionItem,
          isShowPreview: true,
        },
        () => {
          document.body.classList.add("overflow-hidden");
        }
      );
    }
  };

  managePageDocDialogContent() {
    if (this.state.showManagePageDocContent) {
      let { documentVersionItem } = this.state;
      return (
        <Suspense fallback={<ACLoadingPanel loadingText="Loading..." />}>
          <RAFManageDocumentPage
            documentUID={
              this.state.isShowPreview === true
                ? documentVersionItem.ContentLibraryUID
                : this.props.documentUID
            }
            versionUID={
              this.state.isShowPreview === true ? documentVersionItem.UID : null
            }
            contentLibraryRow={
              this.state.isShowPreview === true
                ? documentVersionItem
                : this.props.documentRow
            }
            onSavePageDoc={(uid) => this.onSaveDocumentPage(uid)}
            onClosePageDoc={this.closeManagePageDocDialogContent.bind(this)}
            mode={
              this.state.isShowPreview === true ? "preview" : "updateWebContent"
            }
            isActive
          />
        </Suspense>
      );
    } else {
      return <div></div>;
    }
  }

  onDeleteDocumentPage = () => {
    if (this._isMounted) {
      this.setState({ showManagePageDocContent: false }, () => {
        document.body.classList.remove("overflow-hidden");
      });
    }
  };

  onSaveDocumentPage = (uid) => {
    if (this._isMounted) {
      this.setState({ showManagePageDocContent: false }, () => {
        document.body.classList.remove("overflow-hidden");
        if (this.props.onUpdate) {
          this.props.onUpdate(uid);
        }
      });
    }
  };

  closeManagePageDocDialogContent = () => {
    if (this._isMounted) {
      this.setState(
        { showManagePageDocContent: false, documentVersionItem: null },
        () => {
          document.body.classList.remove("overflow-hidden");
        }
      );
    }
  };
  //document_Page end

  render() {
    let { documentRow } = this.props;
    let { isActive, documentVersions } = this.state;

    if (
      isActive &&
      isNotNullAndUndefined(documentRow) &&
      isNotNullAndUndefined(documentRow.UID)
    ) {
      let fileClassname;
      if (documentRow.DocumentType === RAFDocumentType.Page) {
        fileClassname = "fiv-viv fiv-icon-blank fiv-icon-html";
      } else {
        const fileExtension = getFileExtension(documentRow.FileName);
        if (
          isNotNullAndUndefined(documentVersions) &&
          documentVersions.length > 0
        ) {
          if (isNotNullAndUndefined(documentVersions[0])) {
            this.fileExtensions = documentVersions[0].MimeType;
          }
        }
        if (isNotNullAndUndefined(fileExtension)) {
          fileClassname = "fiv-viv fiv-icon-blank fiv-icon-" + fileExtension;
        }
      }

      let uploadVersionBtn = (
        <div>
          {documentRow.DocumentType === RAFDocumentType.Page ? (
            <RAFPermissionRender
              permissionName={this.props.updatePermissionName ?? PermissionConstants.DocumentManage}
            >
              {this.props.showIconButton ? (
                <ButtonComponent
                  cssClass="primary-custom-button"
                  iconCss="fas fa-upload"
                  onClick={() => this.showManagePageDocDialogContent()}
                ></ButtonComponent>
              ) : (
                <>
                  <ButtonComponent
                    cssClass="d-none primary-custom-button d-sm-inline-block"
                    iconCss="fas fa-upload"
                    onClick={() => this.showManagePageDocDialogContent()}
                  >
                    New Version
                  </ButtonComponent>
                  <ButtonComponent
                    cssClass="primary-custom-button d-sm-none"
                    iconCss="fas fa-upload"
                    onClick={() => this.showManagePageDocDialogContent()}
                  ></ButtonComponent>
                </>
              )}

            </RAFPermissionRender>
          ) : (
            <RAFPermissionRender
              permissionName={this.props.updatePermissionName ?? PermissionConstants.DocumentManage}
            >
              {this.props.showIconButton ? (
                <ButtonComponent
                  cssClass="primary-custom-button"
                  iconCss="fas fa-upload"
                  onClick={() => this.onClickuploadAndLinkDocumentContent()}
                ></ButtonComponent>
              ) : (
                <>
                  <ButtonComponent
                    cssClass="d-none primary-custom-button d-sm-inline-block"
                    iconCss="fas fa-upload"
                    onClick={() => this.onClickuploadAndLinkDocumentContent()}
                  >
                    Upload
                  </ButtonComponent>
                  <ButtonComponent
                    cssClass="primary-custom-button d-sm-none"
                    iconCss="fas fa-upload"
                    onClick={() => this.onClickuploadAndLinkDocumentContent()}
                  ></ButtonComponent>
                </>
              )}

            </RAFPermissionRender>
          )}
        </div>
      );

      const gridListContent = () => {
        return (<div>
          {this.props.uiMode === 'list' ?
            <RAFFieldStateProvider
              moduleName={RAFEntityName.VersionHistory}
            // viewId={currentView.UID}
            >
              <RAFGridTemplates>
                <RAFGrid4
                  gridId={`grid3_${RAFEntityName.VersionHistory}_relatedList`}
                  url={
                    documentRow.DocumentType === RAFDocumentType.Page
                      ? `${Constants.baseAPIUrl}ContentLibrary/PageVersions`
                      : `${Constants.baseAPIUrl}ContentLibrary/Versions`
                  }
                  additionalParams={[
                    {
                      key: "EntityId",
                      value: this.props.documentUID,
                    },
                  ]}
                  moduleName={RAFEntityName.VersionHistory}
                  isRemote
                  showEditColumn={false}
                  allowSelection={false}
                  allowEditing
                  allowFiltering={false}
                  emptyStateProps={{
                    title: "No results found.",
                    body: "Try adjusting your search or changing your filter to find what you're looking for!",
                  }}
                  gridTemplates={VersionHistoryColumnListTemplates}
                  filterBarVisibile={false}
                  disableFirstColumnTemplate
                  customClick={this.onSetActiveClick}
                  customActionClick={this.customActionClick}
                  isRelatedSection
                  cssClass="hide-tableHeader ListGrid"
                  showHeader={false}
                  gridLines="None"
                >
                  <RAFGridColumn<VersionHistoryRow>
                    field="Revision"
                    headerText="Version"
                    width={"150px"}
                    minWidth={"150px"}
                    maxWidth={"300px"}
                  ></RAFGridColumn>
                </RAFGrid4>
              </RAFGridTemplates>
            </RAFFieldStateProvider>
            :
            <RAFFieldStateProvider
              moduleName={RAFEntityName.VersionHistory}
            // viewId={currentView.UID}
            >
              <RAFGridTemplates>
                <RAFGrid4
                  gridId={`grid3_${RAFEntityName.VersionHistory}_relatedList`}
                  url={
                    documentRow.DocumentType === RAFDocumentType.Page
                      ? `${Constants.baseAPIUrl}ContentLibrary/PageVersions`
                      : `${Constants.baseAPIUrl}ContentLibrary/Versions`
                  }
                  additionalParams={[
                    {
                      key: "EntityId",
                      value: this.props.documentUID,
                    },
                  ]}
                  moduleName={RAFEntityName.VersionHistory}
                  isRemote
                  showEditColumn={false}
                  allowSelection={false}
                  allowEditing
                  allowFiltering={false}
                  emptyStateProps={{
                    title: "No results found.",
                    body: "Try adjusting your search or changing your filter to find what you're looking for!",
                  }}
                  gridTemplates={VersionHistoryColumnTemplates}
                  filterBarVisibile={false}
                  disableFirstColumnTemplate
                  customClick={this.onSetActiveClick}
                  customActionClick={this.customActionClick}
                  isRelatedSection
                >
                  <RAFGridColumn<VersionHistoryRow>
                    field="Revision"
                    headerText="Version"
                    width={"150px"}
                    minWidth={"150px"}
                    maxWidth={"300px"}
                  ></RAFGridColumn>
                  <RAFGridColumn<VersionHistoryRow>
                    field="CreatedDate"
                    headerText="Created Date"
                    type={SFColumnType.date}
                    width={"150px"}
                    minWidth={"150px"}
                    maxWidth={"300px"}
                  ></RAFGridColumn>
                  <RAFGridColumn<VersionHistoryRow>
                    field="CreatedBy"
                    headerText="Created By"
                    width={"150px"}
                    minWidth={"150px"}
                    maxWidth={"300px"}
                  ></RAFGridColumn>
                  {documentRow.DocumentType !== RAFDocumentType.Page && (
                    <RAFGridColumn<VersionHistoryRow>
                      field="FileSize"
                      headerText="Size"
                      width={"150px"}
                      minWidth={"150px"}
                      maxWidth={"300px"}
                    ></RAFGridColumn>
                  )}
                </RAFGrid4>
              </RAFGridTemplates>
            </RAFFieldStateProvider>
          }
          {/* <table className="w-100 document-table" id="documentItems">
                            <thead>
                                <tr>
                                    <th>Version </th>
                                    <th>Modified Date</th>
                                    <th>Modified by</th>
                                    {documentRow.DocumentType !== RAFDocumentType.Page && (
                                        <th>Size</th>
                                    )}
                                </tr>
                            </thead>
                            <tbody>
                                {documentVersions && documentVersions.length > 0 && documentVersions.map(documentVersionItem => {
                                    let dateAndTimeTooltip = "<b>Date</b>: " + moment(new Date(documentVersionItem.ModifiedDate + "Z")).format("DD/MM/YYYY hh:mm a").toString();

                                    return <tr className="documentRow-tr" id={'div_' + documentVersionItem.UID} key={documentVersionItem.UID}>
                                        <td style={{ cursor: 'pointer' }} onClick={() => {
                                            if (documentRow.DocumentType !== RAFDocumentType.Page) {
                                                this.downloadDocument(documentVersionItem);
                                            } else {
                                                this.previewPageDocument(documentVersionItem);
                                            }
                                        }}>
                                            <div className="d-flex align-items-center">
                                                <TooltipComponent content={documentRow.DocumentType === RAFDocumentType.Page ? "Click to preview" : "Click to download"} position='BottomCenter'>
                                                    <div className="d-flex align-items-center">
                                                        <div className="d-flex document-item-icon"><span className={fileClassname}></span></div>
                                                        <span className="document-title ecllipseFirstLine">
                                                            {documentVersionItem.Revision}
                                                        </span>
                                                    </div>
                                                </TooltipComponent>
                                                <RAFPermissionRender permissionName={PermissionConstants.DocumentUpdate}>
                                                    {documentVersionItem.IsActive ?
                                                        <TooltipComponent content="Active" position='BottomCenter'>
                                                            <ButtonComponent type="button" iconCss="fas fa-check" cssClass="bg-transparent border-0 px-0 green-text" ></ButtonComponent>
                                                        </TooltipComponent>
                                                        :
                                                        <TooltipComponent content="Click to set active" position='BottomCenter'>
                                                            <ButtonComponent type="button" iconCss="fas fa-check" cssClass="bg-transparent border-0 px-0" onClick={(e) => { e.stopPropagation(); this.onSetActiveClick(documentVersionItem.UID); }} ></ButtonComponent>
                                                        </TooltipComponent>
                                                    }
                                                </RAFPermissionRender>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="d-flex flex-column">
                                                <TooltipComponent content={dateAndTimeTooltip} position='BottomCenter'>
                                                    <span className="document-secondary-title ecllipseFirstLine">
                                                        {moment(new Date(documentVersionItem.ModifiedDate + "Z")).format("DD/MM/YYYY").toString()}
                                                    </span>
                                                </TooltipComponent>
                                            </div>
                                        </td>
                                        <td>
                                            <span className="document-secondary-title ecllipseFirstLine">
                                                {documentVersionItem.CreatedBy}
                                            </span>
                                        </td>
                                        {documentRow.DocumentType !== RAFDocumentType.Page && (
                                            <td>
                                                <span className="document-secondary-title ecllipseFirstLine">
                                                    {getFileSize(documentVersionItem.FileSize)}
                                                </span>
                                            </td>
                                        )}
                                    </tr>;
                                })
                                }
                            </tbody>
                        </table> */}
        </div>);
      };

      const collapsePanelContent = () => {
        return (
          <div>
            {this.props.displayStyle === 'Dialog' ? (gridListContent()) : (
              <RAFCollapse
                title="Version History"
                arrowPosistion="title"
                iconName="history"
                contentCardClassName="relatedListOuterDiv"
                layout={RAFLayout.OneColumnLayout}
                IsCollapsed={false}
                Collapsible={false}
                customButton={uploadVersionBtn}
                removeContentCardPadding
              // loadChildrenOnExpand
              >
                {gridListContent()}
              </RAFCollapse>
            )}
          </div>
        );
      };
      let showInCard = isNotNullAndUndefined(this.props.showInCard) ? this.props.showInCard : true;
      return (
        <Suspense fallback={<ACLoadingPanel loadingText="Loading..." />}>
          {showInCard === true ? (
            <CustomCardWidget cardClassName={isNotNullAndUndefined(this.props.cardClassName)
              ? this.props.cardClassName
              : ""}
              cardId="revisions"
              removeContentPadding
            >
              {collapsePanelContent()}
            </CustomCardWidget>
          )
            :
            collapsePanelContent()
          }
          {this.state.showUploadContent === true && (
            <DialogComponent
              header={"Upload New Version"}
              showCloseIcon
              visible={this.state.showUploadContent}
              cssClass="centerDialog-sm createEditForm form-center-dialog dlg-new-style fixed-header"
              content={this.uploadAndLinkDocumentContent.bind(this)}
              isModal
              target="body"
              closeOnEscape={false}
              close={this.uploadDialogClose.bind(this)}
              animationSettings={this.animationSettings}
              id="manageUploadDocumentDialog"
            ></DialogComponent>
          )}
          {this.state.showManagePageDocContent && (
            <DialogComponent
              header={`<div class="d-flex align-items-center"><span class="fiv-viv fiv-icon-blank fiv-icon-html me-2"></span><span class="fw-normal text-truncate">${this.state.isShowPreview
                ? this.state.documentVersionItem.DisplayName
                : this.props.documentRow.DisplayName
                }</span></div>`}
              showCloseIcon
              visible={this.state.showManagePageDocContent}
              cssClass="rightDialog rightDialog-md createEditForm full-height form-center-dialog dlg-new-style fixed-header"
              content={this.managePageDocDialogContent.bind(this)}
              isModal
              target="body"
              closeOnEscape={false}
              close={this.closeManagePageDocDialogContent.bind(this)}
              animationSettings={this.animationSettings}
              id="managePageDocDialog"
            ></DialogComponent>
          )}
        </Suspense >
      );
    } else {
      return <div></div>;
    }
  }
}

export default React.memo(RAFContentLibraryVersionHistory);
