import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import Compressor from "compressorjs";
import React, { Component, Fragment } from "react";
import {
  showSuccessToast,
  showWarningToast,
} from "../../../../RAFComponents/Utility/RAFToastComponent";
import {
  hideProgress,
  showProgress,
} from "../../../../RAFComponents/helpers/AppHelper";
import {
  Guid,
  getBase64,
  isNotNullAndUndefined,
} from "../../../../RAFComponents/helpers/utils";
import * as repositoryActions from "../../../../RAFComponents/store/actions/repositoryActions";
import { RAFProcessContext } from "../../../../RAFMaster/RMModules/BusinessProcess/Process/RAFProcessContextProvider";
import { ContentLibraryRepository } from "../../../../WorkesioRepository/Document/ContentLibraryRepository";
import {
  ContentType,
  RAFDocumentType,
} from "../../../../constants/Common/Constants";
import { ContentLibraryRow } from "../Library/ContentLibraryRow";
import {
  ContentLibraryCurrentStatus,
  getDocumentModuleNameByRelatedEntity,
} from "../Library/DocumentHelper";
import { DialogComponent } from "@syncfusion/ej2-react-popups";

interface IProps {
  relatedTo?: string;
  relatedToUID?: string;
  relatedToType?: string;
  relatedEntity?: string;
  secondaryRelatedToUID?: string;
  secondaryRelatedTo?: string;
  secondaryRelatedToType?: string;
  onSave?: (entityId?: string, objectName?: string) => void;
  triggerInputFile: boolean;
  hideAddBtn?: boolean;
}

interface IState {
  showAlertDialog: boolean;
  fileUploadObj: any;
  existingDocument: ContentLibraryRow;
  compressImage?: boolean;
}

class RAFCaptureImage extends Component<IProps, IState> {
  constructor(props) {
    super(props);
    this.state = {
      showAlertDialog: false,
      fileUploadObj: null,
      existingDocument: null,
      compressImage: true,
    };
  }

  _isMounted = false;
  inputFileRef: React.RefObject<HTMLInputElement> = React.createRef();

  componentDidMount = () => {
    this._isMounted = true;
  };

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.triggerInputFile !== this.props.triggerInputFile &&
      this.props.triggerInputFile === true
    ) {
      this.uploadNewPhoto();
    }
  }

  uploadNewPhoto = () => {
    if (this._isMounted) {
      this.setState(
        {
          showAlertDialog: false,
          fileUploadObj: null,
          existingDocument: null,
        },
        () => {
          if (isNotNullAndUndefined(this.inputFileRef))
            this.inputFileRef.current.click();
        }
      );
    }
  };

  profilePhotoUpload = (event, compressImage) => {
    let fileData = event.target.files[0];
    this.UploadClicked(fileData, compressImage);
  };

  UploadClicked = (fileData, compressImage) => {
    let allowSubmit = true;
    if (
      isNotNullAndUndefined(fileData) &&
      isNotNullAndUndefined(fileData.name)
    ) {
      if (fileData.size === 0) {
        allowSubmit = false;
        showWarningToast(fileData.name + " File size is too small!");
      }
    } else {
      allowSubmit = false;
      showWarningToast("Please select alteast one image to upload.");
    }

    if (allowSubmit) {
      if (this._isMounted) {
        this.setState(
          {
            fileUploadObj: fileData,
            compressImage,
          },
          () => {
            this.onSubmitUploadDocument("uploadNew"); //uploadNew
          }
        );
      }
    }
  };

  onSubmitUploadDocument = (
    mode: "uploadNew" | "updateExisting",
    existingDocument?: ContentLibraryRow
  ) => {
    let progressDiv = showProgress("body", true);
    if (isNotNullAndUndefined(this.state.fileUploadObj)) {
      let allowSubmit = true;

      let url = "ContentLibrary/Upload";
      const formData = new FormData();
      let objFormDataDB: ContentLibraryRow = {};

      let parentUID;
      let parent;

      let businessProcess;
      let businessProcessUID;
      let processStatus;
      let notes;

      let moduleName = isNotNullAndUndefined(this.props.relatedEntity)
        ? getDocumentModuleNameByRelatedEntity(this.props.relatedEntity)
        : "";
      let relatedToUID = isNotNullAndUndefined(this.props.relatedToUID)
        ? this.props.relatedToUID
        : null;
      let relatedTo = isNotNullAndUndefined(this.props.relatedTo)
        ? this.props.relatedTo
        : null;

      let relatedToType = isNotNullAndUndefined(this.props.relatedToType)
        ? this.props.relatedToType
        : null;

      let secondaryRelatedTo = isNotNullAndUndefined(
        this.props.secondaryRelatedTo
      )
        ? this.props.secondaryRelatedTo
        : null;
      let secondaryRelatedToUID = isNotNullAndUndefined(
        this.props.secondaryRelatedToUID
      )
        ? this.props.secondaryRelatedToUID
        : null;
      let secondaryRelatedToType = isNotNullAndUndefined(
        this.props.secondaryRelatedToType
      )
        ? this.props.secondaryRelatedToType
        : null;

      if (
        (isNotNullAndUndefined(parentUID) ||
          isNotNullAndUndefined(relatedToUID)) &&
        allowSubmit === true
      ) {
        if (isNotNullAndUndefined(relatedToUID)) {
          formData.append("RelatedToUID", relatedToUID);
          objFormDataDB.RelatedToUID = relatedToUID;
        }

        if (isNotNullAndUndefined(relatedTo)) {
          formData.append("RelatedTo", relatedTo);
          objFormDataDB.RelatedTo = relatedTo;
        }

        if (isNotNullAndUndefined(relatedToType)) {
          formData.append("RelatedToType", relatedToType);
          objFormDataDB.RelatedToType = relatedToType;
        }

        if (isNotNullAndUndefined(secondaryRelatedTo)) {
          formData.append("SecondaryRelatedTo", secondaryRelatedTo);
          objFormDataDB.SecondaryRelatedTo = secondaryRelatedTo;
        }

        if (isNotNullAndUndefined(secondaryRelatedToUID)) {
          formData.append("SecondaryRelatedToUID", secondaryRelatedToUID);
          objFormDataDB.SecondaryRelatedToUID = secondaryRelatedToUID;
        }

        if (isNotNullAndUndefined(secondaryRelatedToType)) {
          formData.append("SecondaryRelatedToType", secondaryRelatedToType);
          objFormDataDB.SecondaryRelatedToType = secondaryRelatedToType;
        }

        if (isNotNullAndUndefined(parentUID)) {
          formData.append("ParentUID", parentUID);
          objFormDataDB.ParentUID = parentUID;
        }

        if (isNotNullAndUndefined(parent)) {
          formData.append("Parent", parent);
          objFormDataDB.Parent = parent;
        }

        if (isNotNullAndUndefined(businessProcess)) {
          formData.append("BusinessProcess", businessProcess);
          objFormDataDB.BusinessProcess = businessProcess;
        }
        if (isNotNullAndUndefined(businessProcessUID)) {
          formData.append("BusinessProcessUID", businessProcessUID);
          objFormDataDB.BusinessProcessUID = businessProcessUID;
        }
        if (isNotNullAndUndefined(processStatus)) {
          formData.append("ProcessStatus", processStatus);
          objFormDataDB.ProcessStatus = processStatus;
        }
        if (isNotNullAndUndefined(notes)) {
          formData.append("Notes", notes);
          objFormDataDB.Notes = notes;
        }

        formData.append("Entity", moduleName);
        objFormDataDB.Entity = moduleName;
        formData.append("CurrentStatus", ContentLibraryCurrentStatus.Published);
        objFormDataDB.CurrentStatus = ContentLibraryCurrentStatus.Published;

        let compressedFile;
        let fileData;
        let fileExtension;
        let fileName;
        if (isNotNullAndUndefined(this.state.fileUploadObj)) {
          fileData = this.state.fileUploadObj;
        }
        let compressImage = this.state.compressImage;
        fileExtension = fileData.name.substring(
          fileData.name.lastIndexOf(".") + 1
        );

        console.log({ fileData });
        fileName = "image" + new Date().getTime() + "." + fileExtension;
        if (isNotNullAndUndefined(fileData)) {
          objFormDataDB.DisplayName = fileData.name;
          objFormDataDB.FileSize = fileData.size;
          objFormDataDB.MimeType = fileData.type; //getMimeTypeByExtension(fileExtension);
        }
        objFormDataDB.DocumentType = RAFDocumentType.File;

        let that = this;
        if (compressImage) {
          if (!fileData) {
            return;
          }

          new Compressor(fileData, {
            quality: 0.6,

            // The compression process is asynchronous,
            // which means you have to access the `result` in the `success` hook function.
            success(result) {
              compressedFile = new File(
                [result as any], // cast as any
                fileName,
                {
                  lastModified: new Date().getTime(),
                  type: result.type,
                }
              );

              // The third parameter is required for server
              formData.append("file", compressedFile);

              getBase64(compressedFile).then((objBase64Result) => {
                console.log(
                  "RAFCAptureImage compressed",
                  objFormDataDB,
                  objBase64Result
                );
                objFormDataDB["file"] = objBase64Result;
                objFormDataDB.UID = Guid.newGuid();
                console.log({ objFormDataDB });
                const contentLibraryRepository: ContentLibraryRepository =
                  new ContentLibraryRepository();
                contentLibraryRepository.insertContentLibrary(objFormDataDB);
              });

              // Send the compressed image file to server with XMLHttpRequest.

              repositoryActions
                .postDataAndGetResponse(
                  url,
                  formData,
                  { ...that.props },
                  ContentType.applicationFormUrlEncoded
                )
                .then((response) => {
                  hideProgress(progressDiv);
                  if (
                    isNotNullAndUndefined(response) &&
                    isNotNullAndUndefined(response.data) &&
                    isNotNullAndUndefined(response.data.documentUIDs) &&
                    isNotNullAndUndefined(response.data.documentUIDs[0]) &&
                    isNotNullAndUndefined(
                      response.data.documentUIDs[0].EntityId
                    )
                  ) {
                    let UID = response.data.documentUIDs[0].EntityId;
                    that.setState({ fileUploadObj: null }, () => {
                      showSuccessToast("Image uploaded successfully");

                      if (that.props.onSave) that.props.onSave(UID);
                    });
                  } else {
                    showWarningToast("Sorry something went wrong !");
                  }
                })
                .catch((error) => error);
            },
            error(err) {
              console.log(err.message);
            },
          });
        } else {
          let updatedFileData = new File(
            [fileData as any], // cast as any
            fileName,
            {
              lastModified: new Date().getTime(),
              type: fileData.type,
            }
          );

          formData.append("file", updatedFileData);
          repositoryActions
            .postDataAndGetResponse(
              url,
              formData,
              { ...this.props },
              ContentType.applicationFormUrlEncoded
            )
            .then((response) => {
              hideProgress(progressDiv);
              if (
                isNotNullAndUndefined(response) &&
                isNotNullAndUndefined(response.data) &&
                isNotNullAndUndefined(response.data.documentUIDs) &&
                isNotNullAndUndefined(response.data.documentUIDs[0]) &&
                isNotNullAndUndefined(response.data.documentUIDs[0].EntityId)
              ) {
                let UID = response.data.documentUIDs[0].EntityId;
                that.setState({ fileUploadObj: null }, () => {
                  showSuccessToast("Image uploaded successfully");

                  if (that.props.onSave) that.props.onSave(UID);
                });
              } else {
                showWarningToast("Sorry something went wrong !");
              }
            })
            .catch((error) => error);
        }
      } else {
        hideProgress(progressDiv);
        showWarningToast("Sorry file cannot be added");
      }
    } else {
      hideProgress(progressDiv);
      showWarningToast("Sorry something went wrong !");
    }
  };

  //alert_dialog_start
  alertDialogContent() {
    if (this.state.showAlertDialog === true) {
      let { existingDocument } = this.state;
      let label =
        "matches an existing item in this folder. Do you want to update this as the top version?";
      return (
        <div className="px-4 pt-3 pb-0">
          <span className="pe-1" style={{ fontWeight: 500 }}>
            {isNotNullAndUndefined(existingDocument)
              ? existingDocument.FileName
              : "Na"}
          </span>
          <span>{label}</span>
        </div>
      );
    } else {
      return <div></div>;
    }
  }

  alertDialogFooter() {
    if (this.state.showAlertDialog === true) {
      return (
        <div className="d-flex justify-content-end">
          {/* <ButtonComponent
            type="button"
            cssClass="e-flat e-small me-2"
            onClick={() => this.alertDialogClose()}
          >
            Cancel
          </ButtonComponent> */}
          <ButtonComponent
            type="button"
            cssClass="e-flat e-small e-info me-2 e-outline"
            onClick={() => this.onSubmitUploadDocument("uploadNew")}
          >
            Keep Separate
          </ButtonComponent>
          <ButtonComponent
            type="button"
            cssClass="e-flat e-small e-info me-2"
            onClick={() =>
              this.onSubmitUploadDocument(
                "updateExisting",
                this.state.existingDocument
              )
            }
          >
            Update Existing
          </ButtonComponent>
        </div>
      );
    } else {
      return <div></div>;
    }
  }

  private alertDialogClose(): void {
    if (this._isMounted) {
      this.setState({ showAlertDialog: false, fileUploadObj: null }, () => {
        document.body.classList.remove("overflow-hidden");
      });
    }
  }
  //alert_dialog_end

  render() {
    return (
      <RAFProcessContext.Consumer>
        {({ businessProcess }) => {
          // let bpTheme = RAFBPTemplateSettings.find(
          //   (x) => x.Id === businessProcess.ThemeTemplate
          // );

          // let compressImage =
          //   isNotNullAndUndefined(bpTheme) && isNotNullAndUndefined(bpTheme)
          //     ? bpTheme.CompressImage
          //     : true;

          let compressImage = true;
          return (
            <Fragment>
              {this.props.hideAddBtn !== true && (
                <ButtonComponent
                  type="button"
                  className="link-button"
                  content="Add Photo"
                  onClick={() => {
                    this.uploadNewPhoto();
                  }}
                ></ButtonComponent>
              )}
              <input
                ref={this.inputFileRef}
                className="hidden"
                type="file"
                name="image-upload"
                id="documnetPhoto"
                accept="image/*"
                onChange={(e) => this.profilePhotoUpload(e, compressImage)}
                onTouchCancel={() => {
                  console.log("onTouchCancel");
                }}
                onTouchCancelCapture={() => {
                  console.log("onTouchCancelCapture");
                }}
              />
              <div>
                <div>
                  {this.state.showAlertDialog && (
                    <DialogComponent
                      header="Duplicates!"
                      showCloseIcon
                      visible={this.state.showAlertDialog}
                      cssClass="centerDialog-sm full-height form-center-dialog custom-alert-dialog"
                      id="alertDialog"
                      content={this.alertDialogContent.bind(this)}
                      footerTemplate={this.alertDialogFooter.bind(this)}
                      isModal
                      target="body"
                      closeOnEscape={false}
                      close={this.alertDialogClose.bind(this)}
                    />
                  )}
                </div>
              </div>
            </Fragment>
          );
        }}
      </RAFProcessContext.Consumer>
    );
  }
}

export default RAFCaptureImage;
