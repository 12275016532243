import React, { Component, Suspense } from "react";
import { FormRenderProps } from "react-final-form";
import RAFDropdownCC from "../../../../RAFComponents/Inputs/RAFDropdownCC";
import { ConditionNot, ConditionNotNullOrWhiteSpace } from "../../../../RAFComponents/Inputs/RAFForm";
import RAFTagInput from "../../../../RAFComponents/Inputs/RAFTagInput";
import { getFormValue } from "../../../../RAFComponents/Inputs/RFFUtils";
import CustomCardWidget from "../../../../RAFComponents/Navigation/CustomCardWidget";
import RAFButtonComponent from "../../../../RAFComponents/Navigation/RAFButtonComponent";
import {
  showSuccessToast,
  showWarningToast,
} from "../../../../RAFComponents/Utility/RAFToastComponent";
import {
  getAllDynamicFormsByEntityName,
  hideProgress,
  showProgress,
} from "../../../../RAFComponents/helpers/AppHelper";
import RAFPermissionRender from "../../../../RAFComponents/helpers/PermissionHelper";
import {
  IDialogProps,
  RAFFormComponentProps,
  deepEqual,
  getFileExtension,
  getSaveRequest,
  isNotEmptyArray,
  isNotNullAndUndefined,
  lazyRetry,
  propertyOf,
} from "../../../../RAFComponents/helpers/utils";
import * as repositoryActions from "../../../../RAFComponents/store/actions/repositoryActions";
import { EntityRow } from "../../../../RAFMaster/RMModules/Entity/EntityRow";
import ACTextArea from "../../../../components/shared/ACFormFields/ACTextArea";
import ACTextBox from "../../../../components/shared/ACFormFields/ACTextBox";
import ACLoadingPanel from "../../../../components/shared/ACLoadingPanel";
import {
  ContentType,
  RAFButtonConstant,
  RAFDocumentType,
  RAFLayout,
} from "../../../../constants/Common/Constants";
import { RAFEntityName } from "../../../../constants/Common/EntityConstants";
import { PermissionConstants } from "../../../../constants/Common/PermissionConstants";
import { NavigateParamsProps } from "../../../../router";
import CreateContent from "../../../Common/Create/CreateContent";
import RAFEntityProvider from "../../../Common/Providers/RAFEntityProvider";
import RAFObjectContextProvider, {
  RAFObjectContext,
} from "../../../Common/Providers/RAFObjectContextProvider";
import EditContent from "../../../Common/Update/EditContent";
import { ContentLibraryRow } from "../Library/ContentLibraryRow";
import {
  retrieveDocumentByFileName,
  retrieveDocumentByID,
} from "../Library/DocumentHelper";

const RAFForm = React.lazy(() =>
  lazyRetry(() => import("../../../../RAFComponents/Inputs/RAFForm"), "RAFForm")
);

interface IProps {
  contentLibraryUID: string;
  contentLibraryRow?: ContentLibraryRow;
  onCancel: () => void;
  onUpdate: () => void;
}

interface IState {
  isActive: boolean;
  contentLibraryRow: ContentLibraryRow;
  allDocumentEnableForms: EntityRow[];
  selectedRelatedForm: EntityRow;
}

class RAFManageDocumentProperties extends Component<
  IDialogProps & RAFFormComponentProps & NavigateParamsProps & IProps,
  IState
> {
  public rafForm: FormRenderProps | null;
  public rafRelatedDocumentForm: FormRenderProps | null;

  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      isActive: false,
      contentLibraryRow: null,
      allDocumentEnableForms: null,
      selectedRelatedForm: null,
    };
  }

  componentDidMount = () => {
    this._isMounted = true;
    this.refresh();
  };

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(prevProps /*, prevState*/) {
    if (
      !deepEqual(prevProps.isActive, this.props.isActive) ||
      !deepEqual(prevProps.contentLibraryUID, this.props.contentLibraryUID) ||
      !deepEqual(prevProps.contentLibraryRow, this.props.contentLibraryRow)
    ) {
      if (this._isMounted) {
        //this.getBusinessRuleActions();
        this.setState(
          {
            isActive: false,
            contentLibraryRow: null,
          },
          () => {
            this.refresh();
          }
        );
      }
    }
  }

  refresh = async () => {
    let progressDiv = showProgress("#manageDocPropertiesDialog");
    if (
      isNotNullAndUndefined(this.props.contentLibraryRow) &&
      isNotNullAndUndefined(this.props.contentLibraryRow.UID)
    ) {
      if (isNotNullAndUndefined(this.props.contentLibraryRow)) {
        const allDocumentEnableForms = await getAllDynamicFormsByEntityName(
          RAFEntityName.ContentLibrary
        );
        hideProgress(progressDiv);
        if (this._isMounted) {
          this.setState({
            contentLibraryRow: this.props.contentLibraryRow,
            isActive: this.props.isActive,
            allDocumentEnableForms,
          });
        }
      }
    } else {
      retrieveDocumentByID(this.props.contentLibraryUID)
        .then(async (contentLibraryRow) => {
          const allDocumentEnableForms = await getAllDynamicFormsByEntityName(
            RAFEntityName.ContentLibrary
          );
          hideProgress(progressDiv);
          if (this._isMounted) {
            this.setState({
              contentLibraryRow,
              isActive: this.props.isActive,
              allDocumentEnableForms,
            });
          }
        })
        .catch((error) => error);
    }
  };

  isFileExists = (contentLibraryRow: ContentLibraryRow) => {
    return new Promise<boolean>(async (resolve) => {
      if (isNotNullAndUndefined(contentLibraryRow)) {
        let _isFileExists;
        const response = await retrieveDocumentByFileName(
          contentLibraryRow.DocumentType === RAFDocumentType.Folder ? contentLibraryRow.DisplayName : contentLibraryRow.FileName,
          null,
          null,
          contentLibraryRow.ParentUID,
          contentLibraryRow.DocumentType
        );
        if (isNotNullAndUndefined(response)) {
          let item: ContentLibraryRow = response;
          if (
            isNotNullAndUndefined(contentLibraryRow.UID) &&
            isNotNullAndUndefined(item.UID) &&
            item.UID === contentLibraryRow.UID
          ) {
            _isFileExists = false;
          } else {
            _isFileExists = true;
          }
        }
        resolve(_isFileExists);
      } else {
        resolve(false);
      }
    });
  };

  onSubmitDocument = async (value) => {
    let { selectedRelatedForm } = this.state;
    let contentLibraryRow: ContentLibraryRow = value as ContentLibraryRow;
    let allowSubmit = true;
    if (contentLibraryRow.DocumentType === RAFDocumentType.Page) {
      contentLibraryRow.FileName = contentLibraryRow.DisplayName;
    }
    if (
      isNotNullAndUndefined(contentLibraryRow.ContentUID) ||
      isNotNullAndUndefined(contentLibraryRow.RecordType)
    ) {
      if (
        isNotNullAndUndefined(this.rafRelatedDocumentForm) &&
        this.rafRelatedDocumentForm.invalid === true
      ) {
        allowSubmit = false;
        this.rafRelatedDocumentForm.form.submit();
      }

      if (allowSubmit) {
        let progressDiv = showProgress("#manageDocPropertiesDialog");
        let _isFileExists = await this.isFileExists(contentLibraryRow);
        if (_isFileExists !== true) {
          repositoryActions
            .postDataAndGetResponse(
              "ContentLibrary/Save",
              getSaveRequest(contentLibraryRow, contentLibraryRow.UID),
              { ...this.props },
              ContentType.applicationJson
            )
            .then((response) => {
              if (
                isNotNullAndUndefined(response) &&
                isNotNullAndUndefined(response.data) &&
                isNotNullAndUndefined(response.data.EntityId) &&
                isNotNullAndUndefined(response.data.ObjectName)
              ) {
                if (isNotNullAndUndefined(selectedRelatedForm)) {
                  let entityName = isNotNullAndUndefined(
                    contentLibraryRow.Content
                  )
                    ? contentLibraryRow.Content
                    : selectedRelatedForm.EntityName;
                  this.onSubmitDocumentSubForm(
                    contentLibraryRow,
                    entityName
                  ).then((formObjectId) => {
                    if (isNotNullAndUndefined(formObjectId)) {
                      hideProgress(progressDiv);
                      showSuccessToast("Updated Successfully");
                      if (this.props.onUpdate) {
                        this.props.onUpdate();
                      }
                    }
                  });
                } else if (isNotNullAndUndefined(contentLibraryRow.Content)) {
                  this.onSubmitDocumentSubForm(
                    contentLibraryRow,
                    contentLibraryRow.Content
                  ).then((formObjectId) => {
                    if (isNotNullAndUndefined(formObjectId)) {
                      hideProgress(progressDiv);
                      showSuccessToast("Updated Successfully");
                      if (this.props.onUpdate) {
                        this.props.onUpdate();
                      }
                    }
                  });
                } else {
                  hideProgress(progressDiv);
                  showSuccessToast("Updated Successfully");
                  if (this.props.onUpdate) {
                    this.props.onUpdate();
                  }
                }
              } else {
                hideProgress(progressDiv);
                showWarningToast("Sorry something went wrong !");
              }
            })
            .catch((error) => error);
        } else {
          hideProgress(progressDiv);
          showWarningToast(
            contentLibraryRow.DisplayName +
            " matches an existing item in this folder. Please choose a different name."
          );
        }
      }
    } else {
      let progressDiv = showProgress("#manageDocPropertiesDialog");
      let _isFileExists = await this.isFileExists(contentLibraryRow);
      if (_isFileExists !== true) {
        repositoryActions
          .postDataAndGetResponse(
            "ContentLibrary/Save",
            getSaveRequest(contentLibraryRow, contentLibraryRow.UID),
            { ...this.props },
            ContentType.applicationJson
          )
          .then((response) => {
            hideProgress(progressDiv);
            if (
              isNotNullAndUndefined(response) &&
              isNotNullAndUndefined(response.data) &&
              isNotNullAndUndefined(response.data.EntityId) &&
              isNotNullAndUndefined(response.data.ObjectName)
            ) {
              showSuccessToast("Updated Successfully");
              if (this.props.onUpdate) {
                this.props.onUpdate();
              }
            } else {
              hideProgress(progressDiv);
              showWarningToast("Sorry something went wrong !");
            }
          })
          .catch((error) => error);
      } else {
        hideProgress(progressDiv);
        showWarningToast(
          contentLibraryRow.DisplayName +
          " matches an existing item in this folder. Please choose a different name."
        );
      }
    }
  };

  onSubmitDocumentSubForm = (
    contentLibrary?: ContentLibraryRow,
    entityName?: string
  ) => {
    let values = getFormValue(this.rafRelatedDocumentForm);
    let saveRequestData = getSaveRequest(values, contentLibrary.UID);
    saveRequestData.EntityName = entityName;
    return new Promise<string>((resolve) => {
      repositoryActions
        .postDataAndGetResponse(
          "datalist/save",
          saveRequestData,
          null,
          ContentType.applicationJson
        )
        .then((response) => {
          if (
            isNotNullAndUndefined(response) &&
            isNotNullAndUndefined(response.data) &&
            isNotNullAndUndefined(response.data.EntityId)
          ) {
            resolve(response.data.EntityId);
          } else {
            resolve(null);
          }
        })
        .catch((error) => error);
    });
  };

  onChangeRecordType = (value) => {
    if (isNotNullAndUndefined(value)) {
      let { allDocumentEnableForms } = this.state;
      let selectedRelatedForm = isNotEmptyArray(allDocumentEnableForms)
        ? allDocumentEnableForms.find((x) => x.EntityName === value)
        : null;
      this.setState({
        selectedRelatedForm: isNotNullAndUndefined(selectedRelatedForm)
          ? selectedRelatedForm
          : null,
      });
    } else {
      this.setState(
        {
          selectedRelatedForm: null,
        },
        () => {
          this.rafRelatedDocumentForm = null;
        }
      );
    }
  };

  render() {
    let { contentLibraryRow, isActive, selectedRelatedForm } = this.state;

    /*  switch (fileExtension) {
              case 'doc':
                  fileClassname = 'fiv-viv fiv-icon-doc';
                  break;
              case 'docx':
                  fileClassname = 'fiv-viv fiv-icon-docx';
                  break;
              case 'txt':
                  fileClassname = 'fiv-viv fiv-icon-txt';
                  break;
              case 'pdf':
                  fileClassname = 'fiv-viv fiv-icon-pdf';
                  break;
              case 'xlsx':
                  fileClassname = 'fiv-viv fiv-icon-xls';
                  break;
              case 'csv':
                  fileClassname = 'fiv-viv fiv-icon-csv';
                  break;
              case 'png':
                  fileClassname = 'fiv-viv fiv-icon-png';
                  break;
              case 'jpeg':
                  fileClassname = 'fiv-viv fiv-icon-jpeg';
                  break;
              case 'jpg':
                  fileClassname = 'fiv-viv fiv-icon-jpg';
                  break;
              case 'gif':
                  fileClassname = 'fiv-viv fiv-icon-gif';
                  break;
              default:
                  fileClassname = 'fiv-viv fiv-icon-txt';
                  break;
          }*/

    if (
      isActive === true &&
      isNotNullAndUndefined(contentLibraryRow) &&
      isNotNullAndUndefined(contentLibraryRow.UID)
    ) {
      let fileClassname;
      const fileExtension = getFileExtension(contentLibraryRow.FileName);

      if (
        contentLibraryRow.DocumentType === RAFDocumentType.File &&
        isNotNullAndUndefined(fileExtension)
      ) {
        fileClassname = "fa-md fiv-viv fiv-icon-blank fiv-icon-" + fileExtension;
      }
      if (contentLibraryRow.DocumentType === RAFDocumentType.Folder) {
        fileClassname = "fa-md fiv-viv fiv-icon-folder";
      }
      if (contentLibraryRow.DocumentType === RAFDocumentType.Page) {
        fileClassname = "fa-md fiv-viv fiv-icon-blank fiv-icon-html";
      }

      return (
        <Suspense fallback={<ACLoadingPanel loadingText="Loading..." />}>
          <div className="update-page rafContentDetails h-100">
            <RAFForm
              type={ContentLibraryRow}
              submitOnEnterKey={false}
              initialValues={contentLibraryRow}
              formRef={(g) => (this.rafForm = g)}
              layout={RAFLayout.OneColumnLayout}
              onSubmit={this.onSubmitDocument}
              className="h-100"
            >
              <div className="e-dlg-content-outer">
                <div className="e-dlg-headerContent">
                  <div className="e-dlg-header">
                    Edit Document
                    {/* <span className={fileClassname + " me-2"}></span>
                    {contentLibraryRow.DisplayName} */}
                  </div>
                  <RAFButtonComponent
                    displayMode="DialogCloseBtn"
                    onClick={() => this.props.onCancel()}
                  ></RAFButtonComponent>
                  {/* <ButtonComponent
                    type="button"
                    cssClass="primary-custom-button"
                    iconCss="fas fa-xmark"
                    onClick={() => this.props.onCancel()}
                  ></ButtonComponent> */}
                </div>
                <div className="e-dlg-body-content p-3 document-details-page">
                  <CustomCardWidget cardClassName="mb-3" cardContentClassName="p-2">
                    <div className="row gx-2 align-items-center">
                      <div className="col-auto">
                        <CustomCardWidget cardClassName="border-0 surface_neutral_base" removeContentPadding>
                          <div className="profilPhotoDiv-container surface_neutral_base shadow-none">
                            <div className="profileImg-container profileImg-container-normal document-item-icon surface_neutral_base">
                              <span className={fileClassname}></span>
                            </div>
                          </div>
                        </CustomCardWidget>
                      </div>
                      <div className="col">
                        <div className="subtitle_1"><span>{contentLibraryRow.DisplayName}</span>
                        </div>
                      </div>
                    </div>
                  </CustomCardWidget>
                  <div>
                    <ACTextBox<ContentLibraryRow>
                      field="DisplayName"
                      label="Name"
                      required
                      placeholder="File Name"
                    />
                    {/* <ACDropdown<ContentLibraryRow> field="Category" label="Category" placeholder="Select Category" moduleName={this.moduleName} valueField="DisplayName" allowAdd={false} /> */}
                    {/* {contentLibraryRow.DocumentType !== RAFDocumentType.Folder && (
                                    <ACDropdown<ContentLibraryRow> field="CurrentStatus" label="Status" showLabel
                                        placeholder="Select Status" allowAdd={false} required>
                                        <RAFChoiceOption label={ContentLibraryCurrentStatus.Draft} value={ContentLibraryCurrentStatus.Draft} />
                                        <RAFChoiceOption label={ContentLibraryCurrentStatus.Published} value={ContentLibraryCurrentStatus.Published} />
                                    </ACDropdown>
                                )} */}
                    <ACTextArea<ContentLibraryRow>
                      field="Notes"
                      showLabel
                      label="Description"
                      placeholder="Description"
                      rows={3}
                    />
                    {/* <RAFTagInput<ContentLibraryRow> field="TagsListJson" label="Tags" showAddBtn placeholder="Type and press enter to add a tag"
                                    //onChanged={() => { this.rafForm && this.rafForm.form.submit() }}
                                    /> */}
                    {isNotNullAndUndefined(contentLibraryRow.ContentUID) ? (
                      <RAFEntityProvider moduleName={contentLibraryRow.Content}>
                        <RAFObjectContextProvider
                          moduleName={contentLibraryRow.Content}
                          objectId={contentLibraryRow.UID}
                          progressDivId={"manageDocPropertiesDialog"}
                        >
                          <RAFObjectContext.Consumer>
                            {({ /*objectId,*/ rafObject, saveObject }) => {
                              return (
                                <RAFForm
                                  initialValues={rafObject}
                                  formRef={(g) => {
                                    return (this.rafRelatedDocumentForm = g);
                                  }}
                                  layout={RAFLayout.TwoColumnLayout}
                                  // onSubmit={(
                                  //   values
                                  // ) =>
                                  //   this.onSubmitRelatedForm(
                                  //     values,
                                  //     saveObject
                                  //   )
                                  // }
                                  submitOnEnterKey={false}
                                >
                                  <EditContent
                                    key={contentLibraryRow.UID}
                                    moduleName={contentLibraryRow.Content}
                                    progressDivId="manageDocPropertiesDialog"
                                  />
                                </RAFForm>
                              );
                            }}
                          </RAFObjectContext.Consumer>
                        </RAFObjectContextProvider>
                      </RAFEntityProvider>
                    ) : (
                      <ConditionNot when={propertyOf<ContentLibraryRow>('DocumentType')} isNot={RAFDocumentType.Folder}>
                        <div>
                          <RAFDropdownCC<ContentLibraryRow>
                            field="RecordType"
                            label="Content Type"
                            placeholder="Select content type"
                            moduleName={RAFEntityName.ContentLibrary}
                            createOptionMode="Footer"
                            isColorOption
                            allowAdd
                            mode="discView"
                            uitype="colorpicker"
                            onChanged={(label, value) =>
                              this.onChangeRecordType(value)
                            }
                          ></RAFDropdownCC>

                          <RAFTagInput<ContentLibraryRow>
                            field="TagsListJson"
                            label="Tags"
                            showAddBtn
                            showLabel={true}
                          />
                          <ConditionNotNullOrWhiteSpace when={"RecordType"}>
                            {isNotNullAndUndefined(selectedRelatedForm) &&
                              isNotNullAndUndefined(
                                selectedRelatedForm.EntityName
                              ) &&
                              isNotNullAndUndefined(selectedRelatedForm.UID) && (
                                <div
                                  className="removeformPageContainerPadding"
                                  key={
                                    isNotNullAndUndefined(selectedRelatedForm)
                                      ? selectedRelatedForm.UID
                                      : null
                                  }
                                >
                                  <RAFEntityProvider
                                    moduleName={selectedRelatedForm.EntityName}
                                  >
                                    <RAFObjectContextProvider
                                      moduleName={selectedRelatedForm.EntityName}
                                    >
                                      <RAFObjectContext.Consumer>
                                        {({
                                        /*objectId, rafObject,*/ saveObject,
                                        }) => {
                                          return (
                                            <RAFForm
                                              //initialValues={initalObject}
                                              formRef={(g) => {
                                                return (this.rafRelatedDocumentForm =
                                                  g);
                                              }}
                                              layout={RAFLayout.TwoColumnLayout}
                                              // onSubmit={(
                                              //   values
                                              // ) =>
                                              //   this.onSubmitRelatedForm(
                                              //     values,
                                              //     saveObject
                                              //   )
                                              // }
                                              submitOnEnterKey={false}
                                            //decorators={[accountDecorators]}
                                            //convertBeforeSave={ConvertAccountRowToAccountDto}
                                            >
                                              <CreateContent
                                                key={selectedRelatedForm.UID}
                                                moduleName={
                                                  selectedRelatedForm.EntityName
                                                }
                                                isPreviewMode={true}
                                                progressDivId="manageDocPropertiesDialog"
                                              />
                                            </RAFForm>
                                          );
                                        }}
                                      </RAFObjectContext.Consumer>
                                    </RAFObjectContextProvider>
                                  </RAFEntityProvider>
                                </div>
                              )}
                          </ConditionNotNullOrWhiteSpace>
                        </div>
                      </ConditionNot>
                    )}
                  </div>
                </div>
                <div className="e-dlg-footerContent">
                  <div className="w-100">
                    <div className="row gx-2 justify-content-end">
                      <div className="col-auto">
                        <RAFButtonComponent action={RAFButtonConstant.Cancel}
                          onClick={() => this.props.onCancel()}
                            className="form-custom-button"
                        ></RAFButtonComponent>
                      </div>
                      <RAFPermissionRender
                        permissionName={PermissionConstants.DocumentUpdate}
                      >
                        <div className="col-auto">
                          <RAFButtonComponent action={RAFButtonConstant.Save}
                            className="btn_brand_primary form-custom-button semi_dark"
                            type="submit"
                          ></RAFButtonComponent>
                        </div>
                      </RAFPermissionRender>
                    </div>
                  </div>
                </div>
              </div>
            </RAFForm>
          </div>
        </Suspense>
      );
    } else {
      return (
        <div
          className="container-fluid px-0"
          style={{ background: "transparent" }}
        >
          {/*<ACLoadingPanel /> */}
        </div>
      );
    }
  }
}

export default RAFManageDocumentProperties;
