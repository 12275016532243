import {
  ButtonComponent,
  ChipDirective,
  ChipListComponent,
  ChipsDirective,
} from "@syncfusion/ej2-react-buttons";
import { DialogComponent } from "@syncfusion/ej2-react-popups";
import * as React from "react";
import {
  Fragment,
  PropsWithChildren,
  Reducer,
  useEffect,
  useReducer,
  useRef,
} from "react";
import { Field, FormRenderProps } from "react-final-form";
import RAFFieldLabel from "../../../../RAFComponents/Inputs/RAFFieldLabel";
import {
  RAFFieldError,
  composeValidators,
} from "../../../../RAFComponents/Inputs/RAFForm";
import {
  RAFDefaultFieldProps,
  RAFFormContext,
  RAFLookupFieldProps,
  getFormValue,
  isRequired,
} from "../../../../RAFComponents/Inputs/RFFUtils";
import {
  RAFCustomFilter,
  RAFCustomOperator,
} from "../../../../RAFComponents/RAFViewPanels/RAFFilterColumn/RAFCustomFilter";
import {
  getAllLookUpList,
  hideProgress,
  showProgress,
} from "../../../../RAFComponents/helpers/AppHelper";
import { RAFSort } from "../../../../RAFComponents/helpers/types";
import {
  Guid,
  IsNullOrWhiteSpace,
  isNotEmptyArray,
  isNotNullAndUndefined,
  propertyOf,
} from "../../../../RAFComponents/helpers/utils";
import { LookUpRow } from "../../../../RAFComponents/models/CompositeTypes/LookUpRow";
import { RAFDocumentType } from "../../../../constants/Common/Constants";
import { ContentLibraryRow } from "../Library/ContentLibraryRow";
import { getAllParentFolderItems } from "../Library/DocumentHelper";
import RAFDocFolderSelectTree from "./RAFDocFolderSelectTree";

interface IProps {
  rootDocFolderRow?: ContentLibraryRow;
}

interface IState {
  isActive: boolean;
  lookUpDataItems: LookUpRow[];
  documentTreeViewItems: ContentLibraryRow[];
}

function RAFDocFolderInput<T>({
  field,
  label,
  width,
  type,
  onChanged,
  textField,
  url,
  customFilter,
  //children,
  //closeToolTip,
  moduleName,
  labelClassName,
  description,


  showFullList = true,
  closeToolTip = true,
  SearchCreateOptionMode = "Default",
  required = RAFDefaultFieldProps.required,
  showLabel = RAFDefaultFieldProps.showLabel,
  disabled = RAFDefaultFieldProps.disabled,
  showClearButton = RAFDefaultFieldProps.showClearButton,
  validate = RAFDefaultFieldProps.validate,
  ...props
}: PropsWithChildren<RAFLookupFieldProps<T> & IProps>) {
  let addItemDialogRef = useRef<DialogComponent>(null);
  const rafFormContextValue: FormRenderProps = React.useContext(RAFFormContext);
  const objGuid = Guid.newGuid();
  const [state, setState] = useReducer<Reducer<IState, Partial<IState>>>(
    (state, newState) => ({ ...state, ...newState }),
    {
      isActive: false,
      lookUpDataItems: null,
      documentTreeViewItems: null,
    }
  );

  useEffect(() => {
    refresh();
  }, [moduleName, props.rootDocFolderRow]);

  const refresh = async () => {
    let progressDiv = showProgress(`#docFolderInput_${objGuid}`);
    let { rootDocFolderRow } = props;
    let relatedEntity;
    if (isNotNullAndUndefined(rootDocFolderRow)) {
      if (rootDocFolderRow.IsExternal) {
        if (isNotNullAndUndefined(rootDocFolderRow.FileName))
          relatedEntity = rootDocFolderRow.FileName;
      } else if (isNotNullAndUndefined(rootDocFolderRow.Entity)) {
        relatedEntity = rootDocFolderRow.Entity;
      }
    }

    const sortBy: RAFSort = {
      field: propertyOf<ContentLibraryRow>("DisplayName"),
      order: "ascending",
    };
    const customFilter = getDocFolderLookupFilter(relatedEntity);
    let documentTreeViewItems = await getAllParentFolderItems(
      null,
      relatedEntity,
      null
    );
    let lookUpDataItems = await getAllLookUpList(
      moduleName,
      url,
      customFilter,
      sortBy
    );

    if (isNotNullAndUndefined(rootDocFolderRow)) {
      if (isNotEmptyArray(documentTreeViewItems)) {
        documentTreeViewItems.unshift(rootDocFolderRow);
      } else {
        documentTreeViewItems = [rootDocFolderRow];
      }

      if (isNotEmptyArray(lookUpDataItems)) {
        lookUpDataItems.unshift({
          UID: rootDocFolderRow.UID,
          Value: rootDocFolderRow.DisplayName,
        });
      } else {
        lookUpDataItems = [
          { UID: rootDocFolderRow.UID, Value: rootDocFolderRow.DisplayName },
        ];
      }
    }
    hideProgress(progressDiv);
    setState({ lookUpDataItems, documentTreeViewItems, isActive: true });
  };

  const getDocFolderLookupFilter = (moduleName: string) => {
    let customFilter: RAFCustomFilter = {};
    customFilter.Condition = "and";
    customFilter.Rules = [];

    //doc start
    let filterDocType: RAFCustomFilter = {};
    let filterValDocType: string[] = [];

    filterValDocType.push(RAFDocumentType.Folder);
    filterDocType.Operator = RAFCustomOperator.Equal;
    filterDocType.Value = filterValDocType;
    filterDocType.Field = propertyOf<ContentLibraryRow>("DocumentType");
    customFilter.Rules.push(filterDocType);
    //doc end

    //enityName start
    if (isNotNullAndUndefined(moduleName)) {
      let filterModule: RAFCustomFilter = {};
      let filterValModule: string[] = [];

      filterValModule.push(moduleName);
      filterModule.Operator = RAFCustomOperator.Equal;
      filterModule.Value = filterValModule;
      filterModule.Field = propertyOf<ContentLibraryRow>("Entity");
      customFilter.Rules.push(filterModule);
    }
    //enityName end

    return customFilter;
  };

  //treeViewDialog start
  const onClickAddItemDialog = () => {
    if (
      isNotNullAndUndefined(addItemDialogRef) &&
      isNotNullAndUndefined(addItemDialogRef.current) &&
      disabled !== true
    ) {
      addItemDialogRef.current.show();
    }
    // setState({
    //   showAddItemDialog: true
    // });
  };

  function addItemDialogContent() {
    return (
      <RAFDocFolderSelectTree
        field={field.toString()}
        lookUpUrl={url}
        moduleName={moduleName}
        lookUpDataItems={state.lookUpDataItems}
        documentTreeViewItems={state.documentTreeViewItems}
        onChanged={onChangeFiledValue}
        rootDocFolderRow={props.rootDocFolderRow}
        onClose={onClickCloseAddItemDialog}
      />
    );
  }

  function onChangeFiledValue(label, value) {
    if (isNotNullAndUndefined(onChanged)) {
      onChanged(label, value);
    }
    //onClickCloseAddItemDialog()
  }

  const onClickCloseAddItemDialog = () => {
    if (
      isNotNullAndUndefined(addItemDialogRef) &&
      isNotNullAndUndefined(addItemDialogRef.current)
    ) {
      addItemDialogRef.current.hide();
    }
    // setState({
    //   showAddItemDialog: false
    // });
  };
  //treeViewDialog end

  const getDisplayText = (
    selectedDocumentRow: ContentLibraryRow,
    documentTreeViewItems: ContentLibraryRow[]
  ) => {
    let documentPathArray = getDocumentFolderParents(
      selectedDocumentRow,
      documentTreeViewItems
    );
    let displayText = null;
    isNotEmptyArray(documentPathArray) &&
      documentPathArray.reverse().forEach((item) => {
        if (isNotNullAndUndefined(item))
          displayText = isNotNullAndUndefined(displayText)
            ? `${displayText}/${item.DisplayName}`
            : item.DisplayName;
      });
    return displayText;
  };

  const getDocumentFolderParents = (
    selectedDocumentRow: ContentLibraryRow,
    documentTreeViewItems: ContentLibraryRow[]
  ) => {
    let newDocumentBreadCrumb: ContentLibraryRow[] = [];
    if (
      isNotEmptyArray(documentTreeViewItems) &&
      isNotNullAndUndefined(selectedDocumentRow)
    ) {
      newDocumentBreadCrumb = getAllParentsNodes(
        documentTreeViewItems,
        selectedDocumentRow.UID
      );
      if (isNotEmptyArray(newDocumentBreadCrumb)) {
        newDocumentBreadCrumb.unshift(selectedDocumentRow);
      } else {
        newDocumentBreadCrumb = [selectedDocumentRow];
      }
    }

    return newDocumentBreadCrumb;
  };

  function getAllParentsNodes(
    documentTreeViewItems: ContentLibraryRow[],
    documentUID: string
  ) {
    const item = isNotNullAndUndefined(documentUID)
      ? documentTreeViewItems.find((x) => x.UID === documentUID)
      : null;
    if (
      isNotNullAndUndefined(item) &&
      isNotNullAndUndefined(item.ParentUID) &&
      item.IsExternal !== true
    ) {
      const parent = documentTreeViewItems.find(
        (x) => x.UID === item.ParentUID
      );
      let parentUID = isNotNullAndUndefined(parent) ? parent.UID : null;
      return [parent, ...getAllParentsNodes(documentTreeViewItems, parentUID)];
    }
    return [];
  }

  return (
    <div
      className={
        isNotNullAndUndefined(props.formGroupClassName)
          ? props.formGroupClassName + " form-group"
          : "form-group"
      }
      id={`docFolderInput_${objGuid}`}
    >
      <div className="row gy-2 g-0" id={"rafdiv" + field.toString()}>
        {showLabel === true && (
          <RAFFieldLabel
            field={field}
            label={label}
            required={required}
            labelClassName={labelClassName}
            description={description}
          />
        )}
        <div className="col-12">
          <Field
            name={field.toString()}
            {...(props.initialValue
              ? { initialValue: props.initialValue }
              : {})}
            //validate={validate === true ? (required && isRequired) : null}
            {...(props.validators
              ? {
                validate:
                  validate === true
                    ? composeValidators(
                      required === true ? isRequired : null,
                      ...props.validators
                    )
                    : null,
              }
              : {
                validate:
                  validate === true
                    ? composeValidators(required === true ? isRequired : null)
                    : null,
              })}
            allowNull
            parse={(value) => (value === "" ? null : value)}
          >
            {({ input, meta }) => {
              let fieldValue = input.value;
              const fieldValueUID = getFormValue(
                rafFormContextValue,
                `${field.toString()}UID`
              );

              let displayText: string;

              if (
                isNotNullAndUndefined(fieldValueUID) &&
                isNotEmptyArray(state.documentTreeViewItems)
              ) {
                let selectedItem: ContentLibraryRow =
                  state.documentTreeViewItems.find(
                    (x) => x.UID === fieldValueUID
                  );
                if (isNotNullAndUndefined(selectedItem))
                  displayText = getDisplayText(
                    selectedItem,
                    state.documentTreeViewItems
                  );
              }

              return (
                <div>
                  <Fragment>
                    {state.isActive === true &&
                      !IsNullOrWhiteSpace(fieldValue) && (
                        <div className="row align-items-center gx-3 gy-2 customChiplist" key={fieldValueUID}>
                          <div className="col-auto">
                            <ChipListComponent
                              id="chip-avatar"
                              cssClass="e-outline"
                            >
                              <ChipsDirective>
                                <ChipDirective
                                  text={
                                    !IsNullOrWhiteSpace(displayText)
                                      ? displayText
                                      : fieldValue
                                  }
                                ></ChipDirective>
                              </ChipsDirective>
                            </ChipListComponent>
                          </div>
                          <div className="col-auto">
                            {disabled !== true && (
                              <ButtonComponent
                                id="btn_open_add_dialog"
                                content="Change"
                                cssClass="link-button"
                                iconCss="fas fa-pen-to-square"
                                type="button"
                                disabled={disabled}
                                onClick={() => onClickAddItemDialog()}
                              ></ButtonComponent>
                            )}
                          </div>
                        </div>
                      )}
                  </Fragment>
                  {props.hideRequiredMessage !== true ? (
                    <div className="col">
                      <RAFFieldError name={field.toString()} />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              );
            }}
          </Field>

          {state.isActive && (
            <DialogComponent
              //header={field.toString()}
              header={!IsNullOrWhiteSpace(label) ? label : field.toString()}
              showCloseIcon
              //visible={state.showAddItemDialog}
              visible={false}
              cssClass="rightDialog createEditForm full-height form-center-dialog"
              content={addItemDialogContent.bind(this)}
              isModal
              target="body"
              closeOnEscape={false}
              overlayClick={onClickCloseAddItemDialog.bind(this)}
              close={onClickCloseAddItemDialog.bind(this)}
              ref={addItemDialogRef}
            ></DialogComponent>
          )}
        </div>
      </div>
    </div>
  );
}

export default RAFDocFolderInput;
