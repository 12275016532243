import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import { AnimationSettingsModel, DialogComponent, DialogUtility, TooltipComponent } from "@syncfusion/ej2-react-popups";
import { Component } from "react";
import RAFFieldStateProvider from "../../../RAFComponents/Grid/RAFFieldStateProvider";
import RAFGridCC from "../../../RAFComponents/Grid/RAFGridCC";
import RAFReportDataList from "../../../RAFComponents/List/RAFReportDataList";
import { createInstance } from "../../../RAFComponents/Utility/FormUtility";
import { DeleteRecord, hideProgress, showProgress } from "../../../RAFComponents/helpers/AppHelper";
import { IsNullOrWhiteSpace, deepEqual, isNotNullAndUndefined, propertyOf } from "../../../RAFComponents/helpers/utils";
import { ListServiceRequest } from "../../../RAFComponents/models/Common/ListRequest";
import nodata_es from "../../../assets/ES/nodata_es.svg";
import ACLoadingPanel from "../../../components/shared/ACLoadingPanel";
import { Constants, RAFDocumentType, RAFTreeViewMenu, rafNoViewName } from "../../../constants/Common/Constants";
import RAFGridTemplates, { getGridColumnTemplatesByModule } from "../../../helpers/RAFGridTemplates";
import { NavigateParamsProps } from "../../../router";
import { getGridColumnsByModuleAndView, getViewsReportsDashboards } from "../../Common/List/IndexHelper";
import { ContentLibraryColumnWidths } from "./DocumentGridColumnTemplate";
import { ContentLibraryRow } from "./Library/ContentLibraryRow";
import DocumentDetailsCenterDlg from "./Library/DocumentDetailsCenterDlg";
import { DocumentRetrieveRequest, downloadDocumentFile, retrieveDocumentByID } from "./Library/DocumentHelper";
import RAFManageDocumentProperties from "./ManageDocument/RAFManageDocumentProperties";
import RAFManageUploadDocumentFile from "./ManageDocument/RAFManageUploadDocumentFile";

interface IProps {
    isActive: boolean;
    viewName?: string,
    moduleName?: string,
    onDocumentLookupGridClose?: (isDocumentUpdated: boolean) => void;
    viewUID?: string;
    relatedToUID?: string,
    relatedEntity?: string,
    relatedToType?: string,
    secondaryRelatedToUID?: string;
    secondaryRelatedToType?: string;
    rowclicked?: (value) => void;
}

interface IState {
    isLoading: boolean;
    isActive: boolean;
    showfileDetailsContent: boolean;
    showManageDocProperties: boolean;
    showUploadContent: boolean;
    selectedDocumentRow: ContentLibraryRow;
    isDocumentUpdated: boolean;
    filterBarVisibility?: boolean;
    viewMenuDataSource: RAFTreeViewMenu[],
    currentView: RAFTreeViewMenu;
}

class DocumentLookupGrid extends Component<NavigateParamsProps & IProps, IState> {

    private _isMounted: boolean;
    animationSettings: AnimationSettingsModel;

    private deleteDialog: any;
    dropDownListComponent: DropDownListComponent;
    private moduleName: string = createInstance(ContentLibraryRow).getClassName();

    constructor(props) {
        super(props);
        this.animationSettings = { effect: 'Fade' };

        this.state = {
            isLoading: true,
            isActive: false,
            showfileDetailsContent: false,
            showManageDocProperties: false,
            showUploadContent: false,
            isDocumentUpdated: false,
            selectedDocumentRow: null,
            filterBarVisibility: false,
            currentView: null,
            viewMenuDataSource: null,
        };
    }

    componentDidMount = () => {
        this._isMounted = true;
        if (this.props.isActive === true) {
            this.refresh(true);
        }
    };

    componentWillUnmount() {
        this._isMounted = false;
    }


    componentDidUpdate(prevProps /*, prevState*/) {
        if (!deepEqual(prevProps.viewUID, this.props.viewUID) ||
            !deepEqual(prevProps.relatedToUID, this.props.relatedToUID) ||
            !deepEqual(prevProps.isActive, this.props.isActive)
        ) {
            if (this._isMounted) {
                this.setState({ isLoading: true }, () => {
                    this.refresh();
                });
            }
        }
    }

    refresh = (isActive?: boolean) => {
        this.getViewItems();
    };

    getViewItems = async () => {
        if (this.props.isActive) {
            if (isNotNullAndUndefined(this.props.viewUID) || isNotNullAndUndefined(this.props.viewName)) {
                let currentView: RAFTreeViewMenu = {
                    UID: this.props.viewUID,
                    Name: this.props.viewName,
                };
                this.setState({
                    currentView, isActive: true, isLoading: false
                });
            } else {
                let viewMenuDataSource = await getViewsReportsDashboards(this.moduleName, true, true);
                let fields = { text: 'DisplayName', value: 'itemValue' };
                let datasource: { itemValue: string, DisplayName: string, }[] = [];
                if (isNotNullAndUndefined(viewMenuDataSource) && viewMenuDataSource.length > 0) {
                    viewMenuDataSource.forEach((item, index) => {
                        datasource.push({ DisplayName: item.DisplayName, itemValue: isNotNullAndUndefined(item.UID) ? item.UID : item.Name });
                    });
                }
                let currentView = isNotNullAndUndefined(viewMenuDataSource) ? viewMenuDataSource[0] : null;
                this.setState({ isActive: true, isLoading: false, currentView, viewMenuDataSource }, () => {
                    this.dropDownListComponent.value = isNotNullAndUndefined(datasource) && datasource.length > 0 ? datasource[0].itemValue : null;
                    this.dropDownListComponent.dataSource = datasource as any;
                    this.dropDownListComponent.fields = fields;
                });
            }
        } else {
            if (this._isMounted) {
                this.setState({ isActive: false });
            }
        }
    };

    getViewByID() {
        if (this._isMounted) {
            this.setState({ isLoading: true }, () => {
                setTimeout(() => {
                    this.setState({ isLoading: false });
                }, 100);
            });
        }
    }

    private uploadDocument(data?: Object) {
        if (this._isMounted) {
            let documentRow: ContentLibraryRow = data;
            let documentRetrieveRequest: DocumentRetrieveRequest = new DocumentRetrieveRequest();
            documentRetrieveRequest.EntityId = documentRow.UID as any;
            retrieveDocumentByID(documentRow.UID)
                .then((response) => {
                    if (isNotNullAndUndefined(response)) {
                        this.setState({ showUploadContent: true, selectedDocumentRow: response });
                    }
                }).catch((error) => error);
        }
    }

    private uploadDialogClose(): void {
        if (this._isMounted) {
            this.setState({ showUploadContent: false });
        }
    }

    uploadAndLinkDocumentContent() {
        if (this.state.showUploadContent === true) {
            return (
                <RAFManageUploadDocumentFile
                    onSave={(UID) => this.onUploadDocumentFile(UID)}
                    onClose={() => this.uploadDialogClose()}
                    selectedFolder={null}
                    selectedDocumentRow={this.state.selectedDocumentRow}
                    //relatedToUID={this.state.relatedToUID}
                    //relatedEntity={this.props.moduleName}
                    mode={'updateVersion'}
                    isActive
                />
            );
        } else {
            return (
                <div></div>
            );
        }
    }

    onUploadDocumentFile = (UID) => {
        if (this._isMounted) {
            this.setState({ showUploadContent: false }, () => {
                this.setState({ isDocumentUpdated: true }, () => {
                    this.getViewByID();
                });
            });
        }
    };

    DeleteItemClicked(data?: Object) {
        this.deleteDialog = DialogUtility.confirm({
            animationSettings: { effect: 'Fade' },
            cancelButton: { text: 'No' },
            closeOnEscape: false,
            content: "Are you sure want to delete?",
            okButton: { text: 'Yes', click: this.DeleteItemRecord.bind(this, data) },
            title: ' Delete',
            position: { X: 'center', Y: 'center' },
            cssClass: 'alert-dialog',
        });
    }

    DeleteItemRecord(data?: Object) {
        let progressDiv = showProgress('body', true);
        let documentRow: ContentLibraryRow = data as ContentLibraryRow;
        let id = documentRow.UID;
        DeleteRecord(id, this.moduleName).then((response) => {
            hideProgress(progressDiv);
            if (isNotNullAndUndefined(response)) {
                this.deleteDialog.hide();
                if (this._isMounted) {
                    this.setState({ isDocumentUpdated: true }, () => {
                        this.getViewByID();
                    });
                }
            }
        })
            .catch((error) => error);
    }

    fileProperties = (docLibraryRow: ContentLibraryRow) => {
        if (this._isMounted) {
            if (this._isMounted) {
                this.setState({ showfileDetailsContent: true, selectedDocumentRow: docLibraryRow }, () => {
                });
            }
        }
    };

    rowClick = (docLibraryRow: ContentLibraryRow) => {
        if (this.props.rowclicked) {
            this.props.rowclicked(docLibraryRow);
        } else if (docLibraryRow.DocumentType === RAFDocumentType.File) {
            this.fileProperties(docLibraryRow);
        }
    };

    onActionMenuClicked(selectedId: string, docLibraryRow?: Object) {
        switch (selectedId) {
            case 'UploadVersion':
                this.uploadDocument(docLibraryRow);
                break;
            case 'Edit':
                this.manageDocPropertiesDialogOpen(docLibraryRow);
                break;
            case 'Share':
                //console.log('Share Clicked');
                break;
            case 'Email':
                //console.log('Email Clicked');
                break;
            case 'Download':
                this.downloadDocument(docLibraryRow);
                break;
            case 'Delete':
                this.DeleteItemClicked(docLibraryRow);
                break;
            default:
                break;
        }
    }

    downloadDocument = (item) => {
        downloadDocumentFile(item).then((status) => {

        });
    };

    private refreshOnDeleteDocument(): void {
        if (this._isMounted) {
            this.setState({ isDocumentUpdated: true, showfileDetailsContent: false }, () => {
                this.getViewByID();
            });
        }
    }

    private refreshOnUpdateDocument(): void {
        if (this._isMounted) {
            this.setState({ showfileDetailsContent: false, showManageDocProperties: false, isDocumentUpdated: true }, () => {
                this.getViewByID();
            });
        }
    }

    fileDetailsContent() {
        if (this.state.showfileDetailsContent) {
            return (
                <DocumentDetailsCenterDlg
                    onSave={this.refreshOnUpdateDocument.bind(this)}
                    onDelete={this.refreshOnDeleteDocument.bind(this)}
                    onClose={this.fileDetailsDialogClose.bind(this)}
                    documentUID={this.state.selectedDocumentRow.UID}
                    //module={this.state.selectedDocumentRow.Entity}
                    isActive
                    {...this.props} />
            );
        }
        else {
            return (
                <div></div>
            );
        }
    }

    manageDocPropertiesContent() {
        if (this.state.showManageDocProperties && this.state.showManageDocProperties === true) {
            return (<div>
                <RAFManageDocumentProperties
                    onUpdate={this.refreshOnUpdateDocument.bind(this)}
                    onCancel={this.manageDocPropertiesDialogClose.bind(this)}
                    contentLibraryUID={this.state.selectedDocumentRow.UID}
                    isActive {...this.props} />
            </div>
            );
        }
        else {
            return (
                <div></div>
            );
        }
    }

    private manageDocPropertiesDialogClose(): void {
        if (this._isMounted) {
            this.setState({ showManageDocProperties: false, selectedDocumentRow: null }, () => {
            });
        }
    }

    private fileDetailsDialogClose(): void {
        if (this._isMounted) {
            this.setState({ showfileDetailsContent: false, selectedDocumentRow: null }, () => {
            });
        }
    }

    onViewItemChange = (e) => {
        if (isNotNullAndUndefined(e)) {
            if (this._isMounted) {
                this.setState({ isLoading: true }, () => {
                    let { viewMenuDataSource } = this.state;
                    let currentView = isNotNullAndUndefined(viewMenuDataSource) ? viewMenuDataSource.find(x => (isNotNullAndUndefined(x.UID) && x.UID === e.itemValue) || (x.Name === e.itemValue)) : null;
                    setTimeout(() => {
                        this.setState({ isLoading: false, currentView });
                    }, 100);
                });
            }
        }
    };

    onClickCloseDialog = () => {
        //SetGridState("ContentLibraryRow", null)
        if (this.props.onDocumentLookupGridClose) {
            this.props.onDocumentLookupGridClose(this.state.isDocumentUpdated);
        }
    };

    manageDocPropertiesDialogOpen = (selectedDocumentRow: ContentLibraryRow) => {
        document.body.classList.add('overflow-hidden');
        if (this._isMounted) {
            if (this._isMounted) {
                this.setState({ showManageDocProperties: true, selectedDocumentRow }, () => {
                    /*let divDetailsDialog = document.getElementById('documentDetailsDialog');
                    if (isNotNullAndUndefined(divDetailsDialog)) {
                        divDetailsDialog.classList.remove('fade-in');
                    }*/
                });
            }
        }
    };

    private showHideFilters2 = (show: "show" | "hide" | "auto") => {
        this.setState({ filterBarVisibility: (show === 'show' ? true : false) }, () => {
        });
    };

    render() {
        if (isNotNullAndUndefined(this.state.isActive)) {
            let { secondaryRelatedToType, secondaryRelatedToUID, relatedToUID, relatedToType } = this.props;
            let { currentView } = this.state;

            return (
                <div className="e-dlg-content-outer">
                    <div className="d-flex justify-content-between e-dlg-header-content">
                        <div className="d-flex align-items-center">
                            <TooltipComponent content="Document" position="BottomCenter" opensOn="Hover" >
                                <div className={"backArrowIcon avatar-text me-2"}>
                                    <i className={"fa fa-file-alt headerIcon"} ></i>
                                </div>
                            </TooltipComponent>
                            {(isNotNullAndUndefined(this.props.viewUID) || isNotNullAndUndefined(this.props.viewName)) ?
                                (
                                    <div><span className="breadcrumb-text active ecllipseFirstLine text-break ms-2" style={{ fontSize: '18px' }}>Document</span></div>
                                )
                                :
                                (
                                    <div className="d-none">
                                        <DropDownListComponent cssClass="primary-custom-button border-0" change={(e) => this.onViewItemChange(e.itemData)}
                                            ref={e => this.dropDownListComponent = e}
                                            allowFiltering
                                            width="100%"
                                        />
                                    </div>
                                )
                            }
                        </div>
                        <div className="h-100 row g-2">

                            <div className="col-auto">
                                <ButtonComponent type='button' cssClass="primary-custom-button"
                                    iconCss="fas fa-xmark"
                                    onClick={() => this.onClickCloseDialog()}
                                ></ButtonComponent>
                            </div>
                        </div>
                    </div>
                    <div className="e-dlg-body-content h-100 overflow-auto p-0">
                        <div className="e-card-content h-100">
                            <div className="h-100">
                                <div className="relatedListOuterDiv border-top-0 h-100"  >
                                    {this.state.isLoading === false ?
                                        (
                                            isNotNullAndUndefined(currentView) &&
                                                (isNotNullAndUndefined(currentView) ||
                                                    (IsNullOrWhiteSpace(currentView.UID) &&
                                                        currentView.Name !== rafNoViewName)) ? (
                                                <RAFReportDataList
                                                    url={`${Constants.baseAPIUrl}ContentLibrary/RelatedList`}
                                                    // url={getUrlByModuleAndView(
                                                    //     this.moduleName,
                                                    //     currentView,
                                                    //     isNotNullAndUndefined(currentView.UID) ? 'DataList/List' : null
                                                    // )}
                                                    //viewId={currentView.UID}
                                                    //viewName={currentView.Name}
                                                    additionalParams={[
                                                        {
                                                            key: isNotNullAndUndefined(secondaryRelatedToType) ?
                                                                'SecondaryRelatedToType' :
                                                                'RelatedToType',
                                                            value: isNotNullAndUndefined(secondaryRelatedToType) ? secondaryRelatedToType : relatedToType
                                                        },
                                                        {
                                                            key: "RelatedField", value: isNotNullAndUndefined(secondaryRelatedToUID) ?
                                                                propertyOf<ContentLibraryRow>('SecondaryRelatedToUID') :
                                                                propertyOf<ContentLibraryRow>('RelatedToUID')
                                                        },
                                                        {
                                                            key: "RelatedFieldValue", value: isNotNullAndUndefined(secondaryRelatedToUID) ?
                                                                secondaryRelatedToUID :
                                                                relatedToUID
                                                        },
                                                    ]}
                                                    // additionalFilter={{
                                                    //     Condition: 'and',
                                                    //     Rules: [
                                                    //         {
                                                    //             Operator: RAFCustomOperator.Equal,
                                                    //             Value: [isNotNullAndUndefined(secondaryRelatedToUID) ?
                                                    //                 secondaryRelatedToUID :
                                                    //                 relatedToUID],
                                                    //             Field: isNotNullAndUndefined(secondaryRelatedToUID) ?
                                                    //                 propertyOf<ContentLibraryRow>('SecondaryRelatedToUID') :
                                                    //                 propertyOf<ContentLibraryRow>('RelatedToUID'),
                                                    //         },
                                                    //         {
                                                    //             Operator: RAFCustomOperator.Equal,
                                                    //             Value: [isNotNullAndUndefined(secondaryRelatedToType) ? secondaryRelatedToType : relatedEntity],
                                                    //             Field: isNotNullAndUndefined(secondaryRelatedToType) ?
                                                    //                 propertyOf<ContentLibraryRow>('SecondaryRelatedToType') :
                                                    //                 propertyOf<ContentLibraryRow>('Entity'),
                                                    //         },
                                                    //     ],
                                                    // }}
                                                    isRelated
                                                >
                                                    <RAFFieldStateProvider moduleName={this.moduleName}>
                                                        <RAFGridTemplates
                                                        //actionButtonClicked={(id, docLibrary) => this.onActionMenuClicked(id, docLibrary)}
                                                        >
                                                            <RAFGridCC
                                                                gridId={`${this.moduleName}`}
                                                                moduleName={this.moduleName}
                                                                isRemote
                                                                rowClick={this.rowClick}
                                                                showEditColumn={false}
                                                                allowSelection={false}
                                                                actionButtonClicked={(id, docLibrary) => this.onActionMenuClicked(id, docLibrary)}
                                                                gridTemplates={getGridColumnTemplatesByModule(this.moduleName)}
                                                                gridColumnWidth={ContentLibraryColumnWidths}
                                                                customClick={downloadDocumentFile}
                                                                emptyStateProps={{ title: "No results found.", body: "Try adjusting your search or changing your filter to find what you're looking for!", image: nodata_es }}
                                                                viewId={currentView.UID}
                                                                viewName={currentView.Name}
                                                                filterBarVisibile={this.state.filterBarVisibility}
                                                            >
                                                                {getGridColumnsByModuleAndView(this.moduleName, currentView)}
                                                                {/* <RAFGridColumn<ContentLibraryRow> field="DisplayName" headerText="Name" width="200px" />
                                                                <RAFGridColumn<ContentLibraryRow> field="Revision" headerText="Version" width="60px" minWidth="60px" maxWidth="60px" />
                                                                <RAFGridColumn<ContentLibraryRow> field="TagsListJson" headerText="Tags" width="170px" />
                                                           <RAFGridColumn<ContentLibraryRow> field="Category" headerText="Category" width="120px" />  
                                                                <RAFGridColumn<ContentLibraryRow> field="ModifiedBy" headerText="Modified By" width="120px" /> */}
                                                            </RAFGridCC>
                                                        </RAFGridTemplates>
                                                    </RAFFieldStateProvider>

                                                </RAFReportDataList>
                                            )
                                                :
                                                (
                                                    <div></div>
                                                )
                                        )
                                        :
                                        (
                                            <div className="container-fluid px-0">
                                                <ACLoadingPanel loadingText="Preparing Data..." />
                                            </div>
                                        )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        {this.state.showfileDetailsContent && (
                            <DialogComponent visible={this.state.showfileDetailsContent}
                                cssClass={
                                    "centerDialog centerDialog-md createEditForm full-height form-center-dialog dlg-new-style"
                                }
                                height="100%"
                                content={this.fileDetailsContent.bind(this)} isModal target='body' closeOnEscape={false} id="pageFilePropertiesDialog"
                                close={this.fileDetailsDialogClose.bind(this)} animationSettings={this.animationSettings}
                            >
                            </DialogComponent>
                        )
                        }
                        {this.state.showManageDocProperties && (
                            <DialogComponent visible={this.state.showManageDocProperties}
                                cssClass="rightDialog createEditForm full-height dlg-new-style"
                                height="100%"
                                content={this.manageDocPropertiesContent.bind(this)} isModal target='body'
                                closeOnEscape={false}
                                id="manageDocPropertiesDialog"
                                overlayClick={this.manageDocPropertiesDialogClose.bind(this)}
                                close={this.manageDocPropertiesDialogClose.bind(this)} animationSettings={this.animationSettings}
                            >
                            </DialogComponent>
                        )}
                        {/* {
                            this.state.showUploadContent && (
                                <DialogComponent id="uploadDialog" header="Upload File" buttons={this.uploadDialogButtons} cssClass="centerDialog-sm createEditForm full-height form-center-dialog" showCloseIcon visible={this.state.showUploadContent}
                                    content={this.uploadAndLinkDocumentContent.bind(this)} isModal target='body' closeOnEscape={false} close={this.uploadDialogClose.bind(this)} animationSettings={this.animationSettings} >
                                </DialogComponent>
                            )
                        } */}
                        {this.state.showUploadContent === true && (
                            <DialogComponent
                                header={'Upload New Version'}
                                showCloseIcon
                                visible={this.state.showUploadContent}
                                cssClass="centerDialog-sm createEditForm form-center-dialog dlg-new-style fixed-header"
                                content={this.uploadAndLinkDocumentContent.bind(this)}
                                isModal
                                target="body"
                                closeOnEscape={false}
                                close={this.uploadDialogClose.bind(this)}
                                animationSettings={this.animationSettings}
                                id="manageUploadDocumentDialog"
                            ></DialogComponent>
                        )}
                    </div>
                </div>
            );
        } else {
            return (
                <div className="h-100 d-flex flex-column px-2">
                    <div className="mail-content-header">
                        <div className="d-flex align-items-center">
                            <TooltipComponent content={Document} position="BottomCenter" >
                                <div className={"backArrowIcon avatar-text me-2"}>
                                    <i className={"fa fa-file-alt headerIcon"} ></i>
                                </div>
                            </TooltipComponent>
                        </div>
                        <div className="h-100 d-flex">
                            <ButtonComponent type='button' cssClass="primary-custom-button custom-bg-grey"
                                iconCss="fas fa-xmark"
                                onClick={() => this.onClickCloseDialog()}
                            ></ButtonComponent>
                        </div>
                    </div>
                </div>
            );
        }
    }
}

export class DocumentListRequest extends ListServiceRequest {
    RelativePath?: string;
}

export default DocumentLookupGrid;
