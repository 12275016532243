import { ContentType } from "../../../constants/Common/Constants";
import { RAFSort } from "../../../RAFComponents/helpers/types";
import { isNotNullAndUndefined, IsNotNullOrWhiteSpace, propertyOf } from "../../../RAFComponents/helpers/utils";
import { ListServiceRequest } from "../../../RAFComponents/models/Common/ListRequest";
import { RAFCustomFilter, RAFCustomOperator } from "../../../RAFComponents/RAFViewPanels/RAFFilterColumn/RAFCustomFilter";
import * as repositoryActions from '../../../RAFComponents/store/actions/repositoryActions';
import { showWarningToast } from "../../../RAFComponents/Utility/RAFToastComponent";
import { DocumentCategoryRow, getDocumentByRecordCategoryUID, RAFSystemContentLibraryNode } from "../Document/Library/DocumentHelper";
import { RecordCategoryRow } from "./RecordCategoryRow";

export enum RecordActionType {
    Destroy = 'Destroy',
    Move = 'Move',
    Retain = 'Retain',
    Review = 'Review',
}

export function getAllRecordCategory(headerFilterText?: string) {
    return new Promise<RecordCategoryRow[]>((resolve) => {
        const listRequest: ListServiceRequest = new ListServiceRequest();

        if (IsNotNullOrWhiteSpace(headerFilterText)) {
            let customFilter: RAFCustomFilter = {};
            customFilter.Condition = "or";
            customFilter.Rules = [];

            let relatedFilter1: RAFCustomFilter = {};
            relatedFilter1.Condition = "and";
            relatedFilter1.Rules = [];

            let relatedFilter2: RAFCustomFilter = {};
            relatedFilter2.Condition = "and";
            relatedFilter2.Rules = [];

            let filter1: RAFCustomFilter = {};
            let filterVal1: string[] = [];
            filterVal1.push(headerFilterText);
            filter1.Operator = RAFCustomOperator.Contains;
            filter1.Value = filterVal1;
            filter1.Field = propertyOf<RecordCategoryRow>("Title");
            relatedFilter1.Rules.push(filter1);
            customFilter.Rules.push(relatedFilter1);

            let filter2: RAFCustomFilter = {};
            let filterVal2: string[] = [];
            filterVal2.push(headerFilterText);
            filter2.Operator = RAFCustomOperator.Contains;
            filter2.Value = filterVal2;
            filter2.Field = propertyOf<RecordCategoryRow>("RecordIdentifier");
            relatedFilter2.Rules.push(filter2);
            customFilter.Rules.push(relatedFilter2);

            listRequest.CustomFilter = customFilter;
        }


        listRequest.Skip = 0;
        listRequest.Take = 0;

        let sort: RAFSort = { field: propertyOf<RecordCategoryRow>("Title"), order: 'ascending' };
        let sortQuery: string[] = [];
        if (isNotNullAndUndefined(sort) && isNotNullAndUndefined(sort.field)) {
            sortQuery.push(sort.order === 'descending' ? `${sort.field} desc` : sort.field);
        }

        listRequest.Sort = sortQuery;

        repositoryActions.postDataAndGetResponse('RecordCategory/List', listRequest, null,
            ContentType.applicationJson).then((response) => {
                if (isNotNullAndUndefined(response) && isNotNullAndUndefined(response.data) && isNotNullAndUndefined(response.data.Entities)) {
                    resolve(response.data.Entities);
                } else {
                    resolve(null);
                }
            }).catch((error) => error);
    });
}

export function getRecordCategoryByUID(uid, returnNull?: boolean) {
    return new Promise<RecordCategoryRow>((resolve) => {
        if (isNotNullAndUndefined(uid) && returnNull !== true) {
            repositoryActions.postDataAndGetResponse('RecordCategory/Retrieve', { EntityId: uid }, null,
                ContentType.applicationJson).then((response) => {
                    if (isNotNullAndUndefined(response) && isNotNullAndUndefined(response.data) && isNotNullAndUndefined(response.data.Entity)) {
                        resolve(response.data.Entity);
                    } else {
                        resolve(null);
                    }
                }).catch((error) => error);
        } else {
            resolve(null);
        }
    });
}

export async function isUniqueRecordCategory(selectedRecordCategoryUID: string, selectedRecordCategory: string, allRecordCategory: DocumentCategoryRow[]) {
    return new Promise<boolean>(async (resolve) => {
        if (isNotNullAndUndefined(allRecordCategory) && allRecordCategory.length > 0) {
            let items = allRecordCategory.filter(x =>
                isNotNullAndUndefined(x.CategoryName) &&
                isNotNullAndUndefined(selectedRecordCategory) &&
                x.CategoryName.toLowerCase() === selectedRecordCategory.toLowerCase());
            if (isNotNullAndUndefined(items) && items.length > 0) {
                if (isNotNullAndUndefined(selectedRecordCategoryUID)) {
                    let currentItem = items.find(x => x.UID === selectedRecordCategoryUID);
                    if (isNotNullAndUndefined(currentItem) && isNotNullAndUndefined(currentItem.UID)) {
                        resolve(true);
                    } else {
                        resolve(false);
                    }
                } else {
                    resolve(false);
                }
            } else {
                resolve(true);
            }
        } else {
            let allCategories = await getAllRecordCategory();
            let items = allCategories.filter(x =>
                isNotNullAndUndefined(x.Title) &&
                isNotNullAndUndefined(selectedRecordCategory) &&
                x.Title.toLowerCase() === selectedRecordCategory.toLowerCase());
            if (isNotNullAndUndefined(items) && items.length > 0) {
                if (isNotNullAndUndefined(selectedRecordCategoryUID)) {
                    let currentItem = items.find(x => x.UID === selectedRecordCategoryUID);
                    if (isNotNullAndUndefined(currentItem) && isNotNullAndUndefined(currentItem.UID)) {
                        resolve(true);
                    } else {
                        resolve(false);
                    }
                } else {
                    resolve(false);
                }
            } else {
                resolve(true);
            }
        }
    });
}

export function isRecordCategoryChildItemExist(selectedRecordCategoryUID: string, allRecordCategory: DocumentCategoryRow[]) {
    return new Promise<boolean>(async (resolve) => {
        let recordCategory = isNotNullAndUndefined(allRecordCategory) && allRecordCategory.length > 0 ? allRecordCategory.filter(x => x.Type === RAFSystemContentLibraryNode.RecordCategory.id) : null;
        if (isNotNullAndUndefined(recordCategory) && recordCategory.length > 0) {
            let childItems = recordCategory.find(x => x.ParentUID === selectedRecordCategoryUID);
            if (isNotNullAndUndefined(childItems) && isNotNullAndUndefined(childItems.UID)) {
                showWarningToast('Please delete all the sub categories before deleting this category.');
                resolve(true);
            } else {
                getDocumentByRecordCategoryUID(selectedRecordCategoryUID).then((relatedDocumentList) => {
                    if (isNotNullAndUndefined(relatedDocumentList) && relatedDocumentList.length > 0) {
                        showWarningToast('Please delete all the related documents before deleting this category.');
                        resolve(true);
                    } else {
                        resolve(false);
                    }
                });
            }
        } else {
            let allCategories = await getAllRecordCategory();
            let childItems = isNotNullAndUndefined(allCategories) && allCategories.length > 0 ? allCategories.find(x => x.ParentUID === selectedRecordCategoryUID) : null;
            if (isNotNullAndUndefined(childItems) && isNotNullAndUndefined(childItems.UID)) {
                showWarningToast('Please delete all the sub categories before deleting this category.');
                resolve(true);
            } else {
                getDocumentByRecordCategoryUID(selectedRecordCategoryUID).then((relatedDocumentList) => {
                    if (isNotNullAndUndefined(relatedDocumentList) && relatedDocumentList.length > 0) {
                        showWarningToast('Please delete all the related documents before deleting this category.');
                        resolve(true);
                    } else {
                        resolve(false);
                    }
                });
            }
        }
    });
}