import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import moment from "moment";
import { PropsWithChildren, useContext } from "react";
import { Field, FormRenderProps } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import RAFDatePickerCustom from "../../../RAFComponents/Inputs/RAFDatePickerCustom";
import { RAFDefaultFieldProps, RAFFieldProps, RAFFormContext } from "../../../RAFComponents/Inputs/RFFUtils";
import { showWarningToast } from "../../../RAFComponents/Utility/RAFToastComponent";
import { IsNullOrWhiteSpace, convertUTCDateToLocalTimezone, isNotNullAndUndefined } from "../../../RAFComponents/helpers/utils";
import RAFBPDetailInlineEdit1 from "../../../RAFMaster/RMModules/BusinessProcess/helpers/RAFBPDetailInlineEdit1";
import { ReminderType } from "./ReminderRow";

interface IProps {
    showAdd?: boolean;
    dropDownItems?: any[];
    checkUnique?: boolean;
}

function RAFReminderDropdownWithAddBtn<T>(
    { field, label, dropDownItems,
        showAdd = true,
        checkUnique = true,
        required = RAFDefaultFieldProps.required,
        showLabel = RAFDefaultFieldProps.showLabel,
        disabled = RAFDefaultFieldProps.disabled,
        showClearButton = RAFDefaultFieldProps.showClearButton,
        validate = RAFDefaultFieldProps.validate,
        ...props }: PropsWithChildren<RAFFieldProps<T> & IProps>,
) {

    const rafFormContextValue: FormRenderProps = useContext(RAFFormContext);

    const selectedReminderListValues = checkUnique === true && isNotNullAndUndefined(rafFormContextValue) &&
        isNotNullAndUndefined(rafFormContextValue.values) && isNotNullAndUndefined(rafFormContextValue.values[field.toString()]) ? rafFormContextValue.values[field.toString()] : [];

    const disableReminderDropDownItemsValue = [];
    selectedReminderListValues && selectedReminderListValues.forEach(item => {
        if (isNotNullAndUndefined(item.ReminderType))
            disableReminderDropDownItemsValue.push({ label: item.ReminderType, value: item.ReminderType });
    });

    const reminderDropDownItems = isNotNullAndUndefined(dropDownItems) && dropDownItems.length > 0 ?
        dropDownItems : [
            { label: ReminderType.Email, value: ReminderType.Email },
            { label: ReminderType.SMS, value: ReminderType.SMS },
            { label: ReminderType.InApp, value: ReminderType.InApp }
        ];

    const onAddClick = (field: keyof T | string) => {
        if (isNotNullAndUndefined(rafFormContextValue) && isNotNullAndUndefined(rafFormContextValue.form) && isNotNullAndUndefined(rafFormContextValue.form.mutators)) {
            rafFormContextValue.form.mutators.push(field.toString(), { ReminderType: ReminderType.Email, RemindDate: null });
        }
        openFloatingPopupContent(field);

    };

    const onDeleteClick = (fields, index, required1) => {
        if (isNotNullAndUndefined(required1) && required1 === true) {
            if (isNotNullAndUndefined(fields) && fields.length > 1) {
                fields.remove(index);
            }
            else {
                showWarningToast('Atleast one Reminder number is required');
            }
        }
        else {
            fields.remove(index);
        }
    };

    const onChangeReminderType = (remindDateField) => {
        if (isNotNullAndUndefined(rafFormContextValue) && isNotNullAndUndefined(rafFormContextValue.form) && isNotNullAndUndefined(rafFormContextValue.form.mutators)) {
            rafFormContextValue.form.mutators.setValue(remindDateField, null);
        }
    };

    const onclickReminder = () => {
        let popupContainer = document.getElementById(`popup${field.toString()}`);
        let divContainer = document.getElementById(`rafdiv${field.toString()}Col`);
        let divContainerRect = divContainer.getBoundingClientRect();
        divContainer.insertAdjacentElement('beforeend', popupContainer);
        popupContainer.style.left = `${divContainerRect.x}px`;
        popupContainer.style.top = `${divContainerRect.y + divContainerRect.height}px`;
        popupContainer.style.minWidth = `${divContainerRect.width}px`;
    };

    const onCloseReminder = () => {
        let popupContainer = document.getElementById(`popup${field.toString()}`);
        let popupOutterContainer = document.getElementById(`popup${field.toString()}Container`);
        popupOutterContainer.insertAdjacentElement('beforeend', popupContainer);
    };


    // const remindDropDownButtonComponentClicked = (e: MenuEventArgs, remindDateField) => {
    //     if (e.item.id === 'Custom') {
    //         showDatePicker(remindDateField);
    //     } else {
    //         onDateClicked(e.item.id, remindDateField);
    //     }
    // };

    const openFloatingPopupContent = (field) => {
        let floatingPopupContent = document.getElementById(`floatingCustomPopup${field}`);
        // let btnFloatingPopupContent = document.getElementById(`btn_open_floatingCustomPopup_${field.toString()}`)
        if (isNotNullAndUndefined(floatingPopupContent)) {
            floatingPopupContent.classList.add("active");
        }
        // btnFloatingPopupContent.classList.add("hidden")
    };
    const closeFloatingPopupContent = (field) => {
        let floatingPopupContent = document.getElementById(`floatingCustomPopup${field}`);
        floatingPopupContent.classList.remove("active");
    };

    return (
        <div className="app-container p-0" id={"rafdiv" + field.toString()} >
            <FieldArray name={field.toString()}>
                {({ fields }) =>
                    (fields && fields.length > 0) ? fields.map((name, index) => (
                        <div className="col-12" key={name}>
                            <Field name={`${name}.RemindDate`} id={"rafdiv" + field.toString()}>
                                {({ input }) => {
                                    let inputValue = input.value;
                                    if (
                                        !IsNullOrWhiteSpace(inputValue)
                                    ) {

                                        let localDate = convertUTCDateToLocalTimezone(inputValue);
                                        inputValue = moment(localDate).format("DD/MM/YYYY");
                                    }
                                    else {
                                        inputValue = "Not set";
                                    }
                                    return (
                                        <>
                                            <RAFBPDetailInlineEdit1
                                                fieldName={`${name}.RemindDate`} label={label}
                                                tooltipContent={<div className="p-3">
                                                    <RAFDatePickerCustom
                                                        field={`${name}.RemindDate`}
                                                        secondaryField={`${name}.RemindDate`}
                                                        label="Reminder"
                                                        displayStyle="splitButton"
                                                        showLabel
                                                        // showLabel={true}
                                                        dropdownDateOptions={[
                                                            { id: 1, text: 'Today', value: '0' },
                                                            { id: 2, text: '1D', value: '1' },
                                                            { id: 3, text: '3D', value: '3' },
                                                            { id: 4, text: '7D', value: '7' },
                                                            { id: 5, text: '15D', value: '15' },
                                                        ]}
                                                        showClearTextBtn
                                                    // onChanged={(value) => onChangeTaskDate(value)}
                                                    />
                                                </div>}
                                                labelClassName="col-3"
                                                inputClassName="col-9"
                                                UiMode="floatingCustomDiv"
                                                customValue={inputValue}
                                            />
                                            <div className="row gx-1 gx-md-2 gx-xl-3 gy-2 hidden">
                                                <div className={props.labelClassName ? props.labelClassName : "col-md-4"}>
                                                    {showLabel && showLabel === true &&
                                                        <label className={(required ? "form-label required" : "form-label")}><>{label || field}</></label>
                                                    }
                                                </div>
                                                <div className={props.inputFieldClassName ? props.inputFieldClassName : "col-md-8"} id={`rafdiv${field.toString()}Col`}>
                                                    <div className="d-flex align-items-center justify-content-between formEditDiv ms-1">
                                                        <span
                                                        >
                                                            {inputValue}
                                                        </span>
                                                        <ButtonComponent id={`btn_open_floatingCustomPopup_${field.toString()}`} content="Edit" cssClass="link-button" type="button"
                                                            onClick={() => openFloatingPopupContent(field.toString())}
                                                        ></ButtonComponent>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    );
                                }}
                            </Field>
                            <div className="floatingCustomPopup mb-1 showArrowtip" id={`floatingCustomPopup${field.toString()}`}>
                                <div className="row g-0">
                                    <div className="col-12">
                                        <RAFDatePickerCustom
                                            field={`${name}.RemindDate`}
                                            secondaryField={`${name}.RemindDate`}
                                            label="Reminder"
                                            displayStyle="splitButton"
                                            showLabel
                                            // showLabel={true}
                                            dropdownDateOptions={[
                                                { id: 1, text: 'Today', value: '0' },
                                                { id: 2, text: '1D', value: '1' },
                                                { id: 3, text: '3D', value: '3' },
                                                { id: 4, text: '7D', value: '7' },
                                                { id: 5, text: '15D', value: '15' },
                                            ]}
                                            showClearTextBtn
                                        // onChanged={(value) => onChangeTaskDate(value)}
                                        />
                                    </div>
                                    <div className="col-auto ms-auto">
                                        <ButtonComponent type="button" content="Close" onClick={() => closeFloatingPopupContent(field.toString())}></ButtonComponent>

                                    </div>
                                </div>
                                <div className="e-arrow-tip e-tip-top e-tip-left">
                                    <div className="e-arrow-tip-outer e-tip-top"></div>
                                    <div className="e-arrow-tip-inner e-tip-top"></div>
                                </div>
                            </div>
                        </div>
                    ))
                        :
                        <div>
                            <ButtonComponent id={`btn_open_floatingCustomPopup_${field.toString()}`} content="Add Reminder" cssClass="link-button" iconCss="fas fa-plus" type="button"
                                onClick={() => onAddClick(field)}
                            ></ButtonComponent>
                            {/* <div className="floatingCustomPopup mb-1 showArrowtip" id={`floatingCustomPopup${field.toString()}`}>
                                <div className="row g-0 gy-2">
                                    <div className="col-12">
                                        <RAFDatePickerCustom
                                            field={`${fields[0]}.RemindDate`}
                                            secondaryField={`${fields[0]}.RemindDate`}
                                            label="Reminder"
                                            displayStyle="splitButton"
                                            showLabel={true}
                                            dropdownDateOptions={[
                                                { id: 1, text: 'Today', value: '0' },
                                                { id: 2, text: '1D', value: '1' },
                                                { id: 3, text: '3D', value: '3' },
                                                { id: 4, text: '7D', value: '7' },
                                                { id: 5, text: '15D', value: '15' },
                                            ]}
                                            showClearButton={false}
                                            showClearTextBtn
                                        // onChanged={(value) => onChangeTaskDate(value)}
                                        />
                                    </div>
                                    <div className="col-auto ms-auto">
                                        <ButtonComponent type="button" content="Close" onClick={() => closeFloatingPopupContent(field.toString())}></ButtonComponent>

                                    </div>
                                </div>
                                <div className="e-arrow-tip e-tip-top e-tip-left">
                                    <div className="e-arrow-tip-outer e-tip-top"></div>
                                    <div className="e-arrow-tip-inner e-tip-top"></div>
                                </div>
                            </div> */}
                        </div>
                }
            </FieldArray>
        </div>
    );
}

export default RAFReminderDropdownWithAddBtn;

