import { MenuItemModel } from "@syncfusion/ej2-navigations";
import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import {
  AnimationSettingsModel,
  DialogComponent,
  DialogUtility
} from "@syncfusion/ej2-react-popups";
import { Component } from "react";
import { FormRenderProps } from "react-final-form";
import RAFFieldStateProvider from "../../../RAFComponents/Grid/RAFFieldStateProvider";
import RAFGridColumn from "../../../RAFComponents/Grid/RAFGridColumn";
import RAFListViewComponent from "../../../RAFComponents/List/RAFListViewComponent";
import { RAFReportDataListContext } from "../../../RAFComponents/List/RAFReportDataList";
import RAFCollapse from "../../../RAFComponents/Navigation/RAFCollapse";
import RAFDetailsFieldWithIcon from "../../../RAFComponents/Navigation/RAFDetailsFieldWithIcon";
import RAFMenuComponent from "../../../RAFComponents/Navigation/RAFMenuComponent";
import RAFRecordInfo from "../../../RAFComponents/Navigation/RAFRecordInfo";
import RAFDataResultProvider, {
  RAFDataResultContext
} from "../../../RAFComponents/Providers/RAFDataResultProvider";
import {
  RAFCustomFilter,
  RAFCustomOperator
} from "../../../RAFComponents/RAFViewPanels/RAFFilterColumn/RAFCustomFilter";
import { createInstance } from "../../../RAFComponents/Utility/FormUtility";
import { hideProgress, showProgress } from "../../../RAFComponents/helpers/AppHelper";
import RAFPermissionRender from "../../../RAFComponents/helpers/PermissionHelper";
import {
  IsNullOrWhiteSpace,
  getFileExtension,
  isNotNullAndUndefined,
  propertyOf
} from "../../../RAFComponents/helpers/utils";
import {
  Constants,
  RAFDocumentType,
  RAFLayout,
  SFColumnType
} from "../../../constants/Common/Constants";
import { RAFEntityName } from "../../../constants/Common/EntityConstants";
import { PermissionConstants } from "../../../constants/Common/PermissionConstants";
import RAFGridTemplates from "../../../helpers/RAFGridTemplates";
import { NavigateParamsProps, withRouter } from "../../../router";
import DocumentLookupGrid from "./DocumentLookupGrid";
import { ContentLibraryRow } from "./Library/ContentLibraryRow";
import DocumentDetailsCenterDlg from "./Library/DocumentDetailsCenterDlg";
import {
  deleteDocumentItem,
  downloadDocumentFile,
  getFileSize
} from "./Library/DocumentHelper";
import RAFCaptureImage from "./ManageDocument/RAFCaptureImage";
import RAFManageDocumentProperties from "./ManageDocument/RAFManageDocumentProperties";
import RAFManageRelatedDocuments from "./ManageDocument/RAFManageRelatedDocuments";
import RAFManageUploadDocumentFile from "./ManageDocument/RAFManageUploadDocumentFile";

interface IProps {
  relatedToUID?: string;
  url?: string;
  showLookupGrid?: boolean;
  showAddBtn?: boolean;
  documentCreated?: () => void;
  secondaryRelatedToUID?: string;
  secondaryRelatedToType: string;
  moduleName?: string;
  secondaryRelatedTo?: string;
  showEmptyState?: boolean;
  relatedDocumnetDivKey?: number;
  collapsePanelArrowPosistion: "left" | "title" | "right";
  relatedTo?: string;
  relatedEntity?: string;
}

interface IState {
  selectedDocumentRow: ContentLibraryRow;
  showfileDetailsContent: boolean;
  showManageDocProperties: boolean;
  showUploadContent: boolean;
  isRevisedVersion: boolean;
  showDocumentLookupGridContent?: boolean;
  relatedFilter?: RAFCustomFilter;
  documentDivKey?: number;
  relatedDocumentCount?: number;
  triggerInputFile?: boolean;
  showAttachedDocumentproperty?: boolean;
}

class RelatedDocumentList extends Component<
  IProps & NavigateParamsProps,
  IState
> {
  private _isMounted: boolean;
  deleteDialog: any;
  animationSettings: AnimationSettingsModel;
  private moduleName: string = createInstance(ContentLibraryRow).getClassName();
  public submitButton: ButtonComponent;

  constructor(props) {
    super(props);
    this.animationSettings = { effect: "Fade" };

    this.state = {
      selectedDocumentRow: null,
      showfileDetailsContent: false,
      showManageDocProperties: false,
      isRevisedVersion: false,
      showUploadContent: false,
      showDocumentLookupGridContent: false,
      triggerInputFile: false,
      showAttachedDocumentproperty: false,
      relatedFilter: null,
      relatedDocumentCount: null,
      documentDivKey: Math.random(),
    };
  }

  componentDidMount = () => {
    this._isMounted = true;
  };

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.relatedDocumnetDivKey !== this.props.relatedDocumnetDivKey &&
      isNotNullAndUndefined(this.props.relatedDocumnetDivKey)
    ) {
      if (this._isMounted) {
        this.setState({
          documentDivKey: Math.random(),
          relatedDocumentCount: null,
        });
      }
    }
  }

  enableSubmitButton = (g: FormRenderProps | null) => {
    if (g) {
      if (isNotNullAndUndefined(this.submitButton)) {
        this.submitButton.disabled = g.submitting;
      }
    }
  };

  refresh = () => {
    if (this._isMounted) {
      this.setState({
        documentDivKey: Math.random(),
        relatedDocumentCount: null,
        showAttachedDocumentproperty: false,
        selectedDocumentRow: null,
      });
    }
  };

  documentCreated = () => {
    if (this.props.documentCreated) {
      this.props.documentCreated();
    }
    this.refresh();
  };

  private uploadDocument(data?: Object, isRevisedVersion?: boolean) {
    if (this._isMounted) {
      this.setState({
        showUploadContent: true,
        selectedDocumentRow: data,
        isRevisedVersion: isRevisedVersion,
      });
    }
  }

  private uploadDialogClose(): void {
    if (this._isMounted) {
      this.setState({ showUploadContent: false });
    }
  }

  uploadAndLinkDocumentContent() {
    if (this.state.showUploadContent === true) {
      return (
        <RAFManageUploadDocumentFile
          onSave={(UID) => this.onUploadDocumentFile(UID)}
          onClose={() => this.uploadDialogClose()}
          selectedFolder={null}
          selectedDocumentRow={this.state.selectedDocumentRow}
          //relatedToUID={this.props.relatedToUID}
          //relatedEntity={this.props.moduleName}
          secondaryRelatedToType={RAFEntityName.BusinessProcessStep}
          secondaryRelatedToUID={this.props.secondaryRelatedToUID}
          secondaryRelatedTo={this.props.secondaryRelatedTo}
          relatedToUID={this.props.relatedToUID}
          relatedTo={this.props.relatedTo}
          relatedEntity={this.props.relatedEntity}
          mode={
            this.state.isRevisedVersion === true ? "updateVersion" : "create"
          }
          checkDuplicate={false}
          isActive
        />
      );
    } else {
      return <div></div>;
    }
  }

  onUploadDocumentFile = (UID) => {
    if (this._isMounted) {
      this.setState({ showUploadContent: false }, () => {
        this.documentCreated();
        document.body.classList.remove("overflow-hidden");
      });
    }
  };

  downloadDocument = (item) => {
    downloadDocumentFile(item).then((status) => { });
  };

  rowClick1 = (docLibraryRow: ContentLibraryRow) => {
    //const docLibraryRow: ContentLibraryRow = documentLibraryRow as ContentLibraryRow;
    if (
      docLibraryRow.DocumentType === RAFDocumentType.File ||
      docLibraryRow.DocumentType === RAFDocumentType.Page
    ) {
      this.props.navigate("/DocumentLibrary/View/" + docLibraryRow.UID);
      //window.open("/DocumentLibrary/View/" + docLibraryRow.UID);
      // this.fileProperties(docLibraryRow);
    }
  };

  rowClick = (docLibraryRow: ContentLibraryRow) => {
    if (
      isNotNullAndUndefined(docLibraryRow) &&
      isNotNullAndUndefined(docLibraryRow.UID)
    ) {
      let mimeType = !IsNullOrWhiteSpace(docLibraryRow.MimeType)
        ? docLibraryRow.MimeType.toLowerCase()
        : null;
      if (
        !IsNullOrWhiteSpace(mimeType) &&
        docLibraryRow.DocumentType === RAFDocumentType.File &&
        mimeType.includes("image")
      ) {
        if (this._isMounted) {
          this.setState({
            showAttachedDocumentproperty: true,
            selectedDocumentRow: docLibraryRow,
          });
        }
      } else if (docLibraryRow.DocumentType === RAFDocumentType.File) {
        this.downloadDocument(docLibraryRow);
      } else {
        if (isNotNullAndUndefined(this.props.navigate)) {
          this.props.navigate("/DocumentLibrary/View/" + docLibraryRow.UID);
        } else {
          window.open("/DocumentLibrary/View/" + docLibraryRow.UID);
        }
      }
    }
  };

  //documentProperty_Start
  documentpropertyContent = () => {
    if (
      this.state.showAttachedDocumentproperty &&
      isNotNullAndUndefined(this.state.selectedDocumentRow)
    ) {
      return (
        <RAFManageRelatedDocuments
          documentUID={this.state.selectedDocumentRow.UID}
          onClose={this.documentpropertyDialogClose}
          onDelete={this.onDeleteDocumentItem}
        />
      );
    } else {
      return <div></div>;
    }
  };

  documentpropertyDialogClose = () => {
    if (this._isMounted) {
      this.setState({
        showAttachedDocumentproperty: false,
        selectedDocumentRow: null,
      });
    }
  };
  //documentProperty_End

  fileProperties = (docLibraryRow: ContentLibraryRow) => {
    document.body.classList.add("overflow-hidden");
    if (this._isMounted) {
      if (this._isMounted) {
        this.setState({
          showfileDetailsContent: true,
          selectedDocumentRow: docLibraryRow,
        });
      }
    }
  };

  private fileDetailsDialogClose(): void {
    if (this._isMounted) {
      this.setState(
        { showfileDetailsContent: false, selectedDocumentRow: null },
        () => { }
      );
    }
  }

  fileDetailsContent() {
    if (
      this.state.showfileDetailsContent &&
      this.state.showfileDetailsContent === true
    ) {
      return (
        <div>
          <RAFReportDataListContext.Consumer>
            {({ actions }) => {
              return (
                <DocumentDetailsCenterDlg
                  onSave={this.refreshOnFileDetails.bind(this, actions)}
                  onDelete={this.refreshOnFileDetails.bind(this, actions, true)}
                  onClose={this.fileDetailsDialogClose.bind(this)}
                  documentUID={this.state.selectedDocumentRow.UID}
                  //module={this.state.selectedDocumentRow.Entity}
                  isActive
                  {...this.props}
                />
              );
            }}
          </RAFReportDataListContext.Consumer>
        </div>
      );
    } else {
      return <div></div>;
    }
  }

  private refreshOnFileDetails(
    actions,
    hiderelatedDocumentList?: boolean
  ): void {
    if (this._isMounted) {
      this.setState(
        { showfileDetailsContent: false, documentDivKey: Math.random() },
        () => {
          document.body.classList.remove("overflow-hidden");
          let relatedDocumentListOuterDiv = document.getElementById(
            "relatedDocumentListOuterDiv_" + this.state.documentDivKey
          );
          if (
            this.state.relatedDocumentCount === 1 &&
            hiderelatedDocumentList === true
          ) {
            if (isNotNullAndUndefined(relatedDocumentListOuterDiv)) {
              // relatedDocumentListOuterDiv.classList.add("hidden");
              relatedDocumentListOuterDiv.classList.add("fade-out");
            }
          }
          if (
            isNotNullAndUndefined(actions) &&
            isNotNullAndUndefined(actions.fetchData)
          ) {
            actions.fetchData(1, 10, null, null);
          }
        }
      );
    }
  }

  onActionMenuClicked(selectedId, docLibraryRow?: Object) {
    switch (selectedId) {
      case "UploadVersion":
        this.uploadDocument(docLibraryRow, true);
        break;
      case "Edit":
        this.manageDocPropertiesDialogOpen(docLibraryRow);
        break;
      case "Share":
        //console.log('Share Clicked');
        break;
      case "Email":
        //console.log('Email Clicked');
        break;
      case "Download":
        this.downloadDocument(docLibraryRow);
        break;
      case "Delete":
        this.DeleteItemClicked(docLibraryRow);
        break;
      default:
        break;
    }
  }

  manageDocPropertiesDialogOpen = (docLibraryRow?: ContentLibraryRow) => {
    document.body.classList.add("overflow-hidden");
    if (this._isMounted) {
      this.setState(
        { showManageDocProperties: true, selectedDocumentRow: docLibraryRow },
        () => {
          /*let divDetailsDialog = document.getElementById('documentDetailsDialog');
                          if (isNotNullAndUndefined(divDetailsDialog)) {
                              divDetailsDialog.classList.remove('fade-in');
                          }*/
        }
      );
    }
  };

  DeleteItemClicked(data: ContentLibraryRow) {
    this.deleteDialog = DialogUtility.confirm({
      animationSettings: { effect: "Fade" },
      cancelButton: { text: "No" },
      closeOnEscape: false,
      content: "Are you sure want to delete?",
      okButton: { text: "Yes", click: this.DeleteItemRecord.bind(this, data) },
      title:
        isNotNullAndUndefined(data) && isNotNullAndUndefined(data.UID)
          ? "Delete " + data.DocumentType
          : " Delete",
      position: { X: "center", Y: "center" },
      cssClass: "raf-delete_alert_dialog alert-dialog",
      // cssClass: "alert-dialog",
    });
  }

  DeleteItemRecord(data?: Object) {
    // let progressDiv = showProgress("body", true);
    let progressDiv = showProgress(".raf-delete_alert_dialog.e-dialog");
    deleteDocumentItem(data)
      .then((response) => {
        hideProgress(progressDiv);
        this.deleteDialog.hide();
        if (response) {
          this.onDeleteDocumentItem();
        }
      })
      .catch((error) => error);
  }

  onDeleteDocumentItem = () => {
    let relatedDocumentListOuterDiv = document.getElementById(
      "relatedDocumentListOuterDiv_" + this.state.documentDivKey
    );
    if (this.state.relatedDocumentCount === 1) {
      if (
        isNotNullAndUndefined(relatedDocumentListOuterDiv) &&
        this.props.showEmptyState !== true
      ) {
        relatedDocumentListOuterDiv.classList.add("hidden");
        //relatedDocumentListOuterDiv.classList.add("fade-out");
      }
    }
    this.refresh();
  };

  manageDocPropertiesContent() {
    if (
      this.state.showManageDocProperties &&
      this.state.showManageDocProperties === true &&
      isNotNullAndUndefined(this.state.selectedDocumentRow)
    ) {
      return (
        <div>
          <RAFReportDataListContext.Consumer>
            {({ actions }) => {
              return (
                <RAFManageDocumentProperties
                  onUpdate={this.refreshOnUpdateFile.bind(this, actions)}
                  onCancel={this.manageDocPropertiesDialogClose.bind(this)}
                  contentLibraryUID={this.state.selectedDocumentRow.UID}
                  isActive
                  {...this.props}
                />
              );
            }}
          </RAFReportDataListContext.Consumer>
        </div>
      );
    } else {
      return <div></div>;
    }
  }

  private refreshOnUpdateFile(actions): void {
    if (this._isMounted) {
      this.setState(
        { showManageDocProperties: false, selectedDocumentRow: null },
        () => {
          document.body.classList.remove("overflow-hidden");
          if (
            isNotNullAndUndefined(actions) &&
            isNotNullAndUndefined(actions.fetchData)
          ) {
            actions.fetchData(1, 10, null, null);
          }
        }
      );
    }
  }

  private manageDocPropertiesDialogClose(): void {
    if (this._isMounted) {
      this.setState(
        { showManageDocProperties: false, selectedDocumentRow: null },
        () => { }
      );
    }
  }

  showDocumentLookupGridGrid() {
    if (this._isMounted) {
      this.setState({ showDocumentLookupGridContent: true }, () => {
        document.body.classList.add("overflow-hidden");
      });
    }
  }

  documentLookupGrid() {
    if (
      isNotNullAndUndefined(this.state.showDocumentLookupGridContent) &&
      this.state.showDocumentLookupGridContent === true
    ) {
      return (
        <DocumentLookupGrid
          relatedToUID={this.props.relatedToUID}
          //relatedEntity={this.props.moduleName}
          secondaryRelatedToUID={this.props.secondaryRelatedToUID}
          secondaryRelatedToType={this.props.secondaryRelatedToType}
          onDocumentLookupGridClose={(isUpdate) =>
            this.showDocumentLookupGridDialogClose(isUpdate)
          }
          isActive
        />
      );
    } else {
      return <div></div>;
    }
  }

  showDocumentLookupGridDialogClose(isUpdate?: boolean) {
    if (this._isMounted) {
      this.setState({ showDocumentLookupGridContent: false }, () => {
        if (isNotNullAndUndefined(isUpdate) && isUpdate === true) {
          this.refresh();
        }
      });
    }
  }

  headerBadge = () => {
    return (
      <RAFDataResultContext.Consumer>
        {({ count }) => {
          return (
            <>
              {count > 0 && (
                <span className="ms-1 e-badge e-badge-primary e-badge-circle">
                  {count}
                </span>
              )}
            </>
          );
        }}
      </RAFDataResultContext.Consumer>
    );
  };

  setRelatedDocumentCount = (relatedDocumentCount: number) => {
    if (relatedDocumentCount !== this.state.relatedDocumentCount) {
      if (this._isMounted) {
        this.setState({ relatedDocumentCount }, () => {
          let relatedDocumentListOuterDiv = document.getElementById(
            "relatedDocumentListOuterDiv_" + this.state.documentDivKey
          );
          if (isNotNullAndUndefined(relatedDocumentListOuterDiv)) {
            if (relatedDocumentCount > 0) {
              relatedDocumentListOuterDiv.classList.remove("hidden");
              //relatedDocumentListOuterDiv.classList.add("fade-in");
            } else if (this.props.showEmptyState !== true) {
              relatedDocumentListOuterDiv.classList.add("hidden");
              //relatedDocumentListOuterDiv.classList.add("fade-out");
            }
          }
        });
      }
    }
  };

  contentLibraryRowTemplate = (data) => {
    const fieldValue = data.DisplayName;
    const documentType: string = data.DocumentType;
    const updatedBy: string = data.ModifiedBy;
    const isSystem: boolean = data.IsSystem;
    let createdBy = data.CreatedBy;
    let createdDate = data.CreatedDate;
    let modifiedDate = data.ModifiedDate;
    let modifiedBy = data.ModifiedBy;
    let lastActivityDate = data.LastActivityDate;
    let fileClassname;
    let showMoreButton = true;
    let fileSize = getFileSize(data["FileSize"]);

    const fileNameExtension = data.FileName;
    const fileExtension = getFileExtension(fileNameExtension);
    if (isNotNullAndUndefined(fileExtension)) {
      fileClassname = "fiv-viv fiv-icon-blank fiv-icon-" + fileExtension;
    }

    if (isNotNullAndUndefined(fieldValue)) {
      let menuItems = [];
      if (documentType === RAFDocumentType.File) {
        menuItems = [
          // { id: "Edit", text: "Edit", iconCss: "fal fa-pencil" },
          // {
          //   id: "UploadVersion",
          //   text: "Upload New Version",
          //   iconCss: "fa fa-cloud-upload-alt",
          // },
          // /*{ id: 'Share', text: 'Share', iconCss: 'fa fa-share-alt' },
          //                               { id: 'Email', text: 'Email', iconCss: 'fas fa-envelope' },*/
          // {
          //   id: "Download",
          //   text: "Download",
          //   iconCss: "fa fa-cloud-download-alt",
          // },
          { id: "Delete", text: "Delete", iconCss: "fas fa-trash" },
        ];
      }

      let smMenuItems: MenuItemModel[] = [];

      if (documentType === RAFDocumentType.File) {
        smMenuItems = [
          {
            iconCss: "fa fa-ellipsis-v",
            items: [
              // {
              //   id: "Edit",
              //   text: "Edit",
              //   iconCss: "fal fa-pencil",
              // },
              // {
              //   id: "UploadVersion",
              //   text: "Upload New Version",
              //   iconCss: "fa fa-cloud-upload-alt",
              // },
              // /*{ id: 'Share', text: 'Share', iconCss: 'fa fa-share-alt' },
              //                                       { id: 'Email', text: 'Email', iconCss: 'fas fa-envelope' },*/
              // {
              //   id: "Download",
              //   text: "Download",
              //   iconCss: "fa fa-cloud-download-alt",
              // },
              { id: "Delete", text: "Delete", iconCss: "fas fa-trash" },
            ],
          },
        ];
      }

      return (
        <div className="p-2 px-md-3">
          <div className="gridDefault d-flex align-items-center justify-content-between">
            <div
              className="d-flex align-items-center"
              style={{ width: "calc(100% - 42px)" }}
            >
              <div className="d-flex document-item-icon">
                <span className={fileClassname}></span>
              </div>
              <div className="ms-2">
                <div className="detailsLeftCard-title ecllipseFirstLine text-wrap word-break-all text-break">
                  <span className="" style={{ cursor: "pointer" }}>
                    {fieldValue}
                  </span>
                </div>
                <div className="w-100">
                  <div className="detailsLeftCard-second-title">
                    <div className="row gx-2 align-items-center">
                      {isNotNullAndUndefined(updatedBy) ? (
                        <div className="col-auto detailsLeftCard-second-title-col">
                          <div className="detailsLeftCard-second-title-item">
                            <RAFDetailsFieldWithIcon
                              value={updatedBy}
                              iconClassName="fas fa-user"
                            ></RAFDetailsFieldWithIcon>
                          </div>
                        </div>
                      ) : null}
                      {documentType === RAFDocumentType.File &&
                        isNotNullAndUndefined(fileSize) && (
                          <div className="col-auto detailsLeftCard-second-title-col">
                            <div className="detailsLeftCard-second-title-item">
                              <span>{fileSize}</span>
                            </div>
                          </div>
                        )}
                      <div className="col-auto detailsLeftCard-second-title-col">
                        <div className="detailsLeftCard-second-title-item">
                          <div
                            className="gridDefault d-flex align-items-center justify-content-between"
                            onClick={(e) => e.stopPropagation()}
                          >
                            <div className="recordInfomsg">
                              <RAFRecordInfo
                                createdBy={createdBy}
                                createdDate={createdDate}
                                modifiedDate={modifiedDate}
                                modifiedBy={modifiedBy}
                                lastActivityDate={lastActivityDate}
                                dropdownBtnClassName="noRowclick"
                                content=""
                              ></RAFRecordInfo>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {showMoreButton === true && (
              <div className="gridEditBtn">
                {/*<DropDownButtonComponent
                                                items={menuItems} cssClass="e-flat btndefault-icon e-editbutton e-caret-hide square-btn e-icon-btn"
                                                select={(e) => this.onActionMenuClicked(e.item.id, data)} onClick={(e) => { e.stopPropagation() }}
                                            >
                                                <div className="icon-ellipsis-horizontal icon-ellipsis-sm"><span className="icon-ellipsis-dot"></span></div>
                                            </DropDownButtonComponent>*/}
                <RAFMenuComponent
                  cssClass="e-flat raf-iconbtn documentBtn rafGridActionContentLib noRowclick bg-rgba-grey"
                  permissionsName={[
                    PermissionConstants.DocumentCreate,
                    PermissionConstants.DocumentUpdate,
                    PermissionConstants.DocumentDelete,
                  ]}
                  menuItems={menuItems}
                  smMenuItems={smMenuItems}
                  toolbarItemClicked={(e) => {
                    this.onActionMenuClicked(e.item.id, data);
                  }}
                  pageType={"documentGridPage"}
                />
              </div>
            )}
          </div>
        </div>
      );
    }

    return <div> {fieldValue}</div>;
  };

  //upload_new_Photo_start
  triggerInputFile = () => {
    if (this._isMounted) {
      if (this.state.triggerInputFile === true) {
        this.setState({ triggerInputFile: false }, () => {
          this.setState({ triggerInputFile: true });
        });
      } else {
        this.setState({ triggerInputFile: true });
      }
    }
  };
  //upload_new_Photo_end

  render() {
    let uploadBtn = (
      <RAFPermissionRender permissionName={PermissionConstants.DocumentCreate}>
        <ButtonComponent
          type="button"
          cssClass="e-small d-none primary-custom-button d-sm-inline-block"
          iconCss="fas fa-plus"
          onClick={() => this.uploadDocument()}
        >
          Add
        </ButtonComponent>
        <ButtonComponent
          type="button"
          cssClass="e-small primary-custom-button d-sm-none"
          iconCss="fas fa-plus"
          onClick={() => this.uploadDocument()}
        ></ButtonComponent>
      </RAFPermissionRender>
    );
    let uploadDocumentBtn = (
      <div className="d-flex flex-nowrap">
        {this.props.showAddBtn ? uploadBtn : null}
        {this.props.showLookupGrid === true && (
          <div id="divHeaderButtons">
            <ButtonComponent
              type="button"
              cssClass="primary-custom-button custom-button-md"
              iconCss="fas fa-expand-alt"
              onClick={() => this.showDocumentLookupGridGrid()}
            ></ButtonComponent>
          </div>
        )}
      </div>
    );

    let { secondaryRelatedToType, secondaryRelatedToUID, relatedToUID } =
      this.props;
    return (
      <div
      // className={this.props.showEmptyState === true ? "" : "hidden"}
      >
        {this.props.showAddBtn && (
          <div className="p-2">
            <div className="row g-2">
              <div className="col-auto">
                <ButtonComponent
                  type="button"
                  cssClass="primary-custom-button"
                  content="Attach photo"
                  onClick={() => this.triggerInputFile()}
                ></ButtonComponent>
              </div>
              <div className="col-auto">
                <ButtonComponent
                  type="button"
                  cssClass="primary-custom-button"
                  content="Attach file"
                  onClick={() => this.uploadDocument()}
                ></ButtonComponent>
              </div>
            </div>
          </div>
        )}
        <div
          className={isNotNullAndUndefined(this.props.moduleName === RAFEntityName.BusinessProcessStep) ? "e-card p-0 border-0 bg-transparent" : "e-card p-0 border-0"}
          id={"relatedDocumentListOuterDiv_" + this.state.documentDivKey}
          key={this.state.documentDivKey}
        >
          {/* {(this.state.relatedDocumentCount === null ||
                        this.state.relatedDocumentCount > 0 || this.props.showEmptyState === true) && ( */}
          <div className="e-card-content py-0 px-0">
            <RAFDataResultProvider>
              <RAFCollapse
                title={"Documents"}
                contentCardClassName=""
                layout={RAFLayout.OneColumnLayout}
                iconName="file-alt"
                customButton={uploadDocumentBtn}
                IsCollapsed={false}
                headerBadge={this.headerBadge()}
                arrowPosistion={this.props.collapsePanelArrowPosistion}
                removeContentCardPadding
                showSectionHeader={false}
              >
                <RAFFieldStateProvider
                  moduleName={RAFEntityName.ContentLibrary}
                >
                  <RAFGridTemplates>
                    <RAFListViewComponent
                      gridId={"ContentLibraryRow"}
                      listCssClass={this.props.moduleName === RAFEntityName.BusinessProcessStep ? "border-0 bg-transparent" : "border-0"}
                      moduleName={this.moduleName}
                      isRemote
                      rowClick={this.rowClick}
                      showEditColumn={false}
                      allowSelection={false}
                      actionButtonClicked={(id, docLibrary) =>
                        this.onActionMenuClicked(id, docLibrary)
                      }
                      //gridTemplates={getGridColumnTemplatesByModule(this.moduleName)}
                      emptyStateProps={{ title: "No document found." }}
                      // emptyStateProps={{
                      //   title: "No results found.",
                      //   body: "Try adjusting your search or changing your filter to find what you're looking for!",
                      //   image: nodata_es,
                      // }}
                      sortBy={{
                        field: "LastActivityDate",
                        order: "descending",
                      }}
                      //viewId={'related_content_library'}
                      //viewName={'related_content_library'}
                      allowFiltering={false}
                      isRelatedSection
                      url={`${Constants.baseAPIUrl}ContentLibrary/RelatedList`}
                      rowTemplate={this.contentLibraryRowTemplate as any}
                      showEdit={false}
                      removeSelectionOnRowClick
                      additionalParams={[
                        {
                          key: "RelatedField",
                          value: isNotNullAndUndefined(secondaryRelatedToUID)
                            ? propertyOf<ContentLibraryRow>(
                              "SecondaryRelatedToUID"
                            )
                            : propertyOf<ContentLibraryRow>("RelatedToUID"),
                        },
                        {
                          key: "RelatedFieldValue",
                          value: isNotNullAndUndefined(secondaryRelatedToUID)
                            ? secondaryRelatedToUID
                            : relatedToUID,
                        },
                      ]}
                      currentFilter={{
                        Condition: "and",
                        Rules: [
                          {
                            Operator: RAFCustomOperator.Equal,
                            Value: [secondaryRelatedToType],
                            Field: propertyOf<ContentLibraryRow>(
                              "SecondaryRelatedToType"
                            ),
                          },
                        ],
                      }}
                      searchFields={['DisplayName']}
                      disableFirstColumnTemplate
                    >
                      <RAFGridColumn<ContentLibraryRow>
                        field="DisplayName"
                        headerText="Process Name"
                        type={SFColumnType.string}
                      />
                    </RAFListViewComponent>
                  </RAFGridTemplates>
                </RAFFieldStateProvider>
                <RAFDataResultContext.Consumer>
                  {({ data, count }) => {
                    if (
                      isNotNullAndUndefined(data) &&
                      isNotNullAndUndefined(count)
                    ) {
                      this.setRelatedDocumentCount(count);
                    }
                    return <div></div>;
                  }}
                </RAFDataResultContext.Consumer>
              </RAFCollapse>
            </RAFDataResultProvider>
            <div>
              {this.state.showDocumentLookupGridContent && (
                <DialogComponent
                  visible={this.state.showDocumentLookupGridContent}
                  //cssClass="mailCenterModal"
                  cssClass="rightDialog rightDialog-lg createEditForm full-height form-center-dialog"
                  content={this.documentLookupGrid.bind(this)}
                  isModal
                  target="body"
                  closeOnEscape={false}
                  close={this.showDocumentLookupGridDialogClose.bind(this)}
                ></DialogComponent>
              )}
              <RAFReportDataListContext.Consumer>
                {({ data }) => {
                  if (
                    isNotNullAndUndefined(data) &&
                    isNotNullAndUndefined(data.data) &&
                    isNotNullAndUndefined(data.data.count)
                  ) {
                    this.setRelatedDocumentCount(data.data.count);
                  }
                  return <div></div>;
                }}
              </RAFReportDataListContext.Consumer>
              {this.state.showUploadContent && (
                <DialogComponent
                  header={
                    this.state.isRevisedVersion
                      ? "Upload New Version"
                      : "Upload File"
                  }
                  showCloseIcon
                  visible={this.state.showUploadContent}
                  cssClass="centerDialog-sm createEditForm form-center-dialog dlg-new-style fixed-header"
                  content={this.uploadAndLinkDocumentContent.bind(this)}
                  isModal
                  target="body"
                  closeOnEscape={false}
                  close={this.uploadDialogClose.bind(this)}
                  animationSettings={this.animationSettings}
                  id="manageUploadDocumentDialog"
                ></DialogComponent>
              )}
              {this.state.showfileDetailsContent && (
                <DialogComponent
                  visible={this.state.showfileDetailsContent}
                  cssClass="rightDialog createEditForm full-height form-center-dialog"
                  height="100%"
                  content={this.fileDetailsContent.bind(this)}
                  isModal
                  target="body"
                  closeOnEscape={false}
                  id="pageFilePropertiesDialog"
                  //overlayClick={this.fileDetailsDialogClose.bind(this)}
                  close={this.fileDetailsDialogClose.bind(this)}
                  animationSettings={this.animationSettings}
                  zIndex={1100}
                ></DialogComponent>
              )}
              {this.state.showManageDocProperties && (
                <DialogComponent
                  visible={this.state.showManageDocProperties}
                  cssClass="rightDialog createEditForm full-height form-center-dialog"
                  height="100%"
                  content={this.manageDocPropertiesContent.bind(this)}
                  isModal
                  target="body"
                  closeOnEscape={false}
                  id="manageDocPropertiesDialog"
                  overlayClick={this.manageDocPropertiesDialogClose.bind(this)}
                  close={this.manageDocPropertiesDialogClose.bind(this)}
                  animationSettings={this.animationSettings}
                ></DialogComponent>
              )}
              <div>
                {this.state.showAttachedDocumentproperty && (
                  <DialogComponent
                    //header="Document Property"
                    //showCloseIcon
                    visible={this.state.showAttachedDocumentproperty}
                    cssClass="rightDialog rightDialog-lg createEditForm full-height form-center-dialog dlg-new-style fixed-header"
                    //cssClass="centerDialog-sm createEditForm form-center-dialog"
                    content={this.documentpropertyContent.bind(this)}
                    isModal
                    target="body"
                    closeOnEscape={false}
                    close={this.documentpropertyDialogClose.bind(this)}
                    animationSettings={this.animationSettings}
                  ></DialogComponent>
                )}
              </div>
              {this.props.showAddBtn && (
                <div className="col-auto">
                  <RAFCaptureImage
                    onSave={(UID) => this.onUploadDocumentFile(UID)}
                    secondaryRelatedToType={this.props.secondaryRelatedToType}
                    secondaryRelatedToUID={this.props.secondaryRelatedToUID}
                    secondaryRelatedTo={this.props.secondaryRelatedTo}
                    relatedToUID={this.props.relatedToUID}
                    relatedTo={this.props.relatedTo}
                    relatedEntity={this.props.relatedEntity}
                    triggerInputFile={this.state.triggerInputFile}
                    // hideAddBtn={true}
                    hideAddBtn
                  />
                </div>
              )}
            </div>
          </div>
          {/* )} */}
        </div>
      </div>
    );
  }
}
RelatedDocumentList.contextType = RAFReportDataListContext;
export default withRouter(RelatedDocumentList);
