import { DialogUtility } from "@syncfusion/ej2-popups";
import { DialogComponent } from "@syncfusion/ej2-react-popups";
import * as R from "ramda";
import React, { PropsWithChildren, useEffect } from "react";
import { FormRenderProps } from "react-final-form";
import { msalInstance } from "../../../..";
import { PreventFocusOnDialogOpen } from "../../../../RAFComponents/Dialog/SFDialogUtils";
import RAFForm from "../../../../RAFComponents/Inputs/RAFForm";
import RAFLookUpMUI from "../../../../RAFComponents/Inputs/RAFLookUpMUI";
import {
  getFormatedDate,
  setFormValue,
} from "../../../../RAFComponents/Inputs/RFFUtils";
import CustomCardWidget from "../../../../RAFComponents/Navigation/CustomCardWidget";
import RAFButtonComponent from "../../../../RAFComponents/Navigation/RAFButtonComponent";
import RAFDetailFieldCustom from "../../../../RAFComponents/Navigation/RAFDetailFieldCustom";
import RAFDetailsValueWithSeparator from "../../../../RAFComponents/Navigation/RAFDetailsValueWithSeparator/RAFDetailsValueWithSeparator";
import RAFEmptyState from "../../../../RAFComponents/Navigation/RAFEmptyState";
import { showWarningToast } from "../../../../RAFComponents/Utility/RAFToastComponent";
import {
  hideProgress,
  showProgress,
} from "../../../../RAFComponents/helpers/AppHelper";
import {
  isNotEmptyArray,
  isNotNullAndUndefined,
} from "../../../../RAFComponents/helpers/utils";
import { LookUpRow } from "../../../../RAFComponents/models/CompositeTypes/LookUpRow";
import {
  BrowserIsDevice,
  MomentFormats,
  RAFActionStatus,
  RAFButtonConstant,
  RAFLayout,
} from "../../../../constants/Common/Constants";
import { RAFEntityName } from "../../../../constants/Common/EntityConstants";
import UserProfilePhoto from "../../User/UserProfilePhoto";
import { ContentLibraryRow } from "../Library/ContentLibraryRow";
import {
  AcknowledgementTrackingRow,
  AcknowledgementTrackingSaveRow,
} from "./AcknowledgementTrackingRow";
import AcknowledgementTrackingSignSignature from "./AcknowledgementTrackingSignSignature";

const getAcknowledgementTrackingInitialValue = (
  contentLibraryRow: ContentLibraryRow,
  acknowledgementTrackingRetrieveResponseRow: AcknowledgementTrackingSaveRow,
  newAcknowledgementTrackingRetrieveResponseRow: AcknowledgementTrackingSaveRow
) => {
  return new Promise<AcknowledgementTrackingSaveRow>(async (resolve) => {
    if (isNotNullAndUndefined(contentLibraryRow)) {
      if (
        isNotNullAndUndefined(newAcknowledgementTrackingRetrieveResponseRow)
      ) {
        resolve(newAcknowledgementTrackingRetrieveResponseRow);
      } else if (
        isNotNullAndUndefined(acknowledgementTrackingRetrieveResponseRow)
      ) {
        resolve(acknowledgementTrackingRetrieveResponseRow);
      } else {
        if (isNotNullAndUndefined(contentLibraryRow)) {
          const acknowledgementTrackingRetrieveResponseRow =
            new AcknowledgementTrackingSaveRow();
          acknowledgementTrackingRetrieveResponseRow.RecordType = "Document";
          acknowledgementTrackingRetrieveResponseRow.DocumentUID =
            contentLibraryRow.UID;
          acknowledgementTrackingRetrieveResponseRow.Document =
            contentLibraryRow.DisplayName;
          acknowledgementTrackingRetrieveResponseRow.Status =
            RAFActionStatus.Pending;
          acknowledgementTrackingRetrieveResponseRow.VersionNumber =
            contentLibraryRow.Revision;
          acknowledgementTrackingRetrieveResponseRow.RequestedBy =
            msalInstance.currentUserName;
          acknowledgementTrackingRetrieveResponseRow.RequestedByUID =
            msalInstance.currentUserId;
          acknowledgementTrackingRetrieveResponseRow.RequestedDate = new Date();

          acknowledgementTrackingRetrieveResponseRow.Recipients = [];
          resolve(acknowledgementTrackingRetrieveResponseRow);
        } else {
          resolve(null);
        }
      }
    } else {
      resolve(null);
    }
  });
};

interface IProps {
  contentLibraryRow: ContentLibraryRow;
  recipientsAcknowledgementTrackingList: AcknowledgementTrackingRow[];
  acknowledgementTrackingRetrieveResponseRow: AcknowledgementTrackingSaveRow;
  onUpdateNewAcknowledgementTrackingRetrieveResponseRow: (
    newAcknowledgementTrackingRetrieveResponseRow: AcknowledgementTrackingSaveRow
  ) => void;
  mode: "default" | "Overview";
  selectedTabId: "NewRequest" | "RequestedAcknowledgement";
  newAcknowledgementTrackingRetrieveResponseRow: AcknowledgementTrackingSaveRow;
}

interface IState {
  displayMode: "view" | "edit";
  showSignSignatureDlgContent: boolean;
  selectedAcknowledgementTrackingRow: AcknowledgementTrackingRow;
}

function AcknowledgementTrackingListItemTabContent({
  contentLibraryRow,
  acknowledgementTrackingRetrieveResponseRow,
  ...props
}: PropsWithChildren<IProps>) {
  const outerDivId = `raf_dlg_Outer_Div_${RAFEntityName.AcknowledgementTracking}`;
  let rafForm: FormRenderProps | null;
  let deleteDialog: any;

  const [state, setState] = React.useReducer<
    React.Reducer<IState, Partial<IState>>
  >((state, newState) => ({ ...state, ...newState }), {
    displayMode: "view",
    showSignSignatureDlgContent: false,
    selectedAcknowledgementTrackingRow: null,
  });

  useEffect(() => {
    setState({
      displayMode: isNotNullAndUndefined(
        props.newAcknowledgementTrackingRetrieveResponseRow
      )
        ? "edit"
        : "view",
      showSignSignatureDlgContent: false,
      selectedAcknowledgementTrackingRow: null,
    });
  }, [
    acknowledgementTrackingRetrieveResponseRow,
    props.newAcknowledgementTrackingRetrieveResponseRow,
    contentLibraryRow,
    props.selectedTabId,
  ]);

  async function checkScrollable() {
    const div = await document.querySelector(
      "#raf_tab_header_acknowledgement_tracking"
    );
    const leftButton = await document.getElementById(
      "btn_raf_tab_header_acknowledgement_tracking_left_scroll_iconBtn"
    );
    const rightButton = await document.getElementById(
      "btn_raf_tab_header_acknowledgement_tracking_right_scroll_iconBtn"
    );

    if (div && leftButton && rightButton) {
      // Check if the div is scrollable
      if (div.scrollWidth > div.clientWidth) {
        // The div is scrollable, show the arrow buttons
        leftButton.style.display = "block";
        rightButton.style.display = "block";

        // Add click event listeners to the arrow buttons
        leftButton.addEventListener("click", () => {
          // Scroll the div to the left
          div.scrollTo({
            left: div.scrollLeft - (div.clientWidth - 100),
            behavior: "smooth",
          });
        });

        rightButton.addEventListener("click", () => {
          // Scroll the div to the right
          div.scrollTo({
            left: div.scrollLeft + (div.clientWidth - 100),
            behavior: "smooth",
          });
        });
      } else {
        // The div is not scrollable, hide the arrow buttons
        leftButton.style.display = "none";
        rightButton.style.display = "none";
      }

      // Stop observing the DOM changes
      observer.disconnect();
    }
  }

  // Create a MutationObserver to watch for changes in the DOM
  const observer = new MutationObserver(checkScrollable);

  // Start observing the document with the configured parameters
  observer.observe(document, { childList: true, subtree: true });

  // Signature Dialog start
  const onSignSignatureBtnClick = (
    selectedAcknowledgementTrackingRow: AcknowledgementTrackingRow
  ) => {
    setState({
      showSignSignatureDlgContent: true,
      selectedAcknowledgementTrackingRow,
    });
  };

  const signSignatureDlgContent = () => {
    if (state.showSignSignatureDlgContent) {
      const { selectedAcknowledgementTrackingRow } = state;

      return (
        <AcknowledgementTrackingSignSignature
          acknowledgementTrackingRow={selectedAcknowledgementTrackingRow}
          mode="view"
        />
      );
    } else {
      return null;
    }
  };

  const signSignatureDlgClose = () => {
    setState({ showSignSignatureDlgContent: false });
  };
  // Signature Dialog end

  const onSubmitAcknowledgementTracking = async (newEmployee: LookUpRow) => {
    let progressDiv = showProgress(`#${outerDivId}`);
    const formEmployeeUID = newEmployee.UID;
    const formEmployee = newEmployee.Value;

    const { newAcknowledgementTrackingRetrieveResponseRow } = props;

    const acknowledgementTrackingInitialRow: AcknowledgementTrackingSaveRow =
      R.clone(
        await getAcknowledgementTrackingInitialValue(
          contentLibraryRow,
          acknowledgementTrackingRetrieveResponseRow,
          newAcknowledgementTrackingRetrieveResponseRow
        )
      );

    const acknowledgedBy = isNotEmptyArray(
      acknowledgementTrackingInitialRow.AcknowledgementRecipients
    )
      ? acknowledgementTrackingInitialRow.AcknowledgementRecipients
      : [];

    const selectedEmployeeUID: LookUpRow = {
      UID: formEmployeeUID,
      Value: formEmployee,
    };

    const itemExists = acknowledgedBy.some(
      (recipient) => recipient.UID === selectedEmployeeUID.UID
    );
    if (!itemExists) {
      const selectedAcknowledgementTrackingListValues: LookUpRow[] =
        isNotNullAndUndefined(acknowledgementTrackingRetrieveResponseRow)
          ? acknowledgementTrackingRetrieveResponseRow.AcknowledgementRecipients
          : [];
      const isItemExistInSelectedList = isNotEmptyArray(
        selectedAcknowledgementTrackingListValues
      )
        ? selectedAcknowledgementTrackingListValues.find(
          (recipient) => recipient.UID === selectedEmployeeUID.UID
        )
        : null;
      if (isNotNullAndUndefined(isItemExistInSelectedList)) {
        acknowledgedBy.push(isItemExistInSelectedList);
      } else {
        acknowledgedBy.push({
          UID: selectedEmployeeUID.UID,
          Value: selectedEmployeeUID.Value,
          Type: RAFEntityName.User,
        });
      }
    } else {
      hideProgress(progressDiv);
      showWarningToast("Employee already exists");
      return;
    }

    acknowledgementTrackingInitialRow.AcknowledgementRecipients =
      acknowledgedBy;
    if (props.onUpdateNewAcknowledgementTrackingRetrieveResponseRow) {
      props.onUpdateNewAcknowledgementTrackingRetrieveResponseRow(
        acknowledgementTrackingInitialRow
      );
    }
    hideProgress(progressDiv);
  };

  const onChangeEmployeeLookup = (label: string, value: string) => {
    if (isNotNullAndUndefined(value)) {
      onSubmitAcknowledgementTracking({ Value: label, UID: value });
    }
    setFormValue(rafForm, "Employee", null);
    setFormValue(rafForm, "EmployeeUID", null);
  };

  const getEmployeeMultiSelectLookUpDropdown = () => {
    const { newAcknowledgementTrackingRetrieveResponseRow } = props;
    const acknowledgedByListRow = isNotNullAndUndefined(
      newAcknowledgementTrackingRetrieveResponseRow
    )
      ? newAcknowledgementTrackingRetrieveResponseRow.AcknowledgementRecipients
      : isNotNullAndUndefined(acknowledgementTrackingRetrieveResponseRow)
        ? acknowledgementTrackingRetrieveResponseRow.AcknowledgementRecipients
        : [];

    const disableAcknowledgementTrackingDropDownItemsValue: LookUpRow[] = [];
    acknowledgedByListRow &&
      acknowledgedByListRow.forEach((item) => {
        if (isNotNullAndUndefined(item.UID))
          disableAcknowledgementTrackingDropDownItemsValue.push({
            Value: item.Value,
            UID: item.UID,
          });
      });

    return (
      <div
        className="pb-3"
        key={
          isNotEmptyArray(acknowledgedByListRow)
            ? acknowledgedByListRow.length
            : 0
        }
      >
        <RAFForm
          formRef={(g) => (rafForm = g)}
          layout={RAFLayout.TwoColumnLayout}
          onSubmit={(values) => onSubmitAcknowledgementTracking(values)}
          className="h-100"
        >
          <div className="row g-2">
            <div className="col">
              <RAFLookUpMUI
                field={"Employee"}
                formGroupClassName="m-0"
                showLabel={false}
                placeholder="Select Recipient"
                disableItems={disableAcknowledgementTrackingDropDownItemsValue}
                required
                url="User/EmployeeLookup"
                onChanged={onChangeEmployeeLookup}
              />
              {/* <RAFDropdownCC field={`EmployeeUID`}
                                formGroupClassName="m-0" showLabel={false} placeholder="Select Recipient"
                                disableItems={disableAcknowledgementTrackingDropDownItemsValue}
                                onChanged={(label, value) => { }}
                                showClearButton={true}
                                required
                                allowFiltering
                            >
                                {props.allEmployeeLookUpList && props.allEmployeeLookUpList.map((item, index) => {
                                    return <RAFChoiceOption key={index} label={item.Value} value={item.UID} />;
                                })
                                }
                            </RAFDropdownCC> */}
            </div>
          </div>
        </RAFForm>
      </div>
    );
  };

  //delete Remove Shift Offer item start
  const onRemoveAcknowledgementTrackingBtnClick = (
    acknowledgementTrackingRecipientUID: string,
    acknowledgementTrackingRecipient: string
  ) => {
    deleteDialog = DialogUtility.confirm({
      animationSettings: { effect: "Fade" },
      cancelButton: { text: "No" },
      closeOnEscape: false,
      content: `Are you sure want to remove ${acknowledgementTrackingRecipient}?`,
      okButton: {
        text: "Yes",
        click: deleteRecord.bind(this, acknowledgementTrackingRecipientUID),
      },
      title: `Remove Recipient`,
      position: { X: "center", Y: "center" },
      cssClass: `alert-dialog deleteDialog`,
    });
  };

  async function deleteRecord(acknowledgementTrackingRecipientUID: string) {
    let progressDiv = showProgress(".deleteDialog.e-dialog");
    const acknowledgementTrackingInitialRow: AcknowledgementTrackingSaveRow =
      R.clone(
        await getAcknowledgementTrackingInitialValue(
          contentLibraryRow,
          acknowledgementTrackingRetrieveResponseRow,
          props.newAcknowledgementTrackingRetrieveResponseRow
        )
      );
    const acknowledgedBy = isNotEmptyArray(
      acknowledgementTrackingInitialRow.AcknowledgementRecipients
    )
      ? acknowledgementTrackingInitialRow.AcknowledgementRecipients.filter(
        (x) => x.UID !== acknowledgementTrackingRecipientUID
      )
      : [];
    acknowledgementTrackingInitialRow.AcknowledgementRecipients =
      acknowledgedBy;

    if (props.onUpdateNewAcknowledgementTrackingRetrieveResponseRow) {
      props.onUpdateNewAcknowledgementTrackingRetrieveResponseRow(
        acknowledgementTrackingInitialRow
      );
    }

    deleteDialog.hide();
    hideProgress(progressDiv);
  }
  //delete item end

  const getEmployeeLookupContent = (
    acknowledgedByListRow: LookUpRow[],
    showEmptyMessage: boolean
  ) => {
    const { recipientsAcknowledgementTrackingList } = props;

    const sortedAcknowledgedByListRow: LookUpRow[] = isNotEmptyArray(
      acknowledgedByListRow
    )
      ? acknowledgedByListRow.sort((a, b) => a.Value.localeCompare(b.Value))
      : [];

    if (isNotEmptyArray(sortedAcknowledgedByListRow)) {
      return (
        <div className="row gx-0 gy-3">
          {sortedAcknowledgedByListRow.map((acknowledgedByRow: LookUpRow) => {
            const acknowledgementTrackingItem = isNotEmptyArray(
              recipientsAcknowledgementTrackingList
            )
              ? recipientsAcknowledgementTrackingList.find(
                (item) => item.SignedByUID === acknowledgedByRow.UID
              )
              : null;

            return (
              <div className="col-12" key={acknowledgedByRow.UID}>
                <CustomCardWidget
                  cardClassName="surface_neutral_base"
                  cardContentClassName="p-2 px-2-5"
                >
                  <div className="row gx-2 align-items-center">
                    {BrowserIsDevice ? (
                      <div
                        className="col-auto"
                        id={`employee_${acknowledgedByRow.UID}`}
                      >
                        <div>
                          <UserProfilePhoto
                            uid={acknowledgedByRow.UID}
                            relatedToType={RAFEntityName.User}
                            canEdit={false}
                            profileImgCss={"profileImg-container-xmd"}
                            progressDivID={`#employee_${acknowledgedByRow.UID}`}
                            title={acknowledgedByRow.Value}
                            iconSize="48"
                            fontSize="16"
                            emptyAvatarType="Initial"
                          />
                        </div>
                      </div>
                    ) : (
                      <div
                        className="col-auto"
                        id={`employee_${acknowledgedByRow.UID}`}
                      >
                        <UserProfilePhoto
                          uid={acknowledgedByRow.UID}
                          relatedToType={RAFEntityName.User}
                          canEdit={false}
                          profileImgCss={"profileImg-container-xmd"}
                          progressDivID={`#employee_${acknowledgedByRow.UID}`}
                          title={acknowledgedByRow.Value}
                          iconSize="48"
                          fontSize="16"
                          emptyAvatarType="Initial"
                        />
                      </div>
                    )}
                    <div className="col d-flex">
                      <div>
                        <div>
                          <span className="header_6 semi_bold mb-1 ecllipseFirstLine">
                            {acknowledgedByRow.Value}
                          </span>
                        </div>
                        <div className="row gx-2 gy-0">
                          <RAFDetailsValueWithSeparator outerClassName="h-auto raf_md">
                            {isNotNullAndUndefined(
                              acknowledgementTrackingItem
                            ) &&
                              isNotNullAndUndefined(
                                acknowledgementTrackingItem.Status
                              ) && (
                                <RAFDetailFieldCustom
                                  value={
                                    isNotNullAndUndefined(
                                      acknowledgementTrackingItem.Status
                                    )
                                      ? acknowledgementTrackingItem.Status
                                      : "Not set"
                                  }
                                  displayValue={
                                    acknowledgementTrackingItem.Status
                                  }
                                  title="Status:"
                                  moduleName={
                                    RAFEntityName.AcknowledgementTracking
                                  }
                                  isColorOption
                                  field="Status"
                                  showLabel={true}
                                  rowClassName="gx-1 align-items-baseline"
                                  labelDivClassName="col-auto"
                                  valueDivClassName="col-auto"
                                  labelClassName="body_2_light content_neutral_base"
                                  valueClassName="subtitle_2 semi_bold"
                                  mode="textOnly"
                                />
                              )}
                            {isNotNullAndUndefined(
                              acknowledgementTrackingItem
                            ) &&
                              isNotNullAndUndefined(
                                acknowledgementTrackingItem.RequestedBy
                              ) && (
                                <div className="col-auto">
                                  <span className="body_2_light">
                                    Requested By:
                                    <span className="body_2 semi_bold ps-1">
                                      {
                                        acknowledgementTrackingItem.RequestedBy
                                      }
                                    </span>
                                  </span>
                                </div>
                              )}
                            {isNotNullAndUndefined(
                              acknowledgementTrackingItem
                            ) &&
                              (isNotNullAndUndefined(
                                acknowledgementTrackingItem.SignedDate
                              ) ? (
                                <div className="col-auto">
                                  <span className="body_2_light pe-1">
                                    Response On:
                                    <span className="body_2 semi_bold ps-1">
                                      {getFormatedDate(
                                        acknowledgementTrackingItem.SignedDate,
                                        MomentFormats.DATE
                                      )}
                                    </span>
                                  </span>
                                </div>
                              ) : isNotNullAndUndefined(
                                acknowledgementTrackingItem.RequestedDate
                              ) ? (
                                <div className="col-auto">
                                  <span className="body_2_light pe-1">
                                    Requested On:
                                    <span className="body_2 semi_bold ps-1">
                                      {getFormatedDate(
                                        acknowledgementTrackingItem.RequestedDate,
                                        MomentFormats.DATE
                                      )}
                                    </span>
                                  </span>
                                </div>
                              ) : (
                                ""
                              ))}
                          </RAFDetailsValueWithSeparator>
                          {/* {(isNotNullAndUndefined(acknowledgementTrackingItem) &&
                                                          isNotNullAndUndefined(acknowledgementTrackingItem.Status)) && (
                                                              <div className="col-auto">
                                                                  <span className="body_2_light">
                                                                      Status:
                                                                      <span className="body_2 ms-1">
                                                                          {acknowledgementTrackingItem.Status}
                                                                      </span>
                                                                  </span>
                                                              </div>
                                                          )} */}
                        </div>
                      </div>
                    </div>
                    {isNotNullAndUndefined(acknowledgementTrackingItem) &&
                      acknowledgementTrackingItem.Status ===
                      RAFActionStatus.Acknowledged ? (
                      <div className="col-auto">
                        <RAFButtonComponent
                          type="button"
                          className="new_style link-button raf_sm"
                          btnContent="Check"
                          onClick={() =>
                            onSignSignatureBtnClick(
                              acknowledgementTrackingItem
                            )
                          }
                        ></RAFButtonComponent>
                      </div>
                    ) : (
                      props.mode === "default" && (
                        <div className="col-auto">
                          <RAFButtonComponent
                            type="button"
                            btnContent="Click to remove"
                            className="btn_state_danger white"
                            onClick={() =>
                              onRemoveAcknowledgementTrackingBtnClick(
                                acknowledgedByRow.UID,
                                acknowledgedByRow.Value
                              )
                            }
                            enableToolTip
                            iconBtn
                            action={RAFButtonConstant.Delete}
                          ></RAFButtonComponent>
                        </div>
                      )
                    )}
                  </div>
                </CustomCardWidget>
              </div>
            );
          })}
        </div>
      );
    } else if (showEmptyMessage) {
      return (
        <div className="container-fluid px-0">
          <RAFEmptyState
            title="Please add a signing person to acknowledge and sign."
            displayStyle="TextOnly"
          />
        </div>
      );
    } else {
      return <></>;
    }
  };

  const getNewRequestContent = () => {
    if (props.mode === "default") {
      const { recipientsAcknowledgementTrackingList } = props;

      const { newAcknowledgementTrackingRetrieveResponseRow } = props;

      const allAcknowledgedByListRow = isNotNullAndUndefined(
        newAcknowledgementTrackingRetrieveResponseRow
      )
        ? newAcknowledgementTrackingRetrieveResponseRow.AcknowledgementRecipients
        : [];

      const acknowledgedByListRow =
        isNotEmptyArray(allAcknowledgedByListRow) &&
          isNotEmptyArray(recipientsAcknowledgementTrackingList)
          ? allAcknowledgedByListRow.filter(
            (x) =>
              !recipientsAcknowledgementTrackingList.find(
                (y) => y.SignedByUID === x.UID
              )
          )
          : allAcknowledgedByListRow;

      return (
        <>
          <div className="col-md-12">
            <div className="subtitle_1 semi_bold">New Request</div>
          </div>
          <div className="col-md-12">
            {getEmployeeMultiSelectLookUpDropdown()}
            {getEmployeeLookupContent(acknowledgedByListRow, false)}
          </div>
        </>
      );
    } else {
      return <></>;
    }
  };

  const getRequestedContent = () => {
    const { recipientsAcknowledgementTrackingList } = props;

    const { newAcknowledgementTrackingRetrieveResponseRow } = props;

    const allAcknowledgedByListRow = isNotNullAndUndefined(
      newAcknowledgementTrackingRetrieveResponseRow
    )
      ? newAcknowledgementTrackingRetrieveResponseRow.AcknowledgementRecipients
      : isNotNullAndUndefined(acknowledgementTrackingRetrieveResponseRow)
        ? acknowledgementTrackingRetrieveResponseRow.AcknowledgementRecipients
        : [];

    const pendingItems =
      isNotEmptyArray(allAcknowledgedByListRow) &&
        isNotEmptyArray(recipientsAcknowledgementTrackingList)
        ? allAcknowledgedByListRow.filter((x) =>
          recipientsAcknowledgementTrackingList.find(
            (y) => y.SignedByUID === x.UID && y.Status === RAFActionStatus.Pending
          )
        )
        : [];

    const acknowledgedItems =
      isNotEmptyArray(allAcknowledgedByListRow) &&
        isNotEmptyArray(recipientsAcknowledgementTrackingList)
        ? allAcknowledgedByListRow.filter((x) =>
          recipientsAcknowledgementTrackingList.find(
            (y) => y.SignedByUID === x.UID && y.Status === RAFActionStatus.Acknowledged
          )
        )
        : [];

    const rejectedItems =
      isNotEmptyArray(allAcknowledgedByListRow) &&
        isNotEmptyArray(recipientsAcknowledgementTrackingList)
        ? allAcknowledgedByListRow.filter((x) =>
          recipientsAcknowledgementTrackingList.find(
            (y) => y.SignedByUID === x.UID && y.Status === RAFActionStatus.Rejected
          )
        )
        : [];

    return (
      <>
        <div className="col-md-12">
          <div className="subtitle_1 semi_bold">Pending Requests</div>
        </div>
        <div className="col-md-12">
          {getEmployeeLookupContent(pendingItems, true)}
        </div>
        {isNotEmptyArray(acknowledgedItems) && (
          <>
            <div className="col-md-12">
              <div className="custom__card__separator custom__card__separator__light"></div>
            </div>
            <div className="col-md-12">
              <div className="subtitle_1 semi_bold">Received Requests</div>
            </div>
            <div className="col-md-12">
              {getEmployeeLookupContent(acknowledgedItems, true)}
            </div>
          </>
        )}
        {isNotEmptyArray(rejectedItems) && (
          <>
            <div className="col-md-12">
              <div className="custom__card__separator custom__card__separator__light"></div>
            </div>
            <div className="col-md-12">
              <div className="subtitle_1 semi_bold">Rejected Requests</div>
            </div>
            <div className="col-md-12">
              {getEmployeeLookupContent(rejectedItems, true)}
            </div>
          </>
        )}
      </>
    );
  };

  function renderTabContent() {
    if (props.selectedTabId === "NewRequest") {
      return <>{getNewRequestContent()}</>;
    } else if (props.selectedTabId === "RequestedAcknowledgement") {
      return <>{getRequestedContent()}</>;
    }
  }

  return (
    <div className="row gx-0 gy-3" id={outerDivId}>
      {renderTabContent()}
      {state.showSignSignatureDlgContent && (
        <DialogComponent
          header={"Confirm Acknowledgment"}
          visible={state.showSignSignatureDlgContent}
          cssClass="centerDialog-md createEditForm full-height form-center-dialog dlg-new-style fixed-header centerDialog-height-lg dlg_res_full_page"
          content={signSignatureDlgContent.bind(this)}
          isModal
          target="body"
          closeOnEscape={false}
          close={signSignatureDlgClose.bind(this)}
          open={PreventFocusOnDialogOpen}
          showCloseIcon
          zIndex={1500}
        />
      )}
    </div>
  );
}

export default React.memo(AcknowledgementTrackingListItemTabContent);
