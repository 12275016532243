import { atomFamily } from "recoil";
import {
  RAFCustomFilter,
  RAFCustomOperator,
} from "../../../../RAFComponents/RAFViewPanels/RAFFilterColumn/RAFCustomFilter";
import {
  isNotNullAndUndefined,
  isNullOrUndefined,
  propertyOf
} from "../../../../RAFComponents/helpers/utils";
import { RAFTreeNodeModel } from "../../../../RAFComponents/models/Common/RAFTreeNodeModel";
import { LookUpRow } from "../../../../RAFComponents/models/CompositeTypes/LookUpRow";
import * as repositoryActions from "../../../../RAFComponents/store/actions/repositoryActions";
import { HeaderTabItems } from "../../../../components/shared/NavigationMenu/NavigationHelper";
import {
  ContentType,
  RAFDocumentType,
} from "../../../../constants/Common/Constants";
import { RAFModuleName } from "../../../../constants/Common/EntityConstants";
import { PartnerPortalRow } from "../../../Common/Workspace/PartnerPortal/PartnerPortalRow";
import { ContentLibraryRow } from "../Library/ContentLibraryRow";
import {
  DocumentRetrieveRequest,
  RAFSystemContentLibraryNode,
} from "../Library/DocumentHelper";

export const generalRecordCategoryText = "General";

export const atomSelectedDocumentIndexTabItem = atomFamily<any, any>({
  key: "selectedDocumentIndexTabItem",
  default: null,
});

export const atomSelectedDocumnetIndexDocumentType = atomFamily<any, any>({
  key: "selectedDocumnetIndexDocumentType",
  default: null,
});

export const atomSelectedFormLibrary = atomFamily<any, any>({
  key: "selectedFormLibraryState",
  default: null,
});

export const atomSelectedRecordType = atomFamily<any, any>({
  key: "selectedRecordTypeState",
  default: null,
});

// export const atomSelectedDocumentFolder = atomFamily<any, any>({
//     key: "selectedDocumentFolderState",
//     default: null
// });

export const atomDocumentFolderArray = atomFamily<any, any>({
  key: "documentFolderArrayrState",
  default: null,
});

export const isContentLibrary = () => {
  let isContentLibrary = false;
  let pathName = window.location.pathname;
  let objPath = pathName.split("/");
  if (objPath.includes("DocumentLibrary")) {
    isContentLibrary = true;
  }
  return isContentLibrary;
};

export const getRelatedDocumentsAdditionalParams = (
  parentUID: string,
  moduleName: string,
  selectedRecordCategoryUID: string,
  tagRow?: LookUpRow,
  partnerPortal?: PartnerPortalRow,
  selectedTabValue?: string,
  selectedFormLibraryUID?: string
): { key: string; value?: string | string[]; }[] => {
  let additionalParams: { key: string; value?: string | string[]; }[] = [];

  if (
    selectedTabValue === HeaderTabItems.Category.value &&
    isNotNullAndUndefined(selectedRecordCategoryUID)
  ) {
    if (
      selectedRecordCategoryUID === RAFSystemContentLibraryNode.RecordCategory.id ||
      isNullOrUndefined(selectedRecordCategoryUID)
    ) {
    } else {
      additionalParams.push({
        key: propertyOf<ContentLibraryRow>("RecordCategory"),
        value: selectedRecordCategoryUID,
      });
    }
  } else if (selectedTabValue === HeaderTabItems.Tags.value) {
    if (isNotNullAndUndefined(tagRow) && isNotNullAndUndefined(tagRow.Value)) {
      additionalParams.push({
        key: "Tag",
        value: tagRow.Value,
      });
    } else {
    }
  } else if (selectedTabValue === HeaderTabItems.Folders.value || selectedTabValue === HeaderTabItems.All.value) {
    if (isNotNullAndUndefined(parentUID)) {
      additionalParams.push({
        key: propertyOf<ContentLibraryRow>("ParentUID"),
        value: parentUID,
      });
    }
  }
  // else if (selectedTabValue === HeaderTabItems.Type.value) {
  //   if (isNotNullAndUndefined(selectedFormLibraryUID)) {
  //     additionalParams.push({
  //       key: "ContentUID",
  //       value: selectedFormLibraryUID,
  //     });
  //   }
  // }

  return additionalParams;
};

export const getRelatedDocumentsFilter = (
  parentUID: string,
  moduleName: string,
  selectedRecordCategoryUID: string,
  tagRow: LookUpRow,
  partnerPortal: PartnerPortalRow,
  selectedTabValue: string,
  selectedFormLibraryUID: string,
  headerFilterText: string,
  defaultDocumentType: string
) => {
  let customFilter: RAFCustomFilter = {};
  customFilter.Condition = "and";
  customFilter.Rules = [];

  if (isNotNullAndUndefined(moduleName)) {
    let filterModule: RAFCustomFilter = {};
    let filterValModule: string[] = [];

    filterValModule.push(moduleName);
    filterModule.Operator = RAFCustomOperator.Equal;
    filterModule.Value = filterValModule;
    filterModule.Field = propertyOf<ContentLibraryRow>("Entity");
    customFilter.Rules.push(filterModule);
  }

  if (isNotNullAndUndefined(headerFilterText)) {
    let filterHeader: RAFCustomFilter = {};
    let filterValHeader: string[] = [];

    filterValHeader.push(headerFilterText);
    filterHeader.Operator = RAFCustomOperator.Contains;
    filterHeader.Value = filterValHeader;
    filterHeader.Field = propertyOf<ContentLibraryRow>("DisplayName");
    customFilter.Rules.push(filterHeader);
  }

  if (
    selectedTabValue === HeaderTabItems.Category.value &&
    isNotNullAndUndefined(selectedRecordCategoryUID)
  ) {
    let filterRC: RAFCustomFilter = {};
    let filterValRC: string[] = [];

    if (
      selectedRecordCategoryUID === RAFSystemContentLibraryNode.RecordCategory.id ||
      isNullOrUndefined(selectedRecordCategoryUID)
    ) {
      filterRC.Operator = RAFCustomOperator.IsNotNull;
      filterRC.Field = propertyOf<ContentLibraryRow>("RecordCategory");
      //customFilter.Rules.push(filterRC);
    } else {
      filterValRC.push(selectedRecordCategoryUID);
      filterRC.Operator = RAFCustomOperator.Equal;
      filterRC.Value = filterValRC;
      filterRC.Field = propertyOf<ContentLibraryRow>("RecordCategory");
      //customFilter.Rules.push(filterRC);
    }

    let filterDocType: RAFCustomFilter = {};
    let filterValDocType: string[] = [];

    filterValDocType.push(RAFDocumentType.Folder);
    filterDocType.Operator = RAFCustomOperator.NotEqual;
    filterDocType.Value = filterValDocType;
    filterDocType.Field = propertyOf<ContentLibraryRow>("DocumentType");
    customFilter.Rules.push(filterDocType);
  } else if (selectedTabValue === HeaderTabItems.Tags.value) {
    let filterTag: RAFCustomFilter = {};
    if (isNotNullAndUndefined(tagRow) && isNotNullAndUndefined(tagRow.Value)) {
      let filterValTag: string[] = [];
      filterValTag.push(tagRow.Value);
      filterTag.Operator = RAFCustomOperator.Equal;
      filterTag.Value = filterValTag;
      filterTag.Field = propertyOf<ContentLibraryRow>("TagsListJson");
      //customFilter.Rules.push(filterTag);
    } else {
      filterTag.Operator = RAFCustomOperator.IsNotNull;
      filterTag.Field = propertyOf<ContentLibraryRow>("TagsListJson");
      customFilter.Rules.push(filterTag);
    }

    let filterDocType: RAFCustomFilter = {};
    let filterValDocType: string[] = [];

    filterValDocType.push(RAFDocumentType.Folder);
    filterDocType.Operator = RAFCustomOperator.NotEqual;
    filterDocType.Value = filterValDocType;
    filterDocType.Field = propertyOf<ContentLibraryRow>("DocumentType");
    customFilter.Rules.push(filterDocType);
  } else if (selectedTabValue === HeaderTabItems.Folders.value) {
    if (isNotNullAndUndefined(parentUID)) {
      let filterParent: RAFCustomFilter = {};
      let filterValParent: string[] = [];

      filterValParent.push(parentUID);
      filterParent.Operator = RAFCustomOperator.Equal;
      filterParent.Value = filterValParent;
      filterParent.Field = propertyOf<ContentLibraryRow>("ParentUID");
      //customFilter.Rules.push(filterParent);

      let filterDocType: RAFCustomFilter = {};
      let filterValDocType: string[] = [];

      filterValDocType.push(RAFDocumentType.Folder);
      filterDocType.Operator = RAFCustomOperator.Equal;
      filterDocType.Value = filterValDocType;
      filterDocType.Field = propertyOf<ContentLibraryRow>("DocumentType");
      customFilter.Rules.push(filterDocType);
    }
  } else if (selectedTabValue === HeaderTabItems.Type.value) {
    if (isNotNullAndUndefined(selectedFormLibraryUID)) {
      let filterParent: RAFCustomFilter = {};
      let filterValParent: string[] = [];

      filterValParent.push(selectedFormLibraryUID);
      filterParent.Operator = RAFCustomOperator.Equal;
      filterParent.Value = filterValParent;
      filterParent.Field = "Content";
      customFilter.Rules.push(filterParent);
    }
  } else if (selectedTabValue === HeaderTabItems.All.value) {
    if (defaultDocumentType !== HeaderTabItems.All.value) {
      let filterDocType: RAFCustomFilter = {};
      let filterValDocType: string[] = [];

      filterValDocType.push(defaultDocumentType);
      filterDocType.Operator = RAFCustomOperator.Equal;
      filterDocType.Value = filterValDocType;
      filterDocType.Field = propertyOf<ContentLibraryRow>("DocumentType");
      customFilter.Rules.push(filterDocType);
    }
  }

  return customFilter;
};

export const getRelatedDocumentsFilter22 = (
  id: string,
  moduleName: string,
  selectedRAFTreeNode: RAFTreeNodeModel,
  tagRow?: LookUpRow,
  partnerPortal?: PartnerPortalRow,
  selectedTabValue?: string
) => {
  return new Promise<RAFCustomFilter>((resolve, reject) => {
    let selectedRAFTreeNodeId = isNotNullAndUndefined(selectedRAFTreeNode)
      ? selectedRAFTreeNode.id
      : null;

    let customFilter: RAFCustomFilter = {};
    customFilter.Condition = "and";
    customFilter.Rules = [];

    if (isNotNullAndUndefined(moduleName)) {
      let filterModule: RAFCustomFilter = {};
      let filterValModule: string[] = [];

      filterValModule.push(moduleName);
      filterModule.Operator = RAFCustomOperator.Equal;
      filterModule.Value = filterValModule;
      filterModule.Field = propertyOf<ContentLibraryRow>("Entity");
      customFilter.Rules.push(filterModule);
    }

    if (
      selectedTabValue === HeaderTabItems.Category.value &&
      isNotNullAndUndefined(selectedRAFTreeNodeId)
    ) {
      let filterRC: RAFCustomFilter = {};
      let filterValRC: string[] = [];

      if (
        selectedRAFTreeNodeId ===
        RAFSystemContentLibraryNode.RecordCategory.id ||
        isNullOrUndefined(selectedRAFTreeNodeId)
      ) {
        filterRC.Operator = RAFCustomOperator.IsNotNull;
        filterRC.Field = propertyOf<ContentLibraryRow>("RecordCategory");
        customFilter.Rules.push(filterRC);
      } else {
        filterValRC.push(selectedRAFTreeNodeId);
        filterRC.Operator = RAFCustomOperator.Equal;
        filterRC.Value = filterValRC;
        filterRC.Field = propertyOf<ContentLibraryRow>("RecordCategory");
        //customFilter.Rules.push(filterRC);
      }

      let filterDocType: RAFCustomFilter = {};
      let filterValDocType: string[] = [];

      filterValDocType.push(RAFDocumentType.Folder);
      filterDocType.Operator = RAFCustomOperator.NotEqual;
      filterDocType.Value = filterValDocType;
      filterDocType.Field = propertyOf<ContentLibraryRow>("DocumentType");
      customFilter.Rules.push(filterDocType);
    } else if (selectedTabValue === HeaderTabItems.Tags.value) {
      let filterTag: RAFCustomFilter = {};
      if (
        isNotNullAndUndefined(tagRow) &&
        isNotNullAndUndefined(tagRow.Value)
      ) {
        let filterValTag: string[] = [];
        filterValTag.push(tagRow.Value);
        filterTag.Operator = RAFCustomOperator.Equal;
        filterTag.Value = filterValTag;
        filterTag.Field = propertyOf<ContentLibraryRow>("TagsListJson");
        //customFilter.Rules.push(filterTag);
      } else {
        filterTag.Operator = RAFCustomOperator.IsNotNull;
        filterTag.Field = propertyOf<ContentLibraryRow>("TagsListJson");
        customFilter.Rules.push(filterTag);
      }

      let filterDocType: RAFCustomFilter = {};
      let filterValDocType: string[] = [];

      filterValDocType.push(RAFDocumentType.Folder);
      filterDocType.Operator = RAFCustomOperator.NotEqual;
      filterDocType.Value = filterValDocType;
      filterDocType.Field = propertyOf<ContentLibraryRow>("DocumentType");
      customFilter.Rules.push(filterDocType);
    } else if (selectedTabValue === HeaderTabItems.Folders.value) {
      if (isNotNullAndUndefined(id)) {
        let filterParent: RAFCustomFilter = {};
        let filterValParent: string[] = [];

        filterValParent.push(id);
        filterParent.Operator = RAFCustomOperator.Equal;
        filterParent.Value = filterValParent;
        filterParent.Field = propertyOf<ContentLibraryRow>("ParentUID");
        //customFilter.Rules.push(filterParent);
      }
    }

    resolve(customFilter);
  });
};

export const getRelatedDocumentsFilter2 = (
  id: string,
  moduleName: string,
  selectedRAFTreeNode: RAFTreeNodeModel,
  tagRow?: LookUpRow,
  partnerPortal?: PartnerPortalRow,
  selectedTabValue?: string
) => {
  return new Promise<RAFCustomFilter>((resolve, reject) => {
    let selectedRAFTreeNodeId = isNotNullAndUndefined(selectedRAFTreeNode)
      ? selectedRAFTreeNode.id
      : null;
    let customFilter: RAFCustomFilter = {};
    customFilter.Condition = "and";
    customFilter.Rules = [];

    if (isNotNullAndUndefined(id)) {
      let filter1: RAFCustomFilter = {};
      let filterVal1: string[] = [];

      filterVal1.push(id);
      filter1.Operator = RAFCustomOperator.Equal;
      filter1.Value = filterVal1;
      filter1.Field = propertyOf<ContentLibraryRow>("ParentUID");
      customFilter.Rules.push(filter1);
    }

    if (isNotNullAndUndefined(moduleName)) {
      let filter2: RAFCustomFilter = {};
      let filterVal2: string[] = [];

      filterVal2.push(moduleName);
      filter2.Operator = RAFCustomOperator.Equal;
      filter2.Value = filterVal2;
      filter2.Field = propertyOf<ContentLibraryRow>("Entity");
      customFilter.Rules.push(filter2);
    }

    if (isNotNullAndUndefined(tagRow) && isNotNullAndUndefined(tagRow.Value)) {
      let filter4: RAFCustomFilter = {};
      let filterVal4: string[] = [];
      filterVal4.push(tagRow.Value);
      filter4.Operator = RAFCustomOperator.Equal;
      filter4.Value = filterVal4;
      filter4.Field = propertyOf<ContentLibraryRow>("TagsListJson");
      customFilter.Rules.push(filter4);
    }
    //else
    if (isNotNullAndUndefined(selectedRAFTreeNodeId)) {
      let filter3: RAFCustomFilter = {};
      let filterVal3: string[] = [];

      if (
        selectedRAFTreeNodeId === RAFSystemContentLibraryNode.RecordCategory.id
      ) {
        filter3.Operator = RAFCustomOperator.IsNotNull;
        filter3.Field = propertyOf<ContentLibraryRow>("RecordCategory");
        //customFilter.Rules.push(filter3);
      } else {
        filterVal3.push(selectedRAFTreeNodeId);
        filter3.Operator = RAFCustomOperator.Equal;
        filter3.Value = filterVal3;
        filter3.Field = propertyOf<ContentLibraryRow>("RecordCategory");
        customFilter.Rules.push(filter3);
      }
    }

    if (
      isNotNullAndUndefined(partnerPortal) &&
      isNotNullAndUndefined(partnerPortal.UID)
    ) {
      let filter5: RAFCustomFilter = {};
      let filterVal5: string[] = [];
      filterVal5.push(partnerPortal.UID);
      filter5.Operator = RAFCustomOperator.Equal;
      filter5.Value = filterVal5;
      (filter5.Field = propertyOf<ContentLibraryRow>("PortalUID")),
        customFilter.Rules.push(filter5);
    }

    resolve(customFilter);
  });
};

export const getRelatedDocumentsFilter1 = (
  id: string,
  moduleName: string,
  selectedRAFTreeNode: RAFTreeNodeModel,
  tagRow?: LookUpRow,
  partnerPortal?: PartnerPortalRow,
  selectedTabValue?: string
) => {
  return new Promise<RAFCustomFilter>((resolve, reject) => {
    let selectedRAFTreeNodeId = isNotNullAndUndefined(selectedRAFTreeNode)
      ? selectedRAFTreeNode.id
      : null;
    let customFilter: RAFCustomFilter = {};
    customFilter.Condition = "and";
    customFilter.Rules = [];

    if (isNotNullAndUndefined(id)) {
      let filter1: RAFCustomFilter = {};
      let filterVal1: string[] = [];

      filterVal1.push(id);
      filter1.Operator = RAFCustomOperator.Equal;
      filter1.Value = filterVal1;
      filter1.Field = propertyOf<ContentLibraryRow>("ParentUID");
      customFilter.Rules.push(filter1);
    }

    if (isNotNullAndUndefined(moduleName)) {
      let filter2: RAFCustomFilter = {};
      let filterVal2: string[] = [];

      filterVal2.push(moduleName);
      filter2.Operator = RAFCustomOperator.Equal;
      filter2.Value = filterVal2;
      filter2.Field = propertyOf<ContentLibraryRow>("Entity");
      customFilter.Rules.push(filter2);
    }

    if (selectedTabValue === HeaderTabItems.Category.value) {
      if (isNotNullAndUndefined(selectedRAFTreeNodeId)) {
        let filter3: RAFCustomFilter = {};
        let filterVal3: string[] = [];

        if (
          selectedRAFTreeNodeId ===
          RAFSystemContentLibraryNode.RecordCategory.id
        ) {
          filter3.Operator = RAFCustomOperator.IsNotNull;
          filter3.Field = propertyOf<ContentLibraryRow>("RecordCategory");
          customFilter.Rules.push(filter3);
        } else {
          filterVal3.push(selectedRAFTreeNodeId);
          filter3.Operator = RAFCustomOperator.Equal;
          filter3.Value = filterVal3;
          filter3.Field = propertyOf<ContentLibraryRow>("RecordCategory");
          customFilter.Rules.push(filter3);
        }
      }

      let filter3: RAFCustomFilter = {};
      let filterVal3: string[] = [];

      filterVal3.push(RAFDocumentType.Folder);
      filter3.Operator = RAFCustomOperator.NotEqual;
      filter3.Value = filterVal3;
      filter3.Field = propertyOf<ContentLibraryRow>("DocumentType");
      customFilter.Rules.push(filter3);
    }

    if (selectedTabValue === HeaderTabItems.Tags.value) {
      if (
        isNotNullAndUndefined(tagRow) &&
        isNotNullAndUndefined(tagRow.Value)
      ) {
        let filter4: RAFCustomFilter = {};
        let filterVal4: string[] = [];
        filterVal4.push(tagRow.Value);
        filter4.Operator = RAFCustomOperator.Equal;
        filter4.Value = filterVal4;
        filter4.Field = propertyOf<ContentLibraryRow>("TagsListJson");
        customFilter.Rules.push(filter4);
      }
    }
    //else

    if (
      isNotNullAndUndefined(partnerPortal) &&
      isNotNullAndUndefined(partnerPortal.UID)
    ) {
      let filter5: RAFCustomFilter = {};
      let filterVal5: string[] = [];
      filterVal5.push(partnerPortal.UID);
      filter5.Operator = RAFCustomOperator.Equal;
      filter5.Value = filterVal5;
      (filter5.Field = propertyOf<ContentLibraryRow>("PortalUID")),
        customFilter.Rules.push(filter5);
    }

    resolve(customFilter);
  });
};

// export const getRelatedDocumentsFilter1 = (
//     id: string,
//     moduleName: string,
//     selectedRAFTreeNode: RAFTreeNodeModel,
//     allDocumentCategory: DocumentCategoryRow[]
// ) => {
//     return new Promise<RAFCustomFilter>((resolve, reject) => {
//         let selectedTreeNodeItem =
//             isNotNullAndUndefined(selectedRAFTreeNode) &&
//                 isNotNullAndUndefined(selectedRAFTreeNode.id) &&
//                 isNotNullAndUndefined(allDocumentCategory) &&
//                 allDocumentCategory.length > 0
//                 ? allDocumentCategory.find((x) => x.UID === selectedRAFTreeNode.id)
//                 : null;

//         let selectedRAFTreeNodeId = isNotNullAndUndefined(selectedRAFTreeNode)
//             ? selectedRAFTreeNode.id
//             : null;
//         let selectedRAFTreeNodeParentId = isNotNullAndUndefined(selectedRAFTreeNode)
//             ? selectedRAFTreeNode.parentID
//             : null;
//         let customFilter: RAFCustomFilter = {};
//         customFilter.Condition = "and";
//         customFilter.Rules = [];

//         let filter1: RAFCustomFilter = {};
//         let filterVal1: string[] = [];

//         let filter2: RAFCustomFilter = {};
//         let filterVal2: string[] = [];

//         let filter3: RAFCustomFilter = {};
//         let filterVal3: string[] = [];

//         // if (selectedRAFTreeNodeId === RAFSystemContentLibraryNode.AllDocuments.id) {
//         // } else
//         if (
//             selectedRAFTreeNodeId === RAFSystemContentLibraryNode.PartnerDocuments.id
//         ) {
//             filterVal1.push(msalInstance.currentTenantId);
//             filter1.Operator = RAFCustomOperator.NotEqual;
//             filter1.Value = filterVal1;
//             filter1.Field = propertyOf<ContentLibraryRow>("TenantUID");
//             customFilter.Rules.push(filter1);
//         } else if (
//             isNotNullAndUndefined(selectedTreeNodeItem) &&
//             selectedRAFTreeNodeParentId ===
//             RAFSystemContentLibraryNode.PartnerDocuments.id &&
//             selectedTreeNodeItem.Type === "SourceTenant"
//         ) {
//             filterVal1.push(selectedRAFTreeNodeId);
//             filter1.Operator = RAFCustomOperator.Equal;
//             filter1.Value = filterVal1;
//             filter1.Field = propertyOf<ContentLibraryRow>("TenantUID");
//             customFilter.Rules.push(filter1);
//         } else {
//             filterVal1.push(id);
//             filter1.Operator = RAFCustomOperator.Equal;
//             filter1.Value = filterVal1;
//             filter1.Field = propertyOf<ContentLibraryRow>("ParentUID");
//             customFilter.Rules.push(filter1);

//             filterVal2.push(moduleName);
//             filter2.Operator = RAFCustomOperator.Equal;
//             filter2.Value = filterVal2;
//             filter2.Field = propertyOf<ContentLibraryRow>("Entity");
//             customFilter.Rules.push(filter2);

//             if (
//                 selectedRAFTreeNodeId ===
//                 RAFSystemContentLibraryNode.UncategorizedDocuments.id
//             ) {
//                 filter3.Operator = RAFCustomOperator.IsNull;
//                 filter3.Field = propertyOf<ContentLibraryRow>("RecordCategoryUID");
//                 customFilter.Rules.push(filter3);
//             } else if (
//                 selectedRAFTreeNodeId === RAFSystemContentLibraryNode.RecordCategory.id
//             ) {
//                 filter3.Operator = RAFCustomOperator.IsNotNull;
//                 filter3.Field = propertyOf<ContentLibraryRow>("RecordCategoryUID");
//                 customFilter.Rules.push(filter3);
//             } else if (
//                 isNotNullAndUndefined(selectedTreeNodeItem) &&
//                 selectedTreeNodeItem.Type === "RecordCategory"
//                 // && selectedRAFTreeNodeParentId === RAFSystemContentLibraryNode.RecordCategory.id
//             ) {
//                 filterVal3.push(selectedRAFTreeNodeId);
//                 filter3.Operator = RAFCustomOperator.Equal;
//                 filter3.Value = filterVal3;
//                 filter3.Field = propertyOf<ContentLibraryRow>("RecordCategoryUID");
//                 customFilter.Rules.push(filter3);
//             }
//         }
//         resolve(customFilter);
//     });
// };

export const getDocumentByName = (name) => {
  return new Promise<ContentLibraryRow>((resolve) => {
    if (isNotNullAndUndefined(name)) {
      let url = "ContentLibrary/Retrieve";
      let documentRetrieveRequest: DocumentRetrieveRequest =
        new DocumentRetrieveRequest();
      documentRetrieveRequest.ObjectName = name;
      documentRetrieveRequest.Entity = RAFModuleName.ContentLibrary;
      return repositoryActions
        .postDataAndGetResponse(
          url,
          documentRetrieveRequest,
          null,
          ContentType.applicationJson
        )
        .then((response) => {
          if (
            isNotNullAndUndefined(response) &&
            isNotNullAndUndefined(response.data) &&
            isNotNullAndUndefined(response.data.Entity)
          ) {
            resolve(response.data.Entity);
          } else {
            resolve(null);
          }
        })
        .catch((error) => error);
    } else {
      resolve(null);
    }
  });
};
