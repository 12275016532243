import axios from "axios";
import Compressor from "compressorjs";
import { msalInstance } from "../../../..";
import {
  RAFCustomFilter,
  RAFCustomOperator,
} from "../../../../RAFComponents/RAFViewPanels/RAFFilterColumn/RAFCustomFilter";
import { showSuccessToast, showWarningToast } from "../../../../RAFComponents/Utility/RAFToastComponent";
import {
  DeleteRecord,
  RAFActionMessage,
  hideProgress,
  showProgress,
} from "../../../../RAFComponents/helpers/AppHelper";
import { RAFSort } from "../../../../RAFComponents/helpers/types";
import {
  IsNullOrWhiteSpace,
  getPureSubDomainOrHint,
  isEmptyArray,
  isNotEmptyArray,
  isNotNullAndUndefined,
  isNullOrUndefined,
  propertyOf,
} from "../../../../RAFComponents/helpers/utils";
import {
  ListServiceRequest,
  RelatedListRequest,
  RetrieveRequest,
} from "../../../../RAFComponents/models/Common/ListRequest";
import { RAFTreeNodeModel } from "../../../../RAFComponents/models/Common/RAFTreeNodeModel";
import { LookUpRow } from "../../../../RAFComponents/models/CompositeTypes/LookUpRow";
import * as repositoryActions from "../../../../RAFComponents/store/actions/repositoryActions";
import { getTenantSourcePartners } from "../../../../RAFMaster/helpers/RMutils";
import {
  Constants,
  ContentType,
  RAFDocumentType,
  RAFHeaderNames,
} from "../../../../constants/Common/Constants";
import {
  RAFEntityName,
  RAFModuleName,
} from "../../../../constants/Common/EntityConstants";
import { PartnerPortalRow } from "../../../Common/Workspace/PartnerPortal/PartnerPortalRow";
import { getAllRecordCategory } from "../../RecordCategory/RecordCategoryHelper";
import { RecordCategoryRow } from "../../RecordCategory/RecordCategoryRow";
import { ContentLibraryRow } from "./ContentLibraryRow";

export enum ContentLibraryCurrentStatus {
  Draft = "Draft",
  Published = "Published",
}

export class DocumentCategoryRow {
  UID: string;
  DisplayText: string;
  CategoryName: string;
  ParentUID?: string;
  ICon?: string;
  BusinessProcess?: string;
  BusinessProcessUID?: string;
  EnableProcess?: boolean;
  Type: string; // 'SourceTenant' | 'RecordCategory';
}

export class DocumentRetrieveRequest extends RetrieveRequest {
  Entity?: string;
  ParentUID?: string;
  ObjectName?: string;
  VersionUID?: string;
  RelatedUID?: string;
}

export const getDocumentModuleNameByRelatedEntity = (relatedEntity: string) => {
  let moduleName = relatedEntity; //RAFModuleName
  if (relatedEntity) {
    switch (relatedEntity) {
      case RAFEntityName.Account:
        moduleName = RAFModuleName.Account;
        break;
      case RAFEntityName.Contact:
        moduleName = RAFModuleName.Contact;
        break;
      case RAFEntityName.Deal:
        moduleName = RAFModuleName.Deal;
        break;
      case RAFEntityName.Ticket:
        moduleName = RAFModuleName.Ticket;
        break;
      case RAFEntityName.ContentLibrary:
        moduleName = RAFModuleName.ContentLibrary;
        break;
      case RAFEntityName.Email:
        moduleName = RAFModuleName.Email;
        break;
      case RAFEntityName.Task:
        moduleName = RAFModuleName.Task;
        break;
      case RAFEntityName.BusinessProcess:
        moduleName = RAFModuleName.BusinessProcess;
        break;
      default:
        moduleName = relatedEntity;
        break;
    }
  }
  return moduleName;
};

export function getFileSize(size: number) {
  if (isNotNullAndUndefined(size)) {
    let fileSize;
    let size_kb = size / 1024;
    let size_mb = size_kb / 1049;
    let size_gb = size_mb / 1024;
    if (size < 1024) {
      fileSize = size + " Bytes";
    }
    if (size > 1024) {
      fileSize = Math.floor(size_kb) + " KB";
    }
    if (size_kb > 1049) {
      fileSize = Math.floor(size_mb) + " MB";
    }
    if (size_mb > 1000) {
      fileSize = Math.floor(size_gb) + " GB";
    }
    return fileSize;
  } else {
    return "NA";
  }
}

export function retrieveDocumentByID(uid: string) {
  return new Promise<ContentLibraryRow>((resolve) => {
    if (isNotNullAndUndefined(uid)) {
      let documentRetrieveRequest: DocumentRetrieveRequest =
        new DocumentRetrieveRequest();
      documentRetrieveRequest.EntityId = uid as any;
      return repositoryActions
        .postDataAndGetResponse(
          "ContentLibrary/Retrieve",
          documentRetrieveRequest,
          null,
          ContentType.applicationJson
        )
        .then((response) => {
          if (
            isNotNullAndUndefined(response) &&
            isNotNullAndUndefined(response.data) &&
            isNotNullAndUndefined(response.data.Entity)
          ) {
            resolve(response.data.Entity);
          } else {
            resolve(null);
          }
        })
        .catch((error) => error);
    } else {
      resolve(null);
    }
  });
}

export function retrievePageVersionDocByID(uid: string, versionUID: string) {
  return new Promise<ContentLibraryRow>((resolve) => {
    let documentRetrieveRequest: DocumentRetrieveRequest =
      new DocumentRetrieveRequest();
    documentRetrieveRequest.EntityId = uid as any;
    documentRetrieveRequest["VersionUID"] = versionUID as any;
    return repositoryActions
      .postDataAndGetResponse(
        "ContentLibrary/Retrieve",
        documentRetrieveRequest,
        null,
        ContentType.applicationJson
      )
      .then((response) => {
        if (
          isNotNullAndUndefined(response) &&
          isNotNullAndUndefined(response.data) &&
          isNotNullAndUndefined(response.data.Entity)
        ) {
          resolve(response.data.Entity);
        } else {
          resolve(null);
        }
      })
      .catch((error) => error);
  });
}

export function retrieveDocumentByFileName(
  fileName: string,
  entity: string,
  relatedUID?: string,
  parentUID?: string,
  documentType?: string
) {
  return new Promise<ContentLibraryRow>((resolve) => {
    let documentRetrieveRequest: DocumentRetrieveRequest =
      new DocumentRetrieveRequest();

    documentRetrieveRequest.ObjectName = fileName;
    documentRetrieveRequest.Entity = entity;
    if (isNotNullAndUndefined(relatedUID))
      documentRetrieveRequest.RelatedUID = relatedUID;
    if (isNotNullAndUndefined(parentUID))
      documentRetrieveRequest.ParentUID = parentUID;
    if (isNotNullAndUndefined(documentType))
      documentRetrieveRequest["DocumentType"] = documentType;

    repositoryActions
      .postDataAndGetResponse(
        "ContentLibrary/Retrieve",
        documentRetrieveRequest,
        null,
        ContentType.applicationJson
      )
      .then((response) => {
        if (
          isNotNullAndUndefined(response) &&
          isNotNullAndUndefined(response.data) &&
          isNotNullAndUndefined(response.data.Entity)
        ) {
          resolve(response.data.Entity);
        } else {
          resolve(null);
        }
      })
      .catch((error) => error);
  });
}

export function getAllDocumentByParentUD(parentID: string, entity: string) {
  return new Promise<ContentLibraryRow[]>((resolve) => {
    if (isNotNullAndUndefined(parentID) && isNotNullAndUndefined(entity)) {
      let relatedFilter: RAFCustomFilter = {};
      relatedFilter.Condition = "and";
      relatedFilter.Rules = [];

      let filter2: RAFCustomFilter = {};
      let filterVal2: string[] = [];
      filterVal2.push(entity);
      filter2.Operator = RAFCustomOperator.Equal;
      filter2.Value = filterVal2;
      filter2.Field = propertyOf<ContentLibraryRow>("Entity");
      relatedFilter.Rules.push(filter2);

      let listServiceRequest = new ListServiceRequest();
      listServiceRequest.CustomFilter = relatedFilter;
      listServiceRequest.Skip = 0;
      listServiceRequest.Take = 0;
      listServiceRequest.ViewName = "all_status";
      listServiceRequest["ParentUID"] = parentID;

      repositoryActions
        .postDataAndGetResponse(
          "ContentLibrary/ListAll",
          listServiceRequest,
          null,
          ContentType.applicationJson
        )
        .then((response) => {
          if (
            isNotNullAndUndefined(response) &&
            isNotNullAndUndefined(response.data) &&
            isNotNullAndUndefined(response.data.ResultTable)
          ) {
            resolve(response.data.ResultTable);
          } else {
            resolve(null);
          }
        })
        .catch((error) => resolve(null));
    } else {
      resolve(null);
    }
  });
}

export function getDocumentByRecordCategoryUID(recordCategoryUID: string) {
  return new Promise<ContentLibraryRow[]>((resolve) => {
    if (!IsNullOrWhiteSpace(recordCategoryUID)) {
      const listRequest: ListServiceRequest = new ListServiceRequest();
      //let relatedFilter: RAFCustomFilter = {};
      //relatedFilter.Condition = "and";
      //relatedFilter.Rules = [];

      listRequest.Skip = 0;
      listRequest.Take = 0;

      let customFilter: RAFCustomFilter = {};
      customFilter.Condition = "and";
      customFilter.Rules = [];

      let filterRecordCategoryUID: RAFCustomFilter = {};
      filterRecordCategoryUID.Operator = RAFCustomOperator.Equal;
      filterRecordCategoryUID.Field =
        propertyOf<ContentLibraryRow>("RecordCategory");

      let filterValRecordCategoryUID: string[] = [];
      filterValRecordCategoryUID.push(recordCategoryUID);
      filterRecordCategoryUID.Value = filterValRecordCategoryUID;
      customFilter.Rules.push(filterRecordCategoryUID);

      listRequest.CustomFilter = customFilter;

      repositoryActions
        .postDataAndGetResponse(
          "ContentLibrary/List",
          listRequest,
          null,
          ContentType.applicationJson
        )
        .then((response) => {
          if (
            isNotNullAndUndefined(response) &&
            isNotNullAndUndefined(response.data) &&
            isNotNullAndUndefined(response.data.ResultTable)
          ) {
            resolve(response.data.ResultTable);
          } else {
            resolve(null);
          }
        })
        .catch((error) => error);
    } else {
      resolve(null);
    }
  });
}

export class RAFSystemContentLibraryNode {
  //static SharedByMe = { text: "Shared by me", id: "Shared by me" };
  //static SharedWithMe = { text: "Shared with me", id: "Shared with me" };
  //static AllDocuments = { text: "All Documents", id: "AllDocuments" };
  //static PartnerDocuments = { text: "Partner Documents", id: "PartnerDocuments", };
  //static OrganizationDocuments = { text: "Organization Documents", id: "OrganizationDocuments", };
  //static UncategorizedDocuments = { text: "Uncategorized Documents", id: "UncategorizedDocuments" };
  static RecordCategory = { text: "Add Category", id: "RecordCategory" };
}

export const RAFSystemContentLibraryNodes: RAFTreeNodeModel[] = [
  // {
  //     id: RAFSystemContentLibraryNode.AllDocuments.id,
  //     text: RAFSystemContentLibraryNode.AllDocuments.text,
  //     parentID: null,
  //     hasChildren: false
  // },
  // {
  //     id: RAFSystemContentLibraryNode.PartnerDocuments.id,
  //     text: RAFSystemContentLibraryNode.PartnerDocuments.text,
  //     parentID: null,
  //     hasChildren: true
  // },
  // {
  //     id: RAFSystemContentLibraryNode.OrganizationDocuments.id,
  //     text: RAFSystemContentLibraryNode.OrganizationDocuments.text,
  //     parentID: null,
  //     hasChildren: true
  // },
  // {
  //     id: RAFSystemContentLibraryNode.UncategorizedDocuments.id,
  //     text: RAFSystemContentLibraryNode.UncategorizedDocuments.text,
  //     parentID: RAFSystemContentLibraryNode.OrganizationDocuments.id,
  //     hasChildren: false
  // },
  // {
  //   id: RAFSystemContentLibraryNode.RecordCategory.id,
  //   text: RAFSystemContentLibraryNode.RecordCategory.text,
  //   parentID: null, // RAFSystemContentLibraryNode.OrganizationDocuments.id,
  //   hasChildren: true,
  // },
];

export function checkDocumentItemExist(
  existingDocument: ContentLibraryRow,
  currentDocument: ContentLibraryRow
) {
  let isExist = true;
  if (
    isNotNullAndUndefined(existingDocument) &&
    isNotNullAndUndefined(existingDocument.UID) &&
    existingDocument.UID === currentDocument.UID
  ) {
    isExist = false;
  } else if (
    isNullOrUndefined(existingDocument) ||
    (isNotNullAndUndefined(existingDocument) &&
      IsNullOrWhiteSpace(existingDocument.UID))
  ) {
    isExist = false;
  } else {
    let recordCategory = isNotEmptyArray(existingDocument.RecordCategory)
      ? existingDocument.RecordCategory
      : null;

    let existingDocumentRecordCategory = isNotEmptyArray(
      existingDocument.RecordCategory
    )
      ? existingDocument.RecordCategory.map((x) => x.UID)
      : null;
    let currentDocumentRecordCategory = isNotEmptyArray(
      currentDocument.RecordCategory
    )
      ? currentDocument.RecordCategory.map((x) => x.UID)
      : null;
    if (
      isNotEmptyArray(existingDocumentRecordCategory) &&
      isNotEmptyArray(currentDocumentRecordCategory) &&
      existingDocumentRecordCategory === currentDocumentRecordCategory
    ) {
      showWarningToast(
        currentDocument.DisplayName +
        " matches an existing item in this folder. Please choose a different name."
      );
    } else if (
      isEmptyArray(currentDocument.RecordCategory) &&
      isEmptyArray(existingDocument.RecordCategory)
    ) {
      showWarningToast(
        currentDocument.DisplayName +
        " matches an existing item in General Category. Please choose a different name."
      );
    } else if (isNotNullAndUndefined(recordCategory)) {
      showWarningToast(
        currentDocument.DisplayName +
        " matches an existing item in " +
        recordCategory +
        " Category. Please choose a different name."
      );
    } else {
      showWarningToast(
        currentDocument.DisplayName +
        " matches an existing item in General Category. Please choose a different name."
      );
    }
  }
  return isExist;
}

export function getAllDocumentCategories(headerFilterText?: string) {
  return new Promise<DocumentCategoryRow[]>((resolve, reject) => {
    getAllRecordCategory(headerFilterText).then((allRecordCategory) => {
      getTenantSourcePartners().then((tenantSourceItems) => {
        let allDocumentCategories: DocumentCategoryRow[] = [];

        if (
          isNotNullAndUndefined(allRecordCategory) &&
          allRecordCategory.length > 0
        ) {
          allRecordCategory.forEach((item) => {
            allDocumentCategories.push({
              UID: item.UID,
              DisplayText: isNotNullAndUndefined(item.RecordIdentifier)
                ? item.RecordIdentifier + " - " + item.Title
                : item.Title,
              CategoryName: item.Title,
              ICon: item.IconName,
              ParentUID: item.ParentUID,
              BusinessProcess: item.BusinessProcess,
              BusinessProcessUID: item.BusinessProcessUID,
              EnableProcess: item.EnableProcess,
              Type: RAFSystemContentLibraryNode.RecordCategory.id,
            });
          });
        }

        // if (isNotNullAndUndefined(tenantSourceItems) && tenantSourceItems.length > 0) {
        //     tenantSourceItems.forEach((item) => {
        //         allDocumentCategories.push({ UID: item.UID as any, DisplayText: item.Value, CategoryName: item.Value, Type: 'SourceTenant' });
        //     });
        // }
        resolve(allDocumentCategories);
      });
    });
  });
}

export const publishDocumentByID = (entityId: string, returnNull?: boolean) => {
  return new Promise<boolean>((resolve, reject) => {
    if (returnNull !== true) {
      repositoryActions
        .postDataAndGetResponse(
          "ContentLibrary/Publish",
          { EntityId: entityId },
          null,
          ContentType.applicationJson
        )
        .then((response) => {
          if (
            isNotNullAndUndefined(response) &&
            isNotNullAndUndefined(response.data) &&
            isNotNullAndUndefined(response.data.EntityId)
          ) {
            resolve(true);
          } else {
            resolve(false);
          }
        });
    } else {
      resolve(false);
    }
  });
};

export const getBlobDocumentFile = (uid: string, displayName: string) => {
  return new Promise<File>((resolve, reject) => {
    let url = `${Constants.baseAPIUrl}ContentLibrary/Download`;
    const objData = { EntityId: uid };
    axios({
      url: url,
      method: "POST",
      responseType: "blob",
      headers: {
        [RAFHeaderNames.Authorization]: `Bearer ${msalInstance.accessToken}`,
        [RAFHeaderNames.BusinessUnitUID]: msalInstance.currentBusinessUnitId,
        [RAFHeaderNames.Domain]: getPureSubDomainOrHint(),
      },
      data: objData,
    })
      .then((response) => {
        if (
          response.status === 200 &&
          isNotNullAndUndefined(response) &&
          isNotNullAndUndefined(response.data)
        ) {
          const arrayBuffer = response.data;
          const blob = new Blob([arrayBuffer], {
            type: response.headers["content-type"],
          });
          let newFile = new File([blob], displayName, { type: blob.type });
          resolve(newFile);
        } else {
          resolve(null);
        }
      })
      .catch((error) => {
        resolve(null);
      });
  });
};

export const downloadFile = (uid: string, displayName: string) => {
  return new Promise<boolean>((resolve, reject) => {
    let url = `${Constants.baseAPIUrl}ContentLibrary/Download`;
    const objData = { EntityId: uid };
    axios({
      url: url,
      method: "POST",
      responseType: "blob",
      headers: {
        [RAFHeaderNames.Authorization]: `Bearer ${msalInstance.accessToken}`,
        [RAFHeaderNames.BusinessUnitUID]: msalInstance.currentBusinessUnitId,
        [RAFHeaderNames.Domain]: getPureSubDomainOrHint(),
      },
      data: objData,
    })
      .then((response) => {
        if (
          response.status === 200 &&
          isNotNullAndUndefined(response) &&
          isNotNullAndUndefined(response.data)
        ) {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;

          let extension;

          if (isNotNullAndUndefined(displayName)) {
            let x = displayName.split(".").pop();
            extension = isNotNullAndUndefined(x) ? x : null;
          }

          // if (isNotNullAndUndefined(fileName)) {
          //   let y = fileName.split(".").pop();
          //   if (isNotNullAndUndefined(y) && extension !== y) {
          //     extension = y;
          //   } else {
          //     extension = null;
          //   }
          // }

          let downloadDocName = "Notset.txt";

          if (isNotNullAndUndefined(extension)) {
            if (isNotNullAndUndefined(displayName)) {
              downloadDocName = displayName + "." + extension;
            }
          } else if (isNotNullAndUndefined(displayName)) {
            downloadDocName = displayName;
          }

          link.setAttribute("download", downloadDocName);
          document.body.appendChild(link);
          link.click();

          resolve(true);
        } else {
          resolve(null);
        }
      })
      .catch((error) => {
        resolve(null);
      });
  });
};

export const downloadDocumentFile = (
  documentRow: ContentLibraryRow,
  isVersion?: boolean,
  progressDiv?: any
) => {
  return new Promise<boolean>((resolve, reject) => {
    if (
      isNotNullAndUndefined(documentRow) &&
      isNotNullAndUndefined(documentRow.UID) &&
      (documentRow.DocumentType === RAFDocumentType.File || isVersion === true)
    ) {
      progressDiv = isNotNullAndUndefined(progressDiv)
        ? progressDiv
        : showProgress("body");
      let objData; // { EntityId?: string }; //Module?: string;
      if (isVersion === true) {
        objData = { VersionUID: documentRow.UID };
      } else {
        objData = { EntityId: documentRow.UID };
      }

      let url = `${Constants.baseAPIUrl}ContentLibrary/Download`;
      axios({
        url: url,
        method: "POST",
        responseType: "blob",
        headers: {
          [RAFHeaderNames.Authorization]: `Bearer ${msalInstance.accessToken}`,
          [RAFHeaderNames.BusinessUnitUID]: msalInstance.currentBusinessUnitId,
          [RAFHeaderNames.Domain]: getPureSubDomainOrHint(),
        },
        data: objData,
      }).then((response) => {
        if (isNotNullAndUndefined(response)) {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;

          let extension;
          let displayName = documentRow.DisplayName;
          let fileName = documentRow.FileName;

          if (isNotNullAndUndefined(displayName)) {
            let x = displayName.split(".").pop();
            extension = isNotNullAndUndefined(x) ? x : null;
          }

          if (isNotNullAndUndefined(fileName)) {
            let y = fileName.split(".").pop();
            if (isNotNullAndUndefined(y) && extension !== y) {
              extension = y;
            } else {
              extension = null;
            }
          }

          let downloadDocName = "Notset.txt";

          if (isNotNullAndUndefined(extension)) {
            if (isNotNullAndUndefined(displayName)) {
              downloadDocName = displayName + "." + extension;
            } else if (isNotNullAndUndefined(fileName)) {
              downloadDocName = fileName + "." + extension;
            }
          } else if (isNotNullAndUndefined(displayName)) {
            downloadDocName = displayName;
          } else if (isNotNullAndUndefined(fileName)) {
            downloadDocName = fileName;
          }

          link.setAttribute("download", downloadDocName);
          document.body.appendChild(link);
          link.click();
          hideProgress(progressDiv);
          resolve(true);
        } else {
          hideProgress(progressDiv);
          resolve(false);
        }
      });
    } else {
      showWarningToast("Sorry something went wrong !");
      resolve(false);
    }
  });
};

export const viewUploadedDocumentFile = (
  documentRow: ContentLibraryRow,
  isVersion?: boolean,
) => {
  return new Promise<boolean>((resolve, reject) => {
    if (
      isNotNullAndUndefined(documentRow) &&
      isNotNullAndUndefined(documentRow.UID) &&
      (documentRow.DocumentType === RAFDocumentType.File || isVersion === true)
    ) {
      let progressDiv = showProgress(`#Preview_view_btn_${documentRow.UID}`);

      let objData; // { EntityId?: string }; //Module?: string;
      if (isVersion === true) {
        objData = { VersionUID: documentRow.UID };
      } else {
        objData = { EntityId: documentRow.UID };
      }

      let url = `${Constants.baseAPIUrl}ContentLibrary/Download`;
      axios({
        url: url,
        method: "POST",
        responseType: "blob",
        headers: {
          [RAFHeaderNames.Authorization]: `Bearer ${msalInstance.accessToken}`,
          [RAFHeaderNames.BusinessUnitUID]: msalInstance.currentBusinessUnitId,
          [RAFHeaderNames.Domain]: getPureSubDomainOrHint(),
        },
        data: objData,
      }).then((response) => {
        hideProgress(progressDiv);
        if (isNotNullAndUndefined(response)) {
          const blob = new Blob([response.data], { type: documentRow.MimeType });
          const url = window.URL.createObjectURL(blob);
          // Attempt to open the URL
          const newWindow = window.open(url, '_blank');

          // If a pop-up blocker prevents opening the new window, log an error or notify the user
          if (!newWindow) {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;

            let extension;
            let displayName = documentRow.DisplayName;
            let fileName = documentRow.FileName;

            if (isNotNullAndUndefined(displayName)) {
              let x = displayName.split(".").pop();
              extension = isNotNullAndUndefined(x) ? x : null;
            }

            if (isNotNullAndUndefined(fileName)) {
              let y = fileName.split(".").pop();
              if (isNotNullAndUndefined(y) && extension !== y) {
                extension = y;
              } else {
                extension = null;
              }
            }

            let downloadDocName = "Notset.txt";

            if (isNotNullAndUndefined(extension)) {
              if (isNotNullAndUndefined(displayName)) {
                downloadDocName = displayName + "." + extension;
              } else if (isNotNullAndUndefined(fileName)) {
                downloadDocName = fileName + "." + extension;
              }
            } else if (isNotNullAndUndefined(displayName)) {
              downloadDocName = displayName;
            } else if (isNotNullAndUndefined(fileName)) {
              downloadDocName = fileName;
            }

            link.setAttribute("download", downloadDocName);
            document.body.appendChild(link);
            link.click();
          } else {
            // Ensure the new window is in focus, this line might be redundant but can be helpful
            newWindow.focus();
          }
          resolve(true);
        } else {
          resolve(false);
        }
      });
    } else {
      showWarningToast("Sorry something went wrong !");
      resolve(false);
    }
  });
};

export const deleteDocumentItem = (documentRow: ContentLibraryRow) => {
  return new Promise<Boolean>(async (resolve, reject) => {
    if (
      isNotNullAndUndefined(documentRow) &&
      isNotNullAndUndefined(documentRow.UID)
    ) {
      if (documentRow.DocumentType === RAFDocumentType.Folder) {
        const childItems = await getAllDocumentByParentUD(
          documentRow.UID,
          documentRow.Entity
        );
        if (isNotEmptyArray(childItems)) {
          showWarningToast(
            "Please delete all the related documents before deleting this folder."
          );
          resolve(false);
        } else {
          let isDeleted = await DeleteRecord(
            documentRow.UID,
            RAFEntityName.ContentLibrary
          );
          if (isDeleted === true) {
            showSuccessToast(RAFActionMessage.RecordDeleted);
            resolve(isDeleted);
          } else {
            showWarningToast(RAFActionMessage.RecordNotDeleted);
            resolve(isDeleted);
          }
        }
      } else {
        let isDeleted = await DeleteRecord(
          documentRow.UID,
          RAFEntityName.ContentLibrary
        );
        if (isDeleted === true) {
          showSuccessToast(RAFActionMessage.RecordDeleted);
          resolve(isDeleted);
        } else {
          showWarningToast(RAFActionMessage.RecordNotDeleted);
          resolve(isDeleted);
        }
      }
    }
  });
};

export const getRelatedDocumentsByRelatedObject = (
  relatedToUID: string,
  secondaryRelatedToUID: string,
  relatedToType: string
) => {
  return new Promise<ContentLibraryRow[]>((resolve) => {
    if (isNotNullAndUndefined(relatedToUID)) {
      let url = `ContentLibrary/RelatedList`;
      let relatedFilter: RAFCustomFilter = {};
      relatedFilter.Condition = "and";
      relatedFilter.Rules = [];

      let filter1: RAFCustomFilter = {};
      let filterVal1: string[] = [];
      filterVal1.push(relatedToUID);
      filter1.Operator = RAFCustomOperator.Equal;
      filter1.Value = filterVal1;
      filter1.Field = propertyOf<ContentLibraryRow>("RelatedToUID");
      relatedFilter.Rules.push(filter1);

      if (isNotNullAndUndefined(secondaryRelatedToUID)) {
        let filter2: RAFCustomFilter = {};
        let filterVal2: string[] = [];
        filterVal2.push(secondaryRelatedToUID);
        filter2.Operator = RAFCustomOperator.Equal;
        filter2.Value = filterVal2;
        filter2.Field = propertyOf<ContentLibraryRow>("SecondaryRelatedToUID");
        relatedFilter.Rules.push(filter2);
      }

      let relatedServiceRequest = new RelatedListRequest();
      relatedServiceRequest.RelatedField = "RelatedToUID";
      relatedServiceRequest.RelatedFieldValue = relatedToUID;

      if (isNotNullAndUndefined(secondaryRelatedToUID)) {
        relatedServiceRequest.SecondaryRelatedField = "SecondaryRelatedToUID";
        relatedServiceRequest.SecondaryRelatedFieldValue =
          secondaryRelatedToUID;
      }

      if (isNotNullAndUndefined(relatedToType)) {
        relatedServiceRequest["RelatedToType"] = relatedToType;
      }

      return repositoryActions
        .postDataAndGetResponse(
          url,
          relatedServiceRequest,
          null,
          ContentType.applicationJson,
          false
        )
        .then((response) => {
          const contentLibraryList: ContentLibraryRow[] =
            isNotNullAndUndefined(response) &&
              isNotNullAndUndefined(response.data) &&
              isNotNullAndUndefined(response.data.Entities)
              ? response.data.Entities
              : null;
          if (
            isNotNullAndUndefined(contentLibraryList) &&
            contentLibraryList.length > 0
          ) {
            resolve(contentLibraryList);
          } else {
            resolve(null);
          }
        })
        .catch((error) => error);
    } else {
      resolve(null);
    }
  });
};

export function getSelectedRecordCategory(
  selectedFolder: ContentLibraryRow,
  selectedRecordTypeValue: RecordCategoryRow
) {
  let selectedRecordCategory: LookUpRow = null;
  if (
    isNotNullAndUndefined(selectedRecordTypeValue) &&
    isNotNullAndUndefined(selectedRecordTypeValue.UID) &&
    isNotNullAndUndefined(selectedRecordTypeValue.Title)
  ) {
    selectedRecordCategory = {
      UID: selectedRecordTypeValue.UID,
      Value: selectedRecordTypeValue.Title,
    };
    return selectedRecordCategory;
  } else if (
    isNotNullAndUndefined(selectedFolder) &&
    isNotEmptyArray(selectedFolder.RecordCategory)
  ) {
    selectedRecordCategory = {
      UID: selectedFolder.RecordCategory[0].UID,
      Value: selectedFolder.RecordCategory[0].Value,
    };
    return selectedRecordCategory;
  } else {
    return null;
  }
}

export const getAllParentFolderItems = (
  id: string,
  relatedEntity: string,
  partnerPortal?: PartnerPortalRow
) => {
  return new Promise<ContentLibraryRow[]>((resolve) => {
    let customFilter: RAFCustomFilter = {};
    customFilter.Condition = "and";
    customFilter.Rules = [];

    if (isNotNullAndUndefined(id)) {
      let filter1: RAFCustomFilter = {};
      let filterVal1: string[] = [];

      filterVal1.push(id);
      filter1.Operator = RAFCustomOperator.Equal;
      filter1.Value = filterVal1;
      filter1.Field = propertyOf<ContentLibraryRow>("ParentUID");
      customFilter.Rules.push(filter1);
    }

    if (isNotNullAndUndefined(relatedEntity)) {
      let filter2: RAFCustomFilter = {};
      let filterVal2: string[] = [];

      filterVal2.push(relatedEntity);
      filter2.Operator = RAFCustomOperator.Equal;
      filter2.Value = filterVal2;
      filter2.Field = propertyOf<ContentLibraryRow>("Entity");
      customFilter.Rules.push(filter2);
    }

    if (
      isNotNullAndUndefined(partnerPortal) &&
      isNotNullAndUndefined(partnerPortal.UID)
    ) {
      let filter5: RAFCustomFilter = {};
      let filterVal5: string[] = [];
      filterVal5.push(partnerPortal.UID);
      filter5.Operator = RAFCustomOperator.Equal;
      filter5.Value = filterVal5;
      (filter5.Field = propertyOf<ContentLibraryRow>("PortalUID")),
        customFilter.Rules.push(filter5);
    }

    let filter3: RAFCustomFilter = {};
    let filterVal3: string[] = [];

    filterVal3.push(RAFDocumentType.Folder);
    filter3.Operator = RAFCustomOperator.Equal;
    filter3.Value = filterVal3;
    filter3.Field = propertyOf<ContentLibraryRow>("DocumentType");
    customFilter.Rules.push(filter3);

    const listRequest: ListServiceRequest = new ListServiceRequest();
    listRequest.Take = 0;
    listRequest.Skip = 0;
    listRequest.CustomFilter = customFilter;

    let sort: RAFSort = {
      field: propertyOf<ContentLibraryRow>("DisplayName"),
      order: "ascending",
    };
    let sortQuery: string[] = [];
    if (isNotNullAndUndefined(sort) && isNotNullAndUndefined(sort.field)) {
      sortQuery.push(
        sort.order === "descending" ? `${sort.field} desc` : sort.field
      );
    }
    listRequest.Sort = sortQuery;

    return repositoryActions
      .postDataAndGetResponse(
        "ContentLibrary/ListAll",
        listRequest,
        null,
        ContentType.applicationJson,
        false
      )
      .then((response) => {
        if (
          isNotNullAndUndefined(response) &&
          isNotNullAndUndefined(response.data)
        ) {
          resolve(response.data.ResultTable);
        } else {
          resolve(null);
        }
      })
      .catch((error) => error);
  });
};

export class DocumentImageAttachmentRow {
  UID: string;
  Name: string;
  File: File;
}

export const getImageBlobFileAttachment = (
  relatedToUID: string,
  secondaryRelatedToUID: string,
  relatedToType: string,
  attachments?: LookUpRow[]
) => {
  return new Promise<DocumentImageAttachmentRow[]>(async (resolve) => {
    const documentsRow = isNotEmptyArray(attachments)
      ? attachments
      : await getRelatedDocumentsByRelatedObject(
        relatedToUID,
        secondaryRelatedToUID,
        relatedToType
      );
    if (isNotEmptyArray(documentsRow)) {
      const promises = documentsRow.map(async (x) => {
        const file = await getBlobDocumentFile(x.UID, x.DisplayName ?? x.Value);
        if (isNotNullAndUndefined(file)) {
          return { UID: x.UID, Name: x.DisplayName ?? x.Value, File: file };
        } else {
          return { UID: x.UID, Name: null, File: null };
        }
      });
      const results = await Promise.all(promises);
      const filteredResults = results.filter((x) =>
        isNotNullAndUndefined(x.File)
      );
      resolve(filteredResults);
    } else {
      resolve(null);
    }
  });
};

export const uploadImageAttachment = (
  fileAttchements: DocumentImageAttachmentRow[],
  initialFileAttchements: DocumentImageAttachmentRow[],

  entityId: string,
  objectName: string,
  moduleName: string,

  secondaryRelatedToUID?: string,
  secondaryRelatedTo?: string,
  secondaryRelatedToType?: string
) => {
  return new Promise<string[]>(async (resolve) => {
    const currentFileAttchement = fileAttchements ?? [];
    const initialFileAttchement = initialFileAttchements ?? [];

    const deletedItems = initialFileAttchement.filter(
      (initialItem) =>
        !currentFileAttchement.some(
          (currentItem) => currentItem.UID === initialItem.UID
        )
    ); //find deleted items from initial file attachment

    const deletedItemsUID = isNotEmptyArray(deletedItems)
      ? deletedItems.map((x) => x.UID).toString()
      : null;

    const newUploadedItems = currentFileAttchement.filter(
      (x) => !initialFileAttchement.some((y) => y.UID === x.UID)
    ); //find new uploaded items

    const uploadPromises = isNotEmptyArray(newUploadedItems)
      ? newUploadedItems.map((x) =>
        contentLibraryUpload(
          x.File,
          x.UID,
          entityId,
          objectName,
          moduleName,
          secondaryRelatedToUID,
          secondaryRelatedTo,
          secondaryRelatedToType
        )
      )
      : [];

    const deletePromises = await DeleteRecord(
      deletedItemsUID,
      RAFEntityName.ContentLibrary
    );
    const uploadResults = await Promise.all(uploadPromises);

    resolve(uploadResults);
  });
};

export const getCompressedImage = (fileData: File) => {
  return new Promise<File | Blob>((resolve) => {
    if (isNotNullAndUndefined(fileData) && fileData.type.startsWith("image/")) {
      new Compressor(fileData, {
        quality: 0.7, // Quality between 0 and 1
        maxWidth: 1920, // Maximum width
        maxHeight: 1920, // Maximum height
        checkOrientation: true, // Correct orientation
        //convertSize: 5000000, // Convert images smaller than 5MB to base64 string without compression
        // The compression process is asynchronous,
        // which means you have to access the `result` in the `success` hook function.
        success(result) {
          if (result instanceof Blob) {
            const fileName = fileData.name;
            const file = new File([result], fileName, { type: result.type });
            resolve(file);
          } else {
            resolve(result);
          }
        },
        error(err) {
          resolve(null);
          console.log(err.message);
        },
      });
    } else {
      resolve(null);
    }
  });
};

export const getResizedImage = (fileData: File) => {
  return new Promise<File | Blob>((resolve) => {
    if (isNotNullAndUndefined(fileData) && fileData.type.startsWith("image/")) {
      new Compressor(fileData, {
        quality: 0.6, // Quality between 0 and 1
        //retainExif: false, // Don't retain Exif data
        // width: 120,
        // height: 120,
        maxHeight: 150,
        maxWidth: 150,
        resize: "contain",

        // The compression process is asynchronous,
        // which means you have to access the `result` in the `success` hook function.
        success(result) {
          if (result instanceof Blob) {
            const fileName = fileData.name;
            const file = new File([result], fileName, { type: result.type });
            resolve(file);
          } else {
            resolve(result);
          }
        },
        error(err) {
          resolve(null);
          console.log(err.message);
        },
      });
    } else {
      resolve(null);
    }
  });
};

const contentLibraryUpload = (
  file: File,
  itemUID: string,
  entityId: string,
  objectName: string,
  moduleName: string,

  secondaryRelatedToUID: string,
  secondaryRelatedTo: string,
  secondaryRelatedToType: string
) => {
  return new Promise<string>(async (resolve) => {
    if (isNotNullAndUndefined(file)) {
      const formData = new FormData();
      if (isNotNullAndUndefined(itemUID)) {
        formData.append("UID", itemUID);
      }
      const compressedImg = await getCompressedImage(file);
      formData.append(
        "file",
        isNotNullAndUndefined(compressedImg) ? compressedImg : file
      );
      formData.append("RelatedToUID", entityId);
      formData.append("RelatedTo", objectName ?? "");
      formData.append("RelatedToType", moduleName);
      formData.append("Entity", moduleName);
      formData.append("CurrentStatus", ContentLibraryCurrentStatus.Published);

      if (
        isNotNullAndUndefined(secondaryRelatedToUID) &&
        isNotNullAndUndefined(secondaryRelatedTo) &&
        isNotNullAndUndefined(secondaryRelatedToType)
      ) {
        formData.append("SecondaryRelatedToUID", secondaryRelatedToUID);
        formData.append("SecondaryRelatedTo", secondaryRelatedTo);
        formData.append("SecondaryRelatedToType", secondaryRelatedToType);
      }

      return repositoryActions
        .postDataAndGetResponse(
          "ContentLibrary/Upload",
          formData,
          null,
          ContentType.applicationFormUrlEncoded
        )
        .then((response) => {
          if (
            isNotNullAndUndefined(response) &&
            isNotNullAndUndefined(response.data) &&
            isNotNullAndUndefined(response.data.documentUIDs) &&
            isNotNullAndUndefined(response.data.documentUIDs[0]) &&
            isNotNullAndUndefined(response.data.documentUIDs[0].EntityId)
          ) {
            resolve(response.data.documentUIDs[0].EntityId);
          } else {
            resolve(null);
          }
        })
        .catch((error) => resolve(null));
    } else {
      resolve(null);
    }
  });
};

export const fileUpload = (
  file?: File,
  itemUID?: string,
  // entityId?: string,
  // objectName?: string,
  moduleName?: string
) => {
  return new Promise<{ entityId: string; objectName: string; }>(
    async (resolve) => {
      if (isNotNullAndUndefined(file)) {
        const formData = new FormData();
        if (isNotNullAndUndefined(itemUID)) {
          formData.append("UID", itemUID);
        }
        const compressedImg = await getCompressedImage(file);
        formData.append(
          "file",
          isNotNullAndUndefined(compressedImg) ? compressedImg : file
        );
        formData.append("file", file);
        // formData.append("RelatedToUID", entityId);
        // formData.append("RelatedTo", objectName ?? "");
        formData.append("RelatedToType", moduleName);
        formData.append("Entity", moduleName);
        formData.append("CurrentStatus", ContentLibraryCurrentStatus.Published);

        return repositoryActions
          .postDataAndGetResponse(
            "ContentLibrary/Upload",
            formData,
            null,
            ContentType.applicationFormUrlEncoded
          )
          .then((response) => {
            if (
              isNotNullAndUndefined(response) &&
              isNotNullAndUndefined(response.data) &&
              isNotNullAndUndefined(response.data.documentUIDs) &&
              isNotNullAndUndefined(response.data.documentUIDs[0]) &&
              isNotNullAndUndefined(response.data.documentUIDs[0].EntityId)
            ) {
              resolve({
                entityId: response.data.documentUIDs[0].EntityId,
                objectName: response.data.documentUIDs[0].ObjectName,
              });
            } else {
              resolve(null);
            }
          })
          .catch((error) => resolve(null));
      } else {
        resolve(null);
      }
    }
  );
};

export function DownloadAttachment(fileName: string, id: string) {
  let progressDiv = showProgress("body");
  let objData = { EntityId: id };

  let url = `${Constants.baseAPIUrl}ContentLibrary/Download`;
  axios({
    url: url,
    method: "POST",
    responseType: "blob",
    headers: {
      [RAFHeaderNames.Authorization]: `Bearer ${msalInstance.accessToken}`,
      [RAFHeaderNames.BusinessUnitUID]: msalInstance.currentBusinessUnitId,
      [RAFHeaderNames.Domain]: getPureSubDomainOrHint(),
    },
    data: objData,
  }).then((response) => {
    if (isNotNullAndUndefined(response)) {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;

      let extension;
      let displayName = fileName;

      if (isNotNullAndUndefined(displayName)) {
        let x = displayName.split(".").pop();
        extension = isNotNullAndUndefined(x) ? x : null;
      }

      if (isNotNullAndUndefined(fileName)) {
        let y = fileName.split(".").pop();
        if (isNotNullAndUndefined(y) && extension !== y) {
          extension = y;
        } else {
          extension = null;
        }
      }

      let downloadDocName = "Notset.txt";

      if (isNotNullAndUndefined(extension)) {
        if (isNotNullAndUndefined(displayName)) {
          downloadDocName = displayName + "." + extension;
        } else if (isNotNullAndUndefined(fileName)) {
          downloadDocName = fileName + "." + extension;
        }
      } else if (isNotNullAndUndefined(displayName)) {
        downloadDocName = displayName;
      } else if (isNotNullAndUndefined(fileName)) {
        downloadDocName = fileName;
      }

      link.setAttribute("download", downloadDocName);
      document.body.appendChild(link);
      link.click();
      hideProgress(progressDiv);
    } else {
      hideProgress(progressDiv);
    }
  });
}
