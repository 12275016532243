import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import moment from "moment";
import { PropsWithChildren, useContext } from "react";
import { Field, FormRenderProps } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import RAFChoiceOption from "../../../RAFComponents/Inputs/RAFChoiceOption";
import RAFDatePickerCustom from "../../../RAFComponents/Inputs/RAFDatePickerCustom";
import RAFDropdownCC from "../../../RAFComponents/Inputs/RAFDropdownCC";
import { RAFDefaultFieldProps, RAFFieldProps, RAFFormContext } from "../../../RAFComponents/Inputs/RFFUtils";
import { showWarningToast } from "../../../RAFComponents/Utility/RAFToastComponent";
import { IsNullOrWhiteSpace, convertUTCDateToLocalTimezone, isNotNullAndUndefined } from "../../../RAFComponents/helpers/utils";
import { ReminderType } from "./ReminderRow";

interface IProps {
    showAdd?: boolean;
    dropDownItems?: any[];
    checkUnique?: boolean;
}

function RAFReminderDropdown<T>(
    { field, label, dropDownItems,
        showAdd = true,
        checkUnique = true,

        required = RAFDefaultFieldProps.required,
        showLabel = RAFDefaultFieldProps.showLabel,
        disabled = RAFDefaultFieldProps.disabled,
        showClearButton = RAFDefaultFieldProps.showClearButton,
        validate = RAFDefaultFieldProps.validate,
        ...props }: PropsWithChildren<RAFFieldProps<T> & IProps>,
) {

    const rafFormContextValue: FormRenderProps = useContext(RAFFormContext);

    const selectedReminderListValues = checkUnique === true && isNotNullAndUndefined(rafFormContextValue) &&
        isNotNullAndUndefined(rafFormContextValue.values) && isNotNullAndUndefined(rafFormContextValue.values[field.toString()]) ? rafFormContextValue.values[field.toString()] : [];

    const disableReminderDropDownItemsValue = [];
    selectedReminderListValues && selectedReminderListValues.forEach(item => {
        if (isNotNullAndUndefined(item.ReminderType))
            disableReminderDropDownItemsValue.push({ label: item.ReminderType, value: item.ReminderType });
    });

    const reminderDropDownItems = isNotNullAndUndefined(dropDownItems) && dropDownItems.length > 0 ?
        dropDownItems : [
            { label: ReminderType.Email, value: ReminderType.Email },
            { label: ReminderType.SMS, value: ReminderType.SMS },
            { label: ReminderType.InApp, value: ReminderType.InApp }
        ];

    const onAddClick = (field: keyof T | string) => {
        if (isNotNullAndUndefined(rafFormContextValue) && isNotNullAndUndefined(rafFormContextValue.form) && isNotNullAndUndefined(rafFormContextValue.form.mutators)) {
            rafFormContextValue.form.mutators.push(field.toString(), { ReminderType: ReminderType.Email, RemindDate: null });
        }
    };

    const onDeleteClick = (fields, index, required1) => {
        if (isNotNullAndUndefined(required1) && required1 === true) {
            if (isNotNullAndUndefined(fields) && fields.length > 1) {
                fields.remove(index);
            }
            else {
                showWarningToast('Atleast one Reminder number is required');
            }
        }
        else {
            fields.remove(index);
        }
    };

    const onChangeReminderType = (remindDateField) => {
        if (isNotNullAndUndefined(rafFormContextValue) && isNotNullAndUndefined(rafFormContextValue.form) && isNotNullAndUndefined(rafFormContextValue.form.mutators)) {
            rafFormContextValue.form.mutators.setValue(remindDateField, null);
        }
    };

    const onclickReminder = () => {
        let popupContainer = document.getElementById(`popup${field.toString()}`);
        let divContainer = document.getElementById(`rafdiv${field.toString()}Col`);
        let divContainerRect = divContainer.getBoundingClientRect();
        divContainer.insertAdjacentElement('beforeend', popupContainer);
        popupContainer.style.left = `${divContainerRect.x}px`;
        popupContainer.style.top = `${divContainerRect.y + divContainerRect.height}px`;
        popupContainer.style.minWidth = `${divContainerRect.width}px`;
    };

    const onCloseReminder = () => {
        let popupContainer = document.getElementById(`popup${field.toString()}`);
        let popupOutterContainer = document.getElementById(`popup${field.toString()}Container`);
        popupOutterContainer.insertAdjacentElement('beforeend', popupContainer);
    };


    // const remindDropDownButtonComponentClicked = (e: MenuEventArgs, remindDateField) => {
    //     if (e.item.id === 'Custom') {
    //         showDatePicker(remindDateField);
    //     } else {
    //         onDateClicked(e.item.id, remindDateField);
    //     }
    // };

    return (
        <div className="app-container p-0" id={"rafdiv" + field.toString()} >
            <div className="row gx-1 gx-md-2 gx-xl-3 gy-2">
                <div className={props.labelClassName ? props.labelClassName : "col-md-4"}>
                    {showLabel && showLabel === true &&
                        <label className={(required ? "form-label required" : "form-label")}><>{label || field}</></label>
                    }
                </div>
                {isNotNullAndUndefined(props.inputFieldClassName) ?
                    <div className={props.inputFieldClassName ? props.inputFieldClassName : "col-12"}>
                        <div className="list mb-3 d-none">
                            <div className="row g-2">
                                <FieldArray name={field.toString()}>
                                    {({ fields }) =>
                                        fields.map((name, index) => (
                                            <div className="col-12" key={name}>
                                                <Field name={`${name}.RemindDate`} id={"rafdiv" + field.toString()}>
                                                    {({ input }) => {
                                                        let inputValue = input.value;
                                                        if (
                                                            !IsNullOrWhiteSpace(inputValue)
                                                        ) {
                                                            // if (moment(inputValue, moment.ISO_8601, true).isValid()) {
                                                            //   inputValue = moment(new Date(inputValue + "Z")).format(
                                                            //     fieldFormat.format
                                                            //   );
                                                            // }
                                                            let localDate = convertUTCDateToLocalTimezone(inputValue);
                                                            inputValue = moment(localDate).format("DD/MM/YYYY");
                                                        }
                                                        return (
                                                            <div>
                                                                <span
                                                                    className={"detailsValue"
                                                                    }
                                                                >
                                                                    {inputValue}
                                                                </span>
                                                            </div>
                                                        );
                                                    }}
                                                </Field>
                                                {isNotNullAndUndefined(`${name}.ReminderType`) && `${name}.RemindDate` ?
                                                    <div className="row align-items-center g-2 phoneSection d-none">
                                                        <div className="col">
                                                            <RAFDropdownCC field={`${name}.ReminderType`} showLabel={false} placeholder="Select Type" required
                                                                {...checkUnique === true ? { disableItems: disableReminderDropDownItemsValue } : {}}
                                                                onChanged={() => { if (checkUnique === true) onChangeReminderType(`${name}.RemindDate`); }}
                                                                showClearButton={false}
                                                            >
                                                                {reminderDropDownItems.map((item, index) => {
                                                                    return <RAFChoiceOption key={index} label={item.label} value={item.value} />;
                                                                })}
                                                            </RAFDropdownCC>
                                                        </div>
                                                        <div className="col-xs-12 col-sm-6 inputFieldContent position-relative">
                                                            <RAFDatePickerCustom key={index} field={`${name}.RemindDate`}
                                                                showLabel={false}
                                                                dropdownDateOptions={[
                                                                    { id: 0, text: 'Later today', value: '0' },
                                                                    { id: 1, text: 'Tomorrow', value: '1' },
                                                                    { id: 2, text: '+3 days', value: '3' },
                                                                    { id: 3, text: '+7 days', value: '7' },
                                                                    { id: 4, text: '+15 days', value: '15' },
                                                                ]}
                                                            />
                                                        </div>
                                                        <div className="col-auto inputFieldDeleteButton">
                                                            <div>
                                                                <ButtonComponent type="button" onClick={() => onDeleteClick(fields, index, required)} title="Click to remove" className="removeItem">
                                                                    <span className="fas fa-trash"></span>
                                                                </ButtonComponent>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    :
                                                    null
                                                }
                                            </div>
                                        ))
                                    }
                                </FieldArray>
                                {showAdd && ((checkUnique === true && reminderDropDownItems.length !== disableReminderDropDownItemsValue.length) || checkUnique === false) &&
                                    <div className="col-12">
                                        <button type="button" className="link-button" onClick={() => onAddClick(field)}> Add Reminder </button>
                                    </div>
                                }
                            </div>
                        </div >
                    </div >
                    :
                    <div className="col-md-8" id={`rafdiv${field.toString()}Col`}>
                        <FieldArray name={field.toString()}>
                            {({ fields }) =>
                                fields.map((name, index) => (
                                    <div className="col-12" key={name}>
                                        <Field name={`${name}.RemindDate`} id={"rafdiv" + field.toString()}>
                                            {({ input }) => {
                                                let inputValue = input.value;
                                                if (
                                                    !IsNullOrWhiteSpace(inputValue)
                                                ) {

                                                    let localDate = convertUTCDateToLocalTimezone(inputValue);
                                                    inputValue = moment(localDate).format("DD/MM/YYYY");
                                                }
                                                return (
                                                    <div onClick={onclickReminder}>
                                                        <span
                                                            className={"detailsValue"
                                                            }
                                                        >
                                                            {inputValue}
                                                        </span>
                                                    </div>
                                                );
                                            }}
                                        </Field>
                                        <div id={`popup${field.toString()}Container`} className="d-none">
                                            <div className="pulldownCustomPoopup" id={`popup${field.toString()}`}>
                                                <div className="row g-0 gy-2">
                                                    <div className="col-12">
                                                        <RAFDatePickerCustom
                                                            field={`${name}.RemindDate`}
                                                            secondaryField={`${name}.RemindDate`}
                                                            label="Reminder"
                                                            displayStyle="splitButton"
                                                            showLabel
                                                            // showLabel={true}
                                                            dropdownDateOptions={[
                                                                { id: 1, text: 'Today', value: '0' },
                                                                { id: 2, text: '1D', value: '1' },
                                                                { id: 3, text: '3D', value: '3' },
                                                                { id: 4, text: '7D', value: '7' },
                                                                { id: 5, text: '15D', value: '15' },
                                                            ]}
                                                            showClearButton={false}
                                                            showClearTextBtn
                                                        // onChanged={(value) => onChangeTaskDate(value)}
                                                        />
                                                    </div>
                                                    <div className="col-auto ms-auto">
                                                        <ButtonComponent type="button" content="Close" onClick={onCloseReminder}></ButtonComponent>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row align-items-center g-2 phoneSection d-none">
                                            <div className="col">
                                                <RAFDropdownCC field={`${name}.ReminderType`} showLabel={false} placeholder="Select Type" required
                                                    {...checkUnique === true ? { disableItems: disableReminderDropDownItemsValue } : {}}
                                                    onChanged={(/*label, value*/) => { if (checkUnique === true) onChangeReminderType(`${name}.RemindDate`); }}
                                                    showClearButton={false}
                                                >
                                                    {reminderDropDownItems.map((item, index) => {
                                                        return <RAFChoiceOption key={index} label={item.label} value={item.value} />;
                                                    })}
                                                </RAFDropdownCC>
                                            </div>
                                            <div className="col-xs-12 col-sm-6 inputFieldContent position-relative">
                                                <RAFDatePickerCustom key={index} field={`${name}.RemindDate`}
                                                    showLabel={false}
                                                    dropdownDateOptions={[
                                                        { id: 0, text: 'Later today', value: '0' },
                                                        { id: 1, text: 'Tomorrow', value: '1' },
                                                        { id: 2, text: '+3 days', value: '3' },
                                                        { id: 3, text: '+7 days', value: '7' },
                                                        { id: 4, text: '+15 days', value: '15' },
                                                    ]} />
                                            </div>
                                            <div className="col-auto inputFieldDeleteButton">
                                                <div>
                                                    <ButtonComponent type="button" onClick={() => onDeleteClick(fields, index, required)} title="Click to remove" className="removeItem">
                                                        <span className="fas fa-trash"></span>
                                                    </ButtonComponent>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }
                        </FieldArray>
                        {showAdd && ((checkUnique === true && reminderDropDownItems.length !== disableReminderDropDownItemsValue.length) || checkUnique === false) &&
                            <div className="col">
                                <button type="button" className="link-button" onClick={() => onAddClick(field)}> Add Reminder </button>
                            </div>
                        }
                    </div>
                }
            </div >
        </div >
    );
}

export default RAFReminderDropdown;

