import { DialogComponent, DialogUtility } from "@syncfusion/ej2-react-popups";
import React, { PropsWithChildren, Reducer, useEffect, useReducer } from "react";
import { msalInstance } from "../../../..";
import { PreventFocusOnDialogOpen } from "../../../../RAFComponents/Dialog/SFDialogUtils";
import CustomCardWidget from "../../../../RAFComponents/Navigation/CustomCardWidget";
import RAFButtonComponent from "../../../../RAFComponents/Navigation/RAFButtonComponent";
import RAFDeletedRecordState from "../../../../RAFComponents/Navigation/RAFDeletedRecordState";
import { hideProgress, showProgress } from "../../../../RAFComponents/helpers/AppHelper";
import RAFPermissionRender from "../../../../RAFComponents/helpers/PermissionHelper";
import { isNotEmptyArray, isNotNullAndUndefined } from "../../../../RAFComponents/helpers/utils";
import ACLoadingPanel from "../../../../components/shared/ACLoadingPanel";
import { RAFActionStatus, RAFButtonConstant, RAFDocumentType } from "../../../../constants/Common/Constants";
import { PermissionConstants } from "../../../../constants/Common/PermissionConstants";
import { getRecipientsByAcknowledgementTrackingDocumentUID } from "../AcknowledgementTracking/AcknowledgementTrackingHelper";
import { AcknowledgementTrackingRow } from "../AcknowledgementTracking/AcknowledgementTrackingRow";
import AcknowledgementTrackingSignSignature from "../AcknowledgementTracking/AcknowledgementTrackingSignSignature";
import RAFManageDocumentProperties from "../ManageDocument/RAFManageDocumentProperties";
import { ContentLibraryRow } from "./ContentLibraryRow";
import { deleteDocumentItem, downloadDocumentFile, retrieveDocumentByID } from "./DocumentHelper";
import DocumentPropertiesInfoContent from "./DocumentPropertiesInfoContent";

interface IProps {
    isActive: boolean;
    documentUID: string;

    onSave?: () => void;
    onClose?: () => void;
    onDelete?: () => void;

    readPermissionName?: string;
    createPermissionName?: string;
    updatePermissionName?: string;
    deletePermissionName?: string;
}

interface IState {
    isLoading: boolean;
    noContent: boolean;

    contentLibraryRow: ContentLibraryRow;
    isFileUpdated: boolean;

    showManageDocProperties: boolean;

    showSignSignatureDlgContent: boolean;
    showAcknowledgementTrackingDlg: boolean;

    acknowledgementTrackingRow: AcknowledgementTrackingRow;
    recipientsAcknowledgementTrackingList: AcknowledgementTrackingRow[];
}

function DocumentDetailsCenterDlg({ ...props }: PropsWithChildren<IProps>) {
    let deleteDialog: any;

    const [state, setState] = useReducer<Reducer<IState, Partial<IState>>>(
        (state, newState) => ({ ...state, ...newState }),
        {
            noContent: true,
            isLoading: true,
            isFileUpdated: false,

            contentLibraryRow: null,

            showManageDocProperties: null,

            showSignSignatureDlgContent: false,
            showAcknowledgementTrackingDlg: false,

            acknowledgementTrackingRow: null,
            recipientsAcknowledgementTrackingList: null,
        }
    );

    useEffect(() => {
        refresh();
    }, [props.documentUID]);

    const refresh = async () => {
        if (props.isActive) {
            setState({ isLoading: true });
            const [contentLibraryRow, recipientsByAcknowledgementTrackingDocumentUID] = await Promise.all([
                await retrieveDocumentByID(props.documentUID),
                getRecipientsByAcknowledgementTrackingDocumentUID(props.documentUID)
            ]);
            if (isNotNullAndUndefined(contentLibraryRow) && isNotNullAndUndefined(contentLibraryRow.UID)) {

                const recipientsAcknowledgementTrackingList = isNotNullAndUndefined(recipientsByAcknowledgementTrackingDocumentUID) ? recipientsByAcknowledgementTrackingDocumentUID.recipientsAcknowledgementTrackingList : null;

                const acknowledgementTrackingRow = isNotEmptyArray(recipientsAcknowledgementTrackingList) ?
                    recipientsAcknowledgementTrackingList.find(x => x.SignedByUID === msalInstance.currentUserId) : null;

                setState({
                    noContent: false,
                    contentLibraryRow,
                    isLoading: false,
                    recipientsAcknowledgementTrackingList,
                    acknowledgementTrackingRow
                });
            } else {
                setState({
                    isLoading: false,
                    noContent: true
                });
            }
        }
    };

    const onClickClose = () => {
        if (state.isFileUpdated === true) {
            if (props.onSave) props.onSave();
        } else {
            if (props.onClose) {
                props.onClose();
            }
        }
    };

    const refreshOnUpdate = async (uid: string) => {
        setState({ isFileUpdated: true, isLoading: true, showManageDocProperties: false });
        const contentLibraryRow = await retrieveDocumentByID(uid);

        if (isNotNullAndUndefined(contentLibraryRow) && isNotNullAndUndefined(contentLibraryRow.UID)) {
            setState({
                noContent: false,
                contentLibraryRow,
                isLoading: false
            });
        } else {
            setState({
                isLoading: false,
                noContent: true
            });
        }
    };

    const refreshOnUpdateTag = async (uid: string) => {
        const contentLibraryRow = await retrieveDocumentByID(uid);

        if (isNotNullAndUndefined(contentLibraryRow) && isNotNullAndUndefined(contentLibraryRow.UID)) {
            setState({
                noContent: false,
                contentLibraryRow,
            });
        } else {
            setState({
                noContent: true
            });
        }
    };

    const downloadDocument = () => {
        let progressDiv = showProgress("#documentDetailsCenterDlg");
        downloadDocumentFile(state.contentLibraryRow, null, progressDiv).then((status) => { });
    };

    function DeleteFileClicked(documentType: string) {
        deleteDialog = DialogUtility.confirm({
            animationSettings: { effect: "Fade" },
            cancelButton: { text: "No" },
            closeOnEscape: false,
            content: "Are you sure want to delete?",
            okButton: { text: "Yes", click: DeleteRecord.bind(this) },
            title: `Delete ${documentType}`,
            position: { X: "center", Y: "center" },
            cssClass: "alert-dialog delete-dialog",
        });
    }

    const DeleteRecord = () => {
        let progressDiv = showProgress(".delete-dialog");
        deleteDocumentItem(state.contentLibraryRow)
            .then((response) => {
                hideProgress(progressDiv);
                deleteDialog.hide();
                if (response) {
                    if (props.onDelete) {
                        props.onDelete();
                    }
                }
            })
            .catch((error) => error);
    };

    //edit doc start
    const editFileDetails = () => {
        setState({ showManageDocProperties: true });
    };

    const manageDocPropertiesContent = () => {
        if (state.showManageDocProperties === true) {
            const { contentLibraryRow } = state;
            return (
                <RAFManageDocumentProperties
                    onUpdate={() => onSaveDocumentPage(contentLibraryRow.UID)}
                    onCancel={manageDocPropertiesDialogClose.bind(this)}
                    contentLibraryUID={contentLibraryRow.UID}
                    contentLibraryRow={contentLibraryRow}
                    isActive
                />
            );
        } else {
            return <div></div>;
        }
    };

    const onSaveDocumentPage = (uid) => {
        refreshOnUpdate(uid);
    };


    const manageDocPropertiesDialogClose = () => {
        setState({ showManageDocProperties: false });
    };
    //edit doc end


    // Signature Dialog start 
    const onSignSignatureBtnClick = () => {
        setState({ showSignSignatureDlgContent: true });
    };

    const signSignatureDlgContent = () => {
        if (state.showSignSignatureDlgContent) {
            const { acknowledgementTrackingRow } = state;

            return (
                <AcknowledgementTrackingSignSignature
                    acknowledgementTrackingRow={acknowledgementTrackingRow}
                    onSaveSignature={refresh}
                />
            );
        } else {
            return null;
        }
    };

    const signSignatureDlgClose = () => {
        setState({ showSignSignatureDlgContent: false });
    };
    // Signature Dialog end 

    const acknowledgementRequiredAlert = () => {
        if (isNotNullAndUndefined(state.acknowledgementTrackingRow) && state.acknowledgementTrackingRow.Status === RAFActionStatus.Pending) {
            return (
                <CustomCardWidget
                    cardClassName="border-0 pointer mb-2"
                    cardContentClassName="p-2"
                >
                    <div className="subtitle_2 d-flex align-items-center">
                        <CustomCardWidget
                            cardClassName="surface_state_warning_light border-0 me-2"
                            cardContentClassName="p-2"
                        >
                            <i className="fas fa-circle-info content_state_warning_base line_height_inherit"></i>
                        </CustomCardWidget>
                        <div className="w-100">
                            <span className="subtitle_2">
                                Acknowledgement Required.
                            </span>
                        </div>
                        <RAFButtonComponent
                            btnContent="Sign here"
                            className="btn_state_success outline ms-auto"
                            onClick={onSignSignatureBtnClick}
                        />
                    </div>
                </CustomCardWidget>
            );
        } else {
            return null;
        }
    };

    const getBodyContet = () => {
        if (props.isActive) {
            if (state.isLoading === false) {
                if (state.noContent === false) {
                    const { contentLibraryRow } = state;

                    return (
                        <>
                            <div className="e-dlg-body-content overflow-auto document-details-page p-3">
                                {acknowledgementRequiredAlert()}
                                <DocumentPropertiesInfoContent
                                    contentLibraryRow={contentLibraryRow}
                                    acknowledgementTrackingRow={state.acknowledgementTrackingRow}
                                    recipientsAcknowledgementTrackingList={state.recipientsAcknowledgementTrackingList}
                                    refreshOnUpdate={refreshOnUpdate}
                                    refreshOnUpdateTag={refreshOnUpdateTag}
                                />
                            </div>
                            <div className="e-dlg-footerContent">
                                <div className="w-100">
                                    <div className="row g-2">
                                        <RAFPermissionRender
                                            permissionName={props.deletePermissionName ?? PermissionConstants.DocumentDelete}
                                        >
                                            <div className="col-auto">
                                                <RAFButtonComponent action={RAFButtonConstant.Delete} iconBtn
                                                    onClick={() => DeleteFileClicked(contentLibraryRow.DocumentType)}
                                                    className="new_style raf_sm btn_state_danger outline"
                                                    enableToolTip={false}
                                                />
                                            </div>
                                        </RAFPermissionRender>
                                        <RAFPermissionRender
                                            permissionName={props.updatePermissionName ?? PermissionConstants.DocumentUpdate}
                                        >
                                            <div className={"col-auto ms-auto"}>
                                                <RAFButtonComponent
                                                    type="button"
                                                    onClick={() => {
                                                        editFileDetails();
                                                    }}
                                                    action={RAFButtonConstant.Edit}
                                                    className="new_style raf_sm btn_brand_primary outline"
                                                >
                                                </RAFButtonComponent>
                                            </div>
                                        </RAFPermissionRender>
                                        {contentLibraryRow.DocumentType === RAFDocumentType.File &&
                                            <div className="col-auto">
                                                <RAFButtonComponent
                                                    action={RAFButtonConstant.Download}
                                                    onClick={() => downloadDocument()}
                                                    className="new_style raf_sm btn_state_success semi_dark"
                                                    enableToolTip={false}
                                                />
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                            {state.showManageDocProperties && (
                                <DialogComponent
                                    visible={state.showManageDocProperties}
                                    cssClass="rightDialog createEditForm full-height dlg-new-style"
                                    content={manageDocPropertiesContent.bind(this)}
                                    isModal
                                    target="body"
                                    closeOnEscape={false}
                                    id="manageDocPropertiesDialog"
                                    close={manageDocPropertiesDialogClose.bind(this)}
                                />
                            )}
                            {state.showSignSignatureDlgContent && (
                                <DialogComponent
                                    header={"Confirm Acknowledgment"}
                                    visible={state.showSignSignatureDlgContent}
                                    cssClass="centerDialog-md createEditForm full-height form-center-dialog dlg-new-style fixed-header centerDialog-height-lg dlg_res_full_page"
                                    id="view_care_team_dialog"
                                    content={signSignatureDlgContent.bind(this)}
                                    isModal
                                    target="body"
                                    closeOnEscape={false}
                                    close={signSignatureDlgClose.bind(this)}
                                    open={PreventFocusOnDialogOpen}
                                    showCloseIcon
                                    zIndex={1200}
                                />
                            )}
                        </>
                    );
                } else {
                    return (
                        <div className="container-fluid px-0">
                            <RAFDeletedRecordState
                                title="This item is either deleted or You do not have sufficient privileges to view this item."
                                showBackBtn
                            />
                        </div>
                    );
                }
            } else {
                return (
                    <div className="container-fluid px-0">
                        <ACLoadingPanel loadingText="Loading..." />
                    </div>
                );
            }
        } else {
            return <></>;
        }
    };

    return (
        <div className="e-dlg-content-outer"   >
            <div className="e-dlg-header-content d-flex align-items-center justify-content-between" id={'documentDetailsCenterDlg'}>
                <div className="e-dlg-header ecllipseFirstLine" id="_title">
                    Document Details
                </div>
                <div className="row gx-3 align-items-center flex-nowrap">
                    <div className="col-auto">
                        <RAFButtonComponent displayMode="DialogCloseBtn"
                            onClick={onClickClose}
                        />
                    </div>
                </div>
            </div>
            {getBodyContet()}
        </div>
    );
}

export default React.memo(DocumentDetailsCenterDlg);