import { DialogUtility } from "@syncfusion/ej2-popups";
import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import { PropsWithChildren, Reducer, useCallback, useEffect, useReducer } from "react";
import ACLoadingPanel from "../../../../components/shared/ACLoadingPanel";
import { hideProgress, showProgress } from "../../../../RAFComponents/helpers/AppHelper";
import { ContentType } from "../../../../constants/Common/Constants";
import { getFileExtension, IDialogProps, isNotNullAndUndefined } from "../../../../RAFComponents/helpers/utils";
import RAFDeletedRecordState from "../../../../RAFComponents/Navigation/RAFDeletedRecordState";
import * as repositoryActions from "../../../../RAFComponents/store/actions/repositoryActions";
import { showWarningToast } from "../../../../RAFComponents/Utility/RAFToastComponent";
import { ContentLibraryRow } from "../Library/ContentLibraryRow";
import { deleteDocumentItem, downloadDocumentFile, retrieveDocumentByID } from "../Library/DocumentHelper";
import nodata_es from "../../../../assets/ES/nodata_es.svg";

interface IProps {
    documentUID: string;
    onDelete?: () => void;
}

interface IState {
    documentRow: ContentLibraryRow,
    profilePhoto: any,
    isActive: boolean,
    noContent: boolean,
}

function RAFManageRelatedDocuments({ ...props }: PropsWithChildren<IProps & IDialogProps>) {

    let deleteDialog;//= useRef<any>(null);
    const [state, setState] = useReducer<Reducer<IState, Partial<IState>>>(
        (state, newState) => ({ ...state, ...newState }),
        {
            documentRow: null,
            profilePhoto: null,
            isActive: false,
            noContent: false,
        }
    );

    useEffect(() => {
        refresh();
    }, [props.documentUID]);

    async function refresh() {
        if (isNotNullAndUndefined(props.documentUID)) {
            let documentRow = await retrieveDocumentByID(props.documentUID);
            if (isNotNullAndUndefined(documentRow) && isNotNullAndUndefined(documentRow.UID)) {
                repositoryActions.postDataAndGetResponse('ContentLibrary/Download',
                    { 'EntityId': props.documentUID },
                    null,
                    ContentType.applicationJson, false, "arraybuffer")
                    .then((response) => {
                        if (isNotNullAndUndefined(response) && isNotNullAndUndefined(response.data)) {
                            const base64 = btoa(
                                new Uint8Array(response.data).reduce(
                                    (data, byte) => data + String.fromCharCode(byte),
                                    '',
                                ),
                            );
                            let filename = response.headers['content-disposition']
                                .split(';')
                                .find((n: any) => n.includes('filename='))
                                .replace('filename=', '')
                                .trim();

                            setState({
                                documentRow,
                                isActive: true,
                                noContent: false,
                                profilePhoto: isNotNullAndUndefined(filename) ? "data:;base64," + base64 : null
                            });
                        } else {
                            setState({
                                documentRow,
                                isActive: true,
                                noContent: false,
                                profilePhoto: null,
                            });
                        }
                    })
                    .catch((error) => error);
            } else {
                setState({
                    documentRow: null,
                    isActive: true,
                    noContent: true,
                });
            }
        } else {
            setState({
                isActive: true,
                noContent: true,
            });
        }
    }

    const downloadDocument = useCallback(async () => {
        downloadDocumentFile(state.documentRow).then((status) => {
            if (!status) {
                showWarningToast("Sorry something went wrong !");
            }
        });
    }, [state.documentRow]);

    function DeleteItemClicked(data: ContentLibraryRow) {
        deleteDialog = DialogUtility.confirm({
            animationSettings: { effect: "Fade" },
            cancelButton: { text: "No" },
            closeOnEscape: false,
            content: "Are you sure want to delete?",
            okButton: { text: "Yes", click: DeleteItemRecord.bind(this, data) },
            title:
                isNotNullAndUndefined(data) && isNotNullAndUndefined(data.UID)
                    ? "Delete " + data.DocumentType
                    : " Delete",
            position: { X: "center", Y: "center" },
            cssClass: "raf-delete_alert_dialog alert-dialog",
            // cssClass: "alert-dialog",
        });
    }

    const DeleteItemRecord = useCallback((data) => {
        // let progressDiv = showProgress("body", true);
        let progressDiv = showProgress(".raf-delete_alert_dialog.e-dialog");
        deleteDocumentItem(data)
            .then((response) => {
                hideProgress(progressDiv);
                if (response) {
                    deleteDialog.hide();
                    if (props.onDelete)
                        props.onDelete();
                }
            })
            .catch((error) => error);
    }, [state.documentRow]);

    if (state.isActive === true) {
        if (isNotNullAndUndefined(state.documentRow) && isNotNullAndUndefined(state.documentRow.UID)) {
            const fileExtension = getFileExtension(state.documentRow.FileName);
            return (
                <div className="dlg-new-style update-page">
                    <div className="e-dlg-headerContent">
                        <div className="e-dlg-header d-inline-block" style={{ verticalAlign: "middle" }}>
                            <span style={{ verticalAlign: "middle" }} className={'fiv-viv fiv-icon-blank fiv-icon-' + fileExtension + " e-dlg-header-icon"}></span>
                            <span className=" e-dlg-header-title"> Document Property</span>
                        </div>
                        <div className="row g-2 flex-nowrap">
                            <div className="col-auto">
                                <ButtonComponent
                                    type="button"
                                    cssClass="d-none d-sm-inline-block primary-custom-button"
                                    iconCss="fa fa-cloud-download-alt"
                                    onClick={() => downloadDocument()}>Download
                                </ButtonComponent>
                                <ButtonComponent
                                    type="button"
                                    iconCss="fa fa-cloud-download-alt"
                                    cssClass="d-sm-none primary-custom-button"
                                    onClick={() => downloadDocument()}>
                                </ButtonComponent>
                            </div>
                            <div className="col-auto">
                                <ButtonComponent
                                    type="button"
                                    iconCss="fas fa-trash"
                                    cssClass="d-none d-sm-inline-block primary-custom-button"
                                    onClick={() => DeleteItemClicked(state.documentRow)}>Remove Attachment
                                </ButtonComponent>
                                <ButtonComponent
                                    type="button"
                                    iconCss="fas fa-trash"
                                    cssClass="d-sm-none primary-custom-button"
                                    onClick={() => DeleteItemClicked(state.documentRow)}>
                                </ButtonComponent>
                            </div>
                            <div className="col-auto">
                                <ButtonComponent type='button' cssClass="primary-custom-button"
                                    iconCss="fas fa-xmark"
                                    onClick={() => props.onClose()}
                                ></ButtonComponent>
                            </div>
                        </div>


                        {/*<button type="button" className="btn-close" onClick={() => { this.onClickClose() }}></button>*/}
                    </div>
                    <div className="e-dlg-body-content mt-0 h-100 overflow-auto p-3">
                        {isNotNullAndUndefined(state.profilePhoto) ? (
                            <img src={state.profilePhoto} alt="" className="w-100 h-100" />
                        )
                            :
                            (
                                <div className="empty-profile-img"  >
                                    <span className="font-weight-bold logoText justify-content-center">
                                        <span className="fa-icon fa-Camera" style={{ fontSize: '24px' }}></span>
                                    </span>
                                </div>
                            )}
                    </div>
                </div>
            );
        } else if (state.noContent) {
            return (
                <div className="container-fluid px-0">
                    <RAFDeletedRecordState
                        image={nodata_es}
                        title="This item is either deleted or You do not have sufficient privileges to view this item."
                    />
                </div>
            );
        } else {
            <div></div>;
        }
    } else {
        return (
            <div className="container-fluid px-0">
                <ACLoadingPanel loadingText="Loading..." />
            </div>
        );
    }
}

export default RAFManageRelatedDocuments;