import { Browser } from "@syncfusion/ej2-base";
import { MenuItemModel } from "@syncfusion/ej2-navigations";
import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import {
  AnimationSettingsModel,
  DialogComponent,
  DialogUtility,
} from "@syncfusion/ej2-react-popups";
import { Component, Fragment, Suspense } from "react";
import * as ReactDOMClient from "react-dom/client";
import { PreventFocusOnDialogOpen } from "../../../RAFComponents/Dialog/SFDialogUtils";
import RAFFieldStateProvider from "../../../RAFComponents/Grid/RAFFieldStateProvider";
import RAFGrid4 from "../../../RAFComponents/Grid/RAFGrid4";
import RAFGridCC from "../../../RAFComponents/Grid/RAFGridCC";
import RAFGridColumn from "../../../RAFComponents/Grid/RAFGridColumn";
import RAFReportDataList from "../../../RAFComponents/List/RAFReportDataList";
import RAFButtonComponent from "../../../RAFComponents/Navigation/RAFButtonComponent";
import RAFCollapse from "../../../RAFComponents/Navigation/RAFCollapse";
import RAFRecordInfo from "../../../RAFComponents/Navigation/RAFRecordInfo";
import RAFAttributeRelatedListProvider from "../../../RAFComponents/Providers/RAFAttributeRelatedListProvider";
import RAFDataResultProvider, {
  RAFDataResultContext,
} from "../../../RAFComponents/Providers/RAFDataResultProvider";
import {
  RAFCustomFilter,
  RAFCustomOperator,
} from "../../../RAFComponents/RAFViewPanels/RAFFilterColumn/RAFCustomFilter";
import { createInstance } from "../../../RAFComponents/Utility/FormUtility";
import { showSuccessToast } from "../../../RAFComponents/Utility/RAFToastComponent";
import {
  DeleteRecord,
  hideProgress,
  showProgress
} from "../../../RAFComponents/helpers/AppHelper";
import RAFPermissionRender from "../../../RAFComponents/helpers/PermissionHelper";
import { RAFEventName, triggerRAFEvent } from "../../../RAFComponents/helpers/RAFDocumentEvents";
import {
  IsNullOrWhiteSpace,
  getFileExtension,
  getQueryString,
  isNotNullAndUndefined,
  propertyOf,
} from "../../../RAFComponents/helpers/utils";
import * as repositoryActions from "../../../RAFComponents/store/actions/repositoryActions";
import {
  BrowserIsDevice,
  Constants,
  ContentType,
  RAFButtonConstant,
  RAFDocumentType,
  RAFLayout,
  SFColumnType,
} from "../../../constants/Common/Constants";
import { RAFEntityName } from "../../../constants/Common/EntityConstants";
import { PermissionConstants } from "../../../constants/Common/PermissionConstants";
import RAFGridTemplates, {
  getGridColumnTemplatesByModule,
} from "../../../helpers/RAFGridTemplates";
import { NavigateParamsProps, withRouter } from "../../../router";
import RAFEntityProvider from "../../Common/Providers/RAFEntityProvider";
import AcknowledgementTrackingDialogContent from "./AcknowledgementTracking/AcknowledgementTrackingDialogContent";
import { relatedDocumentListGridColumnTemplates } from "./DocumentGridColumnTemplate";
import DocumentLookupGrid from "./DocumentLookupGrid";
import { ContentLibraryRow } from "./Library/ContentLibraryRow";
import DocumentDetailsCenterDlg from "./Library/DocumentDetailsCenterDlg";
import {
  deleteDocumentItem,
  downloadDocumentFile,
  getFileSize,
} from "./Library/DocumentHelper";
import RAFManageDocumentProperties from "./ManageDocument/RAFManageDocumentProperties";
import RAFManageUploadDocumentFile from "./ManageDocument/RAFManageUploadDocumentFile";
//const RAFGrid4 = React.lazy(() => import('../../../RAFComponents/Grid/RAFGrid4'));

interface IProps {
  relatedUID?: string;
  relatedTo?: string;
  relatedToType?: string;
  moduleName?: string;
  url?: string;
  collapsePanelArrowPosistion?: "left" | "title" | "right";
  showFilterBtn?: boolean;
  enableAddBtn?: boolean;
  isRelatedDocument?: boolean;
  isRelatedSection?: boolean;
  documentCreated?: () => void;
  gridStyle?: "Grid" | "List";
  hideHeader?: boolean;
  showUploadDocumnetBtnOnly?: boolean;
  showExpandBtn?: boolean;
  isAdaptive?: boolean;
  headerRowClassName?: string;
  secondaryRelatedToUID?: string;
  saveContextObject?: any;
  contextObject?: any;
  onUploadCompleted?: (objectId?: string) => void;
  addBtnContent?: string;
  allowFiltering?: boolean;
  contentCardClassName?: string;
  addBtnDivId?: string;
  showSectionHeader?: boolean;
  IsCollapsed?: boolean;
  Collapsible?: boolean;
  addBtnDisplayStyle?: "SectionFooter";
  addBtnClassname?: string;
  allowSelectRecordCategory?: boolean;
  uiStyle?: 'Card' | 'CollapseCard' | 'default';

  readPermissionName?: string;
  createPermissionName?: string;
  updatePermissionName?: string;
  deletePermissionName?: string;
}

interface IState {
  isActive?: boolean;
  relatedToUID?: string;
  relatedTo?: string;
  relatedToType?: string;
  selectedDocumentRow: ContentLibraryRow;
  showfileDetailsContent: boolean;
  showManageDocProperties: boolean;
  showUploadContent: boolean;
  isRevisedVersion: boolean;
  showFilterBtn?: boolean;
  enableAddBtn?: boolean;
  showDocumentLookupGridContent?: boolean;
  relatedFilter?: RAFCustomFilter;
  isRelatedDocument?: boolean;
  isRelatedSection?: boolean;
  documentDivKey?: number;
  contextObject?: Object;
  showAcknowledgementTrackingDlg: boolean;
  acknowledgementMode?: 'default' | 'Overview';
}

class DocumentSummary extends Component<IProps & NavigateParamsProps, IState> {
  private _isMounted: boolean;
  deleteDialog: any;
  animationSettings: AnimationSettingsModel;
  private moduleName: string = createInstance(ContentLibraryRow).getClassName();
  private relatedEntity: string = this.props.moduleName;
  private gridSelectedRows: ContentLibraryRow[];
  private rootBtn;

  constructor(props) {
    super(props);
    this.animationSettings = { effect: "Fade" };
    this.rowClick = this.rowClick.bind(this);

    this.state = {
      selectedDocumentRow: null,
      showfileDetailsContent: false,
      showManageDocProperties: false,
      isRevisedVersion: false,
      showUploadContent: false,
      isActive: false,
      relatedToUID: isNotNullAndUndefined(this.props.relatedUID)
        ? this.props.relatedUID
        : null,
      relatedTo: isNotNullAndUndefined(this.props.relatedTo)
        ? this.props.relatedTo
        : null,
      relatedToType: isNotNullAndUndefined(this.props.relatedToType)
        ? this.props.relatedToType
        : null,
      showFilterBtn: isNotNullAndUndefined(this.props.showFilterBtn)
        ? this.props.showFilterBtn
        : true,
      enableAddBtn: isNotNullAndUndefined(this.props.enableAddBtn)
        ? this.props.enableAddBtn
        : true,
      isRelatedDocument: isNotNullAndUndefined(this.props.isRelatedDocument)
        ? this.props.isRelatedDocument
        : false,
      isRelatedSection: isNotNullAndUndefined(this.props.isRelatedSection)
        ? this.props.isRelatedSection
        : false,
      showDocumentLookupGridContent: false,
      relatedFilter: null,
      documentDivKey: Math.random(),
      contextObject: null,
      showAcknowledgementTrackingDlg: false,
      acknowledgementMode: 'default',
    };
  }

  componentDidMount = () => {
    this._isMounted = true;
    //if (isNotNullAndUndefined(this.state.relatedToUID)) {
    this.getDocuments(this.props.contextObject, false);
    if (isNotNullAndUndefined(this.props.addBtnDivId)) {
      let uploadBtn = (
        <RAFPermissionRender
          permissionName={this.props.createPermissionName ?? PermissionConstants.DocumentCreate}
        >
          <ButtonComponent
            type="button"
            cssClass={
              this.props.addBtnClassname
                ? this.props.addBtnClassname
                : "d-none link-button d-sm-inline-block"
            }
            iconCss="fas fa-plus"
            onClick={() => this.uploadDocument()}
            content={
              isNotNullAndUndefined(this.props.addBtnContent)
                ? this.props.addBtnContent
                : "Add"
            }
          ></ButtonComponent>
          <ButtonComponent
            type="button"
            cssClass="e-small link-button d-sm-none"
            iconCss="fas fa-plus"
            onClick={() => this.uploadDocument()}
          ></ButtonComponent>
        </RAFPermissionRender>
      );
      let addBtnDiv = document.getElementById(this.props.addBtnDivId);
      if (isNotNullAndUndefined(addBtnDiv)) {
        if (IsNullOrWhiteSpace(this.rootBtn)) {
          this.rootBtn = ReactDOMClient.createRoot(addBtnDiv);
        }
      }
      if (isNotNullAndUndefined(this.rootBtn)) {
        this.rootBtn.render(uploadBtn);
      }
    }
    //}
  };

  componentDidUpdate = (prevProps) => {
    if (prevProps.contextObject !== this.props.contextObject) {
      this.getDocuments(this.props.contextObject, false);
    }
  };

  componentWillUnmount() {
    this._isMounted = false;
  }

  DeleteDocument = (id?: string) => {
    if (isNotNullAndUndefined(id)) {
      // let url = `${Constants.baseAPIUrl}` + this.URLmodule + `/Delete`;
      let url = "ContentLibrary/Delete";
      let progressDiv = showProgress(".raf-delete_alert_dialog.e-dialog");
      // let progressDiv = showProgress("body", true);
      this.deleteDialog.hide();
      repositoryActions
        .postDataAndGetResponse(
          url,
          { EntityId: id },
          { ...this.props },
          ContentType.applicationJson
        )
        .then((response) => {
          if (isNotNullAndUndefined(response)) {
            hideProgress(progressDiv);
            showSuccessToast("Document deleted");
            if (isNotNullAndUndefined(this.state.relatedToUID)) {
              this.documentCreated();
            }
          }
        })
        .catch((error) => error);
    }
  };

  deleteDocument(id?: String) {
    this.deleteDialog = DialogUtility.confirm({
      animationSettings: { effect: "Fade" },
      cancelButton: { text: "No" },
      closeOnEscape: false,
      content: "Are you sure want to delete?",
      okButton: { text: "Yes", click: this.DeleteDocument.bind(this, id) },
      showCloseIcon: true,
      title: " Delete document",
      position: { X: "center", Y: "center" },
      cssClass: "raf-delete_alert_dialog alert-dialog",
      // cssClass: "alert-dialog",
    });
  }

  setAdditionalFilter = (relatedUID?: string): RAFCustomFilter => {
    let relatedFilter: RAFCustomFilter = {};
    relatedFilter.Condition = "and";
    relatedFilter.Rules = [];
    let filter1: RAFCustomFilter = {};
    let filterVal1: string[] = [];
    filterVal1.push(relatedUID);
    filter1.Operator = RAFCustomOperator.Equal;
    filter1.Value = filterVal1;
    filter1.Field = propertyOf<ContentLibraryRow>("RelatedToUID");
    relatedFilter.Rules.push(filter1);
    return relatedFilter;
  };

  documentCreated = () => {
    if (this.props.documentCreated) {
      this.props.documentCreated();
    }
    this.getDocuments(null, true);
  };

  getDocuments = (contextObject: Object, reloadRelatedItemsContent: boolean) => {
    if (this._isMounted) {
      if (reloadRelatedItemsContent === true) {
        this.getReloadRelatedItemsContent();
      }
      this.setState(
        {
          documentDivKey: Math.random(),
          isActive: true,
          contextObject,
        },
        () => { }
      );
    }
  };

  getReloadRelatedItemsContent = () => {
    const eventKey = `${RAFEventName.ReloadRelatedItemsContent}_${this.moduleName}_${this.props.relatedUID}`;
    triggerRAFEvent(eventKey, {
      relatedUID: `${this.props.relatedUID}`,
    });
  };

  private uploadDialogClose(): void {
    if (this._isMounted) {
      this.setState({ showUploadContent: false });
    }
  }

  rowClick = (docLibraryRow: ContentLibraryRow) => {
    //const docLibraryRow: ContentLibraryRow = documentLibraryRow as ContentLibraryRow;
    if (
      isNotNullAndUndefined(docLibraryRow) &&
      isNotNullAndUndefined(docLibraryRow.UID) &&
      docLibraryRow.DocumentType === RAFDocumentType.File
    ) {
      // if (isNotNullAndUndefined(this.props.navigate)) {
      //   this.props.navigate("/DocumentLibrary/View/" + docLibraryRow.UID);
      // } else {
      //   window.open("/DocumentLibrary/View/" + docLibraryRow.UID);
      // }
      this.fileProperties(docLibraryRow);
    }
  };

  fileProperties = (docLibraryRow: ContentLibraryRow) => {
    this.props.navigate("/Document/View/" + docLibraryRow.UID);
    return;
    document.body.classList.add("overflow-hidden");
    if (this._isMounted) {
      this.setState({
        showfileDetailsContent: true,
        selectedDocumentRow: docLibraryRow,
      });
    }
  };

  private fileDetailsDialogClose(): void {
    if (this.state.isRelatedDocument === false)
      document.body.classList.remove("overflow-hidden");
    if (this._isMounted) {
      this.setState(
        { showfileDetailsContent: false, selectedDocumentRow: null },
        () => { }
      );
    }
  }

  fileDetailsContent() {
    if (
      this.state.showfileDetailsContent
    ) {
      return (
        <DocumentDetailsCenterDlg
          onSave={this.refreshOnFileDetails.bind(this)}
          onDelete={this.refreshOnFileDetails.bind(this)}
          onClose={this.fileDetailsDialogClose.bind(this)}
          documentUID={this.state.selectedDocumentRow.UID}
          //module={this.state.selectedDocumentRow.Entity}
          isActive
        // {...this.props}
        />
      );
    } else {
      return <div></div>;
    }
  }

  private refreshOnFileDetails(actions): void {
    if (this._isMounted) {
      this.setState({ showfileDetailsContent: false }, () => {
        this.documentCreated();
        document.body.classList.remove("overflow-hidden");
        if (
          isNotNullAndUndefined(actions) &&
          isNotNullAndUndefined(actions.fetchData)
        ) {
          actions.fetchData(1, 10, null, null);
        }
      });
    }
  }

  //acknowledgementTracking start
  onRequestAcknowledgementBtnClick = (docLibraryRow) => {
    this.setState({ showAcknowledgementTrackingDlg: true, selectedDocumentRow: docLibraryRow });
  };
  onAcknowledgementOverviewClick = () => {
    this.setState({ showAcknowledgementTrackingDlg: true, acknowledgementMode: 'Overview' });
  };

  acknowledgementTrackingDlgContent = () => {
    if (this.state.showAcknowledgementTrackingDlg) {
      return (
        <AcknowledgementTrackingDialogContent
          contentLibraryUID={this.state.selectedDocumentRow.UID}
          onClose={this.acknowledgementTrackingDlgContentClose}
          onSave={this.refreshOnUpdateFile.bind(this)}
          mode={this.state.acknowledgementMode}
        />
      );
    } else {
      return null;
    }
  };

  acknowledgementTrackingDlgContentClose = () => {
    this.setState({ showAcknowledgementTrackingDlg: false, acknowledgementMode: 'default', selectedDocumentRow: null });
  };
  //acknowledgementTracking end

  onActionMenuClicked(selectedId, docLibraryRow?: Object) {
    switch (selectedId) {
      case "UploadVersion":
        this.uploadDocument(docLibraryRow, true);
        break;
      case "Detail":
        this.rowClick(docLibraryRow);
        break;
      case "Edit":
        this.manageDocPropertiesDialogOpen(docLibraryRow);
        break;
      case "Share":
        //console.log('Share Clicked');
        break;
      case "Email":
        //console.log('Email Clicked');
        break;
      case "Download":
        let progressDiv = showProgress("#documetDiv");
        downloadDocumentFile(docLibraryRow, false, progressDiv);
        break;
      case "Delete":
        this.DeleteItemClicked(docLibraryRow);
        break;
      case "RequestAcknowledgement":
        this.onRequestAcknowledgementBtnClick(docLibraryRow);
        break;
      default:
        break;
    }
  }

  private uploadDocument(data?: Object, isRevisedVersion?: boolean) {
    // console.log("data", data, isRevisedVersion);
    // console.log("this._isMounted", this._isMounted);
    if (this._isMounted) {
      this.setState({
        showUploadContent: true,
        selectedDocumentRow: data,
        isRevisedVersion: isRevisedVersion,
      });
    }
  }

  onUploadCompleted = (objectId?: string) => {
    if (isNotNullAndUndefined(this.props.onUploadCompleted)) {
      this.props.onUploadCompleted(objectId);
    }
  };

  uploadAndLinkDocumentContent() {
    if (this.state.showUploadContent === true) {
      return (
        <RAFManageUploadDocumentFile
          onSave={(UID) => this.onUploadDocumentFile(UID)}
          onClose={() => this.uploadDialogClose()}
          selectedFolder={null}
          selectedDocumentRow={this.state.selectedDocumentRow}
          relatedToUID={this.state.relatedToUID}
          relatedTo={this.state.relatedTo}
          relatedToType={this.state.relatedToType}
          relatedEntity={this.props.moduleName}
          secondaryRelatedToUID={this.props.secondaryRelatedToUID}
          mode={
            this.state.isRevisedVersion === true ? "updateVersion" : "create"
          }
          isActive
          saveContextObject={this.props.saveContextObject}
          contextObject={this.state.contextObject}
          onUploadCompleted={(id) => this.onUploadCompleted(id)}
          allowSelectRecordCategory={this.props.allowSelectRecordCategory}

          createPermissionName={this.props.createPermissionName}
          updatePermissionName={this.props.updatePermissionName}
          paddingClass='mx-0 mt-0'
          footerPadding='pb-2'
        />
      );
    } else {
      return <div></div>;
    }
  }

  onUploadDocumentFile = (UID) => {
    if (this._isMounted) {
      this.setState({ showUploadContent: false }, () => {
        this.documentCreated();
      });
    }
  };

  manageDocPropertiesDialogOpen = (docLibraryRow: ContentLibraryRow) => {
    document.body.classList.add("overflow-hidden");
    if (this._isMounted) {
      this.setState(
        { showManageDocProperties: true, selectedDocumentRow: docLibraryRow },
        () => {
          /*let divDetailsDialog = document.getElementById('documentDetailsDialog');
                                              if (isNotNullAndUndefined(divDetailsDialog)) {
                                                  divDetailsDialog.classList.remove('fade-in');
                                              }*/
        }
      );
    }
  };

  DeleteItemClicked(data: ContentLibraryRow) {
    this.deleteDialog = DialogUtility.confirm({
      animationSettings: { effect: "Fade" },
      cancelButton: { text: "No" },
      closeOnEscape: false,
      content: "Are you sure want to delete?",
      okButton: { text: "Yes", click: this.DeleteItemRecord.bind(this, data) },
      title:
        isNotNullAndUndefined(data) && isNotNullAndUndefined(data.UID)
          ? "Delete " + data.DocumentType
          : " Delete",
      position: { X: "center", Y: "center" },
      cssClass: "raf-delete_alert_dialog alert-dialog",
      // cssClass: "alert-dialog",
    });
  }

  DeleteItemRecord(data?: Object) {
    // let progressDiv = showProgress("body", true);
    let progressDiv = showProgress(".raf-delete_alert_dialog.e-dialog");
    deleteDocumentItem(data)
      .then((response) => {
        hideProgress(progressDiv);
        this.deleteDialog.hide();
        if (isNotNullAndUndefined(response)) {
          this.getDocuments(null, true);
        }
      })
      .catch((error) => error);
  }

  manageDocPropertiesContent() {
    if (
      this.state.showManageDocProperties
    ) {
      return (
        <RAFManageDocumentProperties
          onUpdate={this.refreshOnUpdateFile.bind(this)}
          onCancel={this.manageDocPropertiesDialogClose.bind(this)}
          contentLibraryUID={this.state.selectedDocumentRow.UID}
          isActive
          {...this.props}
        />
      );
    } else {
      return <div></div>;
    }
  }

  private refreshOnUpdateFile(actions): void {
    if (this._isMounted) {
      this.setState(
        { showManageDocProperties: false, selectedDocumentRow: null, showAcknowledgementTrackingDlg: false },
        () => {
          document.body.classList.remove("overflow-hidden");
          this.documentCreated();
          if (
            isNotNullAndUndefined(actions) &&
            isNotNullAndUndefined(actions.fetchData)
          ) {
            actions.fetchData(1, 10, null, null);
          }
        }
      );
    }
  }

  private manageDocPropertiesDialogClose(): void {
    if (this.state.isRelatedDocument === false)
      document.body.classList.remove("overflow-hidden");
    if (this._isMounted) {
      this.setState(
        { showManageDocProperties: false, selectedDocumentRow: null },
        () => { }
      );
    }
  }

  RowSelected = (selectedRows: ContentLibraryRow[]) => {
    this.gridSelectedRows = selectedRows;
    const showDeleteButton =
      isNotNullAndUndefined(selectedRows) && selectedRows.length > 0
        ? true
        : false;
    const divHeaderButtons = document.querySelector("#divHeaderButtons");
    if (divHeaderButtons !== null) {
      if (showDeleteButton) divHeaderButtons.classList.add("hidden");
      else divHeaderButtons.classList.remove("hidden");
    }
    const divHeaderDeleteButton = document.querySelector(
      "#divHeaderDeleteButton"
    );
    if (divHeaderDeleteButton !== null) {
      if (showDeleteButton) divHeaderDeleteButton.classList.remove("hidden");
      else divHeaderDeleteButton.classList.add("hidden");
    }
  };

  DeleteClicked() {
    this.deleteDialog = DialogUtility.confirm({
      animationSettings: { effect: "Fade" },
      cancelButton: { text: "No" },
      closeOnEscape: false,
      content: "Are you sure want to delete?",
      okButton: { text: "Yes", click: this.DeleteRecord.bind(this) },
      showCloseIcon: true,
      title: " Delete",
      position: { X: "center", Y: "center" },
      cssClass: "raf-delete_alert_dialog alert-dialog",
      // cssClass: "alert-dialog",
    });
  }

  DeleteRecord() {
    let progressDiv = showProgress(".raf-delete_alert_dialog.e-dialog");
    // let progressDiv = showProgress("body", true);
    let selectedRowIds: string[] = [];
    if (isNotNullAndUndefined(this.gridSelectedRows)) {
      this.gridSelectedRows.forEach((item) => {
        selectedRowIds.push(item["UID"]);
      });
    }
    let id = selectedRowIds.toString();
    DeleteRecord(id, this.moduleName)
      .then((response) => {
        hideProgress(progressDiv);
        if (isNotNullAndUndefined(response)) {
          this.deleteDialog.hide();
          this.getDocuments(null, true);
        }
      })
      .catch((error) => error);
  }

  showDocumentLookupGridGrid() {
    if (this._isMounted) {
      this.setState({ showDocumentLookupGridContent: true }, () => {
        document.body.classList.add("overflow-hidden");
      });
    }
  }

  onGridItemClick = (item) => {
    if (this._isMounted) {
      this.setState({ showDocumentLookupGridContent: false }, () => {
        document.body.classList.remove("overflow-hidden");
        this.rowClick(item);
      });
    }
  };

  documentLookupGrid() {
    if (
      isNotNullAndUndefined(this.state.showDocumentLookupGridContent) &&
      this.state.showDocumentLookupGridContent === true
    ) {
      return (
        <DocumentLookupGrid
          //rowclicked={this.onGridItemClick}
          relatedToType={this.state.relatedToType}
          relatedToUID={this.state.relatedToUID}
          relatedEntity={this.relatedEntity}
          viewName={"all_status"}
          onDocumentLookupGridClose={(isUpdate) =>
            this.showDocumentLookupGridDialogClose(isUpdate)
          }
          isActive
        />
      );
    } else {
      return <div></div>;
    }
  }

  showDocumentLookupGridDialogClose(isUpdate?: boolean) {
    if (this._isMounted) {
      this.setState({ showDocumentLookupGridContent: false }, () => {
        if (this.state.isRelatedDocument === false)
          document.body.classList.remove("overflow-hidden");
        if (isNotNullAndUndefined(isUpdate) && isUpdate === true) {
          this.getDocuments(null, true);
        }
      });
    }
  }

  customClick(rowData) {
    if (this._isMounted) {
      let progressDiv = showProgress(`#documentDiv_${this.moduleName}`);
      downloadDocumentFile(rowData, false, progressDiv);
    }
  }

  headerBadge = () => {
    return (
      <RAFDataResultContext.Consumer>
        {({ count }) => {
          return (
            <>
              {count > 0 && (
                <span className="ms-1 e-badge e-badge-primary e-badge-circle">
                  {count}
                </span>
              )}
            </>
          );
        }}
      </RAFDataResultContext.Consumer>
    );
  };

  contentLibraryRowTemplate = (data) => {
    const fieldValue = data.DisplayName;
    const documentType: string = data.DocumentType;
    const updatedBy: string = data.ModifiedBy;
    const isSystem: boolean = data.IsSystem;
    let createdBy = data.CreatedBy;
    let createdDate = data.CreatedDate;
    let modifiedDate = data.ModifiedDate;
    let modifiedBy = data.ModifiedBy;
    let lastActivityDate = data.LastActivityDate;
    let fileClassname;
    let showMoreButton = true;

    let fileSize = getFileSize(data.FileSize);

    const fileNameExtension = data.FileName;
    const fileExtension = getFileExtension(fileNameExtension);
    if (isNotNullAndUndefined(fileExtension)) {
      fileClassname = "fiv-viv fiv-icon-blank fiv-icon-" + fileExtension;
    }

    if (isNotNullAndUndefined(fieldValue)) {
      let menuItems = [];

      if (documentType === RAFDocumentType.Folder) {
        fileClassname = "fiv-viv fiv-icon-folder";
        if (isNotNullAndUndefined(isSystem) && isSystem === true) {
          menuItems = [
            {
              id: "FolderProperties",
              text: "Properties",
              iconCss: "fa fa-info-circle",
            },
          ];
        } else {
          menuItems = [
            {
              id: "FolderProperties",
              text: "Properties",
              iconCss: "fa fa-info-circle",
            },
            { id: "Edit", text: "Edit", iconCss: "fal fa-pencil" },
            { id: "Delete", text: "Delete", iconCss: "fas fa-trash" },
          ];
        }
      } else if (documentType === RAFDocumentType.File) {
        menuItems = [
          { id: "Edit", text: "Edit", iconCss: "fal fa-pencil" },
          {
            id: "UploadVersion",
            text: "Upload New Version",
            iconCss: "fa fa-cloud-upload-alt",
          },
          /*{ id: 'Share', text: 'Share', iconCss: 'fa fa-share-alt' },
                                                            { id: 'Email', text: 'Email', iconCss: 'fas fa-envelope' },*/
          {
            id: "Download",
            text: "Download",
            iconCss: "fa fa-cloud-download-alt",
          },
          { id: "Delete", text: "Delete", iconCss: "fas fa-trash" },
        ];
      } else if (documentType === RAFDocumentType.Page) {
        menuItems = [
          { id: "Edit", text: "Edit", iconCss: "fal fa-pencil" },
          {
            id: "UploadVersion",
            text: "Upload New Version",
            iconCss: "fa fa-cloud-upload-alt",
          },
          /*{ id: 'Share', text: 'Share', iconCss: 'fa fa-share-alt' },
                                                            { id: 'Email', text: 'Email', iconCss: 'fas fa-envelope' },*/
          {
            id: "Download",
            text: "Download",
            iconCss: "fa fa-cloud-download-alt",
          },
          { id: "Delete", text: "Delete", iconCss: "fas fa-trash" },
        ];
      }

      let smMenuItems: MenuItemModel[] = [];

      if (documentType === RAFDocumentType.Folder) {
        fileClassname = "fiv-viv fiv-icon-folder";
        if (isNotNullAndUndefined(isSystem) && isSystem === true) {
          smMenuItems = [
            {
              iconCss: "fa fa-ellipsis-v m-0",
              items: [
                {
                  id: "FolderProperties",
                  text: "Properties",
                  iconCss: "fa fa-info-circle",
                },
              ],
            },
          ];
        } else {
          smMenuItems = [
            {
              iconCss: "fa fa-ellipsis-v m-0",
              items: [
                {
                  id: "FolderProperties",
                  text: "Properties",
                  iconCss: "fa fa-info-circle",
                },
                { id: "Edit", text: "Edit", iconCss: "fal fa-pencil" },
                { id: "Delete", text: "Delete", iconCss: "fas fa-trash" },
              ],
            },
          ];
        }
      } else if (documentType === RAFDocumentType.File) {
        smMenuItems = [
          {
            iconCss: "fa fa-ellipsis-v",
            items: [
              {
                id: "Edit",
                text: "Edit",
                iconCss: "fal fa-pencil",
              },
              {
                id: "UploadVersion",
                text: "Upload New Version",
                iconCss: "fa fa-cloud-upload-alt",
              },
              /*{ id: 'Share', text: 'Share', iconCss: 'fa fa-share-alt' },
                                                                                { id: 'Email', text: 'Email', iconCss: 'fas fa-envelope' },*/
              {
                id: "Download",
                text: "Download",
                iconCss: "fa fa-cloud-download-alt",
              },
              { id: "Delete", text: "Delete", iconCss: "fas fa-trash" },
            ],
          },
        ];
      } else if (documentType === RAFDocumentType.Page) {
        fileClassname = "fiv-viv fiv-icon-blank fiv-icon-html";
        smMenuItems = [
          {
            iconCss: "fa fa-ellipsis-v",
            items: [
              {
                id: "Edit",
                text: "Edit",
                iconCss: "fal fa-pencil",
              },
              {
                id: "UploadVersion",
                text: "Upload New Version",
                iconCss: "fa fa-cloud-upload-alt",
              },
              /*{ id: 'Share', text: 'Share', iconCss: 'fa fa-share-alt' },
                                                                                { id: 'Email', text: 'Email', iconCss: 'fas fa-envelope' },*/
              {
                id: "Download",
                text: "Download",
                iconCss: "fa fa-cloud-download-alt",
              },
              { id: "Delete", text: "Delete", iconCss: "fas fa-trash" },
            ],
          },
        ];
      }

      return (
        <div className="p-2 px-3">
          <div className="gridDefault d-flex align-items-center justify-content-between">
            <div
              className="d-flex align-items-center"
              style={{ width: "calc(100% - 42px)" }}
            >
              <div className="d-flex document-item-icon">
                <span className={fileClassname}></span>
              </div>
              <div className="ms-2">
                <div className="detailsLeftCard-title ecllipseFirstLine text-wrap word-break-all text-break">
                  <span className="" style={{ cursor: "pointer" }}>
                    {fieldValue}
                  </span>
                </div>
                <div className="w-100">
                  <div className="detailsLeftCard-second-title">
                    <div className="row gx-2 align-items-center">
                      {/* {isNotNullAndUndefined(updatedBy) ? (
                                                <div className="col-auto detailsLeftCard-second-title-col">
                                                    <div className="detailsLeftCard-second-title-item">
                                                        <RAFDetailsFieldWithIcon
                                                            value={updatedBy}
                                                            iconClassName="fas fa-user"
                                                        ></RAFDetailsFieldWithIcon>
                                                    </div>
                                                </div>
                                            ) : null} */}
                      {isNotNullAndUndefined(fileSize) ? (
                        <div className="col-auto detailsLeftCard-second-title-col">
                          <div className="detailsLeftCard-second-title-item">
                            {fileSize}
                          </div>
                        </div>
                      ) : null}
                      <div className="col-auto detailsLeftCard-second-title-col">
                        <div className="detailsLeftCard-second-title-item">
                          <div
                            className="gridDefault d-flex align-items-center justify-content-between"
                            onClick={(e) => e.stopPropagation()}
                          >
                            <div className="recordInfomsg">
                              <RAFRecordInfo
                                createdBy={createdBy}
                                createdDate={createdDate}
                                modifiedDate={modifiedDate}
                                modifiedBy={modifiedBy}
                                lastActivityDate={lastActivityDate}
                                dropdownBtnClassName="noRowclick"
                              ></RAFRecordInfo>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* {showMoreButton === true && (
                            <div className="gridEditBtn">
                                <DropDownButtonComponent
                                                items={menuItems} cssClass="e-flat btndefault-icon e-editbutton e-caret-hide square-btn e-icon-btn"
                                                select={(e) => this.onActionMenuClicked(e.item.id, data)} onClick={(e) => { e.stopPropagation() }}
                                            >
                                                <div className="icon-ellipsis-horizontal icon-ellipsis-sm"><span className="icon-ellipsis-dot"></span></div>
                                            </DropDownButtonComponent> 
                                <RAFMenuComponent
                                    cssClass="e-flat raf-iconbtn documentBtn rafGridActionContentLib noRowclick bg-rgba-grey"
                                    permissionsName={[
                                        PermissionConstants.DocumentCreate,
                                        PermissionConstants.DocumentUpdate,
                                        PermissionConstants.DocumentDelete,
                                    ]}
                                    smMenuItems={smMenuItems}
                                    toolbarItemClicked={(e) => {
                                        this.onActionMenuClicked(e.item.id, data);
                                    }}
                                    pageType={"documentGridPage"}
                                />
                            </div>
                        )} */}
            <div className="col-auto">
              <ButtonComponent
                type="button"
                content="Download"
                cssClass="e-outline e-custombutton custom-button-sm min-width-md"
                isPrimary
              // iconCss="fas fa-download"
              ></ButtonComponent>
            </div>
          </div>
        </div>
      );
    }

    return <div> {fieldValue}</div>;
  };

  gridListContent = (listPageGridID) => {
    return this.props.gridStyle === "List" ? (
      <div key={this.state.documentDivKey}>
        <RAFEntityProvider moduleName={this.moduleName} >
          <RAFAttributeRelatedListProvider moduleName={this.moduleName}>
            <Suspense fallback={<div>Loading...</div>}>
              <RAFFieldStateProvider moduleName={this.moduleName}>
                <RAFGridTemplates
                // actionButtonClicked={(id, docLibrary) =>
                //   this.onActionMenuClicked(id, docLibrary)
                // }
                >
                  <RAFGrid4
                    gridId={listPageGridID}
                    isRemote
                    rowClick={this.rowClick}
                    customActionClick={this.manageDocPropertiesDialogOpen}
                    actionButtonClicked={(id, docLibrary) =>
                      this.onActionMenuClicked(id, docLibrary)
                    }
                    customClick={(rowData) => this.customClick(rowData)}
                    showEditColumn={false}
                    allowEditing={false}
                    allowSelection={false}
                    // allowFiltering={true}
                    gridTemplates={relatedDocumentListGridColumnTemplates}
                    emptyStateProps={{
                      title: "Get started by adding a document!",
                      iconClass: "fas fa-file-alt",
                    }}
                    allowFiltering={
                      isNotNullAndUndefined(getQueryString("filtertype")) &&
                        getQueryString("filtertype") === "qb"
                        ? true
                        : false
                    }
                    isRelatedSection
                    url={`${Constants.baseAPIUrl}ContentLibrary/RelatedList`}
                    {...(isNotNullAndUndefined(getQueryString("filtertype")) &&
                      getQueryString("filtertype") === "qb"
                      ? { filterType: "QueryBuilder" }
                      : {})}
                    additionalParams={[
                      {
                        key: "RelatedField",
                        value: propertyOf<ContentLibraryRow>("RelatedToUID"),
                      },
                      {
                        key: "RelatedFieldValue",
                        value: this.state.relatedToUID,
                      },
                      {
                        key: "RelatedToType",
                        value: this.state.relatedToType,
                      },
                    ]}
                    sortBy={{
                      field: "DisplayName",
                      order: "ascending",
                    }}
                    disableFirstColumnTemplate
                    cssClass="hide-tableHeader removeFirstRowMargin ListGrid"
                    showHeader={false}
                    // gridLines="None"
                    pageSizes={[12, 24, 60, 120]}
                    pageSize={12}
                  >
                    <RAFGridColumn<ContentLibraryRow>
                      field="DisplayName"
                      headerText="Process Name"
                      type={SFColumnType.string}
                    />
                  </RAFGrid4>
                </RAFGridTemplates>
              </RAFFieldStateProvider>
            </Suspense>
          </RAFAttributeRelatedListProvider>
        </RAFEntityProvider>
      </div>
    ) : (
      <RAFReportDataList
        url={`${Constants.baseAPIUrl}ContentLibrary/RelatedList`}
        //viewName='related_content_library'
        // additionalFilter={{
        //   Condition: "and",
        //   Rules: [
        //     //{
        //     //    Operator: RAFCustomOperator.Equal,
        //     //    Value: [this.state.relatedToUID],
        //     //    Field: propertyOf<ContentLibraryRow>('RelatedToUID'),
        //     //},
        //     {
        //       Operator: RAFCustomOperator.Equal,
        //       Value: [this.relatedEntity],
        //       Field: propertyOf<ContentLibraryRow>("Entity"),
        //     },
        //   ],
        // }}
        additionalParams={[
          {
            key: "RelatedField",
            value: propertyOf<ContentLibraryRow>("RelatedToUID"),
          },
          {
            key: "RelatedFieldValue",
            value: this.state.relatedToUID,
          },
          {
            key: "RelatedToType",
            value: this.state.relatedToType,
          },
        ]}
        sortBy={{
          field: "DisplayName",
          order: "ascending",
        }}
        isRelated
      >
        <RAFFieldStateProvider moduleName={RAFEntityName.ContentLibrary}>
          <RAFGridTemplates
          // actionButtonClicked={(id, docLibrary) =>
          //   this.onActionMenuClicked(id, docLibrary)
          // }
          >
            <RAFGridCC
              gridId={"ContentLibraryRow"}
              moduleName={this.moduleName}
              isRemote
              rowClick={this.rowClick}
              rowSelected={(e) => {
                this.RowSelected(e);
              }}
              showEditColumn={false}
              allowSelection={false}
              allowFiltering={this.props.allowFiltering}
              // allowFiltering={true}
              actionButtonClicked={(id, docLibrary) =>
                this.onActionMenuClicked(id, docLibrary)
              }
              gridTemplates={getGridColumnTemplatesByModule(this.moduleName)}
              emptyStateProps={{
                title: "No document found.",
              }}
              //emptyStateProps={{ title: "No results found.", body: "Try adjusting your search or changing your filter to find what you're looking for!", image: nodata_es }}
              // sortBy={{
              //     field: 'DisplayName',
              //     order: 'ascending',
              // }}
              //viewId={'related_content_library'}
              isRelatedSection={this.state.isRelatedSection}
              isAdaptive={this.props.isAdaptive}
              customClick={(rowData) => this.customClick(rowData)}
              disableFirstColumnTemplate={this.state.isRelatedSection}
            >
              <RAFGridColumn<ContentLibraryRow>
                field="DisplayName"
                headerText="Name"
                width="200px"
              />
              {/* <RAFGridColumn<ContentLibraryRow> field="Category" headerText="Category" width="120px" /> */}
              <RAFGridColumn<ContentLibraryRow>
                field="ModifiedBy"
                headerText="Modified By"
                width="120px"
              />
            </RAFGridCC>
          </RAFGridTemplates>
        </RAFFieldStateProvider>
      </RAFReportDataList>
    );
  };

  collapseCardContent = (listPageGridID, uploadBtn) => {
    return this.props.addBtnDisplayStyle === "SectionFooter" ? (
      <div className="section__container">
        <div className="section__div section__div__maxHeight customScrollBar">
          {this.gridListContent(listPageGridID)}
        </div>
        <div className="m-3 d-flex align-items-center justify-content-center">
          {uploadBtn}
        </div>
      </div>
    ) : (
      this.gridListContent(listPageGridID)
    );
  };

  getuploadBtnContent = () => {
    return (
      <RAFPermissionRender
        permissionName={this.props.createPermissionName ?? PermissionConstants.DocumentCreate}
      >
        <RAFButtonComponent
          action={RAFButtonConstant.Upload}
          {...(isNotNullAndUndefined(this.props.addBtnDisplayStyle) &&
            this.props.addBtnDisplayStyle === "SectionFooter"
            ? {}
            : {
              className: isNotNullAndUndefined(this.props.addBtnClassname)
                ? this.props.addBtnClassname
                : `${this.props.uiStyle === 'Card' ? '' : 'e-small d-none primary-custom-button d-sm-inline-block'}`,
            })}
          {...((this.props.addBtnDisplayStyle === "SectionFooter" || this.props.uiStyle === 'Card')
            ? { isPrimary: true }
            : {})}
          {...(isNotNullAndUndefined(this.props.addBtnContent)
            ? { btnContent: this.props.addBtnContent }
            : {})}
          onClick={() => this.uploadDocument()}
          {...(this.props.addBtnDisplayStyle === "SectionFooter"
            ? BrowserIsDevice
              ? { displayMode: "FabComponent" }
              : { uiMode: "FooterBtn" }
            : {})}
        ></RAFButtonComponent>
        {/* {isNotNullAndUndefined(this.props.addBtnClassname) ||
        this.props.addBtnDisplayStyle === "SectionFooter" ? (
          ""
        ) : (
          <RAFButtonComponent
            action={RAFButtonConstant.Upload}
            className="e-small primary-custom-button d-sm-none"
            onClick={() => this.uploadDocument()}
          ></RAFButtonComponent>
        )} */}
      </RAFPermissionRender>
    );
  };

  getUploadDocumentBtn = () => {
    if (this.state.enableAddBtn) {
      return (
        <div className="row g-2 flex-nowrap" id="divHeaderButtons">
          <div className="col-auto">{this.getuploadBtnContent()}</div>
          {this.props.showExpandBtn !== false ? (
            <div className="col-auto">
              <ButtonComponent
                type="button"
                cssClass={`${this.props.uiStyle === 'Card' ? 'btn_brand_primary' : 'primary-custom-button custom-button-md'}`}
                iconCss="fas fa-expand-alt"
                onClick={() => this.showDocumentLookupGridGrid()}
              ></ButtonComponent>
            </div>
          ) : null}
        </div>
      );
    } else {
      return null;
    }
  };

  render() {

    if (
      //isNotNullAndUndefined(this.state.relatedToUID) &&
      this.state.isActive === true
    ) {
      const showUploadDocumnetBtnOnly = this.props.showUploadDocumnetBtnOnly;
      let headerRowClassName;
      const listPageGridID = `grid3_${this.moduleName}_relatedList`;
      if (this.props.hideHeader) {
        headerRowClassName = `d-none`;
      }
      if (this.props.headerRowClassName)
        headerRowClassName = `${headerRowClassName} ${this.props.headerRowClassName}`;

      const titleText = 'Documents';

      return (
        <RAFPermissionRender permissionName={this.props.readPermissionName ?? PermissionConstants.DocumentRead}>
          {showUploadDocumnetBtnOnly === true ? (
            this.getuploadBtnContent()
          ) : this.props.showSectionHeader === false ? (
            this.collapseCardContent(listPageGridID, this.getuploadBtnContent())
          ) : (
            this.props.uiStyle === 'Card' ?
              <div>
                <div className="p-2 p-md-3">
                  <div className="row gx-2 align-items-center">
                    <div className="col">
                      <div className="header_5 ecllipseFirstLine">
                        <span>Additional Documents</span>
                      </div>
                    </div>
                    <div className="col-auto">{this.getuploadBtnContent()}</div>
                  </div>
                </div>
                <div className="overflow-auto customScrollBar pt-0 pt-md-0 p-2 p-md-3">
                  {this.collapseCardContent(listPageGridID, this.getuploadBtnContent())}
                </div>
              </div>
              :
              <div className="h-100" id={`documentDiv_${this.moduleName}`}>
                <RAFDataResultProvider>
                  <RAFCollapse
                    headerRowClassName={headerRowClassName}
                    title={titleText}
                    contentCardClassName="relatedListOuterDiv h-100"
                    layout={RAFLayout.OneColumnLayout}
                    iconName="file-alt"
                    customButton={this.getUploadDocumentBtn()}
                    IsCollapsed={
                      isNotNullAndUndefined(this.props.IsCollapsed)
                        ? this.props.IsCollapsed
                        : !Browser.isDevice
                          ? true
                          : true
                    }
                    Collapsible={this.props.Collapsible ?? true}
                    // IsCollapsed={!Browser.isDevice ? true : true}
                    headerBadge={this.headerBadge()}
                    arrowPosistion={this.props.collapsePanelArrowPosistion}
                    removeContentCardPadding
                    {...(this.props.contentCardClassName
                      ? { contentCardClassName: this.props.contentCardClassName }
                      : {})}
                    showSectionHeader={this.props.showSectionHeader}
                    contentRowClassName="h-100"
                    colClassName="h-100"
                  // contentRowClassName={this.props.contentRowClassName}
                  >
                    {this.collapseCardContent(listPageGridID, this.getuploadBtnContent())}
                  </RAFCollapse>
                </RAFDataResultProvider>
              </div>
          )}

          <Fragment>
            {this.state.showUploadContent === true && (
              <DialogComponent
                header={
                  this.state.isRevisedVersion
                    ? "Upload New Version"
                    : "Upload File"
                } //buttons={this.uploadDialogButtons}
                showCloseIcon
                visible={this.state.showUploadContent}
                cssClass="centerDialog-sm createEditForm form-center-dialog fixed-header"
                content={this.uploadAndLinkDocumentContent.bind(this)}
                isModal
                target="body"
                closeOnEscape={false}
                close={this.uploadDialogClose.bind(this)}
                animationSettings={this.animationSettings}
                id="manageUploadDocumentDialog"
              ></DialogComponent>
            )}
            {this.state.showfileDetailsContent && (
              <DialogComponent
                visible={this.state.showfileDetailsContent}
                cssClass={
                  "centerDialog centerDialog-md createEditForm full-height form-center-dialog dlg-new-style"
                }
                height="100%"
                content={this.fileDetailsContent.bind(this)}
                isModal
                target="body"
                closeOnEscape={false}
                id="pageFilePropertiesDialog"
                //overlayClick={this.fileDetailsDialogClose.bind(this)}
                close={this.fileDetailsDialogClose.bind(this)}
                animationSettings={this.animationSettings}
                zIndex={1100}
              ></DialogComponent>
            )}
            {this.state.showManageDocProperties && (
              <DialogComponent
                visible={this.state.showManageDocProperties}
                cssClass="rightDialog createEditForm full-height dlg-new-style"
                height="100%"
                content={this.manageDocPropertiesContent.bind(this)}
                isModal
                target="body"
                closeOnEscape={false}
                id="manageDocPropertiesDialog"
                overlayClick={this.manageDocPropertiesDialogClose.bind(this)}
                close={this.manageDocPropertiesDialogClose.bind(this)}
                animationSettings={this.animationSettings}
              ></DialogComponent>
            )}
          </Fragment>
          {this.state.showDocumentLookupGridContent && (
            <DialogComponent
              visible={this.state.showDocumentLookupGridContent}
              //cssClass="centerDialog-lg dlg-new-style"
              cssClass="rightDialog rightDialog-lg createEditForm full-height form-center-dialog"
              content={this.documentLookupGrid.bind(this)}
              isModal
              target="body"
              closeOnEscape={false}
              close={this.showDocumentLookupGridDialogClose.bind(this)}
            ></DialogComponent>
          )}
          <div>
            {this.state.showAcknowledgementTrackingDlg && (
              <div>
                <DialogComponent
                  visible={this.state.showAcknowledgementTrackingDlg}
                  cssClass="rightDialog rightDialog-lg createEditForm full-height form-center-dialog dlg-new-style"
                  id="shiftOffers_dialog"
                  content={this.acknowledgementTrackingDlgContent.bind(this)}
                  isModal
                  target="body"
                  closeOnEscape={false}
                  close={this.acknowledgementTrackingDlgContentClose.bind(
                    this
                  )}
                  open={PreventFocusOnDialogOpen}
                  zIndex={1200}
                ></DialogComponent>
              </div>
            )}
          </div>

          {/* {this.state.showUploadContent === true && (
            <DialogComponent
              header={
                this.state.isRevisedVersion
                  ? "Upload New Version"
                  : "Upload File"
              } //buttons={this.uploadDialogButtons}
              showCloseIcon
              visible={this.state.showUploadContent}
              cssClass="centerDialog-sm createEditForm form-center-dialog"
              content={this.uploadAndLinkDocumentContent.bind(this)}
              isModal
              target="body"
              closeOnEscape={false}
              close={this.uploadDialogClose.bind(this)}
              animationSettings={this.animationSettings}
              id="manageUploadDocumentDialog"
            ></DialogComponent>
          )} */}
        </RAFPermissionRender>
      );
    } else {
      return <div></div>;
    }
  }
}

export default withRouter(DocumentSummary);
