import { propertyOf } from "../../../../RAFComponents/helpers/utils";
import { LookUpRow } from "../../../../RAFComponents/models/CompositeTypes/LookUpRow";

export class IContentLibraryDto {
    UID?: string;
    DisplayName?: string;
    FileName?: string;
    Notes?: string;
    MimeType?: string;
    FileSize?: number;
    Revision?: number;
    DocumentType?: string;
    Parent?: string;
    ParentUID?: string;
    Content?: string;
    ContentUID?: string;
    Author?: string;
    AuthorUID?: string;
    RelatedTo?: string;
    RelatedToUID?: string;
    RelatedToType?: string;
    RelatedParentUID?: string;
    RelatedParentModule?: string;
    IsExternal?: boolean;
    IsSystem?: boolean;
    IsRevision?: boolean;
    Category?: string;
    Entity?: string;
    VersionUID?: string;
    TagsListJson?: string[];
    BusinessProcess?: string;
    BusinessProcessUID?: string;
    SecondaryRelatedToUID?: string;
    SecondaryRelatedTo?: string;
    SecondaryRelatedToType?: string;
    RecordCategory?: LookUpRow[];
    //RecordCategory?: string;
    //RecordCategoryUID?: string;
    ProcessStatus?: string;
    PageJson?: string;
    CurrentStatus?: string;
    RecordAction?: string;
    DueDate?: Date;
    RecordType?: string;
    ContentLibraryId?: string;
    Portal?: string;
    PortalUID?: string;
    CronExpression?: string;
    RruleExpression?: string;
    DeadlineUnits?: string;
    DeadlineInterval?: number;
    DeadlineCalculation?: string;
    Partner?: string;
    PartnerUID?: string;

    //IsActive?: boolean;
    TenantUID?: string;
    CreatedDate?: Date;
    CreatedBy?: string;
    LastActivityDate?: Date;
    ModifiedDate?: Date;
    ModifiedBy?: string;
    RecordOwnerUID?: string;
    RecordOwner?: string;
    PageUID?: string;
    Page?: string;
}

export class IContentLibraryRow {
    UID?: string;
    DisplayName?: string;
    FileName?: string;
    Notes?: string;
    MimeType?: string;
    FileSize?: number;
    Revision?: number;
    DocumentType?: string;
    Parent?: string;
    ParentUID?: string;
    Content?: string;
    ContentUID?: string;
    Author?: string;
    AuthorUID?: string;
    RelatedTo?: string;
    RelatedToUID?: string;
    RelatedToType?: string;
    RelatedParentUID?: string;
    RelatedParentModule?: string;
    IsExternal?: boolean;
    IsSystem?: boolean;
    IsRevision?: boolean;
    Category?: string;
    Entity?: string;
    VersionUID?: string;
    TagsListJson?: string[];
    BusinessProcess?: string;
    BusinessProcessUID?: string;
    SecondaryRelatedToUID?: string;
    SecondaryRelatedTo?: string;
    SecondaryRelatedToType?: string;
    RecordCategory?: LookUpRow[];
    //RecordCategory?: string;
    //RecordCategoryUID?: string;
    ProcessStatus?: string;
    PageJson?: string;
    CurrentStatus?: string;
    RecordAction?: string;
    DueDate?: Date;
    RecordType?: string;
    ContentLibraryId?: string;
    Portal?: string;
    PortalUID?: string;
    CronExpression?: string;
    RruleExpression?: string;
    DeadlineUnits?: string;
    DeadlineInterval?: number;
    DeadlineCalculation?: string;
    Partner?: string;
    PartnerUID?: string;

    //IsActive?: boolean;
    TenantUID?: string;
    CreatedDate?: Date;
    CreatedBy?: string;
    LastActivityDate?: Date;
    ModifiedDate?: Date;
    ModifiedBy?: string;
    RecordOwnerUID?: string;
    RecordOwner?: string;
    PageUID?: string;
    Page?: string;
}

export class ContentLibraryRow extends IContentLibraryRow {

    getClassName?() {
        return 'content_library';
    }
    getIdField?(): string {
        return propertyOf<ContentLibraryRow>("UID");
    }
    getSaveUrl?(): string {//use route name(entity displayname)
        return "ContentLibrary/Save";
    }
    getListUrl?(): string {//use route name(entity displayname)
        return "ContentLibrary/List";
    }
    getRelatedListUrl?(): string {//use route name(entity displayname)
        return "ContentLibrary/RelatedList";
    }
}
