import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import {
  FileInfo,
  SelectedEventArgs,
  UploaderComponent,
} from "@syncfusion/ej2-react-inputs";
import {
  DialogComponent
} from "@syncfusion/ej2-react-popups";
import React, { PropsWithChildren, Reducer, Suspense, useEffect, useReducer } from "react";
import { FormRenderProps } from "react-final-form";
import RAFDropdownCC from "../../../../RAFComponents/Inputs/RAFDropdownCC";
import {
  RetrieveRecord,
  getAllDynamicFormsByEntityName,
  getSessionStorage,
  hideProgress,
  showProgress,
} from "../../../../RAFComponents/helpers/AppHelper";
import {
  IDialogProps,
  IsNullOrWhiteSpace,
  RAFFormComponentProps,
  getSaveRequest,
  isNotEmptyArray,
  isNotNullAndUndefined,
  isNullOrUndefined,
  lazyRetry,
  propertyOf
} from "../../../../RAFComponents/helpers/utils";
import ACDatePicker from "../../../../components/shared/ACFormFields/ACDatePicker";
import ACDropdown from "../../../../components/shared/ACFormFields/ACDropdown";
import ACTextBox from "../../../../components/shared/ACFormFields/ACTextBox";
import ACLoadingPanel from "../../../../components/shared/ACLoadingPanel";
import {
  ContentType,
  RAFButtonConstant,
  RAFDocumentType,
  RAFLayout,
  StorageKey,
} from "../../../../constants/Common/Constants";
// import RAFChoiceOption from "../../../../RAFComponents/Inputs/RAFChoiceOption";
import {
  Condition,
  ConditionNotNullOrWhiteSpace,
} from "../../../../RAFComponents/Inputs/RAFForm";
import {
  getFormValue,
  setFormValue,
} from "../../../../RAFComponents/Inputs/RFFUtils";
import RAFAttributeRelatedListProvider from "../../../../RAFComponents/Providers/RAFAttributeRelatedListProvider";
import { QueryAttributeJM } from "../../../../RAFComponents/models/Common/QueryAttributeJM";
import { RAFDataType } from "../../../../RAFComponents/models/Common/RAFDataType";
import { LookUpRow } from "../../../../RAFComponents/models/CompositeTypes/LookUpRow";
import * as repositoryActions from "../../../../RAFComponents/store/actions/repositoryActions";
import { BusinessProcessRow } from "../../../../RAFMaster/RMModules/BusinessProcess/Process/BusinessProcessRow";
import { BusinessProcessStepTemplateRow } from "../../../../RAFMaster/RMModules/BusinessProcess/StepTemplate/BusinessProcessStepTemplateRow";
import { BusinessProcessTemplateRow } from "../../../../RAFMaster/RMModules/BusinessProcess/Template/BusinessProcessTemplateRow";
import {
  RetrieveBusinessProcessTemplate,
  StartProcessAndSaveProcessSteps,
} from "../../../../RAFMaster/RMModules/BusinessProcess/helpers/ProcessHelper";
import {
  getBPTemplatesByEntityName,
  getEntityByName,
} from "../../../../RAFMaster/helpers/RMutils";
import {
  RAFBPStatus,
  RAFDeadlineCalculation,
  RAFExecutionOrder,
  RAFPageType,
} from "../../../../constants/Common/RMConstants";
import CreateContent from "../../../Common/Create/CreateContent";
import RAFEntityProvider from "../../../Common/Providers/RAFEntityProvider";
import RAFObjectContextProvider, {
  RAFObjectContext,
} from "../../../Common/Providers/RAFObjectContextProvider";
import RAFPageLayoutProvider from "../../../Common/Providers/RAFPageLayoutProvider";
// import ManageRecordCategory from "../../RecordCategory/ManageRecordCategory";
import * as R from "ramda";
import RAFLookupCC from "../../../../RAFComponents/Inputs/RAFLookupCC";
import RAFTagInput from "../../../../RAFComponents/Inputs/RAFTagInput";
import RAFTextArea from "../../../../RAFComponents/Inputs/RAFTextArea";
import RAFButtonComponent from "../../../../RAFComponents/Navigation/RAFButtonComponent";
import RAFDeletedRecordState from "../../../../RAFComponents/Navigation/RAFDeletedRecordState";
import {
  showSuccessToast,
  showWarningToast,
} from "../../../../RAFComponents/Utility/RAFToastComponent";
import RAFPermissionRender from "../../../../RAFComponents/helpers/PermissionHelper";
import { EntityRow } from "../../../../RAFMaster/RMModules/Entity/EntityRow";
import ACTextArea from "../../../../components/shared/ACFormFields/ACTextArea";
import { CareEsioEntity } from "../../../../constants/CareESIO/CareEsioConstants";
import { RAFEntityName } from "../../../../constants/Common/EntityConstants";
import { PermissionConstants } from "../../../../constants/Common/PermissionConstants";
import { ChecklistItemTransRow } from "../../../CareESIO/ChecklistItemTrans/ChecklistItemTransRow";
import { ActivityRow } from "../../Activity/ActivityRow";
import { getRecordCategoryByUID } from "../../RecordCategory/RecordCategoryHelper";
import { RecordCategoryRow } from "../../RecordCategory/RecordCategoryRow";
import { ContentLibraryRow } from "../Library/ContentLibraryRow";
import {
  ContentLibraryCurrentStatus,
  DocumentCategoryRow,
  getCompressedImage,
  getDocumentModuleNameByRelatedEntity,
  retrieveDocumentByFileName,
  retrieveDocumentByID,
} from "../Library/DocumentHelper";
import { onSubmitDocumentSubForm, updateChecklistItemTransAndCreateActivityObjectData } from "./ManageDocumentHelper";

const RAFChoiceOption = React.lazy(() =>
  lazyRetry(
    () => import("../../../../RAFComponents/Inputs/RAFChoiceOption"),
    "RAFChoiceOption"
  )
);
const RAFForm = React.lazy(() =>
  lazyRetry(() => import("../../../../RAFComponents/Inputs/RAFForm"), "RAFForm")
);

interface IProps {
  isActive: boolean;
  mode: "create" | "updateVersion";
  allowDiffrentFileExtensions?: boolean;
  selectedDocumentRow?: ContentLibraryRow;
  selectedFolderRow?: ContentLibraryRow;
  selectedDocumentUID?: string;
  rootDocFolderRow?: ContentLibraryRow;
  selectedFolder?: ContentLibraryRow;
  relatedTo?: string;
  relatedToUID?: string;
  relatedToType?: string;
  relatedEntity?: string;
  secondaryRelatedToUID?: string;
  secondaryRelatedTo?: string;
  secondaryRelatedToType?: string;
  selectedRecordCategory?: DocumentCategoryRow;
  selectedRecordCategoryUID?: string;
  onProcessStart?: (relatedUID?: string) => void;
  allowSelectRecordCategory?: boolean;
  checkDuplicate?: boolean;
  hasExpiryDateRequired?: boolean;
  showDocFolderlookUp?: boolean;
  saveContextObject?: any;
  contextObject?: any;
  onUploadCompleted?: (
    objectId?: string,
    contentLibraryRow?: ContentLibraryRow
  ) => void;
  isDynamic?: boolean;
  onSave?: (entityId?: string, objectName?: string, contentLibraryRow?: ContentLibraryRow) => void;

  createPermissionName?: string;
  updatePermissionName?: string;
  paddingClass?: string;
  footerPadding?: string;
}

interface IState {
  isLoading: boolean;
  noContent: boolean;
  contentLibraryRow: ContentLibraryRow;
  existingDocument: ContentLibraryRow;
  fileUploadObj: any;
  showAlertDialog: boolean;
  bpTemplates?: BusinessProcessTemplateRow[];
  bpTemplate?: BusinessProcessTemplateRow;
  recordCategoryRow?: RecordCategoryRow;
  allDocumentEnableForms: EntityRow[];
  selectedRelatedForm: EntityRow;
  relatedTo?: string;
  relatedToType?: string;
  relatedToUID?: string;
  showStartProcessContent?: boolean;
  checkDuplicate?: boolean;
}

function RAFManageUploadDocumentFile({
  allowDiffrentFileExtensions = false,
  ...props
}: PropsWithChildren<
  IDialogProps & RAFFormComponentProps & IProps>) {

  let rafForm: FormRenderProps | null;
  let rafRelatedDocumentForm: FormRenderProps | null;

  let fileUploadObj: UploaderComponent;

  const [state, setState] = useReducer<Reducer<IState, Partial<IState>>>(
    (state, newState) => ({ ...state, ...newState }),
    {
      isLoading: true,
      noContent: true,
      contentLibraryRow: null,
      existingDocument: null,
      fileUploadObj: null,
      recordCategoryRow: null,
      showAlertDialog: false,
      bpTemplates: [],
      relatedTo: null,
      relatedToType: null,
      relatedToUID: null,
      allDocumentEnableForms: null,
      selectedRelatedForm: null,
      showStartProcessContent: false,
      checkDuplicate: isNotNullAndUndefined(props.checkDuplicate)
        ? props.checkDuplicate
        : true,
    }
  );

  useEffect(() => {
    refresh();
  }, [
    props.isActive,
    props.mode,
    props.selectedFolder,
    props.selectedRecordCategory,
    props.selectedRecordCategoryUID

  ]);

  const refresh = () => {
    if (props.isActive) {
      setState({ isLoading: true, noContent: true });
      let progressDiv = showProgress("#manageUploadDocumentDialog");
      getEntityByName(RAFEntityName.ContentLibrary).then((entity) => {
        let enableProcess =
          isNotNullAndUndefined(entity) &&
            isNotNullAndUndefined(entity.EntitySettings) &&
            isNotNullAndUndefined(entity.EntitySettings.EnableProcess)
            ? entity.EntitySettings.EnableProcess
            : false;
        getBPTemplatesByEntityName(
          entity.EntityName,
          enableProcess === true ? false : true
        ).then((bpTemplates) => {
          let { selectedRecordCategory, selectedRecordCategoryUID } =
            props;
          let recordCategoryUID = isNotNullAndUndefined(
            selectedRecordCategoryUID
          )
            ? selectedRecordCategoryUID
            : isNotNullAndUndefined(selectedRecordCategory) &&
              isNotNullAndUndefined(selectedRecordCategory.UID)
              ? selectedRecordCategory.UID
              : null;
          //if (isNotNullAndUndefined(bpTemplates && bpTemplates.length > 0)) {
          getRecordCategoryByUID(recordCategoryUID).then(
            async (recordCategoryRow) => {
              const allDocumentEnableForms =
                await getAllDynamicFormsByEntityName(
                  RAFEntityName.ContentLibrary
                );

              const contentLibraryRow: ContentLibraryRow = await setContentLibraryData(recordCategoryRow);
              let selectedRelatedForm: EntityRow;
              if (isNotNullAndUndefined(contentLibraryRow.RecordType)) {
                selectedRelatedForm = isNotEmptyArray(allDocumentEnableForms)
                  ? allDocumentEnableForms.find(
                    (x) => x.DisplayName === contentLibraryRow.RecordType
                  )
                  : null;
              }
              hideProgress(progressDiv);
              setState({
                isLoading: false,
                contentLibraryRow,
                noContent: false,
                bpTemplates,
                recordCategoryRow,
                allDocumentEnableForms,
                selectedRelatedForm,
              });
            }
          );
          //}
        });
      });
    }
  };

  const setContentLibraryData = (selectedRecordCategory: RecordCategoryRow) => {
    return new Promise<ContentLibraryRow>(async (resolve) => {
      const { selectedDocumentRow, selectedFolder, mode, selectedDocumentUID } = props;

      let contentLibraryRow = isNotNullAndUndefined(selectedDocumentUID)
        ? await RetrieveRecord(selectedDocumentUID, RAFEntityName.ContentLibrary) :
        isNotNullAndUndefined(selectedDocumentRow)
          ? selectedDocumentRow
          : new ContentLibraryRow();

      if (isNullOrUndefined(selectedDocumentRow) && mode === "create") {
        if (isNotNullAndUndefined(selectedRecordCategory)) {
          if (isNotNullAndUndefined(selectedRecordCategory.UID)) {
            //contentLibraryRow.RecordCategory = selectedRecordCategory.Title;
            //contentLibraryRow.RecordCategoryUID = selectedRecordCategory.UID;
            let recordCategory: LookUpRow[] = [
              {
                UID: selectedRecordCategory.UID,
                Value: selectedRecordCategory.Title,
              },
            ];
            contentLibraryRow.RecordCategory = recordCategory;
            contentLibraryRow["DocumentCategory"] = selectedRecordCategory.Title;
            contentLibraryRow["DocumentCategoryUID"] = selectedRecordCategory.UID;
            contentLibraryRow.RecordType = selectedRecordCategory.RecordType;
          }
          if (
            isNotNullAndUndefined(selectedRecordCategory.BusinessProcessUID) &&
            selectedRecordCategory.EnableProcess === true
          ) {
            contentLibraryRow.BusinessProcess =
              selectedRecordCategory.BusinessProcess;
            contentLibraryRow.BusinessProcessUID =
              selectedRecordCategory.BusinessProcessUID;
          }
        }

        contentLibraryRow.SecondaryRelatedTo = isNotNullAndUndefined(
          props.secondaryRelatedTo
        )
          ? props.secondaryRelatedTo
          : null;
        contentLibraryRow.SecondaryRelatedToType = isNotNullAndUndefined(
          props.secondaryRelatedToType
        )
          ? props.secondaryRelatedToType
          : null;
        contentLibraryRow.SecondaryRelatedToUID = isNotNullAndUndefined(
          props.secondaryRelatedToUID
        )
          ? props.secondaryRelatedToUID
          : null;

        //if (IsNullOrWhiteSpace(contentLibraryRow.RecordCategoryUID)) {
        //contentLibraryRow.RecordCategory = 'General';
        //}
        contentLibraryRow.CurrentStatus = ContentLibraryCurrentStatus.Published;

        if (
          isNotNullAndUndefined(selectedFolder) &&
          isNotNullAndUndefined(selectedFolder.UID)
        ) {
          contentLibraryRow.ParentUID = selectedFolder.UID;
          contentLibraryRow.Parent = selectedFolder.DisplayName;
          let moduleName = null;
          if (isNotNullAndUndefined(selectedFolder)) {
            if (selectedFolder.IsExternal) {
              if (isNotNullAndUndefined(selectedFolder.FileName))
                moduleName = selectedFolder.FileName;
            } else if (isNotNullAndUndefined(selectedFolder.Entity)) {
              moduleName = selectedFolder.Entity;
            }
          }
          contentLibraryRow.Entity = moduleName;
        }
      }

      resolve(contentLibraryRow);
    });
  };

  //submit document form start
  const onSubmitUploadDocument = async (
    mode: "uploadNew" | "isRevision" | "updateExisting",
    existingDocument?: ContentLibraryRow
  ) => {
    let progressDiv =
      state.showAlertDialog === true
        ? showProgress("#alertDialog")
        : showProgress("#manageUploadDocumentDialog");
    const { relatedTo, relatedToUID } = props;
    const selectedDocumentRow: ContentLibraryRow = R.clone(state.contentLibraryRow);

    let allowSubmit = true;

    let fileData = isNotNullAndUndefined(fileUploadObj)
      ? fileUploadObj.getFilesData()
      : isNotNullAndUndefined(state.fileUploadObj)
        ? state.fileUploadObj
        : null;

    let fileExtensions =
      mode === "isRevision" && isNotNullAndUndefined(selectedDocumentRow)
        ? selectedDocumentRow.MimeType
        : null;
    if (isNotEmptyArray(fileData)) {
      fileData.forEach((item) => {
        let file = item.rawFile;
        if (mode === "isRevision") {
          if (allowDiffrentFileExtensions === true) {

          } else if (file.type !== fileExtensions) {
            showWarningToast(
              "File extension is different. Use the same file extension for version uploads"
            );
            allowSubmit = false;
          }
        }
      });
    } else {
      allowSubmit = false;
    }

    if (allowSubmit === true) {
      const relatedRecordResponse =
        isNullOrUndefined(relatedTo) && isNullOrUndefined(relatedToUID)
          ? await saveContextObject()
          : null;

      const formData = await getDocumentSubmitFormData(
        mode,
        existingDocument,
        relatedRecordResponse
      );

      if (isNotNullAndUndefined(formData)) {
        repositoryActions
          .postDataAndGetResponse(
            "ContentLibrary/Upload",
            formData,
            { ...props },
            ContentType.applicationFormUrlEncoded
          )
          .then(async (response) => {
            //hideProgress(progressDiv);
            if (
              isNotNullAndUndefined(response) &&
              isNotNullAndUndefined(response.data) &&
              isNotNullAndUndefined(response.data.documentUIDs) &&
              isNotNullAndUndefined(response.data.documentUIDs[0]) &&
              isNotNullAndUndefined(response.data.documentUIDs[0].EntityId)
            ) {
              if (props.relatedToType === CareEsioEntity.ChecklistItemTrans.EntityName) {
                const documentFormValue = getFormValue(rafForm);
                const updateChecklistItemTransAndCreateActivityObjectDataResponse = await updateChecklistItemTransAndCreateActivityObjectData(documentFormValue, response.data.documentUIDs[0].EntityId, props.relatedToUID);
              }
              let UID = response.data.documentUIDs[0].EntityId;
              if (mode === "uploadNew") {
                retrieveDocumentByID(UID).then((contentLibraryResponse) => {
                  let contentLibraryRow: ContentLibraryRow = getFormValue(
                    rafForm
                  );

                  let businessProcess = contentLibraryRow.BusinessProcess;
                  let businessProcessUID = contentLibraryRow.BusinessProcessUID;
                  if (
                    isNotNullAndUndefined(businessProcess) &&
                    isNotNullAndUndefined(businessProcessUID) &&
                    isNullOrUndefined(contentLibraryRow.ProcessStatus)
                  ) {
                    hideProgress(progressDiv);
                    contentLibraryResponse.BusinessProcess = businessProcess;
                    contentLibraryResponse.BusinessProcessUID =
                      businessProcessUID;
                    contentLibraryResponse.UID = UID;

                    setState(
                      {
                        showAlertDialog: false,
                        contentLibraryRow: contentLibraryResponse,
                        relatedTo: contentLibraryResponse.DisplayName,
                        relatedToUID: UID,
                        relatedToType: RAFEntityName.ContentLibrary,
                      });
                    let bpTemplateUID = businessProcessUID;
                    if (isNotNullAndUndefined(bpTemplateUID)) {
                      RetrieveBusinessProcessTemplate(bpTemplateUID).then(
                        (bpTemplate) => {
                          if (
                            isNotNullAndUndefined(
                              bpTemplate.ProcessDeadlineCalculation
                            ) &&
                            bpTemplate.ProcessDeadlineCalculation ===
                            RAFDeadlineCalculation.PromptUserWhenRun
                          ) {
                            startProcessDialogOpen(bpTemplate);
                          } else {
                            let progressDiv = showProgress(
                              "#manageUploadDocumentDialog"
                            );
                            StartProcessAndSaveProcessSteps(
                              {
                                bpRelatedTo:
                                  contentLibraryRow.DisplayName,
                                bpRelatedToType:
                                  RAFEntityName.ContentLibrary,
                                bpRelatedToUID: UID,
                              },
                              bpTemplate
                            ).then((bpResponse) => {
                              hideProgress(progressDiv);
                              onProcessStart(
                                bpResponse.bpRow,
                                bpResponse.bpStepTemplateRows
                              );
                            });
                          }
                        }
                      );
                    }
                  } else {
                    let allowSubmit = true;
                    if (isNotNullAndUndefined(state.selectedRelatedForm)) {
                      if (
                        isNotNullAndUndefined(rafRelatedDocumentForm) &&
                        rafRelatedDocumentForm.invalid === true
                      ) {
                        allowSubmit = false;
                        hideProgress(progressDiv);
                        rafRelatedDocumentForm.form.submit();
                      }
                      if (allowSubmit) {
                        onSubmitDocumentSubForm(
                          contentLibraryResponse,
                          state.selectedRelatedForm,
                          getFormValue(rafRelatedDocumentForm)
                        ).then((formObjectId) => {
                          if (isNotNullAndUndefined(formObjectId)) {
                            setState(
                              { showAlertDialog: false },
                            );
                            showSuccessToast(
                              "File Uploaded Successfully"
                            );

                            hideProgress(progressDiv);
                            if (props.onSave) props.onSave(UID);
                            if (props.onUploadCompleted) {
                              if (isNotNullAndUndefined(relatedToUID))
                                props.onUploadCompleted(
                                  relatedToUID,
                                  contentLibraryResponse
                                );
                              else if (
                                isNotNullAndUndefined(
                                  relatedRecordResponse
                                ) &&
                                isNotNullAndUndefined(
                                  relatedRecordResponse.objectUID
                                )
                              )
                                props.onUploadCompleted(
                                  relatedRecordResponse.objectUID,
                                  contentLibraryResponse
                                );
                              else props.onUploadCompleted(null);
                            }
                          } else {
                            setState(
                              { showAlertDialog: false },
                            );

                            showSuccessToast(
                              "File Uploaded Successfully"
                            );

                            hideProgress(progressDiv);
                            if (props.onSave) props.onSave(UID);
                            if (props.onUploadCompleted) {
                              if (isNotNullAndUndefined(relatedToUID))
                                props.onUploadCompleted(
                                  relatedToUID,
                                  contentLibraryResponse
                                );
                              else if (
                                isNotNullAndUndefined(
                                  relatedRecordResponse
                                ) &&
                                isNotNullAndUndefined(
                                  relatedRecordResponse.objectUID
                                )
                              )
                                props.onUploadCompleted(
                                  relatedRecordResponse.objectUID,
                                  contentLibraryResponse
                                );
                              else props.onUploadCompleted(null);
                            }
                          }
                        });
                      }
                    } else {
                      setState(
                        { showAlertDialog: false },
                      );
                      showSuccessToast("File Uploaded Successfully");

                      hideProgress(progressDiv);
                      if (props.onSave) props.onSave(UID);
                      if (props.onUploadCompleted) {
                        if (isNotNullAndUndefined(relatedToUID))
                          props.onUploadCompleted(
                            relatedToUID,
                            contentLibraryResponse
                          );
                        else if (
                          isNotNullAndUndefined(relatedRecordResponse) &&
                          isNotNullAndUndefined(
                            relatedRecordResponse.objectUID
                          )
                        )
                          props.onUploadCompleted(
                            relatedRecordResponse.objectUID,
                            contentLibraryResponse
                          );
                        else props.onUploadCompleted(null);
                      }
                    }
                  }
                });
              } else {
                setState({ showAlertDialog: false });
                showSuccessToast("File Uploaded Successfully");

                if (props.onSave) props.onSave(UID);
                if (props.onUploadCompleted) {
                  if (isNotNullAndUndefined(relatedToUID))
                    props.onUploadCompleted(relatedToUID);
                  else if (
                    isNotNullAndUndefined(relatedRecordResponse) &&
                    isNotNullAndUndefined(relatedRecordResponse.objectUID)
                  )
                    props.onUploadCompleted(
                      relatedRecordResponse.objectUID
                    );
                  else props.onUploadCompleted(null);
                }
              }
            } else {
              showWarningToast("Sorry something went wrong !");
            }
          })
          .catch((error) => error);
      } else {
        hideProgress(progressDiv);
        showWarningToast("Sorry something went wrong !");
      }
    } else {
      hideProgress(progressDiv);
    }
  };

  const saveContextObject = () => {
    return new Promise<{
      objectUID: string;
      objectName: string;
      moduleName: string;
      relatedToType: string;
    }>(async (resolve, reject) => {
      if (isNotNullAndUndefined(props.saveContextObject)) {
        let progressDiv = showProgress(".body", true);
        const rafFormContextValue = props.contextObject;
        props.saveContextObject(rafFormContextValue).then((response) => {
          hideProgress(progressDiv);
          if (
            isNotNullAndUndefined(response) &&
            isNotNullAndUndefined(response.objectUID)
          ) {
            const relatedRecordResponse = {
              objectUID: response.objectUID,
              objectName: response.objectName,
              moduleName: null,
              relatedToType: null,
            };
            resolve(relatedRecordResponse);
          } else {
            resolve(null);
          }
        });
      }
      // else {
      //   if (
      //     isNotNullAndUndefined(rafRelatedDocumentForm) &&
      //     isNotNullAndUndefined(state.selectedRelatedForm)
      //   ) {
      //     let progressDiv =
      //       state.showAlertDialog === true
      //         ? showProgress("#alertDialog")
      //         : showProgress("#manageUploadDocumentDialog");
      //     let { selectedRelatedForm } = state;
      //     let entity = await getEntityByObjectName({
      //       ObjectName: ConvertSystemName(selectedRelatedForm.EntityName),
      //     });
      //     let response = await submitRAFForm(
      //       getFormValue(rafRelatedDocumentForm),
      //       null,
      //       entity,
      //       selectedRelatedForm
      //     );
      //     hideProgress(progressDiv);
      //     const relatedRecordResponse = {
      //       objectUID: response.objectUID,
      //       objectName: response.objectName,
      //       moduleName: null,
      //       relatedToType: selectedRelatedForm.EntityName,
      //     };
      //     resolve(relatedRecordResponse);
      //   } else {
      //     resolve(null);
      //   }
      // }
      else {
        resolve(null);
      }
    });
  };

  const getDocumentSubmitFormData = (
    mode: "uploadNew" | "isRevision" | "updateExisting",
    existingDocument: ContentLibraryRow,
    relatedRecordResponse: {
      objectUID: string;
      objectName: string;
      moduleName: string;
      relatedToType: string;
    }
  ) => {
    return new Promise<FormData>(async (resolve) => {
      const selectedDocumentRow: ContentLibraryRow = R.clone(state.contentLibraryRow);
      const formData = new FormData();

      let fileData = isNotNullAndUndefined(fileUploadObj)
        ? fileUploadObj.getFilesData()
        : isNotNullAndUndefined(state.fileUploadObj)
          ? state.fileUploadObj
          : null;

      let selectedFileData = null;
      if (isNotEmptyArray(fileData)) {
        fileData.forEach((item) => {
          let file = item.rawFile;
          selectedFileData = file;
        });
      }

      const compressedImg = await getCompressedImage(R.clone(selectedFileData));

      formData.append("file", isNotNullAndUndefined(compressedImg) ? compressedImg : selectedFileData);

      let fileUID;
      let fileDisplayName = getFormValue(rafForm, "DisplayName");
      let fileName;
      let parentUID = getFormValue(rafForm, "ParentUID");
      let parent = getFormValue(rafForm, "Parent");

      let businessProcess;
      let businessProcessUID;
      let processStatus;

      let moduleName =
        mode === "uploadNew" ? getFormValue(rafForm, "Entity") : null;

      if (IsNullOrWhiteSpace(moduleName)) {
        moduleName = isNotNullAndUndefined(props.relatedEntity)
          ? getDocumentModuleNameByRelatedEntity(props.relatedEntity)
          : null;
      }

      let relatedToUID = isNotNullAndUndefined(props.relatedToUID)
        ? props.relatedToUID
        : null;
      let relatedTo = isNotNullAndUndefined(props.relatedTo)
        ? props.relatedTo
        : null;
      let relatedToType = isNotNullAndUndefined(props.relatedToType)
        ? props.relatedToType
        : null;

      let secondaryRelatedTo = isNotNullAndUndefined(
        props.secondaryRelatedTo
      )
        ? props.secondaryRelatedTo
        : null;
      let secondaryRelatedToUID = isNotNullAndUndefined(
        props.secondaryRelatedToUID
      )
        ? props.secondaryRelatedToUID
        : null;
      let secondaryRelatedToType = isNotNullAndUndefined(
        props.secondaryRelatedToType
      )
        ? props.secondaryRelatedToType
        : null;

      if (mode === "uploadNew") {
        fileUID = null;
        //fileDisplayName = null;
        // if (isNotNullAndUndefined(selectedFolder)) {
        //     if (IsNullOrWhiteSpace(parentUID)) {
        //         parentUID = selectedFolder.UID;
        //         parent = selectedFolder.FileName;
        //     }
        //     if (selectedFolder.IsExternal) {
        //         if (isNotNullAndUndefined(selectedFolder.FileName))
        //             moduleName = selectedFolder.FileName;
        //     } else if (isNotNullAndUndefined(selectedFolder.Entity)) {
        //         moduleName = selectedFolder.Entity;
        //     }
        // }
        businessProcess = getFormValue(rafForm, "BusinessProcess");
        businessProcessUID = getFormValue(rafForm, "BusinessProcessUID");
        processStatus = getFormValue(rafForm, "ProcessStatus");
      }

      if (mode === "updateExisting") {
        if (isNotNullAndUndefined(existingDocument)) {
          fileUID = existingDocument.UID;
          fileDisplayName = IsNullOrWhiteSpace(fileDisplayName)
            ? existingDocument.DisplayName
            : fileDisplayName;
          fileName = existingDocument.FileName;
          parentUID = existingDocument.ParentUID;
          parent = existingDocument.Parent;
          moduleName = existingDocument.Entity;
          relatedToUID = existingDocument.RelatedToUID;
          relatedTo = existingDocument.RelatedTo;
          relatedToType = existingDocument.RelatedToType;
          secondaryRelatedTo = existingDocument.SecondaryRelatedTo;
          secondaryRelatedToUID = existingDocument.SecondaryRelatedToUID;
          secondaryRelatedToType = existingDocument.SecondaryRelatedToType;
          businessProcess = existingDocument.BusinessProcess;
          businessProcessUID = existingDocument.BusinessProcessUID;
          processStatus = existingDocument.ProcessStatus;
        }
      }

      if (mode === "isRevision") {
        if (isNotNullAndUndefined(selectedDocumentRow)) {
          fileUID = selectedDocumentRow.UID;
          fileDisplayName = IsNullOrWhiteSpace(fileDisplayName)
            ? selectedDocumentRow.DisplayName
            : fileDisplayName;
          fileName = selectedDocumentRow.FileName;
          moduleName = selectedDocumentRow.Entity;
          parentUID = selectedDocumentRow.ParentUID;
          parent = selectedDocumentRow.Parent;
          relatedToUID = selectedDocumentRow.RelatedToUID;
          relatedTo = selectedDocumentRow.RelatedTo;
          relatedToType = selectedDocumentRow.RelatedToType;
          secondaryRelatedTo = selectedDocumentRow.SecondaryRelatedTo;
          secondaryRelatedToUID = selectedDocumentRow.SecondaryRelatedToUID;
          secondaryRelatedToType = selectedDocumentRow.SecondaryRelatedToType;
          businessProcess = selectedDocumentRow.BusinessProcess;
          businessProcessUID = selectedDocumentRow.BusinessProcessUID;
          processStatus = selectedDocumentRow.ProcessStatus;
        }
      }

      if (
        isNullOrUndefined(relatedTo) &&
        isNullOrUndefined(relatedToUID) &&
        isNotNullAndUndefined(relatedRecordResponse)
      ) {
        relatedTo = relatedRecordResponse.objectName;
        relatedToUID = relatedRecordResponse.objectUID;
        if (isNotNullAndUndefined(relatedRecordResponse.relatedToType))
          relatedToType = relatedRecordResponse.relatedToType;
        if (isNotNullAndUndefined(relatedRecordResponse.moduleName))
          moduleName = relatedRecordResponse.moduleName;
      }

      if (
        isNotNullAndUndefined(parentUID) ||
        isNotNullAndUndefined(relatedToUID)
      ) {

        if (mode === 'isRevision' && allowDiffrentFileExtensions === true) {
          fileName = getFormValue(rafForm, "DisplayName");
          fileDisplayName = getFormValue(rafForm, "DisplayName");
        }

        if (mode === "updateExisting" || mode === "isRevision") {
          formData.append("UID", fileUID);
          //formData.append("DisplayName", fileDisplayName);
          formData.append("FileName", fileName);
          formData.append("IsRevision", "true");
        }

        if (isNotNullAndUndefined(fileDisplayName))
          formData.append("DisplayName", fileDisplayName);

        if (isNotNullAndUndefined(relatedToUID))
          formData.append("RelatedToUID", relatedToUID);

        if (isNotNullAndUndefined(relatedTo))
          formData.append("RelatedTo", relatedTo);

        if (isNotNullAndUndefined(relatedToType))
          formData.append("RelatedToType", relatedToType);

        if (isNotNullAndUndefined(secondaryRelatedTo))
          formData.append("SecondaryRelatedTo", secondaryRelatedTo);

        if (isNotNullAndUndefined(secondaryRelatedToUID))
          formData.append("SecondaryRelatedToUID", secondaryRelatedToUID);

        if (isNotNullAndUndefined(secondaryRelatedToType))
          formData.append("SecondaryRelatedToType", secondaryRelatedToType);

        if (isNotNullAndUndefined(parentUID))
          formData.append("ParentUID", parentUID);

        if (isNotNullAndUndefined(parent)) formData.append("Parent", parent);

        if (isNotNullAndUndefined(businessProcess))
          formData.append("BusinessProcess", businessProcess);
        if (isNotNullAndUndefined(businessProcessUID))
          formData.append("BusinessProcessUID", businessProcessUID);
        if (isNotNullAndUndefined(processStatus))
          formData.append("ProcessStatus", processStatus);

        formData.append("Entity", moduleName);
        formData.append(
          "CurrentStatus",
          getFormValue(rafForm, "CurrentStatus")
        );

        const tagsListJson = getFormValue(
          rafForm,
          propertyOf<ContentLibraryRow>("TagsListJson")
        );
        if (isNotEmptyArray(tagsListJson)) {
          formData.append(
            propertyOf<ContentLibraryRow>("TagsListJson"),
            JSON.stringify(tagsListJson)
          );
        }

        const allViewAttributeStorage = getSessionStorage(
          StorageKey.viewAttribs_modulename + RAFEntityName.ContentLibrary,
          true
        );
        let extraInfoAttributes: QueryAttributeJM[] = [];
        let viewAttributes: QueryAttributeJM[] = [];
        if (
          isNotNullAndUndefined(allViewAttributeStorage) &&
          !IsNullOrWhiteSpace(allViewAttributeStorage)
        ) {
          viewAttributes = JSON.parse(allViewAttributeStorage);
        }

        if (
          isNotNullAndUndefined(viewAttributes) &&
          viewAttributes.length > 0
        ) {
          extraInfoAttributes =
            viewAttributes &&
            viewAttributes.filter(
              (x) =>
                x.AttributeName === RAFEntityName.ExtraInfoJson &&
                x.DataType !== RAFDataType.Form
            );
        }

        if (
          isNotNullAndUndefined(extraInfoAttributes) &&
          extraInfoAttributes.length > 0
        ) {
          let extraInfoValue = {};

          extraInfoAttributes.forEach((item) => {
            if (
              isNotNullAndUndefined(
                getFormValue(rafForm, item.PropertyName)
              )
            ) {
              extraInfoValue = {
                ...extraInfoValue,
                [item.PropertyName]: getFormValue(
                  rafForm,
                  item.PropertyName
                ),
              };
            }
          });
          let extraInfo = JSON.stringify(extraInfoValue);
          formData.append("ExtraInfoJson", extraInfo);
        }

        if (isNotNullAndUndefined(getFormValue(rafForm, "Notes")))
          formData.append("Notes", getFormValue(rafForm, "Notes"));

        if (mode === "uploadNew" || mode === "updateExisting") {
          let recordCategoryUID = null;
          let recordCategory = null;
          let formRC: LookUpRow[] = isNotEmptyArray(
            getFormValue(rafForm, "RecordCategory")
          )
            ? getFormValue(rafForm, "RecordCategory")
            : [];
          if (mode === "updateExisting") {
            let existingRC: LookUpRow[] =
              isNotNullAndUndefined(existingDocument) &&
                isNotEmptyArray(existingDocument.RecordCategory)
                ? existingDocument.RecordCategory
                : [];
            recordCategory = existingRC.concat(
              formRC.filter(
                (item2) => !existingRC.some((item1) => item1.UID === item2.UID)
              )
            );
            recordCategoryUID = isNotEmptyArray(recordCategory)
              ? recordCategory[0].UID
              : null;
          } else {
            recordCategoryUID = isNotEmptyArray(formRC) ? formRC[0].UID : null;
            recordCategory = formRC;
          }
          let recordCategoryRow = await getRecordCategoryByUID(
            recordCategoryUID
          );

          if (
            isNotNullAndUndefined(recordCategoryRow) &&
            isNotNullAndUndefined(recordCategoryRow.UID)
          ) {
            if (isNotEmptyArray(recordCategory))
              formData.set("RecordCategory", JSON.stringify(recordCategory));

            // if (isNotNullAndUndefined(recordCategoryRow.UID))
            //   formData.append(
            //     "RecordCategoryUID",
            //     recordCategoryRow.UID as any
            //   );

            // if (isNotNullAndUndefined(recordCategoryRow.DueDate))
            //   formData.append("DueDate", recordCategoryRow.DueDate as any);

            if (isNotNullAndUndefined(getFormValue(rafForm, "RecordType")))
              formData.append(
                "RecordType",
                getFormValue(rafForm, "RecordType")
              );

            if (isNotNullAndUndefined(recordCategoryRow.RecordAction))
              formData.append("RecordAction", recordCategoryRow.RecordAction);

            if (isNotNullAndUndefined(recordCategoryRow.DeadlineUnits))
              formData.append("DeadlineUnits", recordCategoryRow.DeadlineUnits);

            if (isNotNullAndUndefined(recordCategoryRow.DeadlineInterval))
              formData.append(
                "DeadlineInterval",
                recordCategoryRow.DeadlineInterval as any
              );

            if (isNotNullAndUndefined(recordCategoryRow.DeadlineCalculation))
              formData.append(
                "DeadlineCalculation",
                recordCategoryRow.DeadlineCalculation
              );
          } else {
            if (isNotNullAndUndefined(getFormValue(rafForm, "RecordType")))
              formData.append(
                "RecordType",
                getFormValue(rafForm, "RecordType")
              );
          }
        } else {
          const selectedDocumentRow: ContentLibraryRow = R.clone(state.contentLibraryRow);
          if (
            isNotNullAndUndefined(selectedDocumentRow) &&
            isNotNullAndUndefined(selectedDocumentRow.UID)
          ) {
            if (isNotNullAndUndefined(selectedDocumentRow.RecordCategory))
              formData.append(
                "RecordCategory",
                JSON.stringify(selectedDocumentRow.RecordCategory) as any
              );

            // if (isNotNullAndUndefined(selectedDocumentRow.RecordCategoryUID))
            //   formData.append(
            //     "RecordCategoryUID",
            //     selectedDocumentRow.RecordCategoryUID as any
            //   );

            // if (isNotNullAndUndefined(selectedDocumentRow.DueDate))
            //   formData.append("DueDate", selectedDocumentRow.DueDate as any);

            if (isNotNullAndUndefined(selectedDocumentRow.RecordType))
              formData.append("RecordType", selectedDocumentRow.RecordType);

            if (isNotNullAndUndefined(selectedDocumentRow.RecordAction))
              formData.append("RecordAction", selectedDocumentRow.RecordAction);

            if (isNotNullAndUndefined(selectedDocumentRow.DeadlineUnits))
              formData.append(
                "DeadlineUnits",
                selectedDocumentRow.DeadlineUnits
              );

            if (isNotNullAndUndefined(selectedDocumentRow.DeadlineInterval))
              formData.append(
                "DeadlineInterval",
                selectedDocumentRow.DeadlineInterval as any
              );

            if (isNotNullAndUndefined(selectedDocumentRow.DeadlineCalculation))
              formData.append(
                "DeadlineCalculation",
                selectedDocumentRow.DeadlineCalculation
              );
          }
        }
        resolve(formData);
      } else {
        resolve(null);
      }
    });
  };

  //submit document form end

  //submit document sub form start
  const onProcessStart = (
    bpRow: BusinessProcessRow,
    bpStepTemplateRows: BusinessProcessStepTemplateRow[]
  ) => {
    if (isNotNullAndUndefined(bpRow)) {
      let contentLibraryRow: ContentLibraryRow = state
        .contentLibraryRow as ContentLibraryRow;
      contentLibraryRow.ProcessStatus =
        bpRow.ExecutionOrder === RAFExecutionOrder.AnyOrder
          ? RAFBPStatus.InProgress
          : bpStepTemplateRows && bpStepTemplateRows[0].Title;
      contentLibraryRow.BusinessProcess = bpRow.ProcessName;
      contentLibraryRow.BusinessProcessUID = bpRow.UID;
      let progressDiv = showProgress("body", true);
      repositoryActions
        .postDataAndGetResponse(
          "ContentLibrary/Save",
          getSaveRequest(contentLibraryRow, contentLibraryRow.UID),
          { ...props },
          ContentType.applicationJson
        )
        .then((response) => {
          hideProgress(progressDiv);
          if (
            isNotNullAndUndefined(response) &&
            isNotNullAndUndefined(response.data)
          ) {
            if (isNotNullAndUndefined(response.data.EntityId)) {
              if (props.onProcessStart)
                props.onProcessStart(contentLibraryRow.UID);
              else if (props.onSave)
                props.onSave(contentLibraryRow.UID);
              document.body.classList.remove("overflow-hidden");
              showSuccessToast("File Uploaded Successfully");
            } else if (
              response.data.Error !== null &&
              response.data.Error !== undefined &&
              response.data.Error !== ""
            ) {
              // console.log("Error on save", response.Error);
            }
          }
        })
        .catch((error) => error);
    }
  };

  const startProcessDialogOpen = (bpTempateResponse) => {
    setState(
      { showStartProcessContent: true, bpTemplate: bpTempateResponse },
    );
  };


  const onSubmitProcessTemplate = (value) => {
    let bPTemplateRow: BusinessProcessTemplateRow = value;
    let progressDiv = showProgress("body", true);

    let { relatedTo, relatedToType, relatedToUID } = state;
    StartProcessAndSaveProcessSteps(
      {
        bpRelatedTo: relatedTo,
        bpRelatedToType: relatedToType,
        bpRelatedToUID: relatedToUID,
      },
      bPTemplateRow
    ).then((bpResponse) => {
      hideProgress(progressDiv);
      onProcessStart(bpResponse.bpRow, bpResponse.bpStepTemplateRows);
    });
  };

  const startProcessContent = () => {
    let { bpTemplate } = state;
    if (
      state.showStartProcessContent &&
      state.showStartProcessContent === true
    ) {
      return (
        <Suspense fallback={<ACLoadingPanel loadingText="Loading..." />}>
          <div id="startBusinessProcessForm">
            <RAFForm
              type={BusinessProcessTemplateRow}
              initialValues={bpTemplate}
              layout={RAFLayout.TwoColumnLayout}
              onSubmit={onSubmitProcessTemplate}
            >
              <div className="dlg-new-style">
                <div className="e-dlg-header-content justify-content-between">
                  <div className="d-flex align-items-center w-100">
                    <div className="e-dlg-header" id="_title">
                      {" "}
                      {bpTemplate.ProcessName}
                    </div>
                  </div>
                  <ButtonComponent
                    type="button"
                    cssClass="primary-custom-button"
                    iconCss="fas fa-xmark"
                    onClick={() => startProcessDialogClose()}
                  ></ButtonComponent>
                </div>
                <div className="e-dlg-body-content pt-3 h-100 overflow-auto">
                  <Condition when="ProcessDeadlineCalculation" is={"Not set"}>
                    <ACDatePicker<BusinessProcessTemplateRow>
                      field="DueDate"
                      label="Do you want to set deadline?"
                    />
                  </Condition>
                  <Condition when="ProcessDeadlineCalculation" is={null}>
                    <ACDatePicker<BusinessProcessTemplateRow>
                      field="DueDate"
                      label="Do you want to set deadline?"
                    />
                  </Condition>
                </div>
                <div className="e-dlg-footerContent ">
                  <ButtonComponent
                    type="submit"
                    className="me-2"
                    cssClass="e-flat e-info"
                  >
                    Start
                  </ButtonComponent>
                  <ButtonComponent
                    type="button"
                    cssClass="e-flat"
                    onClick={() => startProcessDialogClose()}
                  >
                    Cancel
                  </ButtonComponent>
                </div>
              </div>
            </RAFForm>
          </div>
        </Suspense>
      );
    } else {
      return <div></div>;
    }
  };

  const startProcessDialogClose = () => {
    setState({ showStartProcessContent: false });
    if (props.onSave) props.onSave(state.relatedToUID);
  };
  //submit document sub form end

  //upload document start
  let allowFormSubmit = true;
  const onSubmitDocumentForm = () => {
    if (
      isNotNullAndUndefined(rafRelatedDocumentForm) &&
      rafRelatedDocumentForm.invalid === true
    ) {
      allowFormSubmit = false;
      rafRelatedDocumentForm.form.submit();
    }
    if (allowFormSubmit === true) onClickUploadDocument();
  };

  const onClickUploadDocument = async () => {
    let allowSubmit = true;
    let fileData = fileUploadObj.getFilesData();
    let fileName;

    if (props.mode === "updateVersion") {
      if (fileData.length !== 1) {
        allowSubmit = false;
        showWarningToast("Please select one document to upload.");
        return;
      }
    } else {
      if (fileData.length > 0) {
        fileData.forEach((item) => {
          fileName = item.name;
          if (item.size === 0) {
            allowSubmit = false;
            showWarningToast(item.name + " File size is too small!");
          }
        });
      } else {
        allowSubmit = false;
        showWarningToast("Please select alteast one document to upload.");
      }
    }

    if (allowSubmit) {
      if (state.checkDuplicate) {
        if (props.mode !== "updateVersion") {
          let progressDiv = showProgress("#manageUploadDocumentDialog");

          let parentUID = getFormValue(rafForm, "ParentUID");
          let moduleName = getFormValue(rafForm, "Entity");

          let relatedToUID = isNotNullAndUndefined(props.relatedToUID)
            ? props.relatedToUID
            : null;
          // let relatedTo = isNotNullAndUndefined(props.relatedTo)
          //     ? props.relatedTo
          //     : null;
          // let relatedToType = isNotNullAndUndefined(props.relatedToType)
          //     ? props.relatedToType
          //     : null;
          let relatedEntity = isNotNullAndUndefined(moduleName)
            ? moduleName
            : isNotNullAndUndefined(props.relatedEntity)
              ? getDocumentModuleNameByRelatedEntity(props.relatedEntity)
              : null;

          let existingDocument: ContentLibraryRow =
            await retrieveDocumentByFileName(
              fileName,
              relatedEntity,
              relatedToUID,
              parentUID,
              RAFDocumentType.File
            );
          let _isFileExists =
            isNotNullAndUndefined(existingDocument) &&
              isNotNullAndUndefined(existingDocument.UID)
              ? true
              : false;
          if (_isFileExists) {
            setState(
              {
                showAlertDialog: true,
                existingDocument,
                fileUploadObj: fileData,
              });
          } else {
            onSubmitUploadDocument("uploadNew"); //uploadNew
          }
          hideProgress(progressDiv);
        } else {
          onSubmitUploadDocument("isRevision");
        }
      } else {
        onSubmitUploadDocument("uploadNew");
      }
    }
  };
  //upload document end


  //alert_dialog_start
  const alertDialogContent = () => {
    if (state.showAlertDialog === true) {
      let { existingDocument } = state;
      let label =
        "matches an existing item in this folder. Do you want to update this as the top version?";
      return (
        <div className="px-4 pt-3 pb-0">
          <span className="pe-1" style={{ fontWeight: 500 }}>
            {isNotNullAndUndefined(existingDocument)
              ? existingDocument.FileName
              : "Na"}
          </span>
          <span>{label}</span>
        </div>
      );
    } else {
      return <div></div>;
    }
  };

  const alertDialogFooter = () => {
    if (state.showAlertDialog === true) {
      return (
        <div>
          <ButtonComponent
            type="button"
            cssClass="e-flat e-small me-2"
            onClick={() => alertDialogClose()}
          >
            Cancel
          </ButtonComponent>
          <ButtonComponent
            type="button"
            cssClass="e-flat e-small e-info me-2 e-outline"
            onClick={() => onSubmitUploadDocument("uploadNew")}
          >
            Keep Both
          </ButtonComponent>
          <ButtonComponent
            type="button"
            cssClass="e-flat e-small e-info me-2"
            onClick={() =>
              onSubmitUploadDocument(
                "updateExisting",
                state.existingDocument
              )
            }
          >
            New Version
          </ButtonComponent>
        </div>
      );
    } else {
      return <div></div>;
    }
  };

  const alertDialogClose = () => {
    setState({ showAlertDialog: false });
  };
  //alert_dialog_end

  const onChangeRecordType = (value) => {
    if (isNotNullAndUndefined(value)) {
      let { allDocumentEnableForms } = state;
      let selectedRelatedForm = isNotEmptyArray(allDocumentEnableForms)
        ? allDocumentEnableForms.find((x) => x.EntityName === value)
        : null;
      setState({
        selectedRelatedForm: isNotNullAndUndefined(selectedRelatedForm)
          ? selectedRelatedForm
          : null,
        checkDuplicate: false,
      });
    } else {
      setState(
        {
          selectedRelatedForm: null,
          checkDuplicate: state.checkDuplicate,
        });
      rafRelatedDocumentForm = null;
    }
  };

  const onBusinessProcessChange = (label) => {
    setFormValue(rafForm, "BusinessProcess", label);
  };

  const onChangeDocumentCategory = async (label, value) => {
    let { allDocumentEnableForms } = state;
    let recordCategory: LookUpRow[] = [
      {
        UID: value,
        Value: label,
      },
    ];
    setFormValue(rafForm, "RecordCategory", recordCategory);
    const recordCategoryRow = await getRecordCategoryByUID(value);
    if (
      isNotNullAndUndefined(recordCategoryRow) &&
      isNotNullAndUndefined(recordCategoryRow.RecordType)
    ) {
      setFormValue(rafForm, "RecordType", recordCategoryRow.RecordType);
      let selectedRelatedForm: EntityRow;
      if (isNotNullAndUndefined(recordCategoryRow.RecordType)) {
        selectedRelatedForm = isNotEmptyArray(allDocumentEnableForms)
          ? allDocumentEnableForms.find(
            (x) => x.DisplayName === recordCategoryRow.RecordType
          )
          : null;
      }
      if (isNotNullAndUndefined(selectedRelatedForm)) {
        setState({ selectedRelatedForm });
      } else {
        setState({ selectedRelatedForm: null });
      }
    } else {
      setFormValue(rafForm, "RecordType", null);
      setState({ selectedRelatedForm: null });
    }
  };

  const onChangeRecordCategory = (value) => {
    let progressDiv = showProgress("#manageUploadDocumentDialog");
    let selectedItems: LookUpRow[] = !IsNullOrWhiteSpace(value)
      ? JSON.parse(value)
      : null;
    let recordCategoryUID = isNotEmptyArray(selectedItems)
      ? selectedItems[0].UID
      : null;
    getRecordCategoryByUID(recordCategoryUID).then((recordCategoryRow) => {
      setState({ recordCategoryRow });
      hideProgress(progressDiv);
      if (
        isNotNullAndUndefined(recordCategoryRow) &&
        recordCategoryRow.EnableProcess === true &&
        isNotNullAndUndefined(recordCategoryRow.BusinessProcess) &&
        isNotNullAndUndefined(recordCategoryRow.BusinessProcessUID)
      ) {
        setFormValue(
          rafForm,
          "BusinessProcess",
          recordCategoryRow.BusinessProcess
        );
        setFormValue(
          rafForm,
          "BusinessProcessUID",
          recordCategoryRow.BusinessProcessUID
        );
      } else {
        setFormValue(rafForm, "BusinessProcess", null);
        setFormValue(rafForm, "BusinessProcessUID", null);
      }
    });
  };

  function onFileSelected(args: SelectedEventArgs): void {
    let existingFiles: FileInfo[] = fileUploadObj.getFilesData();
    for (let i = 0; i < args.filesData.length; i++) {
      for (const j of existingFiles) {
        if (!isNullOrUndefined(args.filesData[i])) {
          if (j.name === args.filesData[i].name) {
            args.filesData.splice(i, 1);
          }
        }
      }
    }
    existingFiles = existingFiles.concat(args.filesData);
    // args.modifiedFilesData = existingFiles;
    // args.isModified = true;

    let displayName;
    if (isNotEmptyArray(existingFiles)) {
      existingFiles.forEach((item) => {
        if (item.size !== 0) {
          displayName = item.name;
        }
      });
    }
    updateDisplayName(displayName);
  }

  const updateDisplayName = (displayName: string) => {
    if (props.mode === 'updateVersion' && allowDiffrentFileExtensions === true) {
      setFormValue(rafForm, "DisplayName", displayName);
    } else if (isNullOrUndefined(getFormValue(rafForm, "UID")))
      setFormValue(rafForm, "DisplayName", displayName);
  };

  const getCheckListTransAdditionalInputFields = () => {
    if (props.relatedToType === CareEsioEntity.ChecklistItemTrans.EntityName) {
      return (
        <div className="row">
          {props.hasExpiryDateRequired && (
            <div className="col-md-12">
              <ACDatePicker
                field={propertyOf<ChecklistItemTransRow>('ExpiryDate')}
                label="Expiry Date"
                required
              />
            </div>
          )}
          <div className="col-md-12">
            <RAFTextArea
              field={propertyOf<ActivityRow>('Message')}
              label="Add Document Information"
              showLabel
              placeholder="Enter document number, certification authority, and any additional notes."
              rows={5}
              onInputs={(e) => { }}
              onChanged={(e) => { }}
              useMentions
              mentionsField={propertyOf<ActivityRow>('Mentions')}
            />
          </div>
        </div>
      );
    }
  };

  if (props.isActive) {
    if (state.isLoading === false) {
      if (state.noContent === false) {
        const {
          contentLibraryRow,
          bpTemplates,
          recordCategoryRow,
          selectedRelatedForm,
        } = state;
        return (
          < >
            {isNotNullAndUndefined(props.isDynamic) &&
              props.isDynamic === false ? (
              <RAFForm
                type={ContentLibraryRow}
                submitOnEnterKey={false}
                initialValues={contentLibraryRow}
                formRef={(g) => (rafForm = g)}
                layout={RAFLayout.TwoColumnLayout}
                onSubmit={() => onSubmitDocumentForm()}
                className="h-100"
              >
                <div className="e-dlg-content-outer">
                  <div className="e-dlg-body-content m-0 pt-2 p-3">
                    <div
                      className="row align-items-center mb-3"
                      id="attachmentDiv"
                    >
                      <div className="col-12 d-flex">
                        <label className="form-label required">
                          Choose a file
                        </label>
                      </div>
                      <div className="col-12 d-flex">
                        <div className="w-100">
                          <UploaderComponent
                            id="fileUpload"
                            type="file"
                            ref={(dialog) => (fileUploadObj = dialog)}
                            //asyncSettings={asyncSettings} multiple
                            //uploading={addAdditionalHeaders.bind(this)}
                            //success={onSuccessUpload.bind(this)}
                            minFileSize={1}
                            multiple={false}
                            //multiple={state.isRevisedVersion === true ? false : true}
                            selected={
                              onFileSelected.bind(this)
                            }
                            removing={() => {
                              updateDisplayName(null);
                            }}
                          ></UploaderComponent>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="e-dlg-footerContent ">
                    <ButtonComponent className="me-2" cssClass="e-flat e-info">
                      Upload
                    </ButtonComponent>
                    <ButtonComponent
                      type="button"
                      cssClass="e-flat"
                      onClick={() => props.onClose()}
                    >
                      Cancel
                    </ButtonComponent>
                  </div>
                </div>
              </RAFForm>
            ) : (
              <RAFEntityProvider moduleName={RAFEntityName.ContentLibrary}>
                <RAFAttributeRelatedListProvider
                  moduleName={RAFEntityName.ContentLibrary}
                >
                  <RAFPageLayoutProvider
                    rafPageType={RAFPageType.Create}
                    {...((isNotNullAndUndefined(
                      props.allowSelectRecordCategory
                    ) &&
                      props.allowSelectRecordCategory === true &&
                      props.mode === "create") ||
                      (isNotNullAndUndefined(props.showDocFolderlookUp) &&
                        props.showDocFolderlookUp === true)
                      ? { mode: "Create" }
                      : {})}
                  >
                    <RAFForm
                      type={ContentLibraryRow}
                      submitOnEnterKey={false}
                      initialValues={contentLibraryRow}
                      formRef={(g) => (rafForm = g)}
                      layout={RAFLayout.TwoColumnLayout}
                      onSubmit={() => onSubmitDocumentForm()}
                      className="h-100"
                    >
                      <div className="e-dlg-content-outer">
                        <div className={`e-dlg-body-content${props.paddingClass ? ` ${props.paddingClass}` : ' pt-2 p-3 m-0'}`}>
                          <div
                            className="row align-items-center mb-3"
                            id="attachmentDiv"
                          >
                            <div className="col-12 d-flex">
                              <label className="form-label required">
                                Choose a file
                              </label>
                            </div>
                            <div className="col-12 d-flex">
                              <div className="w-100">
                                <UploaderComponent
                                  id="fileUpload"
                                  type="file"
                                  ref={(dialog) => (fileUploadObj = dialog)}
                                  //asyncSettings={asyncSettings} multiple
                                  //uploading={addAdditionalHeaders.bind(this)}
                                  //success={onSuccessUpload.bind(this)}
                                  minFileSize={1}
                                  multiple={false}
                                  //multiple={state.isRevisedVersion === true ? false : true}
                                  selected={
                                    onFileSelected.bind(this)
                                  }
                                  removing={() => {
                                    updateDisplayName(null);
                                  }}
                                ></UploaderComponent>
                              </div>
                            </div>
                          </div>

                          {props.relatedToType === CareEsioEntity.ChecklistItemTrans.EntityName ? (
                            getCheckListTransAdditionalInputFields()
                          )
                            :
                            (
                              <div>
                                {/* {props.allowSelectRecordCategory === true &&
                                props.mode === "create" && (
                                  <div className="row">
                                    <div className="col-md-12">
                                      <RAFRecordCategoryInput<ContentLibraryRow>
                                        field="RecordCategory"
                                        label="Choose record category"
                                        url={"RecordCategory/Lookup"}
                                        placeholder="Choose record category"
                                        showClearButton
                                        //required
                                        onChanged={(value) =>
                                          onChangeRecordCategory(value)
                                        }
                                        // disabled={
                                        //     isNotEmptyArray(
                                        //         contentLibraryRow.RecordCategory
                                        //     )
                                        //         ? true
                                        //         : false
                                        // }
                                        //addEmpty
                                        //emptyString="General"
                                      />
    
                                      
                                    </div>
                                  </div>
                                )} */}
                                {props.allowSelectRecordCategory === true &&
                                  props.mode === "create" && (
                                    <>
                                      {false && (
                                        <div className="col-md-12">
                                          <RAFLookupCC
                                            field="DocumentCategory"
                                            label="Category"
                                            placeholder="Select category"
                                            showCreateButton
                                            url={"RecordCategory/Lookup"}
                                            moduleName={RAFEntityName.Contact}
                                            //customFilter={customFilter}
                                            showFullList={false}
                                            onChanged={(lable, value) =>
                                              onChangeDocumentCategory(
                                                lable,
                                                value
                                              )
                                            }
                                          // disabled={
                                          //   isNullOrUndefined(
                                          //     contentLibraryRow.RecordCategory
                                          //   )
                                          //     ? false
                                          //     : true
                                          // }
                                          />
                                        </div>
                                      )}
                                      <div className="col-md-12">
                                        <RAFDropdownCC<ContentLibraryRow>
                                          field="RecordType"
                                          label="Content Type"
                                          placeholder="Select content type"
                                          moduleName={RAFEntityName.ContentLibrary}
                                          createOptionMode="Footer"
                                          isColorOption
                                          allowAdd
                                          mode="discView"
                                          uitype="colorpicker"
                                          onChanged={(label, value) =>
                                            onChangeRecordType(value)
                                          }
                                        ></RAFDropdownCC>
                                      </div>
                                    </>
                                  )}

                                {props.mode !== "updateVersion" && (
                                  <div className="">
                                    <div className="col-md-12">
                                      <ACTextBox<ContentLibraryRow>
                                        field="DisplayName"
                                        label="Display Name"
                                        showLabel
                                      />
                                    </div>
                                    <div className="col-12">
                                      <ACTextArea<ContentLibraryRow>
                                        field="Notes"
                                        label="Description"
                                        showLabel
                                        placeholder="Add a description"
                                      />
                                    </div>
                                  </div>
                                )}

                                {props.mode !== "updateVersion" && (
                                  <div>
                                    {/* <RAFDropdownCC<ContentLibraryRow>
                                    field="RecordType"
                                    label="Type"
                                    placeholder="Select type"
                                    moduleName={RAFEntityName.ContentLibrary}
                                    createOptionMode="Footer"
                                    isColorOption
                                    allowAdd
                                    mode="discView"
                                    uitype="colorpicker"
                                    onChanged={(label, value) =>
                                      onChangeRecordType(value)
                                    }
                                  ></RAFDropdownCC> */}
                                    <RAFTagInput<ContentLibraryRow>
                                      field="TagsListJson"
                                      label="Tags"
                                      showAddBtn
                                      showLabel={true}
                                    />
                                    <ConditionNotNullOrWhiteSpace when={"RecordType"}>
                                      {isNotNullAndUndefined(selectedRelatedForm) &&
                                        isNotNullAndUndefined(
                                          selectedRelatedForm.EntityName
                                        ) &&
                                        isNotNullAndUndefined(
                                          selectedRelatedForm.UID
                                        ) && (
                                          <div
                                            className="removeformPageContainerPadding mt-3"
                                            key={
                                              isNotNullAndUndefined(
                                                selectedRelatedForm
                                              )
                                                ? selectedRelatedForm.UID
                                                : null
                                            }
                                          >
                                            <RAFEntityProvider
                                              moduleName={
                                                selectedRelatedForm.EntityName
                                              }
                                            >
                                              <RAFObjectContextProvider
                                                moduleName={
                                                  selectedRelatedForm.EntityName
                                                }
                                              >
                                                <RAFObjectContext.Consumer>
                                                  {({
                                                  /*objectId, rafObject,*/ saveObject,
                                                  }) => {
                                                    return (
                                                      <RAFForm
                                                        //initialValues={initalObject}
                                                        formRef={(g) => {
                                                          return (rafRelatedDocumentForm =
                                                            g);
                                                        }}
                                                        layout={
                                                          RAFLayout.TwoColumnLayout
                                                        }
                                                        // onSubmit={(
                                                        //   values
                                                        // ) =>
                                                        //   onSubmitRelatedForm(
                                                        //     values,
                                                        //     saveObject
                                                        //   )
                                                        // }
                                                        submitOnEnterKey={false}
                                                      //decorators={[accountDecorators]}
                                                      //convertBeforeSave={ConvertAccountRowToAccountDto}
                                                      >
                                                        <CreateContent
                                                          key={
                                                            selectedRelatedForm.UID
                                                          }
                                                          moduleName={
                                                            selectedRelatedForm.EntityName
                                                          }
                                                          isPreviewMode={true}
                                                          progressDivId="manageUploadDocumentDialog"
                                                        />
                                                      </RAFForm>
                                                    );
                                                  }}
                                                </RAFObjectContext.Consumer>
                                              </RAFObjectContextProvider>
                                            </RAFEntityProvider>
                                          </div>
                                        )}
                                    </ConditionNotNullOrWhiteSpace>
                                  </div>
                                )}

                                {props.mode === "create" &&
                                  isNotNullAndUndefined(recordCategoryRow) &&
                                  recordCategoryRow.EnableProcess === true &&
                                  isNotEmptyArray(bpTemplates) ? (
                                  <div className="col-12">
                                    <ACDropdown<ContentLibraryRow>
                                      field="BusinessProcessUID"
                                      label="Process"
                                      showLabel
                                      placeholder="Select Process"
                                      allowAdd={false}
                                      onChanged={(label, value) =>
                                        onBusinessProcessChange(label)
                                      }
                                      required={
                                        recordCategoryRow.IsProcessRequired === true
                                          ? true
                                          : false
                                      }
                                    >
                                      {bpTemplates.map((bpTemplate) => {
                                        return (
                                          <RAFChoiceOption
                                            key={bpTemplate.UID}
                                            label={bpTemplate.ProcessName}
                                            value={bpTemplate.UID}
                                          />
                                        );
                                      })}
                                    </ACDropdown>
                                  </div>
                                ) : null}
                              </div>
                            )}
                        </div>
                        <div className={`e-dlg-footerContent${props.footerPadding ? ` ${props.footerPadding}` : ''}`}>
                          <div className="w-100">
                            <div className="row gx-3">
                              <div className="col">
                                <RAFButtonComponent action={RAFButtonConstant.Cancel}
                                  className="w-100 new_style"
                                  onClick={() => props.onClose()}
                                ></RAFButtonComponent>
                              </div>
                              <RAFPermissionRender
                                permissionName={
                                  props.mode === "create"
                                    ? props.createPermissionName ?? PermissionConstants.DocumentCreate
                                    : props.updatePermissionName ?? PermissionConstants.DocumentUpdate
                                }
                              >
                                <div className="col">
                                  <RAFButtonComponent action={RAFButtonConstant.Upload}
                                    isPrimary
                                    className="w-100 new_style"
                                    type="submit"
                                    showIcon={false}
                                  ></RAFButtonComponent>
                                </div>
                              </RAFPermissionRender>
                            </div>
                          </div>
                        </div>
                      </div>
                    </RAFForm>
                  </RAFPageLayoutProvider>
                </RAFAttributeRelatedListProvider>
              </RAFEntityProvider>
            )}

            <div>
              {state.showAlertDialog && (
                <DialogComponent
                  header="Duplicates!"
                  showCloseIcon
                  visible={state.showAlertDialog}
                  cssClass="centerDialog-sm full-height form-center-dialog custom-alert-dialog"
                  id="alertDialog"
                  content={alertDialogContent.bind(this)}
                  footerTemplate={alertDialogFooter.bind(this)}
                  isModal
                  target="body"
                  closeOnEscape={false}
                  close={alertDialogClose.bind(this)}
                />
              )}
            </div>
            {state.showStartProcessContent && (
              <DialogComponent
                visible={state.showStartProcessContent}
                cssClass="rightDialog createEditForm full-height form-center-dialog"
                id="startBusinessProcessDialog"
                content={startProcessContent.bind(this)}
                isModal
                target="body"
                closeOnEscape={false}
                close={startProcessDialogClose.bind(this)}
              />
            )}
          </ >
        );
      } else {
        return (
          <div className="container-fluid px-0">
            <RAFDeletedRecordState
              title="This item is either deleted or You do not have sufficient privileges to view this item."
            />
          </div>
        );
      }
    } else {
      return (
        <div className="container-fluid px-0">
          <ACLoadingPanel loadingText="Preparing Data..." />
        </div>
      );
    }
  } else {
    return <div></div>;
  }
}


export default React.memo(RAFManageUploadDocumentFile);
