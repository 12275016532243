import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import {
  AnimationSettingsModel,
  DialogComponent,
} from "@syncfusion/ej2-react-popups";
import React, { Component } from "react";
import { RAFReportDataListContext } from "../../../RAFComponents/List/RAFReportDataList";
import { createInstance } from "../../../RAFComponents/Utility/FormUtility";
import { NavigateParamsProps, withRouter } from "../../../router";
import { ContentLibraryRow } from "./Library/ContentLibraryRow";
import RAFCaptureImage from "./ManageDocument/RAFCaptureImage";
import RAFManageUploadDocumentFile from "./ManageDocument/RAFManageUploadDocumentFile";
import { RAFEntityName } from "../../../constants/Common/EntityConstants";

interface IProps {
  relatedToUID?: string;
  showAddBtn?: boolean;
  documentCreated?: () => void;
  secondaryRelatedToUID?: string;
  secondaryRelatedToType: string;
  moduleName?: string;
  secondaryRelatedTo?: string;
  relatedTo?: string;
  relatedEntity?: string;
}

interface IState {
  selectedDocumentRow: ContentLibraryRow;
  showUploadContent: boolean;
  isRevisedVersion: boolean;
  triggerInputFile?: boolean;
}

class RelatedDocumentListAttachButtons extends Component<
  IProps & NavigateParamsProps,
  IState
> {
  private _isMounted: boolean;
  animationSettings: AnimationSettingsModel;
  private moduleName: string = createInstance(ContentLibraryRow).getClassName();

  constructor(props) {
    super(props);
    this.animationSettings = { effect: "Fade" };

    this.state = {
      selectedDocumentRow: null,
      isRevisedVersion: false,
      showUploadContent: false,
      triggerInputFile: false,
    };
  }

  componentDidMount = () => {
    this._isMounted = true;
  };

  componentWillUnmount() {
    this._isMounted = false;
  }

  documentCreated = () => {
    if (this.props.documentCreated) {
      this.props.documentCreated();
    }
  };

  private uploadDocument(data?: Object, isRevisedVersion?: boolean) {
    if (this._isMounted) {
      this.setState({
        showUploadContent: true,
        selectedDocumentRow: data,
        isRevisedVersion: isRevisedVersion,
      });
    }
  }

  private uploadDialogClose(): void {
    if (this._isMounted) {
      this.setState({ showUploadContent: false });
    }
  }

  uploadAndLinkDocumentContent() {
    if (this.state.showUploadContent === true) {
      return (
        <RAFManageUploadDocumentFile
          onSave={(UID) => this.onUploadDocumentFile(UID)}
          onClose={() => this.uploadDialogClose()}
          selectedFolder={null}
          selectedDocumentRow={this.state.selectedDocumentRow}
          //relatedToUID={this.props.relatedToUID}
          //relatedEntity={this.props.moduleName}
          secondaryRelatedToType={RAFEntityName.BusinessProcessStep}
          secondaryRelatedToUID={this.props.secondaryRelatedToUID}
          secondaryRelatedTo={this.props.secondaryRelatedTo}
          relatedToUID={this.props.relatedToUID}
          relatedTo={this.props.relatedTo}
          relatedEntity={this.props.relatedEntity}
          mode={
            this.state.isRevisedVersion === true ? "updateVersion" : "create"
          }
          checkDuplicate={false}
          isActive
        />
      );
    } else {
      return <div></div>;
    }
  }

  onUploadDocumentFile = (UID) => {
    if (this._isMounted) {
      this.setState({ showUploadContent: false }, () => {
        this.documentCreated();
        document.body.classList.remove("overflow-hidden");
      });
    }
  };
  //upload_new_Photo_start
  triggerInputFile = () => {
    if (this._isMounted) {
      if (this.state.triggerInputFile === true) {
        this.setState({ triggerInputFile: false }, () => {
          this.setState({ triggerInputFile: true });
        });
      } else {
        this.setState({ triggerInputFile: true });
      }
    }
  };
  //upload_new_Photo_end

  render() {
    return (
      <div
      // className={this.props.showEmptyState === true ? "" : "hidden"}
      >
        {this.props.showAddBtn && (
          <div className="p-2">
            <div className="row g-2 flex-nowrap">
              <div className="col-auto">
                <ButtonComponent
                  type="button"
                  cssClass="btn-roundedCorner e-btn_To-Do custom-button-sm d-flex align-items-center"
                  content="Attach photo"
                  onClick={() => this.triggerInputFile()}
                ></ButtonComponent>
              </div>
              <div className="col-auto">
                <ButtonComponent
                  type="button"
                  cssClass="btn-roundedCorner e-btn_To-Do custom-button-sm d-flex align-items-center"
                  content="Attach file"
                  onClick={() => this.uploadDocument()}
                ></ButtonComponent>
              </div>
            </div>
          </div>
        )}
        <div>
          {this.state.showUploadContent && (
            <DialogComponent
              header={
                this.state.isRevisedVersion
                  ? "Upload New Version"
                  : "Upload File"
              }
              showCloseIcon
              visible={this.state.showUploadContent}
              cssClass="centerDialog-sm createEditForm form-center-dialog dlg-new-style fixed-header"
              content={this.uploadAndLinkDocumentContent.bind(this)}
              isModal
              target="body"
              closeOnEscape={false}
              close={this.uploadDialogClose.bind(this)}
              animationSettings={this.animationSettings}
              id="manageUploadDocumentDialog"
            ></DialogComponent>
          )}
          {this.props.showAddBtn && (
            <div className="col-auto">
              <RAFCaptureImage
                onSave={(UID) => this.onUploadDocumentFile(UID)}
                secondaryRelatedToType={this.props.secondaryRelatedToType}
                secondaryRelatedToUID={this.props.secondaryRelatedToUID}
                secondaryRelatedTo={this.props.secondaryRelatedTo}
                relatedToUID={this.props.relatedToUID}
                relatedTo={this.props.relatedTo}
                relatedEntity={this.props.relatedEntity}
                triggerInputFile={this.state.triggerInputFile}
                hideAddBtn
              // hideAddBtn={true}
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}
RelatedDocumentListAttachButtons.contextType = RAFReportDataListContext;
export default withRouter(RelatedDocumentListAttachButtons);
