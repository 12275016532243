import { DialogComponent } from "@syncfusion/ej2-react-popups";
import DOMPurify from "dompurify";
import moment from "moment";
import React, { PropsWithChildren, Reducer, useReducer } from "react";
import { PreventFocusOnDialogOpen } from "../../../../RAFComponents/Dialog/SFDialogUtils";
import CustomCardWidget from "../../../../RAFComponents/Navigation/CustomCardWidget";
import RAFButtonComponent from "../../../../RAFComponents/Navigation/RAFButtonComponent";
import RAFDetailFieldCustom from "../../../../RAFComponents/Navigation/RAFDetailFieldCustom";
import RAFDetailsValueWithSeparator from "../../../../RAFComponents/Navigation/RAFDetailsValueWithSeparator/RAFDetailsValueWithSeparator";
import RAFRecordInfo from "../../../../RAFComponents/Navigation/RAFRecordInfo";
import RAFTagDetails from "../../../../RAFComponents/helpers/RAFTagDetails";
import {
  IsNotNullOrWhiteSpace,
  convertUTCDateToLocalTimezone,
  getFileExtension,
  isNotEmptyArray,
  isNotNullAndUndefined,
} from "../../../../RAFComponents/helpers/utils";
import { RAFDataType } from "../../../../RAFComponents/models/Common/RAFDataType";
import {
  MomentFormats,
  RAFActionStatus,
  RAFButtonConstant,
  RAFDocumentType,
} from "../../../../constants/Common/Constants";
import { RAFEntityName } from "../../../../constants/Common/EntityConstants";
import AcknowledgementTrackingDialogContent from "../AcknowledgementTracking/AcknowledgementTrackingDialogContent";
import { AcknowledgementTrackingRow } from "../AcknowledgementTracking/AcknowledgementTrackingRow";
import RAFContentLibraryVersionHistory from "../DocumentVersionHistory/RAFContentLibraryVersionHistory";
import { ContentLibraryRow } from "./ContentLibraryRow";
import { getFileSize } from "./DocumentHelper";

interface IProps {
  showFileCardInfo?: boolean;
  contentLibraryRow: ContentLibraryRow;

  refreshOnUpdateTag: (uid: string) => void;

  acknowledgementTrackingRow: AcknowledgementTrackingRow;
  recipientsAcknowledgementTrackingList: AcknowledgementTrackingRow[];
  refreshOnUpdate: (uid: string) => void;
}

interface IState {
  showVersionHistory: boolean;
  showAcknowledgementTrackingDlg: boolean;
  selectedTabIdAcknowledgementTracking:
    | "NewRequest"
    | "RequestedAcknowledgement";
}

function DocumentPropertiesInfoContent({
  showFileCardInfo = true,
  contentLibraryRow,
  ...props
}: PropsWithChildren<IProps>) {
  const [state, setState] = useReducer<Reducer<IState, Partial<IState>>>(
    (state, newState) => ({ ...state, ...newState }),
    {
      showVersionHistory: false,
      showAcknowledgementTrackingDlg: false,
      selectedTabIdAcknowledgementTracking: null,
    }
  );

  //VersionHistory dialog start

  const showVersionHistoryDialog = () => {
    setState({ showVersionHistory: true });
  };

  const closeVersionHistoryDialog = () => {
    setState({ showVersionHistory: false });
  };

  const renderRelatedSectionContent = () => {
    if (
      isNotNullAndUndefined(contentLibraryRow) &&
      isNotNullAndUndefined(contentLibraryRow.UID)
    ) {
      return (
        <RAFContentLibraryVersionHistory
          onUpdate={refreshOnUpdate}
          documentRow={contentLibraryRow}
          documentUID={contentLibraryRow.UID}
          module={contentLibraryRow.Entity}
          isActive
        />
      );
    } else {
      return <div></div>;
    }
  };
  //VersionHistory dialog end

  //acknowledgementTracking start
  const showAcknowledgementDlgClick = (
    selectedTabIdAcknowledgementTracking:
      | "NewRequest"
      | "RequestedAcknowledgement"
  ) => {
    setState({
      showAcknowledgementTrackingDlg: true,
      selectedTabIdAcknowledgementTracking,
    });
  };

  const acknowledgementTrackingDlgContent = () => {
    if (state.showAcknowledgementTrackingDlg) {
      return (
        <AcknowledgementTrackingDialogContent
          contentLibraryUID={contentLibraryRow.UID}
          onClose={acknowledgementTrackingDlgContentClose}
          onSave={() => refreshOnUpdate(contentLibraryRow.UID)}
          selectedTabIdAcknowledgementTracking={
            state.selectedTabIdAcknowledgementTracking
          }
          // mode={'Overview'}
        />
      );
    } else {
      return null;
    }
  };

  const acknowledgementTrackingDlgContentClose = () => {
    setState({ showAcknowledgementTrackingDlg: false });
  };
  //acknowledgementTracking end

  const refreshOnUpdate = (uid: string) => {
    setState({
      showVersionHistory: false,
      showAcknowledgementTrackingDlg: false,
    });
    if (props.refreshOnUpdate) {
      props.refreshOnUpdate(uid);
    }
  };

  const refreshOnUpdateTag = () => {
    if (isNotNullAndUndefined(props.refreshOnUpdateTag)) {
      props.refreshOnUpdateTag(contentLibraryRow.UID);
    }
  };

  const getAcknowledgementTrackingContent = () => {
    const {
      recipientsAcknowledgementTrackingList,
      acknowledgementTrackingRow,
    } = props;
    // if (isNotEmptyArray(recipientsAcknowledgementTrackingList)) {
    const acknowledgedItemCount =
      isNotEmptyArray(recipientsAcknowledgementTrackingList) &&
      recipientsAcknowledgementTrackingList.filter(
        (x) => x.Status === RAFActionStatus.Acknowledged
      ).length;

    const signedDate =
      isNotNullAndUndefined(acknowledgementTrackingRow) &&
      isNotNullAndUndefined(acknowledgementTrackingRow.SignedDate)
        ? convertUTCDateToLocalTimezone(acknowledgementTrackingRow.SignedDate)
        : null;

    const signedDateDisplayValue = IsNotNullOrWhiteSpace(signedDate)
      ? moment(signedDate).format(MomentFormats.DATETIME)
      : "Not set";

    return (
      <>
        {isNotNullAndUndefined(acknowledgementTrackingRow) && (
          <>
            <div className="col-12">
              <div className="custom__card__separator custom__card__separator__light"></div>
            </div>
            <div className="col-12">
              <div
                className={`subtitle_2 semi_bold ${
                  acknowledgementTrackingRow.Status === RAFActionStatus.Pending
                    ? "content_brand_secondary_base"
                    : "content_state_success_base"
                }`}
              >
                {acknowledgementTrackingRow.Status === RAFActionStatus.Pending
                  ? "Your acknowledgment status is pending"
                  : `You have acknowledged the document on ${[
                      signedDateDisplayValue,
                    ]}.`}
              </div>
            </div>
          </>
        )}
        <div className="col-12">
          <div className="custom__card__separator custom__card__separator__light"></div>
        </div>
        <div className="col-12">
          <div className="row gx-2 flex-nowrap align-items-center">
            <div className="col">
              <div className="subtitle_2 semi_bold ecllipseFirstLine">
                Acknowledgment Status Overview
              </div>
            </div>
            <div className="col-auto">
              <RAFButtonComponent
                action={RAFButtonConstant.AcknowledgmentRequest}
                showIcon={false}
                className="btn_brand_primary outline new_style raf_xsm"
                onClick={() => showAcknowledgementDlgClick("NewRequest")}
              ></RAFButtonComponent>
            </div>
          </div>
        </div>
        <div className="col-12">
          <div className="row gx-2 align-items-center justify-content-between flex-nowrap">
            <div className="col">
              <span className="body_2_light ecllipseFirstLine word-break-all">
                Total Requests Sent
              </span>
            </div>
            <div className="col-auto">
              <span
                className={`subtitle_2 pointer semi_bold${
                  isNotEmptyArray(recipientsAcknowledgementTrackingList) &&
                  recipientsAcknowledgementTrackingList.length > 0
                    ? " text-decoration-underline"
                    : ""
                }`}
                onClick={() =>
                  showAcknowledgementDlgClick("RequestedAcknowledgement")
                }
              >
                {isNotEmptyArray(recipientsAcknowledgementTrackingList) &&
                recipientsAcknowledgementTrackingList.length > 0
                  ? recipientsAcknowledgementTrackingList.length
                  : "0"}
              </span>
            </div>
          </div>
        </div>
        <div className="col-12">
          <div className="row gx-2 align-items-center justify-content-between flex-nowrap">
            <div className="col">
              <span className="body_2_light ecllipseFirstLine word-break-all">
                Total Acknowledgments Completed
              </span>
            </div>
            <div className="col-auto">
              <span
                className={`subtitle_2 pointer semi_bold${
                  acknowledgedItemCount > 0 ? " text-decoration-underline" : ""
                }`}
                onClick={() =>
                  showAcknowledgementDlgClick("RequestedAcknowledgement")
                }
              >
                {acknowledgedItemCount > 0 ? acknowledgedItemCount : "0"}
              </span>
            </div>
          </div>
        </div>
      </>
    );
    // } else {
    //   return null;
    // }
  };

  const getImageCardInfo = () => {
    if (showFileCardInfo) {
      let fileClassname;
      const fileNameExtension = contentLibraryRow.FileName;
      const fileExtension = getFileExtension(fileNameExtension);
      const documentType: string = contentLibraryRow.DocumentType;

      if (isNotNullAndUndefined(fileExtension)) {
        fileClassname = "fiv-viv fiv-icon-blank fiv-icon-" + fileExtension;
      }
      if (documentType === RAFDocumentType.Folder) {
        fileClassname = "fiv-viv fiv-icon-folder";
      } else if (documentType === RAFDocumentType.Page) {
        fileClassname = "fiv-viv fiv-icon-blank fiv-icon-html";
      }

      return (
        <div className="col-12">
          <CustomCardWidget cardContentClassName="p-2">
            <div className="row gx-2 align-items-center">
              <div className="col-auto">
                <CustomCardWidget
                  cardClassName="border-0 surface_neutral_base"
                  removeContentPadding
                >
                  <div className="profilPhotoDiv-container surface_neutral_base shadow-none">
                    <div className="profileImg-container profileImg-container-md document-item-icon surface_neutral_base">
                      <span className={fileClassname}></span>
                    </div>
                  </div>
                </CustomCardWidget>
              </div>
              <div className="col" style={{ zIndex: "10" }}>
                <div className="subtitle_1 semi_bold mb-1 ecllipseFirstLine word-break-all">
                  <span>{contentLibraryRow.DisplayName}</span>
                </div>
                <div className="d-flex justify-content-between align-items-center">
                  <RAFDetailsValueWithSeparator outerClassName="h-auto">
                    {isNotNullAndUndefined(contentLibraryRow.RecordType) && (
                      <RAFDetailFieldCustom
                        value={
                          isNotNullAndUndefined(contentLibraryRow.RecordType)
                            ? contentLibraryRow.RecordType
                            : "Not set"
                        }
                        displayValue={contentLibraryRow.RecordType}
                        title="RecordType"
                        moduleName={RAFEntityName.ContentLibrary}
                        isColorOption
                        mode="discView"
                        field="RecordType"
                        showLabel={false}
                        valueClassName="ecllipseFirstLine ps-1 body_2_light raf-icon-view"
                      />
                    )}
                    {documentType === RAFDocumentType.File && (
                      <div className="ecllipseFirstLine body_2_light">
                        <span>{getFileSize(contentLibraryRow.FileSize)}</span>
                      </div>
                    )}
                  </RAFDetailsValueWithSeparator>
                </div>
              </div>
            </div>
          </CustomCardWidget>
        </div>
      );
    } else {
      return <></>;
    }
  };

  return (
    <div className="row gy-3 gx-0">
      {getImageCardInfo()}
      {isNotNullAndUndefined(contentLibraryRow.Notes) && (
        <div className="col-12">
          <div className="">
            <div className="body_2_light">Description</div>
            <div className="mt-1">
              <CustomCardWidget
                cardClassName="surface_neutral_base"
                cardContentClassName="p-2"
              >
                <span
                  className="description-text body_2"
                  dangerouslySetInnerHTML={{
                    __html:
                      contentLibraryRow.Notes !== null
                        ? DOMPurify.sanitize(contentLibraryRow.Notes)
                        : undefined,
                  }}
                ></span>
              </CustomCardWidget>
            </div>
          </div>
        </div>
      )}
      <RAFDetailFieldCustom
        value={
          isNotNullAndUndefined(contentLibraryRow.RecordType)
            ? contentLibraryRow.RecordType
            : "Not set"
        }
        displayValue={contentLibraryRow.RecordType}
        title="RecordType"
        moduleName={RAFEntityName.ContentLibrary}
        isColorOption
        mode="discView"
        field="RecordType"
        rowClassName="justify-content-between"
        labelDivClassName="col-auto"
        valueDivClassName="col-auto"
        labelClassName="body_2_light content_neutral_base"
        valueClassName="subtitle_2 semi_bold ps-1"
        colClassName="col-12"
      />
      <div className="col-12">
        <RAFDetailFieldCustom
          value={
            isNotNullAndUndefined(contentLibraryRow.CurrentStatus)
              ? contentLibraryRow.CurrentStatus
              : "Not set"
          }
          displayValue={contentLibraryRow.CurrentStatus}
          title="Status"
          moduleName={RAFEntityName.ContentLibrary}
          isColorOption
          field="CurrentStatus"
          showLabel={true}
          rowClassName="justify-content-between"
          labelDivClassName="col-auto"
          valueDivClassName="col-auto"
          labelClassName="body_2_light content_neutral_base"
          valueClassName="subtitle_2 semi_bold ps-1"
          mode="discView"
        />
      </div>

      <div className="col-12">
        <RAFDetailFieldCustom
          value={
            isNotNullAndUndefined(contentLibraryRow.Revision)
              ? `${contentLibraryRow.Revision}.0.0`
              : "Not set"
          }
          {...(isNotNullAndUndefined(contentLibraryRow.Revision)
            ? {
                customValue: (
                  <div className="col-auto">
                    <div className="row gx-2 align-items-center">
                      <div className="col-auto">
                        <span className="subtitle_2 semi_bold">
                          {`${contentLibraryRow.Revision}.0.0`}
                        </span>
                      </div>
                      <div className="col-auto">
                        <RAFButtonComponent
                          btnContent="View"
                          className="new_style link-button raf_sm text-decoration-underline"
                          onClick={showVersionHistoryDialog}
                        ></RAFButtonComponent>
                      </div>
                    </div>
                  </div>
                ),
              }
            : {})}
          title="Version"
          moduleName={RAFEntityName.ContentLibrary}
          field="Revision"
          showLabel={true}
          rowClassName="justify-content-between"
          labelDivClassName="col-auto"
          valueDivClassName="col-auto"
          labelClassName="body_2_light content_neutral_base"
        />
      </div>

      <div className="col-12">
        <RAFDetailFieldCustom
          value={
            isNotNullAndUndefined(contentLibraryRow.LastActivityDate)
              ? contentLibraryRow.LastActivityDate
              : "Not set"
          }
          customValue={
            <div className="col-auto">
              <RAFRecordInfo
                createdDate={contentLibraryRow.CreatedDate}
                modifiedDate={contentLibraryRow.ModifiedDate}
                lastActivityDate={contentLibraryRow.LastActivityDate}
                createdBy={contentLibraryRow.CreatedBy}
                modifiedBy={contentLibraryRow.ModifiedBy}
                spanContentClassName={"subtitle_2 semi_bold"}
              ></RAFRecordInfo>
            </div>
          }
          title="Last Updated"
          field="LastActivityDate"
          showLabel={true}
          rowClassName="justify-content-between"
          labelDivClassName="col-auto"
          valueDivClassName="col-auto"
          labelClassName="body_2_light content_neutral_base"
        />
      </div>
      {isNotNullAndUndefined(contentLibraryRow.DueDate) && (
        <div className="col-12">
          <RAFDetailFieldCustom
            field="DueDate"
            title="Due Date"
            value={
              isNotNullAndUndefined(contentLibraryRow.DueDate)
                ? contentLibraryRow.DueDate
                : "Not set"
            }
            rowClassName="justify-content-between"
            labelDivClassName="col-auto"
            valueDivClassName="col-auto"
            labelClassName="subtitle_3 medium content_neutral_base"
            fieldFormat={{ type: RAFDataType.Date, format: "DD/MM/YYYY" }}
          ></RAFDetailFieldCustom>
        </div>
      )}
      {getAcknowledgementTrackingContent()}
      <div className="col-12">
        <div className="custom__card__separator custom__card__separator__light"></div>
      </div>

      <div className="col-12">
        <div className="row gx-0 gy-2">
          <div className="col-12">
            <div className="subtitle_2 semi_bold">Tags</div>
          </div>
          <div className="col-12">
            <RAFTagDetails
              initialTags={
                isNotNullAndUndefined(contentLibraryRow) &&
                isNotNullAndUndefined(contentLibraryRow["TagsListJson"])
                  ? contentLibraryRow["TagsListJson"]
                  : []
              }
              relatedToUID={contentLibraryRow.UID}
              moduleName={RAFEntityName.ContentLibrary}
              submitURL="ContentLibrary/Save"
              onSave={refreshOnUpdateTag.bind(this)}
            />
          </div>
        </div>
      </div>
      {state.showVersionHistory && (
        <DialogComponent
          visible={state.showVersionHistory}
          cssClass="rightDialog createEditForm full-height form-center-dialog dlg-new-style"
          height="100%"
          content={renderRelatedSectionContent.bind(this)}
          isModal
          target="body"
          closeOnEscape={false}
          id="manageDocPropertiesDialog"
          header={"Version History"}
          showCloseIcon
          close={closeVersionHistoryDialog.bind(this)}
          zIndex={1500}
        />
      )}
      {state.showAcknowledgementTrackingDlg && (
        <DialogComponent
          visible={state.showAcknowledgementTrackingDlg}
          cssClass="rightDialog rightDialog-lg createEditForm full-height form-center-dialog dlg-new-style"
          id="shiftOffers_dialog"
          content={acknowledgementTrackingDlgContent.bind(this)}
          isModal
          target="body"
          closeOnEscape={false}
          close={acknowledgementTrackingDlgContentClose.bind(this)}
          open={PreventFocusOnDialogOpen}
          zIndex={1200}
        ></DialogComponent>
      )}
    </div>
  );
}

export default React.memo(DocumentPropertiesInfoContent);
