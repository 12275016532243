import { propertyOf } from '../../../RAFComponents/helpers/utils';

//export class ReminderRow extends RAFEntityBase {

export enum ReminderType {
    Email = "Email",
    SMS = "SMS",
    InApp = "Alert In-App",
}

export class IReminderRowDto {
    UID?: string;
    Subject?: string;
    RemindDate?: Date;
    Priority?: string;
    Status?: string;
    Description?: string;
    RelatedToUID?: string;
    RelatedTo?: string;
    RelatedToType?: string;
    ReminderType?: string;
    ReminderUnits?: string;
    ReminderUnitsType?: string;
    ReminderActions?: string;
    RecipientUID?: string;
    Recipient?: string;
    RecipientType?: string;

    CreatedDate?: Date;
    CreatedByUID?: string;
    CreatedBy?: string;
    ModifiedDate?: Date;
    ModifiedByUID?: string;
    ModifiedBy?: string;
    RecordOwnerUID?: string;
    RecordOwner?: string;
    LastActivityDate?: Date;
}

export class IReminderRow {
    UID?: string;
    Subject?: string;
    RemindDate?: Date;
    Priority?: string;
    Status?: string;
    Description?: string;
    RelatedToUID?: string;
    RelatedTo?: string;
    RelatedToType?: string;
    ReminderType?: string;
    ReminderUnits?: string;
    ReminderUnitsType?: string;
    ReminderActions?: string;
    RecipientUID?: string;
    Recipient?: string;
    RecipientType?: string;

    CreatedDate?: Date;
    CreatedByUID?: string;
    CreatedBy?: string;
    ModifiedDate?: Date;
    ModifiedByUID?: string;
    ModifiedBy?: string;
    RecordOwnerUID?: string;
    RecordOwner?: string;
    LastActivityDate?: Date;
}

export class ReminderRow extends IReminderRow {
    getClassName?() {
        return 'reminder';
    }
    getIdField?(): string {
        return propertyOf<ReminderRow>("UID");
    }
    getListUrl?(): string {
        return "Reminder/List";
    }
    getSaveUrl?(): string {
        return "Reminder/Save";
    }
    getRelatedListUrl?(): string {//use route name(entity displayname)
        return "Reminder/RelatedList";
    }

}
