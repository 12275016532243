import { RAFCustomFilter, RAFCustomOperator } from "../../../../RAFComponents/RAFViewPanels/RAFFilterColumn/RAFCustomFilter";
import { getSaveRequest, isNotEmptyArray, isNotNullAndUndefined, propertyOf } from "../../../../RAFComponents/helpers/utils";
import { ListServiceRequest } from "../../../../RAFComponents/models/Common/ListRequest";
import * as repositoryActions from "../../../../RAFComponents/store/actions/repositoryActions";
import { ContentType } from "../../../../constants/Common/Constants";
import { AcknowledgementTrackingRow, AcknowledgementTrackingSaveRow } from "./AcknowledgementTrackingRow";

export class AcceptAcknowledgementTrackingRow {
    SignedUser?: string;
    SignedDate?: Date;
    Signature?: string;
    SignatureInputText?: string;
    Status?: string;
    SignatureInputType?: 'Auto' | 'Draw';
    IAccept?: boolean;

    Browser?: string;
    IpAddress?: string;
    OperatingSystem?: string;
}

export const getAllAcknowledgementTrackingListByDocumentUID = (documentUID: string) => {
    return new Promise<AcknowledgementTrackingRow[]>((resolve) => {
        if (isNotNullAndUndefined(documentUID)) {

            let relatedFilter: RAFCustomFilter = {};
            relatedFilter.Condition = "and";
            relatedFilter.Rules = [];

            let filter1: RAFCustomFilter = {};
            let filterVal1: string[] = [];
            filterVal1.push(documentUID);
            filter1.Operator = RAFCustomOperator.Equal;
            filter1.Value = filterVal1;
            filter1.Field = propertyOf<AcknowledgementTrackingRow>("DocumentUID");
            relatedFilter.Rules.push(filter1);

            const listRequest: ListServiceRequest = new ListServiceRequest();
            listRequest.Skip = 0;
            listRequest.Take = 0;

            listRequest.CustomFilter = relatedFilter;

            return repositoryActions
                .postDataAndGetResponse(
                    "AcknowledgementTracking/List",
                    listRequest,
                    null,
                    ContentType.applicationJson
                )
                .then((response) => {
                    if (isNotNullAndUndefined(response) && isNotNullAndUndefined(response.data) && isNotNullAndUndefined(response.data.Entities)) {
                        resolve(response.data.Entities);
                    } else {
                        resolve(null);
                    }

                });
        } else {
            resolve([]);
        }
    });
};

export const getRecipientsByAcknowledgementTrackingUID = (uid: string) => {
    return new Promise<AcknowledgementTrackingRow[]>((resolve) => {
        if (isNotNullAndUndefined(uid)) {
            return repositoryActions
                .postDataAndGetResponse(
                    "AcknowledgementTracking/ListRecipients",
                    {
                        "EntityId": uid
                    },
                    null,
                    ContentType.applicationJson
                )
                .then((response) => {
                    if (isNotNullAndUndefined(response) && isNotNullAndUndefined(response.data) && isNotNullAndUndefined(response.data.Entities)) {
                        resolve(response.data.Entities);
                    } else {
                        resolve(null);
                    }

                });
        } else {
            resolve([]);
        }
    });
};

export const getRecipientsByAcknowledgementTrackingDocumentUID = (documentUID: string) => {
    return new Promise<{ acknowledgementTrackingItemRow: AcknowledgementTrackingRow, recipientsAcknowledgementTrackingList: AcknowledgementTrackingRow[]; }>(async (resolve) => {
        const allAcknowledgementTrackingListItemRow = await getAllAcknowledgementTrackingListByDocumentUID(documentUID);
        const acknowledgementTrackingItemRow = isNotEmptyArray(allAcknowledgementTrackingListItemRow) ? allAcknowledgementTrackingListItemRow[0] : null;
        const recipientsAcknowledgementTrackingList = isNotNullAndUndefined(acknowledgementTrackingItemRow)
            ? await getRecipientsByAcknowledgementTrackingUID(acknowledgementTrackingItemRow.UID) : null;
        resolve({ acknowledgementTrackingItemRow, recipientsAcknowledgementTrackingList });
    });
};

export const getAllAcknowledgementTrackingListBySignedByUID = (signedByUID: string, status: string) => {
    return new Promise<AcknowledgementTrackingRow[]>((resolve) => {
        if (isNotNullAndUndefined(signedByUID)) {

            let relatedFilter: RAFCustomFilter = {};
            relatedFilter.Condition = "and";
            relatedFilter.Rules = [];

            let filter1: RAFCustomFilter = {};
            let filterVal1: string[] = [];
            filterVal1.push(signedByUID);
            filter1.Operator = RAFCustomOperator.Equal;
            filter1.Value = filterVal1;
            filter1.Field = propertyOf<AcknowledgementTrackingRow>("SignedByUID");
            relatedFilter.Rules.push(filter1);

            if (isNotNullAndUndefined(status)) {
                let filter2: RAFCustomFilter = {};
                let filterVal2: string[] = [];
                filterVal2.push(status);
                filter2.Operator = RAFCustomOperator.Equal;
                filter2.Value = filterVal2;
                filter2.Field = propertyOf<AcknowledgementTrackingRow>("Status");
                relatedFilter.Rules.push(filter2);
            }

            const listRequest: ListServiceRequest = new ListServiceRequest();
            listRequest.Skip = 0;
            listRequest.Take = 0;

            listRequest.CustomFilter = relatedFilter;

            return repositoryActions
                .postDataAndGetResponse(
                    "AcknowledgementTracking/ListRecipients",
                    listRequest,
                    null,
                    ContentType.applicationJson
                )
                .then((response) => {
                    if (isNotNullAndUndefined(response) && isNotNullAndUndefined(response.data) && isNotNullAndUndefined(response.data.Entities)) {
                        resolve(response.data.Entities);
                    } else {
                        resolve(null);
                    }

                });
        } else {
            resolve([]);
        }
    });
};

export const saveMultipleAcknowledgementTrackingItems = (submitAcknowledgedItemRow: AcknowledgementTrackingSaveRow) => {
    return new Promise<boolean>((resolve) => {
        if (isNotNullAndUndefined(submitAcknowledgedItemRow)) {
            const saveRequest = getSaveRequest(submitAcknowledgedItemRow, submitAcknowledgedItemRow.UID);
            repositoryActions
                .postDataAndGetResponse(
                    "AcknowledgementTracking/SaveList",
                    saveRequest,
                    null,
                    ContentType.applicationJson
                )
                .then((response) => {
                    if (isNotNullAndUndefined(response) && isNotNullAndUndefined(response.data)) {
                        resolve(true);
                    } else {
                        resolve(false);
                    }
                });
        } else {
            resolve(false);
        }
    });
};

export const saveAcknowledgementTrackingRow = (submitAcknowledgedRow: AcknowledgementTrackingRow) => {
    return new Promise<boolean>((resolve) => {
        if (isNotNullAndUndefined(submitAcknowledgedRow)) {
            const saveRequest = getSaveRequest(submitAcknowledgedRow, submitAcknowledgedRow.UID);
            repositoryActions
                .postDataAndGetResponse(
                    "AcknowledgementTracking/Save",
                    saveRequest,
                    null,
                    ContentType.applicationJson
                )
                .then((response) => {
                    if (isNotNullAndUndefined(response) && isNotNullAndUndefined(response.data)) {
                        resolve(true);
                    } else {
                        resolve(false);
                    }
                });
        } else {
            resolve(false);
        }
    });
};