import { MenuItemModel } from "@syncfusion/ej2-navigations";
import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import { DialogComponent, DialogUtility } from "@syncfusion/ej2-react-popups";
import * as React from "react";
import { PropsWithChildren, useContext } from "react";
import { Field, FormRenderProps } from "react-final-form";
import { useNavigate } from "react-router-dom";
import "../../../../RAFComponents/Inputs/InputStyle.scss";
import RAFFieldLabel from "../../../../RAFComponents/Inputs/RAFFieldLabel";
import {
  RAFFieldError,
  composeValidators,
} from "../../../../RAFComponents/Inputs/RAFForm";
import {
  RAFDefaultFieldProps,
  RAFFieldProps,
  RAFFormContext,
  isRequired,
} from "../../../../RAFComponents/Inputs/RFFUtils";
import RAFRecordInfo from "../../../../RAFComponents/Navigation/RAFRecordInfo";
import { RAFAttributesContext } from "../../../../RAFComponents/Providers/RAFAttributeRelatedListProvider";
import {
  DeleteRecord,
  hideProgress,
  showProgress,
} from "../../../../RAFComponents/helpers/AppHelper";
import {
  getFileExtension,
  isNotNullAndUndefined,
  isNullOrUndefined,
} from "../../../../RAFComponents/helpers/utils";
import {
  RAFButtonConstant,
  RAFDocumentType,
} from "../../../../constants/Common/Constants";
import { RAFEntityName } from "../../../../constants/Common/EntityConstants";
import { RAFEntityContext } from "../../../Common/Providers/RAFEntityProvider";
import { RAFObjectContext } from "../../../Common/Providers/RAFObjectContextProvider";
import { ContentLibraryRow } from "../Library/ContentLibraryRow";
import {
  downloadDocumentFile,
  getFileSize,
  getRelatedDocumentsByRelatedObject,
} from "../Library/DocumentHelper";
import RAFManageUploadDocumentFile from "../ManageDocument/RAFManageUploadDocumentFile";
//const RAFTextBox = ({ field, label, required }) => {

interface IState {
  showUploadContent: boolean;
  relatedDocumentsList: ContentLibraryRow[];
}

function RAFDocFileUploader<T>({
  field,
  label,
  children,
  labelClassName,
  description,
  required = RAFDefaultFieldProps.required,
  showLabel = RAFDefaultFieldProps.showLabel,
  disabled = RAFDefaultFieldProps.disabled,
  showClearButton = RAFDefaultFieldProps.showClearButton,
  validate = RAFDefaultFieldProps.validate,
  ...props
}: PropsWithChildren<RAFFieldProps<T>>) {
  const rafFormContextValue: FormRenderProps = useContext(RAFFormContext);
  const rafObject = useContext(RAFObjectContext);
  const entityRow = useContext(RAFEntityContext);
  const entity = entityRow.entity;
  const attributeContext = useContext(RAFAttributesContext);
  const queryAttributes = attributeContext.queryAttributes;
  let selectedField =
    queryAttributes &&
    queryAttributes.find((x) => x.PropertyName === field.toString());
  let deleteDialog;
  const [state, setState] = React.useReducer<
    React.Reducer<IState, Partial<IState>>
  >((state, newState) => ({ ...state, ...newState }), {
    showUploadContent: false,
    relatedDocumentsList: [],
  });
  React.useEffect(() => {
    getRelatedDocuments();
  }, [field.toString()]);

  const getRelatedDocuments = async () => {
    let relatedDocumentsList = await getRelatedDocumentsByRelatedObject(
      rafObject.objectId,
      selectedField && selectedField.AttributeUID,
      entity.EntityName
    );

    setState({ relatedDocumentsList });
  };

  const uploadAndLinkDocumentContent = () => {
    if (state.showUploadContent === true) {
      console.log("rafObj", rafObject);
      console.log("selectedField", selectedField);
      return (
        <RAFManageUploadDocumentFile
          onClose={uploadDocumentDialogClose}
          selectedFolder={null}
          selectedDocumentRow={null}
          relatedToUID={rafObject.objectId}
          relatedTo={rafObject.objectName}
          relatedToType={entity.EntityName}
          relatedEntity={entity.DisplayName}
          secondaryRelatedTo={
            isNotNullAndUndefined(selectedField)
              ? selectedField.PropertyName
              : null
          }
          secondaryRelatedToUID={
            isNotNullAndUndefined(selectedField)
              ? selectedField.AttributeUID
              : null
          }
          mode={"create"}
          isActive
          {...(isNullOrUndefined(rafObject.objectId)
            ? { saveContextObject: rafObject.saveObject }
            : {})}
          {...(isNullOrUndefined(rafObject.objectId)
            ? {
              contextObject:
                rafFormContextValue && rafFormContextValue.values,
            }
            : {})}
          onUploadCompleted={(id) => onUploadDocumentCompleted(id)}
        />
      );
    } else {
      return <div></div>;
    }
  };

  const uploadDocumentDialogOpen = () => {
    if (disabled !== true) setState({ showUploadContent: true });
  };

  const uploadDocumentDialogClose = () => {
    setState({ showUploadContent: false });
  };

  let navigate = useNavigate();

  const onUploadDocumentCompleted = (id: string) => {
    if (isNullOrUndefined(rafObject.objectId)) {
      navigate(`/RAF/Update/${entity.EntityName}/${id}`);
    } else {
      setState({ showUploadContent: false });
      getRelatedDocuments();
    }
  };

  const downloadDocument = (docLibraryRow) => {
    let progressDiv = showProgress("body", true);
    downloadDocumentFile(docLibraryRow, false, progressDiv);
  };

  function deleteClicked(documentId: string) {
    deleteDialog = DialogUtility.confirm({
      animationSettings: { effect: "Fade" },
      cancelButton: { text: "No" },
      closeOnEscape: false,
      content: "Are you sure want to delete?",
      okButton: { text: "Yes", click: DeleteDocument.bind(this, documentId) },
      showCloseIcon: true,
      title: " Delete",
      position: { X: "center", Y: "center" },
      cssClass: "alert-dialog delete-dialog",
    });
  }

  function DeleteDocument(id) {
    let progressDiv = showProgress(".delete-dialog");

    DeleteRecord(id, RAFEntityName.ContentLibrary)
      .then((response) => {
        hideProgress(progressDiv);
        if (isNotNullAndUndefined(response)) {
          deleteDialog.hide();
          getRelatedDocuments();
        }
      })
      .catch((error) => error);
  }

  const contentLibraryRowTemplate = (data: ContentLibraryRow) => {
    const fieldValue = data.DisplayName;
    const documentType: string = data.DocumentType;
    const updatedBy: string = data.ModifiedBy;
    const isSystem: boolean = data.IsSystem;
    let createdBy = data.CreatedBy;
    let createdDate = data.CreatedDate;
    let modifiedDate = data.ModifiedDate;
    let modifiedBy = data.ModifiedBy;
    let lastActivityDate = data.LastActivityDate;
    let fileClassname;
    let showMoreButton = true;

    let fileSize = getFileSize(data.FileSize);

    const fileNameExtension = data.FileName;
    const fileExtension = getFileExtension(fileNameExtension);
    if (isNotNullAndUndefined(fileExtension)) {
      fileClassname = "fiv-viv fiv-icon-blank fiv-icon-" + fileExtension;
    }

    if (isNotNullAndUndefined(fieldValue)) {
      let menuItems = [];

      if (documentType === RAFDocumentType.Folder) {
        fileClassname = "fiv-viv fiv-icon-folder";
        if (isNotNullAndUndefined(isSystem) && isSystem === true) {
          menuItems = [
            {
              id: "FolderProperties",
              text: "Properties",
              iconCss: "fa fa-info-circle",
            },
          ];
        } else {
          menuItems = [
            {
              id: "FolderProperties",
              text: "Properties",
              iconCss: "fa fa-info-circle",
            },
            { id: "Edit", text: "Edit", iconCss: "fal fa-pencil" },
            { id: "Delete", text: "Delete", iconCss: "fas fa-trash" },
          ];
        }
      } else if (documentType === RAFDocumentType.File) {
        menuItems = [
          { id: "Edit", text: "Edit", iconCss: "fal fa-pencil" },
          {
            id: "UploadVersion",
            text: "Upload New Version",
            iconCss: "fa fa-cloud-upload-alt",
          },
          /*{ id: 'Share', text: 'Share', iconCss: 'fa fa-share-alt' },
                                                            { id: 'Email', text: 'Email', iconCss: 'fas fa-envelope' },*/
          {
            id: "Download",
            text: "Download",
            iconCss: "fa fa-cloud-download-alt",
          },
          { id: "Delete", text: "Delete", iconCss: "fas fa-trash" },
        ];
      } else if (documentType === RAFDocumentType.Page) {
        menuItems = [
          { id: "Edit", text: "Edit", iconCss: "fal fa-pencil" },
          {
            id: "UploadVersion",
            text: "Upload New Version",
            iconCss: "fa fa-cloud-upload-alt",
          },
          /*{ id: 'Share', text: 'Share', iconCss: 'fa fa-share-alt' },
                                                            { id: 'Email', text: 'Email', iconCss: 'fas fa-envelope' },*/
          {
            id: "Download",
            text: "Download",
            iconCss: "fa fa-cloud-download-alt",
          },
          { id: "Delete", text: "Delete", iconCss: "fas fa-trash" },
        ];
      }

      let smMenuItems: MenuItemModel[] = [];

      if (documentType === RAFDocumentType.Folder) {
        fileClassname = "fiv-viv fiv-icon-folder";
        if (isNotNullAndUndefined(isSystem) && isSystem === true) {
          smMenuItems = [
            {
              iconCss: "fa fa-ellipsis-v",
              items: [
                {
                  id: "FolderProperties",
                  text: "Properties",
                  iconCss: "fa fa-info-circle",
                },
              ],
            },
          ];
        } else {
          smMenuItems = [
            {
              iconCss: "fa fa-ellipsis-v",
              items: [
                {
                  id: "FolderProperties",
                  text: "Properties",
                  iconCss: "fa fa-info-circle",
                },
                { id: "Edit", text: "Edit", iconCss: "fal fa-pencil" },
                { id: "Delete", text: "Delete", iconCss: "fas fa-trash" },
              ],
            },
          ];
        }
      } else if (documentType === RAFDocumentType.File) {
        smMenuItems = [
          {
            iconCss: "fa fa-ellipsis-v",
            items: [
              {
                id: "Edit",
                text: "Edit",
                iconCss: "fal fa-pencil",
              },
              {
                id: "UploadVersion",
                text: "Upload New Version",
                iconCss: "fa fa-cloud-upload-alt",
              },
              /*{ id: 'Share', text: 'Share', iconCss: 'fa fa-share-alt' },
                                                                                { id: 'Email', text: 'Email', iconCss: 'fas fa-envelope' },*/
              {
                id: "Download",
                text: "Download",
                iconCss: "fa fa-cloud-download-alt",
              },
              { id: "Delete", text: "Delete", iconCss: "fas fa-trash" },
            ],
          },
        ];
      } else if (documentType === RAFDocumentType.Page) {
        fileClassname = "fiv-viv fiv-icon-blank fiv-icon-html";
        smMenuItems = [
          {
            iconCss: "fa fa-ellipsis-v",
            items: [
              {
                id: "Edit",
                text: "Edit",
                iconCss: "fal fa-pencil",
              },
              {
                id: "UploadVersion",
                text: "Upload New Version",
                iconCss: "fa fa-cloud-upload-alt",
              },
              /*{ id: 'Share', text: 'Share', iconCss: 'fa fa-share-alt' },
                                                                                { id: 'Email', text: 'Email', iconCss: 'fas fa-envelope' },*/
              {
                id: "Download",
                text: "Download",
                iconCss: "fa fa-cloud-download-alt",
              },
              { id: "Delete", text: "Delete", iconCss: "fas fa-trash" },
            ],
          },
        ];
      }

      return (
        <div className="p-2 px-md-3">
          <div className="gridDefault d-flex align-items-center justify-content-between">
            <div
              className="d-flex align-items-center"
              style={{ width: "calc(100% - 42px)" }}
            >
              <div className="d-flex document-item-icon">
                <span className={fileClassname}></span>
              </div>
              <div className="ms-2">
                <div className="detailsLeftCard-title ecllipseFirstLine text-wrap word-break-all text-break">
                  <span className="" style={{ cursor: "pointer" }}>
                    {fieldValue}
                  </span>
                </div>
                <div className="w-100">
                  <div className="detailsLeftCard-second-title">
                    <div className="row gx-2 align-items-center">
                      {/* {isNotNullAndUndefined(updatedBy) ? (
                                                <div className="col-auto detailsLeftCard-second-title-col">
                                                    <div className="detailsLeftCard-second-title-item">
                                                        <RAFDetailsFieldWithIcon
                                                            value={updatedBy}
                                                            iconClassName="fas fa-user"
                                                        ></RAFDetailsFieldWithIcon>
                                                    </div>
                                                </div>
                                            ) : null} */}
                      {isNotNullAndUndefined(fileSize) ? (
                        <div className="col-auto detailsLeftCard-second-title-col">
                          <div className="detailsLeftCard-second-title-item">
                            {fileSize}
                          </div>
                        </div>
                      ) : null}
                      <div className="col-auto detailsLeftCard-second-title-col">
                        <div className="detailsLeftCard-second-title-item">
                          <div
                            className="gridDefault d-flex align-items-center justify-content-between"
                            onClick={(e) => e.stopPropagation()}
                          >
                            <div className="recordInfomsg">
                              <RAFRecordInfo
                                createdBy={createdBy}
                                createdDate={createdDate}
                                modifiedDate={modifiedDate}
                                modifiedBy={modifiedBy}
                                lastActivityDate={lastActivityDate}
                                dropdownBtnClassName="noRowclick"
                              ></RAFRecordInfo>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-auto">
              <ButtonComponent
                type="button"
                //content="Download"
                //cssClass="e-outline e-custombutton custom-button-sm min-width-md"
                cssClass="e-flat mx-1"
                onClick={() => downloadDocument(data)}
                iconCss="fas fa-download"
              ></ButtonComponent>
              <ButtonComponent
                type="button"
                //content="Delete"
                //cssClass="e-outline e-custombutton custom-button-sm min-width-md ms-2"
                cssClass="e-flat mx-1"
                onClick={() => deleteClicked(data.UID)}
                iconCss="fas fa-trash"
              ></ButtonComponent>
            </div>
          </div>
        </div>
      );
    }

    return <div> {fieldValue}</div>;
  };

  return (
    <div
      className={
        isNotNullAndUndefined(props.formGroupClassName)
          ? props.formGroupClassName + " form-group"
          : "form-group"
      }
    >
      <div className="row gy-2 g-0" id={"rafdiv" + field.toString()}>
        {showLabel && showLabel === true && (
          <div className="d-flex">
            {" "}
            <RAFFieldLabel
              field={field}
              label={label}
              required={required}
              labelClassName={labelClassName}
              count={
                isNotNullAndUndefined(state.relatedDocumentsList) &&
                  state.relatedDocumentsList.length > 0
                  ? state.relatedDocumentsList.length
                  : 0
              }
              description={description}
            ></RAFFieldLabel>
          </div>
        )}
        <div className={"col-12"}>
          <Field
            name={field.toString()}
            {...(props.initialValue
              ? { initialValue: props.initialValue }
              : {})}
            //validate={validate === true ? (required && isRequired) : null}
            {...(props.validators
              ? {
                validate:
                  validate === true
                    ? composeValidators(
                      required === true ? isRequired : null,
                      ...props.validators
                    )
                    : null,
              }
              : {
                validate:
                  validate === true
                    ? composeValidators(required === true ? isRequired : null)
                    : null,
              })}
            allowNull
            parse={(value) => (value === "" ? null : value)}
          >
            {({ }) => (
              <div>
                <div>
                  <div className="row align-items-center" id="attachmentDiv">
                    <div className="col-12 d-flex">
                      <ButtonComponent
                        type="button"
                        cssClass="e-small d-none primary-custom-button d-sm-inline-block"
                        iconCss={RAFButtonConstant.Attach.IconCss}
                        disabled={disabled === true ? true : false}
                        onClick={() => uploadDocumentDialogOpen()}
                        content={RAFButtonConstant.Attach.DisplayName}
                      ></ButtonComponent>
                    </div>
                    {isNotNullAndUndefined(state.relatedDocumentsList) &&
                      state.relatedDocumentsList.length > 0 &&
                      state.relatedDocumentsList.map((document) => {
                        return (
                          <div key={document.UID}>
                            {contentLibraryRowTemplate(document)}
                          </div>
                        );
                      })}
                  </div>

                  {props.hideRequiredMessage !== true ? (
                    <RAFFieldError name={field.toString()} />
                  ) : (
                    ""
                  )}
                </div>
              </div>
            )}
          </Field>
        </div>
      </div>

      {state.showUploadContent === true && (
        <DialogComponent
          header={"Upload File"}
          showCloseIcon
          visible={state.showUploadContent}
          cssClass="centerDialog-sm createEditForm form-center-dialog dlg-new-style fixed-header"
          content={uploadAndLinkDocumentContent.bind(this)}
          isModal
          target="body"
          closeOnEscape={false}
          close={uploadDocumentDialogClose.bind(this)}
          //animationSettings={animationSettings}
          id="manageUploadDocumentDialog"
        ></DialogComponent>
      )}
    </div>
  );
}

export default RAFDocFileUploader;
