import moment from "moment";
import React, { Fragment, PropsWithChildren, Reducer, useEffect, useReducer, useRef } from "react";
import { Field, FormRenderProps } from "react-final-form";
import { msalInstance } from "../../../..";
import RAFCheckBox from "../../../../RAFComponents/Inputs/RAFCheckBox";
import RAFChoiceOption from "../../../../RAFComponents/Inputs/RAFChoiceOption";
import RAFForm, { Condition } from "../../../../RAFComponents/Inputs/RAFForm";
import RAFRadioButtonList from "../../../../RAFComponents/Inputs/RAFRadioButtonList";
import RAFSignaturePad from "../../../../RAFComponents/Inputs/RAFSignaturePad";
import RAFTextBox from "../../../../RAFComponents/Inputs/RAFTextBox";
import { getFormValue, setFormValue } from "../../../../RAFComponents/Inputs/RFFUtils";
import CustomCardWidget from "../../../../RAFComponents/Navigation/CustomCardWidget";
import RAFButtonComponent from "../../../../RAFComponents/Navigation/RAFButtonComponent";
import RAFDeletedRecordState from "../../../../RAFComponents/Navigation/RAFDeletedRecordState";
import RAFDetailFieldCustom from "../../../../RAFComponents/Navigation/RAFDetailFieldCustom";
import { showWarningToast } from "../../../../RAFComponents/Utility/RAFToastComponent";
import { hideProgress, showProgress } from "../../../../RAFComponents/helpers/AppHelper";
import { IsNotNullOrWhiteSpace, isNotNullAndUndefined, propertyOf } from "../../../../RAFComponents/helpers/utils";
import ACLoadingPanel from '../../../../components/shared/ACLoadingPanel';
import { MomentFormats, RAFActionStatus, RAFButtonConstant, RAFLayout } from "../../../../constants/Common/Constants";
import { RAFEntityName } from "../../../../constants/Common/EntityConstants";
import { getUserClientInfo } from "../../../../helpers/ACutils";
import { AcceptAcknowledgementTrackingRow, saveAcknowledgementTrackingRow } from "./AcknowledgementTrackingHelper";
import { AcknowledgementTrackingRow } from "./AcknowledgementTrackingRow";
import './AcknowledgementTrackingStyle.scss';

interface IProps {
    acknowledgementTrackingRow: AcknowledgementTrackingRow;
    onSaveSignature?: () => void;
    mode?: 'view' | 'edit';
}

interface IState {
    noContent: boolean;
    isLoading: boolean;
    initialFormValues: AcceptAcknowledgementTrackingRow;
}

function AcknowledgementTrackingSignSignature({
    acknowledgementTrackingRow,
    mode = 'edit',
    ...props
}: PropsWithChildren<IProps>) {
    const moduleName: string = RAFEntityName.AcknowledgementTracking;
    const outerDivId = `acknowledgement_tracking_Outer_Div_${moduleName}`;
    let rafFormRef: FormRenderProps | null;

    const signatureComponentRef = useRef(null);

    const [state, setState] = useReducer<Reducer<IState, Partial<IState>>>(
        (state, newState) => ({ ...state, ...newState }),
        {
            noContent: true,
            isLoading: true,
            initialFormValues: null
        }
    );

    useEffect(() => {
        refresh();
    }, [acknowledgementTrackingRow]);

    const refresh = async () => {
        if (isNotNullAndUndefined(acknowledgementTrackingRow)) {
            setState({ isLoading: true, noContent: false });

            if (mode === 'edit') {
                const clientInfoRow = await getUserClientInfo();

                const initialFormValues: AcceptAcknowledgementTrackingRow = {
                    Status: acknowledgementTrackingRow.Status,
                    SignatureInputType: 'Auto',
                    SignatureInputText: msalInstance.currentUserName,
                    Browser: clientInfoRow.Browser,
                    IpAddress: clientInfoRow.IpAddress,
                    OperatingSystem: clientInfoRow.OperatingSystem,
                    IAccept: false,
                };

                setState({ initialFormValues, isLoading: false });
            } else if (mode === 'view') {
                const initialFormValues = new AcceptAcknowledgementTrackingRow();
                initialFormValues.SignedUser = acknowledgementTrackingRow.SignedBy;
                initialFormValues.SignedDate = acknowledgementTrackingRow.SignedDate;
                initialFormValues.Signature = acknowledgementTrackingRow.DataJson['Signature'];
                initialFormValues.SignatureInputText = acknowledgementTrackingRow.DataJson['SignatureInputText'];
                initialFormValues.Status = acknowledgementTrackingRow.Status;
                initialFormValues.SignatureInputType = acknowledgementTrackingRow.DataJson['SignatureInputType'];
                initialFormValues.IAccept = true;
                initialFormValues.Browser = acknowledgementTrackingRow.DataJson['Browser'];
                initialFormValues.IpAddress = acknowledgementTrackingRow.DataJson['IpAddress'];
                initialFormValues.OperatingSystem = acknowledgementTrackingRow.DataJson['OperatingSystem'];

                setState({ initialFormValues, isLoading: false });
            }
        } else {
            setState({ isLoading: false, noContent: true });
        }
    };


    const onClickSaveBtn = async () => {
        const iAccept = getFormValue(rafFormRef, propertyOf<AcceptAcknowledgementTrackingRow>('IAccept'));
        if (iAccept === true) {
            const formValue: AcceptAcknowledgementTrackingRow = getFormValue(rafFormRef);
            if (isNotNullAndUndefined(formValue) && (IsNotNullOrWhiteSpace(formValue.SignatureInputText) || IsNotNullOrWhiteSpace(formValue.Signature))) {
                let progressDiv = showProgress('.body');
                const submitValue = new AcknowledgementTrackingRow();
                submitValue.UID = acknowledgementTrackingRow.UID;
                submitValue.SignedBy = msalInstance.currentUserName;
                submitValue.SignedByUID = msalInstance.currentUserId;
                submitValue.SignedDate = formValue.SignedDate;
                submitValue.Status = RAFActionStatus.Acknowledged;
                if (isNotNullAndUndefined(acknowledgementTrackingRow.DataJson)) {
                    const dataJson = acknowledgementTrackingRow.DataJson;
                    dataJson['SignatureInputType'] = formValue.SignatureInputType;
                    dataJson['SignatureInputText'] = formValue.SignatureInputText;
                    dataJson['Signature'] = formValue.Signature;
                    dataJson['Browser'] = formValue.Browser;
                    dataJson['IpAddress'] = formValue.IpAddress;
                    dataJson['OperatingSystem'] = formValue.OperatingSystem;
                    submitValue.DataJson = dataJson;
                } else {
                    submitValue.DataJson = {
                        SignatureInputType: formValue.SignatureInputType,
                        SignatureInputText: formValue.SignatureInputText,
                        Signature: formValue.Signature,
                        Browser: formValue.Browser,
                        IpAddress: formValue.IpAddress,
                        OperatingSystem: formValue.OperatingSystem
                    };
                }
                const response = await saveAcknowledgementTrackingRow(submitValue);
                hideProgress(progressDiv);
                if (props.onSaveSignature) {
                    props.onSaveSignature();
                }
            } else {
                showWarningToast('Please provide signature.');
            }
        } else {
            showWarningToast('Please select accept to apply signature.');
        }
    };

    const onChangeSignatureInputText = () => {
        setFormValue(rafFormRef, propertyOf<AcceptAcknowledgementTrackingRow>('IAccept'), false);
        setFormValue(rafFormRef, propertyOf<AcceptAcknowledgementTrackingRow>('SignedUser'), null);
        setFormValue(rafFormRef, propertyOf<AcceptAcknowledgementTrackingRow>('SignedDate'), null);
    };

    const onChangeSignatureType = (value: 'Auto' | 'Draw') => {
        if (value === 'Auto') {
            onDrawBtnClick();
        } else if (value === 'Draw') {
            onClearSignatureButtonClick();
        }
    };

    const onDrawBtnClick = () => {
        setFormValue(rafFormRef, propertyOf<AcceptAcknowledgementTrackingRow>('Signature'), null);
        setFormValue(rafFormRef, propertyOf<AcceptAcknowledgementTrackingRow>('SignatureInputText'), msalInstance.currentUserName);
        setFormValue(rafFormRef, propertyOf<AcceptAcknowledgementTrackingRow>('IAccept'), false);
        setFormValue(rafFormRef, propertyOf<AcceptAcknowledgementTrackingRow>('SignedUser'), null);
        setFormValue(rafFormRef, propertyOf<AcceptAcknowledgementTrackingRow>('SignedDate'), null);
        if (isNotNullAndUndefined(signatureComponentRef) && isNotNullAndUndefined(signatureComponentRef.current)) {
            signatureComponentRef.current.onClearSignatureCall();
            setTimeout(() => {
                if (isNotNullAndUndefined(signatureComponentRef) && isNotNullAndUndefined(signatureComponentRef.current)) {
                    signatureComponentRef.current.reloadSignatureCall();
                }
            }, 100);
        }
    };

    const onClearSignatureButtonClick = () => {
        if (isNotNullAndUndefined(signatureComponentRef) && isNotNullAndUndefined(signatureComponentRef.current)) {
            signatureComponentRef.current.onClearSignatureCall();
        }
        setFormValue(rafFormRef, propertyOf<AcceptAcknowledgementTrackingRow>('Signature'), null);
        setFormValue(rafFormRef, propertyOf<AcceptAcknowledgementTrackingRow>('SignatureInputText'), null);
        setFormValue(rafFormRef, propertyOf<AcceptAcknowledgementTrackingRow>('IAccept'), false);
        setFormValue(rafFormRef, propertyOf<AcceptAcknowledgementTrackingRow>('SignedUser'), null);
        setFormValue(rafFormRef, propertyOf<AcceptAcknowledgementTrackingRow>('SignedDate'), null);
    };

    const onChangeIAccept = (value) => {
        if (value === true) {
            setFormValue(rafFormRef, propertyOf<AcceptAcknowledgementTrackingRow>('SignedUser'), msalInstance.currentUserName);
            setFormValue(rafFormRef, propertyOf<AcceptAcknowledgementTrackingRow>('SignedDate'), new Date());
        } else {
            setFormValue(rafFormRef, propertyOf<AcceptAcknowledgementTrackingRow>('SignedUser'), null);
            setFormValue(rafFormRef, propertyOf<AcceptAcknowledgementTrackingRow>('SignedDate'), null);
        }
    };

    const getFormContent = () => {
        const { initialFormValues } = state;
        return (
            <div className="row gx-0 gy-3">
                <Condition when={propertyOf<AcceptAcknowledgementTrackingRow>('SignatureInputType')} is="Auto">
                    <>
                        <div className="col-md-12">
                            <RAFTextBox
                                field={propertyOf<AcceptAcknowledgementTrackingRow>('SignatureInputText')}
                                label="Signature"
                                placeholder="Type your name to sign"
                                showLabel={false}
                                onChanged={onChangeSignatureInputText}
                                formGroupClassName="mb-0"
                                disabled={mode === 'view' ? true : false}
                            />
                        </div>
                        <div className="col-md-12">
                            <div className="signature_outer_div">
                                <Field name={propertyOf<AcceptAcknowledgementTrackingRow>('SignatureInputText')}>
                                    {({ input }) => {
                                        const signatureInputText = input.value;
                                        return (
                                            IsNotNullOrWhiteSpace(signatureInputText) ? (
                                                <p
                                                    style={{
                                                        fontFamily: 'Dancing Script',
                                                        fontSize: '30px',
                                                        color: '#000',
                                                    }}
                                                    className="mb-0"
                                                >{signatureInputText}</p>
                                            ) : (
                                                <p
                                                    className="mb-0"
                                                >Start typing to see your signature...</p>
                                            )
                                        );
                                    }}
                                </Field>
                            </div>
                        </div>
                    </>
                </Condition>
                <Condition when={propertyOf<AcceptAcknowledgementTrackingRow>('SignatureInputType')} is="Draw">
                    <div className="col">
                        <div className="signature_parent_div"
                            style={{ margin: '-1rem -1rem 0' }}
                        >
                            <RAFSignaturePad
                                ref={signatureComponentRef}
                                showLabel={
                                    false
                                }
                                field={propertyOf<AcceptAcknowledgementTrackingRow>('Signature')}
                                showClearButton={false}
                                rowClassName="g-0"
                                disabled={mode === 'view' ? true : false}
                            />
                        </div>
                    </div>
                </Condition>
                {mode === 'edit' && (
                    <div className="col-12 mt-0">
                        <Field name={propertyOf<AcceptAcknowledgementTrackingRow>('SignatureInputText')}>
                            {({ input }) => {
                                const signatureInputText = input.value;
                                return (
                                    <Field name={propertyOf<AcceptAcknowledgementTrackingRow>('Signature')}>
                                        {({ input }) => {
                                            const signature = input.value;
                                            const disabled = (IsNotNullOrWhiteSpace(signatureInputText) || IsNotNullOrWhiteSpace(signature)) ? false : true;
                                            return (
                                                <Fragment key={disabled.toString()}>
                                                    <RAFCheckBox
                                                        field={propertyOf<AcceptAcknowledgementTrackingRow>('IAccept')}
                                                        label="I accept and acknowledge the document"
                                                        showLabel={true}
                                                        uitype={'lightCheckbox'}
                                                        labelPosition="right"
                                                        inputFieldClassName="col-auto acknowledge_check_box"
                                                        labelClassName="col unset-min-height"
                                                        formGroupClassName="mb-0"
                                                        rowClassName="row g-2 align-items-start"
                                                        disabled={disabled}
                                                        labelClass='unset-min-height'
                                                        onChanged={onChangeIAccept}
                                                    />
                                                </Fragment>
                                            );
                                        }}
                                    </Field>
                                );
                            }}
                        </Field>
                    </div>
                )}
                <div className="col-12">
                    <CustomCardWidget
                        cardClassName="surface_neutral_base"
                    >
                        <div className="row gx-0 gy-3">
                            <div className="col-12">
                                <div className={`subtitle_1 semi_bold`}>
                                    This is a digital representation of the signature.
                                </div>
                            </div>
                            <Field name={propertyOf<AcceptAcknowledgementTrackingRow>('SignedUser')}>
                                {({ input }) => {
                                    return (
                                        <RAFDetailFieldCustom
                                            title="Signed by (Logged-in User):"
                                            value={input.value ?? 'N/A'}
                                            labelDivClassName="col-auto"
                                            valueDivClassName="col-auto"
                                            valueClassName="word-break-unset details-value semi_bold"
                                            rowClassName="gx-2 gy-1 align-items-baseline justify-content-between flex-wrap"
                                            colClassName="col-12"
                                        />
                                    );
                                }}
                            </Field >
                            <Field name={propertyOf<AcceptAcknowledgementTrackingRow>('SignedDate')}>
                                {({ input }) => {
                                    return (
                                        <RAFDetailFieldCustom
                                            title="Date Time:"
                                            value={input.value ? moment(input.value).format(MomentFormats.DATETIME) : 'N/A'}
                                            labelDivClassName="col-auto"
                                            valueDivClassName="col-auto"
                                            valueClassName="word-break-unset details-value semi_bold"
                                            rowClassName="gx-2 gy-1 align-items-baseline justify-content-between flex-wrap"
                                            colClassName="col-12"
                                        />
                                    );
                                }}
                            </Field >
                            {isNotNullAndUndefined(initialFormValues) && (
                                <>
                                    <RAFDetailFieldCustom
                                        title="IP Address:"
                                        value={initialFormValues.IpAddress}
                                        labelDivClassName="col-auto"
                                        valueDivClassName="col-auto"
                                        valueClassName="word-break-unset details-value semi_bold"
                                        rowClassName="gx-2 gy-1 align-items-baseline justify-content-between flex-wrap"
                                        colClassName="col-12"
                                    />
                                    <RAFDetailFieldCustom
                                        title="Browser:"
                                        value={initialFormValues.Browser}
                                        labelDivClassName="col-auto"
                                        valueDivClassName="col-auto"
                                        valueClassName="word-break-unset details-value semi_bold"
                                        rowClassName="gx-2 gy-1 align-items-baseline justify-content-between flex-wrap"
                                        colClassName="col-12"
                                    />
                                    <RAFDetailFieldCustom
                                        title="OS:"
                                        value={initialFormValues.OperatingSystem}
                                        labelDivClassName="col-auto"
                                        valueDivClassName="col-auto"
                                        valueClassName="word-break-unset details-value semi_bold"
                                        rowClassName="gx-2 gy-1 align-items-baseline justify-content-between flex-wrap"
                                        colClassName="col-12"
                                    />
                                </>
                            )}
                        </div>
                    </CustomCardWidget>
                </div>
            </div>
        );
    };

    if (state.isLoading === false) {
        if (state.noContent === false) {
            return (
                <RAFForm
                    initialValues={state.initialFormValues}
                    formRef={(g) => {
                        return (rafFormRef = g);
                    }}
                    layout={RAFLayout.TwoColumnLayout}
                    submitOnEnterKey={false}
                    className="h-100"
                >
                    <div className="e-dlg-content-outer" id={outerDivId}>
                        <RAFRadioButtonList
                            field={propertyOf<AcceptAcknowledgementTrackingRow>('SignatureInputType')}
                            label="Type"
                            showLabel={false}
                            validate={false}
                            uitype="tabButton"
                            onChanged={onChangeSignatureType}
                            formGroupClassName="mb-0 border-bottom"
                            disabled={mode === 'view' ? true : false}
                        >
                            <RAFChoiceOption
                                label="Auto-Print Signature"
                                value={'Auto'}
                            />
                            <RAFChoiceOption
                                label="Draw Signature"
                                value={'Draw'}
                            />
                        </RAFRadioButtonList>
                        <div className="e-dlg-body-content overflowX-hidden">
                            {getFormContent()}
                        </div>
                        {mode === 'edit' && (
                            <div className="e-dlg-footerContent">
                                <div className="w-100">
                                    <div className="row gx-2">
                                        <Condition when={propertyOf<AcceptAcknowledgementTrackingRow>('SignatureInputType')} is="Draw">
                                            <div className='col-auto'>
                                                <RAFButtonComponent
                                                    action={RAFButtonConstant.Clear}
                                                    showIcon={false}
                                                    onClick={() => onClearSignatureButtonClick()}
                                                    className='btn_state_warning transparent text-decoration-underline'
                                                ></RAFButtonComponent>
                                            </div>
                                        </Condition>
                                        <div className="col-auto ms-auto">
                                            <Field name={propertyOf<AcceptAcknowledgementTrackingRow>('IAccept')}>
                                                {({ input }) => {
                                                    const isAccepted = input.value;
                                                    return (
                                                        <RAFButtonComponent
                                                            type="button"
                                                            isPrimary
                                                            btnContent='Apply Signature'
                                                            onClick={onClickSaveBtn}
                                                            disabled={!isAccepted}
                                                        />
                                                    );
                                                }}
                                            </Field>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </RAFForm>
            );
        } else if (state.noContent === true) {
            return (
                <div className="container-fluid px-0">
                    <RAFDeletedRecordState
                        title="This item is either deleted or You do not have sufficient privileges to view this item."
                        showBackBtn
                    />
                </div>
            );
        } else {
            return null;
        }
    } else {
        return (
            <div className="container-fluid px-0">
                <ACLoadingPanel loadingText="Loading..." />
            </div>
        );
    }
}

export default React.memo(AcknowledgementTrackingSignSignature);