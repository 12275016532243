import { ButtonComponent, ChipDirective, ChipListComponent, ChipsDirective } from "@syncfusion/ej2-react-buttons";
import {
  DialogComponent
} from "@syncfusion/ej2-react-popups";
import { PropsWithChildren, useRef } from "react";
import { Field } from "react-final-form";
import RAFFieldLabel from "../../../../RAFComponents/Inputs/RAFFieldLabel";
import { RAFFieldError, composeValidators } from "../../../../RAFComponents/Inputs/RAFForm";
import {
  RAFDefaultFieldProps, RAFLookupFieldProps,
  isRequired
} from "../../../../RAFComponents/Inputs/RFFUtils";
import {
  IsNullOrWhiteSpace,
  isNotEmptyArray,
  isNotNullAndUndefined
} from "../../../../RAFComponents/helpers/utils";
import { LookUpRow } from "../../../../RAFComponents/models/CompositeTypes/LookUpRow";
import RAFCategoryMultiSelectTree from "./RAFCategoryMultiSelectTree";

// interface IState {
//   showAddItemDialog: boolean;
// }

function RAFRecordCategoryInput<T>({
  field,
  label,
  width,
  type,
  onChanged,
  textField,
  url,
  customFilter,
  //children,
  moduleName,
  labelClassName,
  titleLocation,

  showFullList = true,
  closeToolTip = true,
  SearchCreateOptionMode = "Default",

  required = RAFDefaultFieldProps.required,
  showLabel = RAFDefaultFieldProps.showLabel,
  disabled = RAFDefaultFieldProps.disabled,
  showClearButton = RAFDefaultFieldProps.showClearButton,
  validate = RAFDefaultFieldProps.validate,
  ...props
}: PropsWithChildren<RAFLookupFieldProps<T>>) {
  let addItemDialogRef = useRef<DialogComponent>(null);

  // const [state, setState] = useReducer<Reducer<IState, Partial<IState>>>(
  //   (state, newState) => ({ ...state, ...newState }),
  //   {
  //     showAddItemDialog: false
  //   }
  // );

  //treeViewDialog start
  const onClickAddItemDialog = () => {
    if (isNotNullAndUndefined(addItemDialogRef) && isNotNullAndUndefined(addItemDialogRef.current) && disabled !== true) {
      addItemDialogRef.current.show();
    }
    // setState({
    //   showAddItemDialog: true
    // });
  };

  function addItemDialogContent() {
    return (
      <RAFCategoryMultiSelectTree
        field={field.toString()}
        lookUpUrl={url}
        moduleName={moduleName}
        onChanged={onChangeFiledValue}
        onClose={onClickCloseAddItemDialog}
      />
    );
  }

  function onChangeFiledValue(args) {
    if (isNotNullAndUndefined(onChanged)) {
      onChanged(args);
    }
  };

  const onClickCloseAddItemDialog = () => {
    if (isNotNullAndUndefined(addItemDialogRef) && isNotNullAndUndefined(addItemDialogRef.current)) {
      addItemDialogRef.current.hide();
    }
    // setState({
    //   showAddItemDialog: false
    // });
  };
  //treeViewDialog end

  const removeItemData = (indexToRemove, input, inputValue) => {
    if (disabled !== true) {
      const itemData = inputValue;
      let itemUpdatedData = [];
      if (isNotNullAndUndefined(itemData)) {
        itemUpdatedData = itemData && [...itemData.filter((_, index) => index !== indexToRemove)];
      }
      input.onChange(itemUpdatedData);
      if (isNotNullAndUndefined(onChanged)) {
        onChanged(JSON.stringify(itemUpdatedData));
      }
    }
  };
  labelClassName = isNotNullAndUndefined(labelClassName)
    ? labelClassName
    : "";
  let rowClassName = isNotNullAndUndefined(props.rowClassName)
    ? `${props.rowClassName} row`
    : "row g-0";
  let inputFieldClassName = isNotNullAndUndefined(props.inputFieldClassName)
    ? props.inputFieldClassName
    : "col-12";
  if (titleLocation === 'Right') {
    rowClassName = `${rowClassName} flex-nowrap gx-2`;
    labelClassName = 'col-auto order-last';
    inputFieldClassName = 'col';
  }
  else if (titleLocation === 'Left') {
    rowClassName = `${rowClassName} flex-nowrap gx-2`;
    labelClassName = 'col-3';
    inputFieldClassName = 'col';
  }
  else if (titleLocation === 'Bottom') {
    labelClassName = 'order-last';
  }

  return (
    <div
      className={
        isNotNullAndUndefined(props.formGroupClassName)
          ? props.formGroupClassName +
          " form-group"
          : "form-group"
      }
    >
      <div className={rowClassName} id={"rafdiv" + field.toString()}>
        {showLabel === true && (
          <RAFFieldLabel field={field} label={label} required={required} labelClassName={labelClassName} />
        )}
        <div className={inputFieldClassName}>
          <Field
            name={field.toString()}
            {...(props.initialValue ? { initialValue: props.initialValue } : {})}
            //validate={validate === true ? (required && isRequired) : null}
            {...(props.validators
              ? {
                validate:
                  validate === true
                    ? composeValidators(
                      required === true ? isRequired : null,
                      ...props.validators
                    )
                    : null,
              }
              : {
                validate:
                  validate === true
                    ? composeValidators(
                      required === true ? isRequired : null
                    )
                    : null,
              })}
            allowNull
            parse={(value) => (value === "" ? null : value)}
          >
            {({ input, meta }) => {
              let fieldValue: LookUpRow[] = input.value;
              return (
                <div>
                  <div className="row gx-3 gy-2 align-items-center customChiplist">
                    {isNotEmptyArray(fieldValue) &&
                      <div className="col-auto">
                        <ChipListComponent id="chip-avatar" //cssClass="e-outline"
                          enableDelete={disabled === true ? false : true}
                          deleted={e => removeItemData(e.index, input, fieldValue)}
                        >
                          <ChipsDirective>
                            {fieldValue.map((item, index) => {
                              return (
                                <ChipDirective key={index} text={item.Value}></ChipDirective>
                              );
                            }
                            )}
                          </ChipsDirective>
                        </ChipListComponent>
                      </div>
                    }
                    <div className="col-auto">
                      {disabled !== true && (
                        <ButtonComponent
                          id="btn_open_add_dialog"
                          content="Add"
                          cssClass="link-button"
                          iconCss="fas fa-plus"
                          type="button"
                          disabled={disabled}
                          onClick={() => onClickAddItemDialog()}
                        ></ButtonComponent>
                      )}
                    </div>
                  </div>
                  {props.hideRequiredMessage !== true ?
                    <div className="col">
                      <RAFFieldError name={field.toString()} />
                    </div>
                    :
                    ""}
                </div>
              );
            }}
          </Field>

          {/* {state.showAddItemDialog && ( */}
          <DialogComponent
            header={!IsNullOrWhiteSpace(label) ? label : field.toString()}
            showCloseIcon
            //visible={state.showAddItemDialog}
            visible={false}
            cssClass="rightDialog createEditForm full-height form-center-dialog"
            content={addItemDialogContent.bind(this)}
            isModal
            target="body"
            closeOnEscape={false}
            overlayClick={onClickCloseAddItemDialog.bind(this)}
            close={onClickCloseAddItemDialog.bind(this)}
            ref={addItemDialogRef}
          ></DialogComponent>
          {/* )} */}
        </div>
      </div>
    </div>
  );
}

export default RAFRecordCategoryInput;
