import moment from "moment";
import { isNotNullAndUndefined, setZeroHours } from "../../../RAFComponents/helpers/utils";
import { TaskReminder, TaskRow } from "../Task/TaskRow";
import { ReminderRow } from "./ReminderRow";

export function getRemindersFromModel(taskRow: TaskRow): ReminderRow[] {
    let remindersRow: ReminderRow[] = [];
    let reminders: TaskReminder[] = taskRow.Reminders;
    reminders && reminders.forEach((item) => {
        if (isNotNullAndUndefined(item.RemindDate) && isNotNullAndUndefined(item.ReminderType)) {
            remindersRow.push({
                UID: item.UID,
                RemindDate: item.RemindDate,
                ReminderType: item.ReminderType,
            });
        }
    });
    return remindersRow;
}

export function getTaskRemindersRow(reminderList: ReminderRow[]): TaskReminder[] {
    let taskReminder: TaskReminder[] = [];
    if (isNotNullAndUndefined(reminderList) && reminderList.length > 0) {
        reminderList.forEach((item) => {
            taskReminder.push({
                UID: item.UID,
                RemindDate: item.RemindDate,
                ReminderType: item.ReminderType,
                //RemindText: generateCustomDate(convertUTCDateToLocalTimezone(item.RemindDate))
            });
        });
    }

    // if (taskReminder.length === 0) {
    //     taskReminder.push({
    //         UID: null,
    //         RemindDate: null,
    //         ReminderType: ReminderType.Email,
    //     });
    // }
    return taskReminder;
}

export function generateCustomDate2(date): string {
    if (isNotNullAndUndefined(date)) {
        if (moment(setZeroHours(moment().toDate())).isSame(date, 'day')) {
            return "0";
        } else if (moment(setZeroHours(moment().add(1, 'days').toDate())).isSame(date, 'day')) {
            return "1";
        } else if (moment(setZeroHours(moment().add(2, 'days').toDate())).isSame(date, 'day')) {
            return "2";
        } else if (moment(setZeroHours(moment().add(7, 'days').toDate())).isSame(date, 'day')) {
            return "7";
        } else if (moment(setZeroHours(moment().add(15, 'days').toDate())).isSame(date, 'day')) {
            return "15";
        } else if (moment(setZeroHours(moment().add(30, 'days').toDate())).isSame(date, 'day')) {
            return "30";
        } else {
            return moment(date).format('DD/MM/YYYY');
        }
    } else {
        return null;
    }
}

export function generateCustomDateText(date): string {
    if (isNotNullAndUndefined(date)) {
        if (moment(setZeroHours(moment().toDate())).isSame(date, 'day')) {
            return "Later today";
        } else if (moment(setZeroHours(moment().add(1, 'days').toDate())).isSame(date, 'day')) {
            return "Tomorrow";
        } else if (moment(setZeroHours(moment().add(2, 'days').toDate())).isSame(date, 'day')) {
            return "Day after tomorrow";
        } else if (moment(setZeroHours(moment().add(7, 'days').toDate())).isSame(date, 'day')) {
            return "Next week";
        }
        // else if (moment(setZeroHours(moment().add(15, 'days').toDate())).isSame(date, 'day')) {
        //     return "After 2 week";
        // } 
        else if (moment(setZeroHours(moment().add(30, 'days').toDate())).isSame(date, 'day')) {
            return "Next month";
        } else {
            return moment(date).format('DD/MM/YYYY');
        }
    } else {
        return null;
    }
}
