import DOMPurify from "dompurify";
import React, { PropsWithChildren } from "react";
import CustomCardWidget from "../../../../RAFComponents/Navigation/CustomCardWidget";
import RAFDetailFieldCustom from "../../../../RAFComponents/Navigation/RAFDetailFieldCustom";
import RAFDetailsValueWithSeparator from "../../../../RAFComponents/Navigation/RAFDetailsValueWithSeparator/RAFDetailsValueWithSeparator";
import RAFRecordInfo from "../../../../RAFComponents/Navigation/RAFRecordInfo";
import RAFTagDetails from "../../../../RAFComponents/helpers/RAFTagDetails";
import { getFileExtension, isNotNullAndUndefined } from "../../../../RAFComponents/helpers/utils";
import { RAFDataType } from "../../../../RAFComponents/models/Common/RAFDataType";
import { BrowserIsDevice, RAFDocumentType, RAFInputType } from "../../../../constants/Common/Constants";
import { RAFEntityName } from "../../../../constants/Common/EntityConstants";
import RAFManageDocumentProperties from "../ManageDocument/RAFManageDocumentProperties";
import { ContentLibraryRow } from "./ContentLibraryRow";
import { getFileSize, viewUploadedDocumentFile } from "./DocumentHelper";

interface IProps {
    contentLibraryRow: ContentLibraryRow;
    realtedWebPageDocument?: React.ReactNode;
    hideRevisionAndStatus?: boolean;
    refreshOnUpdateTag?: () => void;
}

function DocumentLeftCardContent({ ...props }: PropsWithChildren<IProps>) {
    const contentLibraryRow = props.contentLibraryRow;

    const [showManageDocProperties, setShowManageDocProperties] = React.useState(
        false
    );

    let fileClassname;
    const fileNameExtension = contentLibraryRow.FileName;
    const fileExtension = getFileExtension(fileNameExtension);
    const documentType: string = contentLibraryRow.DocumentType;

    if (isNotNullAndUndefined(fileExtension)) {
        fileClassname = "fiv-viv fiv-icon-blank fiv-icon-" + fileExtension;
    }
    if (documentType === RAFDocumentType.Folder) {
        fileClassname = "fiv-viv fiv-icon-folder";
    }
    else if (documentType === RAFDocumentType.Page) {
        fileClassname = "fiv-viv fiv-icon-blank fiv-icon-html";
    }

    const handlePreviewClick = async () => {
        const response = await viewUploadedDocumentFile(props.contentLibraryRow, null);
    };

    const refreshOnUpdateTag = () => {
        if (isNotNullAndUndefined(props.refreshOnUpdateTag)) {
            props.refreshOnUpdateTag();
        }
    };

    const editFileDetails = () => {
        document.body.classList.add("overflow-hidden");
        setShowManageDocProperties(true);
    };

    function manageDocPropertiesContent() {
        if (showManageDocProperties === true) {
            let { documentRow } = this.state;
            return (
                <RAFManageDocumentProperties
                    onUpdate={() => onSaveDocumentPage(documentRow.UID)}
                    onCancel={manageDocPropertiesDialogClose.bind(this)}
                    contentLibraryUID={documentRow.UID}
                    contentLibraryRow={documentRow}
                    isActive
                    {...props}
                />
            );
        } else {
            return <div></div>;
        }
    }

    const manageDocPropertiesDialogClose = () => {
        setShowManageDocProperties(false);
    };

    const onSaveDocumentPage = (uid) => {
        setShowManageDocProperties(false);
        refreshOnUpdateTag();
    };
    return (
        <div className="row gy-3 gx-0">
            <div className="col-12">
                <CustomCardWidget cardContentClassName="p-2">
                    <div className="row gx-2 align-items-center">
                        <div className="col-auto">
                            <CustomCardWidget cardClassName="border-0 surface_neutral_base" removeContentPadding>
                                <div className="profilPhotoDiv-container surface_neutral_base shadow-none">
                                    <div className="profileImg-container profileImg-container-md document-item-icon surface_neutral_base">
                                        <span className={fileClassname}></span>
                                    </div>
                                </div>
                            </CustomCardWidget>
                        </div>
                        <div className="col" style={{ zIndex: '10' }}>
                            <div className="subtitle_1 semi_bold mb-1 ecllipseFirstLine word-break-all">
                                <span>{contentLibraryRow.DisplayName}</span>
                            </div>
                            <div className="d-flex justify-content-between align-items-center">
                                <RAFDetailsValueWithSeparator
                                    outerClassName="h-auto"
                                >
                                    {isNotNullAndUndefined(contentLibraryRow.RecordType) ? (
                                        <RAFDetailFieldCustom
                                            value={
                                                isNotNullAndUndefined(contentLibraryRow.RecordType)
                                                    ? contentLibraryRow.RecordType
                                                    : "Not set"
                                            }
                                            displayValue={contentLibraryRow.RecordType}
                                            title="RecordType"
                                            moduleName={RAFEntityName.ContentLibrary}
                                            isColorOption
                                            mode="discView"
                                            field="RecordType"
                                            showLabel={false}
                                            valueClassName="ecllipseFirstLine ps-1 body_2_light raf-icon-view"
                                        />
                                    ) : ""}
                                    {documentType === RAFDocumentType.File &&
                                        <div className="ecllipseFirstLine body_2_light">
                                            <span>{getFileSize(
                                                contentLibraryRow.FileSize
                                            )}</span>
                                        </div>
                                    }
                                </RAFDetailsValueWithSeparator>
                            </div>
                        </div>
                    </div>
                </CustomCardWidget>
            </div>
            {isNotNullAndUndefined(contentLibraryRow.Notes) &&
                <div className="col-12">
                    <div className="">
                        <div className="body_2_light">Description</div>
                        <div className="mt-1">
                            <CustomCardWidget
                                cardClassName="surface_neutral_base"
                                cardContentClassName="p-2"
                            >
                                <span
                                    className="description-text body_2"
                                    dangerouslySetInnerHTML={{
                                        __html:
                                            contentLibraryRow.Notes !== null
                                                ? DOMPurify.sanitize(contentLibraryRow.Notes)
                                                : undefined,
                                    }}
                                ></span>
                            </CustomCardWidget>
                        </div>
                    </div>
                </div>
            }
            <div className="col-12 raf_line_separator">
            </div>
            <div className="col-12">
                <RAFDetailFieldCustom
                    value={
                        isNotNullAndUndefined(contentLibraryRow.CurrentStatus)
                            ? contentLibraryRow.CurrentStatus
                            : "Not set"
                    }
                    displayValue={contentLibraryRow.CurrentStatus}
                    title="Status"
                    moduleName={RAFEntityName.ContentLibrary}
                    isColorOption
                    field="CurrentStatus"
                    showLabel={true}
                    rowClassName="justify-content-between"
                    labelDivClassName="col-auto"
                    valueDivClassName="col-auto"
                    labelClassName="body_2_light content_neutral_base"
                    valueClassName="subtitle_2 semi_bold ps-1"
                    mode="discView"
                />
            </div>

            <div className="col-12">
                <RAFDetailFieldCustom
                    value={
                        isNotNullAndUndefined(contentLibraryRow.Revision)
                            ? `${contentLibraryRow.Revision}.0.0`
                            : "Not set"
                    }
                    {...isNotNullAndUndefined(contentLibraryRow.Revision) ?
                        {
                            customValue:
                                <div className="col-auto">
                                    <div className="row gx-2 align-items-center">
                                        <div className="col-auto">
                                            <span className="subtitle_2 semi_bold">
                                                {`${contentLibraryRow.Revision}.0.0`}
                                            </span>
                                        </div>
                                        {/* <div className="col-auto">
                                            <RAFButtonComponent
                                                btnContent="View"
                                                className="new_style link-button raf_sm text-decoration-underline"
                                            ></RAFButtonComponent>
                                        </div> */}
                                    </div>
                                </div>
                        }
                        :
                        {}
                    }
                    title="Version"
                    moduleName={RAFEntityName.ContentLibrary}
                    field="Revision"
                    showLabel={true}
                    rowClassName="justify-content-between"
                    labelDivClassName="col-auto"
                    valueDivClassName="col-auto"
                    labelClassName="body_2_light content_neutral_base"
                />
            </div>

            <div className="col-12">
                <RAFDetailFieldCustom
                    value={
                        isNotNullAndUndefined(contentLibraryRow.LastActivityDate)
                            ? contentLibraryRow.LastActivityDate
                            : "Not set"
                    }
                    customValue={
                        <div className="col-auto">
                            <RAFRecordInfo
                                createdDate={contentLibraryRow.CreatedDate}
                                modifiedDate={contentLibraryRow.ModifiedDate}
                                lastActivityDate={contentLibraryRow.LastActivityDate}
                                createdBy={contentLibraryRow.CreatedBy}
                                modifiedBy={contentLibraryRow.ModifiedBy}
                                spanContentClassName={'subtitle_2 semi_bold'}
                            ></RAFRecordInfo>
                        </div>
                    }
                    title="Last Updated"
                    field="LastActivityDate"
                    showLabel={true}
                    rowClassName="justify-content-between"
                    labelDivClassName="col-auto"
                    valueDivClassName="col-auto"
                    labelClassName="body_2_light content_neutral_base"
                />
            </div>
            {false && (
                <div className="col-12">
                    <RAFDetailFieldCustom
                        field="RecordCategory"
                        title="Category"
                        value={
                            contentLibraryRow.RecordCategory
                        }
                        fieldFormat={{ type: RAFDataType.Dropdown, format: RAFInputType.LookupDropdownList }}
                        rowClassName="gy-1"
                    >

                    </RAFDetailFieldCustom>
                </div>
            )}
            {isNotNullAndUndefined(contentLibraryRow.DueDate) ? <>
                <div className="col-12">
                    <RAFDetailFieldCustom
                        field="DueDate"
                        title="Due Date"
                        value={
                            isNotNullAndUndefined(
                                contentLibraryRow.DueDate
                            )
                                ? contentLibraryRow.DueDate
                                : "Not set"
                        }
                        rowClassName="justify-content-between"
                        labelDivClassName="col-auto"
                        valueDivClassName="col-auto"
                        labelClassName="subtitle_3 medium content_neutral_base"
                        fieldFormat={{ type: RAFDataType.Date, format: "DD/MM/YYYY" }}
                    >

                    </RAFDetailFieldCustom>
                </div>
            </> : ""}
            <div className="col-12">
                <div className="custom__card__separator custom__card__separator__light"></div>
            </div>
            <div className="col-12">
                <div className="row gx-0 gy-2">
                    <div className="col-12">
                        <div className="subtitle_2 semi_bold">
                            Tags
                        </div>
                    </div>
                    <div className="col-12">
                        <RAFTagDetails
                            initialTags={
                                isNotNullAndUndefined(contentLibraryRow) &&
                                    isNotNullAndUndefined(contentLibraryRow["TagsListJson"])
                                    ? contentLibraryRow["TagsListJson"]
                                    : []
                            }
                            relatedToUID={contentLibraryRow["UID"]}
                            moduleName={RAFEntityName.ContentLibrary}
                            onSave={refreshOnUpdateTag.bind(this)}
                        />
                    </div>
                </div>
            </div>
            {!BrowserIsDevice && contentLibraryRow.DocumentType === RAFDocumentType.Page && (
                <>
                    <div className="col-12 raf_line_separator">
                    </div>
                    <div className="col-12">
                        {props.realtedWebPageDocument}
                    </div>
                </>
            )}
        </div>
    );
}

export default DocumentLeftCardContent;