import { ButtonComponent, CheckBoxComponent } from "@syncfusion/ej2-react-buttons";
import { TreeViewComponent } from "@syncfusion/ej2-react-navigations";
import * as R from "ramda";
import { PropsWithChildren, Reducer, useContext, useEffect, useReducer } from "react";
import { IconPickerItem } from "react-fa-icon-picker";
import { FormRenderProps } from "react-final-form";
import ACLoadingPanel from "../../../../components/shared/ACLoadingPanel";
import { getAllLookUpList } from "../../../../RAFComponents/helpers/AppHelper";
import { IDialogProps, isNotEmptyArray, isNotNullAndUndefined } from "../../../../RAFComponents/helpers/utils";
import { getFormValue, RAFFormContext, setFormValue } from "../../../../RAFComponents/Inputs/RFFUtils";
import { RAFTreeNodeModel } from "../../../../RAFComponents/models/Common/RAFTreeNodeModel";
import { LookUpRow } from "../../../../RAFComponents/models/CompositeTypes/LookUpRow";
import { DocumentCategoryRow, getAllDocumentCategories } from "../../Document/Library/DocumentHelper";
import "./../../../../styles/treeviewStyle.scss";

interface IProps {
    moduleName: string,
    field: string,
    lookUpUrl: string,
    onChanged: (...args) => void;
}

interface IState {
    isActive: boolean;
    lookUpDataItems: LookUpRow[];
    allDocumentCategory: DocumentCategoryRow[];
}

function RAFCategoryMultiSelectTree({ ...props }: PropsWithChildren<IProps & IDialogProps>) {

    const rafFormContextValue: FormRenderProps = useContext(RAFFormContext);
    const fieldValue = getFormValue(rafFormContextValue, props.field.toString());

    const [state, setState] = useReducer<Reducer<IState, Partial<IState>>>(
        (state, newState) => ({ ...state, ...newState }),
        {
            isActive: false,
            lookUpDataItems: null,
            allDocumentCategory: null,
        }
    );

    useEffect(() => {
        refresh();
    }, []);

    const refresh = async () => {
        const allDocumentCategory = await getAllDocumentCategories();
        let lookUpDataItems = await getAllLookUpList(props.moduleName, props.lookUpUrl);
        setState({ lookUpDataItems, allDocumentCategory, isActive: true });
    };

    function generatePortalTreeDataSource(
        allDocumentCategory: DocumentCategoryRow[],
        fieldValue: LookUpRow[],
    ) {
        let outVal: RAFTreeNodeModel[] = [];
        let inVal: DocumentCategoryRow[] = isNotEmptyArray(allDocumentCategory) ? R.clone(allDocumentCategory) : [];
        let selectedItemsUID = isNotEmptyArray(fieldValue) ? fieldValue.map(x => x.UID) : null;

        inVal.forEach((x) => {
            let isSelected = isNotNullAndUndefined(selectedItemsUID) && selectedItemsUID.includes(x.UID) ? true : false;
            let activeClass = isSelected === true ? "e-list-item e-level-1 user-selected-tree-item e-active e-node-focus" : "e-list-item e-level-1";
            outVal.push({
                id: x.UID,
                text: x.DisplayText,
                parentID: x.ParentUID,
                selected: isSelected,
                htmlAttributes: {
                    class: activeClass,
                },
                iconCss: isNotNullAndUndefined(x.ICon) ? x.ICon : "FaBook",
            });
        });
        //setting has children start
        outVal.forEach((x) => {
            //const tmpChildren = inVal.filter(y => y.ParentUID === x.id);
            const tmpChildren = outVal.filter((y) => y.parentID === x.id);
            if (isNotNullAndUndefined(tmpChildren) && tmpChildren.length > 0) {
                x.hasChildren = true;
                x.expanded = true;
            }
        });
        //setting has children end 

        return outVal;
    }

    function nodeTemplate(
        data: any
    ): JSX.Element {
        const itemID = data["id"];
        //let partnerPortalRow: PartnerPortalRow = partnerPortalList.find((x) => x.UID === itemID);
        let hasChildren = data.hasChildren;
        let documentName: string = data["text"];
        let isSelected: boolean = data["selected"];
        let iconName = data.iconCss;

        return (
            <div
                id={itemID}
                className={isNotNullAndUndefined(hasChildren) && hasChildren === true ? "treeview w-100 hasChildren" : "treeview w-100"}
                onClick={() => onClickItem(itemID)}
            >
                <div className="w-100">
                    <div className="row align-items-center justify-content-between g-2">
                        <div className="col-auto d-flex">
                            <CheckBoxComponent name={documentName} change={(e) => {
                                //console.log('onChange');
                            }}
                                //label={documentName}

                                checked={isSelected} />
                        </div>
                        <div className="col-auto">
                            <div className="ms-1">
                                {isNotNullAndUndefined(iconName) ? (
                                    <IconPickerItem
                                        icon={iconName}
                                        size={18}
                                        color={"inherit"}
                                        containerStyles={{ lineHeight: "16px" }}
                                    />
                                ) : (
                                    <i className="fas fa-books"></i>
                                )}
                            </div>
                        </div>
                        <div className="col d-flex align-items-center overflow-auto">
                            <div className="text-truncate">{documentName}</div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    function onClickItem(itemUID: string) {
        if (isNotEmptyArray(state.lookUpDataItems)) {
            let selectedItem = state.lookUpDataItems.find(x => x.UID === itemUID);
            let isItemExist = isNotEmptyArray(fieldValue) && isNotNullAndUndefined(fieldValue.find(x => x.UID === itemUID)) ? true : false;
            let newFieldValue: LookUpRow[] = [];
            if (isItemExist === true) {
                newFieldValue = fieldValue.filter(x => x.UID !== itemUID);
            } else {
                newFieldValue = fieldValue;
                if (isNotEmptyArray(newFieldValue)) {
                    newFieldValue.push(selectedItem);
                } else {
                    newFieldValue = [selectedItem];
                }
            }
            if (isNotNullAndUndefined(props.onChanged)) {
                props.onChanged(JSON.stringify(newFieldValue));
            }
            setFormValue(rafFormContextValue, props.field.toString(), newFieldValue);
        }
    }

    // function onNodeSelect(e: NodeSelectEventArgs) {
    //     if (e.isInteracted) {
    //         let itemUID = e.nodeData["id"];
    //         if (isNotNullAndUndefined(itemUID)) {
    //             onClickItem(itemUID as string);
    //         }
    //     }
    // }

    const onClickCloseDlg = () => {
        if (props.onClose) {
            props.onClose();
        }
    };

    return (
        state.isActive === true ? (
            <div  >
                <div className="e-dlg-body-content">
                    <TreeViewComponent
                        cssClass="custom-treeview hover-parent-div fixedTemplate"
                        id="tree1"
                        allowDragAndDrop={false}
                        nodeTemplate={nodeTemplate.bind(
                            this,
                        )}
                        fullRowSelect
                        allowMultiSelection={true}
                        //nodeSelected={onNodeSelect}
                        //showCheckBox={true}
                        fields={{
                            dataSource: generatePortalTreeDataSource(
                                state.allDocumentCategory,
                                fieldValue
                            ) as any,
                        }}
                    />
                </div>
                <div className="e-dlg-footerContent ">
                    <ButtonComponent
                        type="button"
                        cssClass="form-custom-button e-flat e-info"
                        onClick={onClickCloseDlg}
                    >
                        OK
                    </ButtonComponent>
                </div>
            </div>
        )
            :
            (
                <div className="container-fluid px-0">
                    <ACLoadingPanel loadingText="Preparing Data..." />
                </div>
            )
    );
}

export default RAFCategoryMultiSelectTree;;