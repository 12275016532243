import { RAFRelatedToRow } from "../../../../RAFComponents/models/Common/RAFRelatedToRow";

export class AcknowledgementTrackingRow {
    UID?: string;
    Description?: string;
    Status?: string;
    RecordType?: string;
    SignedDate?: Date;
    RelatedToUID?: string;
    RelatedTo?: string;
    RelatedToType?: string;
    DocumentUID?: string;
    Document?: string;
    VersionNumber?: number;
    SignedByUID?: string;
    SignedBy?: string;
    RequestedByUID?: string;
    RequestedBy?: string;
    RequestedDate?: Date;
    DataJson?: any;
    Recipients?: RAFRelatedToRow[];
    TrackingId?: string;
    PortalUID?: string;
    Portal?: string;
    CreatedDate?: Date;
    CreatedBy?: string;
    CreatedByUID?: string;
    ModifiedDate?: Date;
    ModifiedBy?: string;
    ModifiedByUID?: string;
    RecordOwner?: string;
    RecordOwnerUID?: string;
}

export class AcknowledgementTrackingSaveRow extends AcknowledgementTrackingRow {
    AcknowledgementRecipients: RAFRelatedToRow[];
}