import { MapperConfiguration, MappingPair } from '@dynamic-mapper/mapper';
import { TaskRow } from '../Task/TaskRow';
import { getRemindersFromModel } from './ReminderHelper';
import { TaskReminderRow } from './TaskReminderRow';

export const ConvertTaskRowToITaskReminderRow = (taskRow: TaskRow): TaskReminderRow => {

    const ConvertTaskRowToTaskReminderRow = new MappingPair<TaskRow, TaskReminderRow>();
    const ConvertTaskRowToTaskReminderRowConfiguration = new MapperConfiguration(cfg => {
        cfg.createAutoMap(ConvertTaskRowToTaskReminderRow, {})
            .forSourceMember("Purpose", opt => opt.ignore())
            .forSourceMember("DueDate", opt => opt.ignore())
            .forSourceMember("ASAPDate", opt => opt.ignore())
            .forSourceMember("RepeatProcesss", opt => opt.ignore());
    });

    const mapper = ConvertTaskRowToTaskReminderRowConfiguration.createMapper();
    return mapper.map(ConvertTaskRowToTaskReminderRow, taskRow);
};

export const generateTaskReminderRow = (taskRow: TaskRow): TaskReminderRow => {
    const IContactDtoToContactRow = new MappingPair<TaskRow, TaskReminderRow>();
    const IContactDtoToContactRowConfiguration = new MapperConfiguration(cfg => {
        cfg.createAutoMap(IContactDtoToContactRow, {
            Reminders: opt => opt.mapFrom(src => getRemindersFromModel(src)),
        });
    });

    const mapper = IContactDtoToContactRowConfiguration.createMapper();
    return mapper.map(IContactDtoToContactRow, taskRow);
};

