import {
  NodeSelectEventArgs,
  TreeViewComponent,
} from "@syncfusion/ej2-react-navigations";
import * as R from "ramda";
import { PropsWithChildren, useContext } from "react";
import { FormRenderProps } from "react-final-form";
import {
  IDialogProps,
  isNotEmptyArray,
  isNotNullAndUndefined,
} from "../../../../RAFComponents/helpers/utils";
import {
  getFormValue,
  RAFFormContext,
  setFormValue,
} from "../../../../RAFComponents/Inputs/RFFUtils";
import { RAFTreeNodeModel } from "../../../../RAFComponents/models/Common/RAFTreeNodeModel";
import { LookUpRow } from "../../../../RAFComponents/models/CompositeTypes/LookUpRow";
import { ContentLibraryRow } from "../Library/ContentLibraryRow";
import { ButtonComponent } from "@syncfusion/ej2-react-buttons";

interface IProps {
  moduleName: string;
  field: string;
  lookUpUrl: string;
  lookUpDataItems: LookUpRow[];
  documentTreeViewItems: ContentLibraryRow[];
  onChanged: (...args) => void;
  rootDocFolderRow: ContentLibraryRow;
}

function RAFDocFolderSelectTree({ ...props }: PropsWithChildren<IProps & IDialogProps>) {
  const rafFormContextValue: FormRenderProps = useContext(RAFFormContext);
  const fieldValueUID = getFormValue(
    rafFormContextValue,
    `${props.field.toString()}UID`
  );

  function generatePortalTreeDataSource(
    documentTreeViewItems: ContentLibraryRow[],
    fieldValueUID: string
  ) {
    let outVal: RAFTreeNodeModel[] = [];
    let inVal: ContentLibraryRow[] = isNotEmptyArray(documentTreeViewItems)
      ? R.clone(documentTreeViewItems)
      : [];
    let selectedItemUID = fieldValueUID;

    inVal.forEach((x) => {
      let isSelected =
        isNotNullAndUndefined(selectedItemUID) &&
          selectedItemUID.includes(x.UID)
          ? true
          : false;
      let activeClass =
        isSelected === true
          ? "e-list-item e-level-1 user-selected-tree-item e-active e-node-focus"
          : "e-list-item e-level-1";
      let parentID = isNotNullAndUndefined(
        inVal.find((y) => y.UID === x.ParentUID)
      )
        ? x.ParentUID
        : null;
      // let displayText = x.IsExternal === true ? "All Folders" : x.DisplayName;
      let displayText = x.DisplayName;
      outVal.push({
        id: x.UID,
        text: displayText,
        parentID: parentID,
        selected: isSelected,
        htmlAttributes: {
          class: activeClass,
        },
        // iconCss: "FaBook",
      });
    });
    //setting has children start
    outVal.forEach((x) => {
      //const tmpChildren = inVal.filter(y => y.ParentUID === x.id);
      const tmpChildren = outVal.filter((y) => y.parentID === x.id);
      if (isNotNullAndUndefined(tmpChildren) && tmpChildren.length > 0) {
        x.hasChildren = true;
        x.expanded = true;
      }
    });
    //setting has children end
    return outVal;
  }

  function nodeTemplate(data: any): JSX.Element {
    const itemID = data["id"];
    //let partnerPortalRow: PartnerPortalRow = partnerPortalList.find((x) => x.UID === itemID);
    let hasChildren = data.hasChildren;
    let displayName: string = data["text"];
    // let iconName = data.iconCss;
    let isSelected: boolean = data["selected"];

    return (
      <div
        id={itemID}
        className={
          isNotNullAndUndefined(hasChildren) && hasChildren === true
            ? "treeview w-100 hasChildren"
            : "treeview w-100"
        }
      //onClick={() => onClickItem(itemID)}
      >
        <div className="w-100">
          <div className="row align-items-center justify-content-between g-2">
            <div className="col-auto d-flex">
              {/* <RadioButtonComponent
                                // className="radioButton-sm"
                                //id={`rbn_${item.value.toString()}`}
                                key={displayName}
                                //label={displayName}
                                //value={displayName}
                                name={displayName}
                                change={(e) => {
                                    //console.log('onCLIck');
                                }} 
                                checked={isSelected} /> */}
              {/* <CheckBoxComponent
                                name={displayName} change={(e) => {
                                    //console.log('onChange');
                                }}
                                //label={documentName}

                                checked={isSelected} /> */}
            </div>
            {/* <div className="col-auto">
                            <div className="treeview-Icon">
                                {isNotNullAndUndefined(iconName) ? (
                                    <IconPickerItem
                                        icon={iconName}
                                        size={16}
                                        color={"inherit"}
                                        containerStyles={{ lineHeight: "16px" }}
                                    />
                                ) : (
                                    <i className="fas fa-books"></i>
                                )}
                            </div>
                        </div> */}
            <div className="col d-flex align-items-center overflow-auto">
              <div className="text-truncate">{displayName}</div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  function onClickItem(itemUID: string) {
    let selectedItem =
      isNotNullAndUndefined(itemUID) && isNotEmptyArray(props.lookUpDataItems)
        ? props.lookUpDataItems.find((x) => x.UID === itemUID)
        : null;
    let newFieldValue;
    let newFieldValueUID;
    if (isNotNullAndUndefined(selectedItem)) {
      newFieldValue = selectedItem.Value;
      newFieldValueUID = selectedItem.UID;
    }

    if (fieldValueUID !== newFieldValueUID) {
      setFormValue(rafFormContextValue, props.field.toString(), newFieldValue);
      setFormValue(
        rafFormContextValue,
        `${props.field.toString()}UID`,
        newFieldValueUID
      );

      if (isNotNullAndUndefined(props.onChanged)) {
        props.onChanged(newFieldValue, newFieldValueUID);
      }
    }
  }

  function onNodeSelect(e: NodeSelectEventArgs) {
    if (e.isInteracted) {
      let itemUID = e.nodeData["id"];
      if (isNotNullAndUndefined(itemUID)) {
        onClickItem(itemUID as string);
      }
    }
  }

  const onClickCloseDlg = () => {
    if (props.onClose) {
      props.onClose();
    }
  };

  return (
    <div>
      <div className="e-dlg-body-content">
        <TreeViewComponent
          cssClass="custom-treeview hover-parent-div fixedTemplate remove-overflow"
          id="tree1"
          allowDragAndDrop={false}
          nodeTemplate={nodeTemplate.bind(this)}
          nodeSelected={onNodeSelect}
          fullRowSelect
          allowMultiSelection={false}
          //showCheckBox={true}
          fields={{
            dataSource: generatePortalTreeDataSource(
              props.documentTreeViewItems,
              fieldValueUID
            ) as any,
          }}
        />
        <div className="e-dlg-footerContent ">
          <ButtonComponent
            type="button"
            cssClass="form-custom-button e-flat e-info"
            onClick={onClickCloseDlg}
          >
            OK
          </ButtonComponent>
        </div>
      </div>
    </div>
  );
}

export default RAFDocFolderSelectTree;
